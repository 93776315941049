<script>
import {
  closeNavigationFlyoutFunction,
  getNavigationGroupsFunction,
  navigationLayerTitleFunction,
} from 'composables/navigation';

export default {
  methods: {
    navigationLayerTitle(groupData) {
      return navigationLayerTitleFunction(groupData, this.$dict);
    },
    getNavigationGroups(navigationSubElements) {
      return getNavigationGroupsFunction(navigationSubElements);
    },
    closeNavigationFlyout() {
      closeNavigationFlyoutFunction(this.$store);
    },
  },
};
</script>
