<template>
  <component
    :is="isAsync && !isMounted ? 'void-icon' : iconName"
    v-if="iconName"
    :alt="alt"
    :class="[
      size ? `icon-${size}x` : null,
      color ? `icon-fi--${color}` : null,
      internalRotation ? `icon-fi--${internalRotation}` : null,
    ]"
    @click="$emit('click')"
  />
</template>

<script>
import arrowsComponent from 'svg-icons/arrows.svg';
import checkComponent from 'svg-icons/check.svg';
import chevronDownComponent from 'svg-icons/chevron-down.svg';
import downloadComponent from 'svg-icons/download.svg';
import filterComponent from 'svg-icons/filter.svg';
import globeComponent from 'svg-icons/globe.svg';
import menuComponent from 'svg-icons/menu.svg';
import messageDouble from 'svg-icons/message-double.svg';
import playComponent from 'svg-icons/play.svg';
import screwComponent from 'svg-icons/screw.svg';
import searchComponent from 'svg-icons/search.svg';
import shoppingCartComponent from 'svg-icons/shopping-cart.svg';
import userComponent from 'svg-icons/user.svg';
import voidComponent from 'svg-icons/void.svg';
import { defineAsyncComponent, useSSRContext } from 'vue';

const camelCase = (component) =>
  component.replace(/(?:^|-)(.)/g, (_match, letter) => letter.toUpperCase());

const basePackage = {
  '/src/assets/svg-icons/arrows.svg': arrowsComponent,
  '/src/assets/svg-icons/check.svg': checkComponent,
  '/src/assets/svg-icons/chevron-down.svg': chevronDownComponent,
  '/src/assets/svg-icons/download.svg': downloadComponent,
  '/src/assets/svg-icons/filter.svg': filterComponent,
  '/src/assets/svg-icons/globe.svg': globeComponent,
  '/src/assets/svg-icons/menu.svg': menuComponent,
  '/src/assets/svg-icons/play.svg': playComponent,
  '/src/assets/svg-icons/screw.svg': screwComponent,
  '/src/assets/svg-icons/search.svg': searchComponent,
  '/src/assets/svg-icons/shopping-cart.svg': shoppingCartComponent,
  '/src/assets/svg-icons/message-double.svg': messageDouble,
  '/src/assets/svg-icons/user.svg': userComponent,
  '/src/assets/svg-icons/void.svg': voidComponent,
};

const components = {
  ...Object.fromEntries(
    Object.entries(
      import.meta.glob([
        'svg-icons/*.svg',
        '!svg-icons/arrows.svg',
        '!svg-icons/check.svg',
        '!svg-icons/chevron-down.svg',
        '!svg-icons/download.svg',
        '!svg-icons/filter.svg',
        '!svg-icons/globe.svg',
        '!svg-icons/menu.svg',
        '!svg-icons/play.svg',
        '!svg-icons/screw.svg',
        '!svg-icons/search.svg',
        '!svg-icons/shopping-cart.svg',
        '!svg-icons/message-double.svg',
        '!svg-icons/user.svg',
        '!svg-icons/void.svg',
      ]),
    ).map(([key, value]) => [
      key.replace(
        /^\/src\/assets\/svg-icons\/(.*)\.svg$/,
        (_match, inKebabCase) => `${camelCase(inKebabCase)}Icon`,
      ),
      defineAsyncComponent({
        loader: value,
        loadingComponent: voidComponent,
        delay: 0,
      }),
    ]),
  ),
  ...Object.fromEntries(
    Object.entries(basePackage).map(([key, value]) => [
      key.replace(
        /^\/src\/assets\/svg-icons\/(.*)\.svg$/,
        (_match, inKebabCase) => `${camelCase(inKebabCase)}Icon`,
      ),
      value,
    ]),
  ),
};

export default {
  name: 'IconFi',
  components,
  props: {
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    rotate: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
  },
  emits: ['click'],
  data() {
    return {
      iconName: '',
      isMounted: false,
      isAsync: false,
    };
  },
  computed: {
    internalRotation() {
      let internalRotation = null;
      if (this.icon === 'chevron-up') {
        internalRotation = 180;
      } else if (this.icon === 'chevron-left') {
        internalRotation = 90;
      } else if (this.icon === 'chevron-right') {
        internalRotation = 270;
      } else if (this.icon === 'arrow-left') {
        internalRotation = 180;
      }
      if (!internalRotation) {
        return this.rotate;
      }
      if (this.rotate) {
        internalRotation = (internalRotation + Number.parseInt(this.rotate)) % 360;
      }
      return String(internalRotation);
    },
  },
  watch: {
    icon: {
      handler(newIcon, oldIcon) {
        if (newIcon !== oldIcon) {
          this.setIconName(newIcon);
        }
        if (IS_SSR) {
          const ctx = useSSRContext();
          if (ctx.modules) {
            ctx.modules.add(`src/assets/svg-icons/${newIcon}.svg`);
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    setIconName(icon) {
      let i = icon;

      if (icon === 'chevron-up') {
        i = 'chevron-down';
      } else if (icon === 'chevron-left') {
        i = 'chevron-down';
      } else if (icon === 'chevron-right') {
        i = 'chevron-down';
      } else if (icon === 'arrow-left') {
        i = 'arrow-right';
      }

      const component = `${i}-icon`;

      const camelCased = camelCase(component);
      const resolvedComponent = components[camelCased];
      if (!resolvedComponent) {
        console.error('unknown icon', i);
        this.iconName = null;
        return;
      }
      this.isAsync = resolvedComponent.name === 'AsyncComponentWrapper';

      this.iconName = component;
    },
  },
};
</script>

<style lang="scss">
@import './icon-fi.scss';
</style>
