const mappings = {
  adminHome: 'users',
  selectCountry: 'globe',
  memolist: 'wishlist',
  login: 'user',
  logout: 'log-out',
  myAccount: 'user',
  retailConnectCart: 'shopping-cart',
  shopCart: 'shopping-cart',
};

export type IconMappingKeys = keyof typeof mappings;

export default mappings;
