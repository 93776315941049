import { computed, watch } from 'vue';
import { useStore } from 'vuex';

import { useFischer } from './fischerPlugin';

export const useDataLayer = () => {
  const store = useStore();
  const fischer = useFischer();

  const cartPositionsCount = computed<number>(() => store.getters['cart/positionsCount']);

  watch(cartPositionsCount, (count) => {
    if (IS_SSR) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      cartItems: count,
    });
  });

  if (!IS_SSR) {
    const data = {
      pageId: fischer.pageId(),
      pageTitle: document.title,
      pageUrl: fischer.uri(),
      pageTemplate: fischer.pageTemplate(),
      userStatus: fischer.user.authenticated() ? 'LoggedIn' : 'LoggedOut',
    };

    const catalogId = fischer.catalogId();
    if (catalogId) {
      // @ts-ignore
      data.catalogId = catalogId;
    }

    const targetGroup = fischer.user.targetGroup();
    if (targetGroup) {
      // @ts-ignore
      data.targetGroup = targetGroup;
    }

    const subTargetGroup = fischer.user.subTargetGroup();
    if (subTargetGroup) {
      // @ts-ignore
      data.subTargetGroup = subTargetGroup;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
};
