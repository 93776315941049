<script>
import axios from 'axios';

export default {
  methods: {
    async postAdminTokenInSession(accessToken, idToken) {
      if (IS_SHOWROOM) {
        this.$fischer.user.setAccessToken(accessToken);
        this.$cookies.set('IdToken', idToken, null, null, null, null, 'Strict');
        return {
          LoginSuccessPage: '/assisted-service/assisted-service-account-management',
        };
      }
      const { data } = await axios.post('/webapi/shop/userapi/AgentLogin', {
        agent: {
          AccessToken: accessToken,
          IdToken: idToken,
        },
      });
      return data;
    },
    async postAssistedServiceToSitecore(assistedService) {
      if (IS_SHOWROOM) {
        return true;
      }
      return axios.post('/webapi/shop/userapi/Impersonate', {
        admin: {
          AccessToken: assistedService.token,
          IdToken: assistedService.idToken,
        },
        email: assistedService.user.uid,
      });
    },
  },
};
</script>
