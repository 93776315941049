<template>
  <picture
    v-if="item?.fields.image?.value?.src"
    :key="isMounted ? 'dom' : 'ssr'"
  >
    <source
      media="(max-width: 767px)"
      :srcset="
        appendParams(
          `${getProxySrc(item.fields.imageMobile?.value?.src || item.fields.image.value.src)}`,
          'h=960&aspect_ratio=16:20&func=crop&org_if_sml=0&gravity=north',
        )
      "
    />
    <source
      media="(max-width: 1023px)"
      :srcset="
        appendParams(
          `${getProxySrc(item.fields.imageTabletSmall?.value?.src || item.fields.image.value.src)}`,
          'h=512&aspect_ratio=16:8&func=crop&org_if_sml=0',
        )
      "
    />
    <source
      media="(max-width: 1439px)"
      :srcset="
        appendParams(
          `${getProxySrc(item.fields.imageTablet?.value?.src || item.fields.image.value.src)}`,
          'h=720&aspect_ratio=16:8&func=crop&org_if_sml=0',
        )
      "
    />
    <img
      :src="
        appendParams(
          `${getProxySrc(item.fields.image.value.src)}`,
          'h=720&aspect_ratio=16:6&func=crop&org_if_sml=0',
        )
      "
      :alt="item.fields.image.value?.alt || item.fields.headline?.value"
    />
  </picture>
</template>

<script setup lang="ts">
import type { HeaderSlide } from 'components/header-fi/header-types';
import { useCloudImage } from 'composables/cloudImage';
import { onMounted, ref } from 'vue';

defineOptions({
  name: 'HeaderMediaTextItemImageFi',
});

const { appendParams, getProxySrc } = useCloudImage();

const isMounted = ref(false);

defineProps<{
  item?: HeaderSlide;
}>();

onMounted(() => {
  isMounted.value = true;
});
</script>
