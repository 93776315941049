import { useFischer } from 'composables/fischerPlugin';
import { inject } from 'vue';
import type { VueCookies } from 'vue-cookies';

import type { FischerPlugin } from '@/lib/fischerPlugin';

// This is the (unmodified) logic copied from vue-cookies.
const vueCookiesParse = (value: string | null | undefined) => {
  if (value?.substring(0, 1) === '{' && value.substring(value.length - 1, value.length) === '}') {
    try {
      value = JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
  return value;
};

export const getCookieFunction: (
  key: string,
  fischer: FischerPlugin,
  cookies: VueCookies,
) => any = (key: string, fischer: FischerPlugin, cookies: VueCookies) => {
  if (IS_SSR) {
    const ssrCookies = fischer.ssrCookies();
    const dataRaw = ssrCookies?.[key];
    if (!dataRaw) {
      return null;
    }
    const data = decodeURIComponent(dataRaw);

    return vueCookiesParse(data);
  }

  return cookies.get(key);
};

export const useSsrCookies = () => {
  const cookies = inject<VueCookies>('$cookies');
  const fischer = useFischer();

  if (!cookies) {
    throw new Error('missing injection $cookies');
  }

  const getCookie = (key: string) => getCookieFunction(key, fischer, cookies);

  return { getCookie };
};
