<template>
  <component
    :is="guessedTagName"
    :id="id"
    :href="href"
    :autofocus="autofocus"
    :disabled="disabled"
    :value="value"
    :name="name"
    :type="type"
    @click="clickHandler"
    @focus="focusHandler"
    @blur="blurHandler"
    @keydown="keydownHandler"
    @mousedown="mousedownHandler"
  >
    <slot>{{ text || field?.value }}</slot>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const definition = {
  name: 'ButtonFi',
  emits: [
    'blur',
    // do not mark this or hrefs won't work: 'click',
    'focus',
    'keydown',
    'mousedown',
    'natural-click',
  ],
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    tagName: {
      type: String,
      default: 'button',
      validator: (value: string): boolean => ['button', 'a'].indexOf(value) !== -1,
    },
    type: {
      type: String,
      default: undefined,
    },
    // Handled attributes
    href: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: undefined,
    },
    field: {
      type: Object,
      default: null,
    },
    naturalEvents: Boolean,
  },
  computed: {
    guessedTagName(): string {
      if (this.href !== undefined && this.href !== null && !this.disabled) {
        return 'a';
      }

      return this.tagName;
    },
  },
  methods: {
    blurHandler(e: FocusEvent) {
      this.$emit('blur', e);
    },
    focusHandler(e: FocusEvent) {
      this.$emit('focus', e);
    },
    clickHandler(e: MouseEvent) {
      if (this.naturalEvents) {
        this.$emit('natural-click', e);
        return;
      }
      e.preventDefault();
      e.stopImmediatePropagation();
      this.$emit('click', e);
    },
    keydownHandler(e: MouseEvent) {
      this.$emit('keydown', e);
    },
    mousedownHandler(e: MouseEvent) {
      this.$emit('mousedown', e);
    },
    blur() {
      (this.$el as HTMLButtonElement).blur();
    },
    focus() {
      (this.$el as HTMLButtonElement).focus();
    },
  },
};

export { definition };
export default defineComponent(definition);
</script>

<style lang="scss">
// NOTE: unscoped, so it also applies to eg. rte
@import './button-fi-unscoped';
</style>
