export type IdTokenData = {
  at_hash: string;
  sub: string;
  iss: string;
  groups: [string];
  salesOrg: string;
  nonce: string;
  division: string;
  aud: string;
  countryIso: string;
  scope: [string];
  sapCustomerId: string;
  name: string;
  state: string;
  exp: number;
  distributionChannel: string;
  iat: number;
  email: string;
};

const decodeUnicode = (str: string) =>
  decodeURIComponent(
    str.replace(/(.)/g, (_m, p) => {
      let code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = `0${code}`;
      }
      return `%${code}`;
    }),
  );

const padBase64 = (str: string) => {
  switch (str.length % 4) {
    case 0:
      return str;
    case 2:
      return `${str}==`;
    case 3:
      return `${str}=`;
    default:
      throw new Error('Illegal base64url string!');
  }
};

const decodeBase64 = (base64: string) => {
  const bin = atob(base64);
  try {
    return decodeUnicode(bin);
  } catch (err) {
    return bin;
  }
};

export default (token: string): IdTokenData => {
  const [, base64Url] = token.split('.');
  const base64 = padBase64(base64Url).replace(/_/g, '/').replace(/-/g, '+');

  return JSON.parse(decodeBase64(base64));
};
