import type { ApolloProvider } from '@vue/apollo-option';
import axios from 'axios';

import type { DictionaryPhrases } from '@/lib/dictPlugin';
import dictPlugin from '@/lib/dictPlugin';
import type { InitialState, SitecoreDictionary } from '@/sitecoreState';

import GETTRANSLATIONSCACHED from './graphql/getTranslationsCached.gql';

export interface SsrDictionaryCachePerId {
  timestamp: string;
  translations: DictionaryPhrases;
}

export interface SsrDictionaryCachePerLang {
  [id: string]: SsrDictionaryCachePerId;
}

export interface SsrDictionaryCache {
  [lang: string]: SsrDictionaryCachePerLang;
}

const ssrDictionaryCache: SsrDictionaryCache = {};

const getTranslationsFromGraphQL = async (
  graphQLProvider: ApolloProvider,
  lang: string,
  dictionary: SitecoreDictionary,
  host: string,
): Promise<DictionaryPhrases> => {
  const { data: translationData } = await graphQLProvider.defaultClient.query({
    query: GETTRANSLATIONSCACHED,
    variables: {
      lang,
      timestamp: dictionary.timestamp,
      host,
      dict: dictionary.id,
    },
  });
  return JSON.parse(translationData.getTranslationsCached.translations);
};

const getTranslationsLocally = async (
  lang: string,
  dictionary: SitecoreDictionary,
  baseUrl: string,
): Promise<DictionaryPhrases> => {
  const { data: translations } = await axios.get(
    `${baseUrl}/api/dictionary/${encodeURIComponent(
      dictionary.id,
    )}?lang=${lang}&timestamp=${encodeURIComponent(dictionary.timestamp)}}`,
    {
      timeout: 5000,
    },
  );
  return translations;
};

const setupDictionary = async (
  lang: string,
  graphQLProvider: ApolloProvider,
  initialState: InitialState | undefined,
  baseUrl: string,
) => {
  try {
    if (IS_SHOWROOM || IS_PAGE_RENDERER) {
      // @ts-ignore
      let fallbackDictionary = (await import('../data/dictionary/de.yaml'))
        .default as DictionaryPhrases;
      if (lang === 'en') {
        const enDictionary = (await import('../data/dictionary/en.json'))
          .default as DictionaryPhrases;
        fallbackDictionary = {
          ...fallbackDictionary,
          ...enDictionary,
        };
      }

      dictPlugin.setData(fallbackDictionary);
    } else {
      let translations: DictionaryPhrases;

      const dictionary: SitecoreDictionary | null | undefined =
        initialState?.sitecore?.settings?.site?.dictionary;
      if (!dictionary) {
        return;
      }

      if (IS_SSR && ssrDictionaryCache[lang]?.[dictionary.id]?.timestamp === dictionary.timestamp) {
        ({ translations } = ssrDictionaryCache[lang][dictionary.id]);
      } else {
        translations = await (IS_SSR ||
        APP_ENVIRONMENT === 'VM' ||
        initialState?.sitecore?.data?.isContentManagement ||
        window.location.pathname.startsWith('/sitecore')
          ? getTranslationsLocally(lang, dictionary, baseUrl)
          : getTranslationsFromGraphQL(graphQLProvider, lang, dictionary, baseUrl));

        if (IS_SSR) {
          dictionary.ssrFetched = true;
          if (!ssrDictionaryCache[lang]) {
            ssrDictionaryCache[lang] = {};
          }
          ssrDictionaryCache[lang][dictionary.id] = {
            translations,
            timestamp: dictionary.timestamp,
          };
        }
      }
      dictPlugin.setData(translations);
    }
  } catch (error: any) {
    dictPlugin.setData({});
    console.error(
      'Could not load translations',
      ['ETIMEDOUT', 'ECONNABORTED'].includes(error?.code) ? error?.code : error,
      error?.config?.url,
    );
  }
};

export default setupDictionary;
