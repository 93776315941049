<template>
  <span
    :class="{
      'country-flag-fi': true,
      'country-flag-fi--small': size === 'small',
    }"
  >
    <country-flag
      v-if="country && isMounted"
      :country="country"
      class="country-flag-fi__country-flag"
      size="big"
      :rounded="rounded"
    />
  </span>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted, ref } from 'vue';
const CountryFlag = defineAsyncComponent(() => import('vue-country-flag-next'));

withDefaults(
  defineProps<{
    country?: string;
    rounded?: boolean;
    size?: string;
  }>(),
  {
    rounded: true,
    size: 'big',
    country: undefined,
  },
);

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>

<style scoped lang="scss">
@import './country-flag-fi';
</style>
