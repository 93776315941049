import JssFormsEventBus from 'jss/jss-forms-event-bus';
import { onMounted } from 'vue';

export interface DataFormElementKeyProps {
  dataFormElementKey?: string;
  dataFormItemId?: string;
}

export default (props: DataFormElementKeyProps) => {
  onMounted(() => {
    if (props.dataFormElementKey) {
      JssFormsEventBus.$emit('forms_init:field-conditions', props.dataFormElementKey);
    }
  });
};
