<template>
  <ul
    v-if="navigationData"
    ref="marker-container"
    class="navigation-bar-fi"
  >
    <li
      id="navigation-bar-fi--marker"
      ref="marker"
    />
    <template v-for="(item, index) in navigationData">
      <li
        v-if="!item.mobileOnly"
        :key="item.id"
        :class="{
          'navigation-bar-fi__item': true,
          'navigation-bar-fi__item--active': isMounted && item.isActiveSelfOrDescendant,
          'marker-mixin-marked': index === navIndex,
        }"
        data-test="navigation"
        :data-test-2="item.label"
        :data-test-3="isProducts(item) ? 'products' : null"
      >
        <template
          v-if="!item.disableSubnavigation && (item.subElements?.length || isProducts(item))"
        >
          <span
            ref="navigationItem"
            class="navigation-bar-fi__item--text"
            @click="toggleFlyout(item, index)"
          >
            {{ item.label }}
          </span>
        </template>
        <template v-else>
          <a
            :href="item.url"
            :target="item.target"
            :rel="item.target === '_blank' ? 'noopener noreferrer' : undefined"
            class="navigation-bar-fi__item--text"
          >
            {{ item.label }}
          </a>
        </template>
      </li>
    </template>
    <template v-for="(navigation, navItemIdx) in serviceNavigation">
      <li
        v-for="(navigationElement, elemIdx) in navigation.elements"
        :key="`${navigation.title}${navigationElement.identifier}`"
        :class="{
          'navigation-bar-fi__item': true,
          'navigation-bar-fi__item--icon': true,
          'navigation-bar-fi__item--active':
            isCountrySelectPage && navigationElement?.identifier === 'selectCountry',
          'navigation-bar-fi__item--focus': !isCountrySelectActive
            ? elemIdx === navIndex
            : navIndex === navigationData.length + 10 * navItemIdx + elemIdx,
          [`navigation-bar-fi__item-${navigation.title}-${navigationElement.identifier}`]: true,
        }"
      >
        <service-navigation-item-fi
          ref="serviceNavigationItem"
          :navigation-element="navigationElement"
          @toggle-flyout-icon="toggleFlyoutIcon(10 * navItemIdx + elemIdx)"
          @login="$emit('login')"
        />
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import ServiceNavigationItemFi from 'components/header-fi/header-top-fi/service-navigation-item-fi/ServiceNavigationItemFi.vue';
import { useMarker } from 'composables/marker';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { useFischer } from '@/composables/fischerPlugin';
import type { ServiceNavigation, SitecoreNavigation } from '@/sitecoreState';

defineOptions({
  name: 'NavigationBarFi',
});

const props = withDefaults(
  defineProps<{
    navigationData?: SitecoreNavigation[];
    serviceNavigation: ServiceNavigation[];
  }>(),
  {
    navigationData: () => [],
  },
);

defineEmits<{
  login: [value: void];
}>();

const store = useStore();
const { animateMarker } = useMarker();
const fischer = useFischer();

const isMounted = ref<boolean>(false);

const isCountrySelectActive = computed(() => store.getters['navigation/isCountrySelect']);
const isCountrySelectPage = computed(() => store.getters['navigation/isCountrySelectPage']);

const showFlyout = computed({
  get: () => store.getters['navigation/getFlyout'],
  set: (showFlyout) => store.commit('navigation/SHOWFLYOUT', showFlyout),
});

const navIndex = computed({
  get: () => store.getters['navigation/getNavIndex'],
  set: (navIndex) => store.commit('navigation/NAVINDEX', navIndex),
});

const subElements = computed({
  get: () => store.getters['navigation/getSubElements'],
  set: (subElements) => store.commit('navigation/SUBELEMENTS', subElements),
});

const groupData = computed({
  get: () => store.getters['navigation/getGroupData'],
  set: (groupData) => store.commit('navigation/GROUPDATA', groupData),
});

const showBrandArea = computed({
  get: () => store.getters['navigation/showBrandArea'],
  set: (showBrandArea) => store.commit('navigation/SHOWBRANDAREA', showBrandArea),
});

const changeMarker = async () => {
  await nextTick();
  animateMarker();
};

watch(showFlyout, (newState: boolean, oldState: boolean) => {
  if (!newState) {
    navIndex.value = -1;
    groupData.value = {};
    subElements.value = [];
    showBrandArea.value = true;
  }
  if (newState !== oldState) {
    changeMarker();
  }
});

watch(() => props.navigationData, changeMarker);

watch(isCountrySelectPage, (value) => {
  if (value) {
    animateMarker();
  }
});

watch(navIndex, animateMarker);

onMounted(() => {
  isMounted.value = true;
  if (navIndex.value) {
    changeMarker();
  }
});

const toggleFlyout = (item: SitecoreNavigation, index: number) => {
  if (index === navIndex.value) {
    showFlyout.value = false;
  } else {
    showFlyout.value = true;
    navIndex.value = index;
    groupData.value = item;
    subElements.value = item.subElements;
    showBrandArea.value = false;
  }
  store.dispatch('navigation/showSearchSuggest', false);
  store.commit('navigation/IS_COUNTRY_SELECT', false);
  store.commit('navigation/ISPRODUCTS', item.url === fischer.pages.products().url);
  store.commit('navigation/ISCOMPANY', item.url === fischer.pages.companies().url);
};

const toggleFlyoutIcon = (index: number) => {
  navIndex.value = props.navigationData.length + index;

  return changeMarker();
};

const isProducts = (item: SitecoreNavigation) => item.url === fischer.pages.products().url;
</script>

<style lang="scss" scoped>
@import './navigation-bar-fi';
</style>
