import axios from 'axios';
import { useFischer } from 'composables/fischerPlugin';
import { computed } from 'vue';
import { useStore } from 'vuex';

import type { LinkFieldValue } from '@/sitecoreFieldTypes';

export interface Locale {
  name: string;
  language: string;
  country: string;
  code: string;
  current: boolean;
  url: string;
}

export interface Language {
  Name: string;
}

export interface Localization {
  iso_Code: string;
  name: string;
  id: string;
  displayName: string;
  language: Language;
  code: string;
}

export interface Country {
  flag_icon: string;
  link: LinkFieldValue;
  localizations: Localization[];
  title: string;
  value: string;
  id: string;
  name: string;
  displayName: string;
  language: Language;
}

export interface LocaleData {
  locales?: Locale[];
  country?: Country;
}

export const useCountrySelect = () => {
  const store = useStore();
  const fischer = useFischer();

  const isSingleLocale = computed<boolean>(() => store.getters['navigation/isSingleLocale']);
  const currentLocaleData = computed<LocaleData | null>(
    () => (store.getters['navigation/getCurrentLocaleData'] as LocaleData) || null,
  );

  const localizations = computed<Localization[]>(
    () => currentLocaleData.value?.country?.localizations || [],
  );

  const currentLocale = computed<Locale | undefined>(() =>
    currentLocaleData.value?.locales?.find((l) => l.current),
  );

  const getLocaleData: () => Promise<void> = async () => {
    if (!currentLocaleData.value) {
      const api = IS_SHOWROOM
        ? '/__mocks__/sitecoreApi/api/i18n/locales.json'
        : `/api/i18n/locales/${fischer.pageId()}`;
      const params = {
        pageId: fischer.pageId(),
        language: fischer.siteLanguage(),
      };
      const { data } = await axios.get(api, IS_SHOWROOM ? undefined : { params });

      store.commit('navigation/SET_CURRENT_LOCALE_DATA', data);
    }

    const newIsSingleLocale = localizations.value.length <= 1;
    store.commit('navigation/IS_SINGLE_LOCALE', newIsSingleLocale);
  };

  const countrySelectUrl = (path: string | undefined) => {
    const url = IS_SHOWROOM
      ? `${window.location.origin}/country-selection`
      : `${window.location.origin}${path}`;
    if ((!IS_SHOWROOM && !path) || window.location.href === url) {
      return null;
    }

    return url;
  };

  return {
    isSingleLocale,
    localizations,
    currentLocale,
    getLocaleData,
    countrySelectUrl,
    currentLocaleData,
  };
};
