<script lang="ts">
import Key from './Key';

export default {
  emits: ['change', 'input', 'update:modelValue'],
  watch: {
    fallback(newValue) {
      this.$emit('input', newValue);
    },
    modelValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    clickHandler(evt: MouseEvent) {
      if (evt?.detail !== 1) {
        return;
      }
      this.toggle();
    },
    toggle() {
      if (this.disabled) {
        return;
      }
      if (this.modelValue === undefined) {
        this.fallback = !this.fallback;
        this.$emit('change', this.fallback);
        return;
      }

      let localValue;
      if (Array.isArray(this.modelValue)) {
        localValue = [...this.modelValue];
        if (this.isChecked) {
          localValue = this.modelValue.filter((value) => value !== this.value);
        } else if (!this.modelValue.includes(this.value)) {
          localValue = [...this.modelValue, this.value];
        }
      } else {
        localValue = Boolean(this.isChecked ? this.uncheckedValue : this.value);
      }

      this.$emit('update:modelValue', localValue);
    },
    clickHandlerWrapper(evt) {
      if (evt?.detail !== 1) {
        return;
      }
      this.toggle();
    },
    onWrapperKeydown(e: KeyboardEvent) {
      if (e.keyCode === Key.SPACE) {
        this.wrapperSpaceHandler(e);
      }
    },
    wrapperSpaceHandler(e: KeyboardEvent) {
      e.preventDefault();
      this.click();
    },
  },
};
</script>
