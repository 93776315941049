<script>
import {
  appendParams,
  cloudImageDomain,
  getLocalHostAlias,
  getLocalMediaRegex,
  isCloudimageAlias,
  replacements,
} from 'composables/cloudImage';

export default {
  data() {
    return {
      cloudImageDomain,
    };
  },
  computed: {
    hostnameRegex() {
      return new RegExp(`^https?://(?:www\\.)?${this.$fischer.hostname()}`);
    },
    localHostAlias() {
      return getLocalHostAlias(this.$fischer);
    },
  },
  methods: {
    getProxySrc(url) {
      if (!url) {
        return null;
      }
      if (url.startsWith(cloudImageDomain)) {
        return url;
      }
      const alias = this.getCloudImageAlias(url);
      return `${cloudImageDomain}${alias}`;
    },
    getCloudImageAlias(url) {
      if (this.isCloudimageAlias(url)) {
        return url;
      }

      if (IS_SHOWROOM && (url.startsWith('/data/media') || url.startsWith('/img/'))) {
        return `${this.localHostAlias}${url}`;
      }

      if (
        IS_SHOWROOM &&
        (url.startsWith('https://www.fischer.de/de-de/-/media/national/samples/social/') ||
          url === 'https://www.fischer.de/de-de/-/media/national/samples/logos/logo-footer.ashx')
      ) {
        return url;
      }

      const localMediaRegex = getLocalMediaRegex(this.$fischer);
      if (localMediaRegex.test(url)) {
        return url.replace(localMediaRegex, this.localHostAlias);
      }
      if (this.hostnameRegex.test(url)) {
        const shortened = url.replace(this.hostnameRegex, '').replace(localMediaRegex, '');
        return `${this.localHostAlias}${shortened}`;
      }
      for (let key in replacements) {
        if (url.startsWith(key)) {
          const replaced = url.replace(key, replacements[key]);
          console.debug('replacing', key, replaced);
          return replaced;
        }
      }
      console.debug('Unhandled url', url, this.$fischer.hostname(), this.$fischer.isCM());
      return url;
    },
    appendParams(src, newParams) {
      return appendParams(src, newParams);
    },
    isCloudimageAlias(url) {
      return isCloudimageAlias(url);
    },
  },
};
</script>
