<template>
  <ssr-cloud-image-fi
    class="logo-header-fi fischer-brand-logo"
    :src="logoHeader.src"
    :alt="logoHeader.alt ? logoHeader.alt : 'Logo Image'"
    :title="logoHeader.title ? logoHeader.title : undefined"
    :breakpoints="breakpoints"
    :unlimited-height="unlimitedHeight"
    :unlimited="unlimited"
    params="func=bound"
  />
</template>

<script setup lang="ts">
import SsrCloudImageFi, {
  type BreakPoints,
} from 'components/atoms/ssr-cloud-image-fi/SsrCloudImageFi.vue';
import { useFischer } from 'composables/fischerPlugin';
import screenWidths from 'tailwind/screenWidths';
import { computed } from 'vue';

import type { ImageFieldValue } from '@/sitecoreFieldTypes';

const props = withDefaults(
  defineProps<{
    logoHeader?: ImageFieldValue;
    large?: boolean;
  }>(),
  {
    logoHeader: () => ({}),
  },
);

const fischer = useFischer();

const unlimitedHeight = computed<number>(() => {
  if (fischer.app.isUpat()) {
    return 56;
  }

  return props.large ? 40 : 30;
});

const unlimited = computed<number>(() => {
  const width: number | undefined =
    typeof props.logoHeader.width === 'string'
      ? Number.parseInt(props.logoHeader?.width, 10)
      : props.logoHeader.width;
  const height: number | undefined =
    typeof props.logoHeader.height === 'string'
      ? Number.parseInt(props.logoHeader?.height, 10)
      : props.logoHeader.height;
  if (width && height) {
    return Math.round((width / height) * unlimitedHeight.value);
  }
  return 200;
});

const breakpoints = computed<BreakPoints>(() => [
  {
    mediaWidth: screenWidths.sm,
    imageHeight: 18,
    imageWidth: (18 * unlimited.value) / unlimitedHeight.value,
  },
  {
    mediaWidth: screenWidths.lg,
    imageHeight: 27,
    imageWidth: (27 * unlimited.value) / unlimitedHeight.value,
  },
]);
</script>

<style scoped lang="scss">
@import './logo-header-fi.scss';
</style>
