<script lang="ts">
import { type ClickEventData, sendClickToEventHub, sendEventFunction } from 'composables/eventHub';
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    sendClickToEventHub(clickEvent: ClickEventData, mouseEvent: MouseEvent, href: string) {
      return sendClickToEventHub(this.$fischer, this.$cookies, clickEvent, mouseEvent, href);
    },
    sendEvent(type: string, message: object = {}, customType?: string, attributes?: object) {
      return sendEventFunction(this.$fischer, this.$cookies, type, message, customType, attributes);
    },
  },
});
</script>
