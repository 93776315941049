<template>
  <transition
    :leave-active-class="`animated ${animationOut} fast`"
    :enter-active-class="`animated ${animationIn} fast`"
  >
    <div
      v-if="showMobileFirstLvl"
      v-click-outside="onClickOutside"
      class="mobile-navigation-first-level-fi"
    >
      <div class="mobile-navigation-first-level-fi__header">
        <a
          v-if="homeData && logoHeader"
          :href="homeData.url"
          :title="homeData.title"
          data-test="logo"
          class="mobile-navigation-first-level-fi__header__logo"
        >
          <img
            :src="logoHeader.src"
            :alt="logoHeader.alt"
            :title="logoHeader.title"
            class="fischer-brand-logo"
          />
        </a>

        <div
          class="mobile-navigation-first-level-fi__header__close"
          data-test="hide-flyout"
          @click="hideFlyout"
        >
          <icon-fi
            icon="close"
            size="2"
          />
        </div>
      </div>
      <ul class="mobile-navigation-first-level-fi__list">
        <template v-for="(item, index) in navigationData">
          <li
            v-if="!item.desktopOnly"
            :key="item.url"
            :class="{
              'mobile-navigation-first-level-fi__list__item': true,
              'mobile-navigation-first-level-fi__list__item--last':
                index === navigationData.length - 1,
              'mobile-navigation-first-level-fi__list__item--active': item.isActiveSelfOrDescendant,
              'mobile-navigation-first-level-fi__list__item--focus': index === navIndex,
            }"
            :data-test-2="item.label"
            :data-test-3="isProducts(item) ? 'products' : null"
            data-test="navigation"
          >
            <div
              v-if="item.subElements.length"
              class="mobile-navigation-first-level-fi__list__item__wrapper"
              @click="showSecondLevel(item, index)"
            >
              <span class="mobile-navigation-first-level-fi__list__item__label">
                {{ item.label }}
              </span>

              <icon-fi icon="arrow-right" />
            </div>
            <a
              v-else
              :target="item.target ? item.target : null"
              :href="item.url"
              class="mobile-navigation-first-level-fi__list__item__wrapper"
            >
              <span class="mobile-navigation-first-level-fi__list__item__label">
                {{ item.label }}
              </span>

              <icon-fi icon="arrow-right" />
            </a>
          </li>
        </template>

        <template v-for="navigation in serviceNavigation">
          <li
            v-for="navigationElement in navigation.elements"
            :key="`${navigation.title}_${navigationElement.identifier}`"
            class="mobile-navigation-first-level-fi__list__item mobile-navigation-first-level-fi__list__item--icon"
          >
            <a
              :href="
                navigationElement.link && navigationElement.identifier !== 'selectCountry'
                  ? navigationElement.link.url
                  : null
              "
              :aria-label="navigationElement.title"
              :data-test-2="navigationElement.identifier"
              data-test="navigation"
              class="mobile-navigation-first-level-fi__list__item__wrapper"
              @click="(evt) => onMobileNavigationClicked(navigationElement, evt)"
            >
              <span class="mobile-navigation-first-level-fi__list__item--icon--label">
                <icon-fi
                  :icon="iconMappings[navigationElement.identifier]"
                  :alt="navigationElement.title"
                />
                {{ navigationElement.link ? navigationElement.link.text : navigationElement.title }}
              </span>
              <icon-fi icon="arrow-right" />
            </a>
          </li>
        </template>
      </ul>
    </div>
  </transition>
</template>

<script>
import { IconFi } from 'atoms';
import vClickOutside from 'click-outside-vue3';
import iconMappings from 'components/header-fi/header-top-fi/service-navigation-item-fi/iconMappings';
import bodyScrollMixin from 'mixins/bodyScrollMixin';
import breakpointMixin from 'mixins/breakpointMixin';
import countrySelectMixin from 'mixins/countrySelectMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'MobileNavigationFirstLevelFi',
  components: {
    IconFi,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [bodyScrollMixin, breakpointMixin, countrySelectMixin],
  props: {
    serviceNavigation: {
      type: Array,
      required: true,
    },
    logoHeader: {
      type: Object,
      default: () => ({}),
    },
    homeData: {
      type: Object,
      default: () => ({}),
    },
    navigationData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShown: false,
      animationOut: 'mobileNavigationOut',
      animationIn: 'mobileNavigationIn',
    };
  },
  computed: {
    ...mapGetters({
      navIndex: 'navigation/getNavIndex',
      showMobileFirstLvl: 'navigation/getMobileFirstLvl',
      showMobileSubLvl: 'navigation/getMobileSubLvl',
    }),
    iconMappings() {
      return iconMappings;
    },
  },
  watch: {
    showMobileFirstLvl(newVal) {
      this.isShown = newVal;
    },
    showMobileSubLvl(newVal) {
      if (!newVal && !this.isShown) {
        this.animationOut = 'mobileNavigationOut';
        this.animationIn = 'mobileNavigationIn';
      }
    },
  },
  methods: {
    isProducts(item) {
      return item.url === this.$fischer.pages.products().url;
    },
    async hideFlyout() {
      this.animationOut = 'mobileNavigationOut';
      this.animationIn = 'mobileNavigationIn';

      this.bodyEnableScroll();
      this.$store.commit('navigation/SHOWBRANDAREA', true);

      // timeout needed for animation change
      await this.$nextTick();
      this.$store.commit('navigation/SHOWMOBILEFIRSTLVL', false);
    },
    async showSecondLevel(item, index) {
      if (!this.isSM) {
        this.animationOut = 'mobileNavigationFirstToSecond';
        this.animationIn = 'mobileNavigationSecondToFirst';
      }

      this.$store.commit('navigation/SUBELEMENTS', item.subElements);
      this.$store.commit('navigation/GROUPDATA', item);
      this.$store.commit('navigation/NAVINDEX', index);
      this.$store.commit('navigation/ISPRODUCTS', this.isProducts(item));
      this.$store.commit('navigation/ISCOMPANY', item.url === this.$fischer.pages.companies().url);

      // timeout needed for animation change
      await this.$nextTick();
      this.$store.commit('navigation/SHOWMOBILEFIRSTLVL', false);
      if (this.isSM) {
        this.$store.commit('navigation/SHOWFLYOUT', true);
      } else {
        this.$store.commit('navigation/SHOWMOBILESUBLVL', true);
      }
    },
    onClickOutside() {
      if (this.isSM) {
        this.hideFlyout();
      }
    },
    async onMobileNavigationClicked(navigationElement, evt) {
      const { identifier } = navigationElement;
      const isCountrySelect = identifier === 'selectCountry';

      if (!isCountrySelect) {
        return;
      }

      evt.preventDefault();
      evt.stopImmediatePropagation();

      await this.getLocaleData();

      if (this.isSingleLocale) {
        const url = navigationElement?.link?.url;
        const targetUrl = this.countrySelectUrl(url);
        if (targetUrl) {
          window.location.href = targetUrl;
        } else {
          this.$store.commit('navigation/SHOWMOBILEFIRSTLVL', false);
        }

        return;
      }

      this.$store.commit('navigation/IS_COUNTRY_SELECT', isCountrySelect);
      this.$store.commit('navigation/SHOWMOBILEFIRSTLVL', false);

      if (this.isSM) {
        this.$store.commit('navigation/SHOWFLYOUT', true);
      } else {
        this.$store.commit('navigation/SHOWMOBILESUBLVL', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mobile-navigation-first-level-fi';
</style>
