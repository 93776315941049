<template>
  <grid-container-fi class="jump-mark-tabs-fi">
    <tab-navigation-fi
      ref="jumpMarkTabNavi"
      class="jump-mark-tabs-fi__tabs"
      :tab-navigation="tabNavigation"
      :is-slider="true"
      theme="jump-marks"
      enable-scroll-if-out-of-bounds-only
      line-hidden
      @change="(value) => tabChanged(value)"
    />
  </grid-container-fi>
</template>

<script>
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import TabNavigationFi from 'components/tab-navigation-fi/TabNavigationFi';
import { mapGetters } from 'vuex';

export default {
  name: 'JumpMarkTabsFi',
  components: {
    GridContainerFi,
    TabNavigationFi,
  },
  data() {
    return {
      spacing: 30,
      tabChangeByScrollingEnabled: true,
      scrollToJumpMarkEnabled: true,
      scrollDuration: 500,
    };
  },
  computed: {
    ...mapGetters({
      jumpMarks: 'navigation/getJumpMarks',
      activeJumpMark: 'navigation/getActiveJumpMark',
      headerHeight: 'layout/getHeaderHeight',
    }),
    tabNavigation() {
      return this.jumpMarks.map((jm, index) => ({
        id: index + 1,
        text: jm.title,
        offsetTop: jm.offsetTop,
      }));
    },
  },
  watch: {
    jumpMarks: {
      deep: true,
      immediate: true,
      handler(items) {
        if (!items?.length) {
          return;
        }

        this.checkAnchors();
      },
    },
    activeJumpMark: {
      deep: true,
      handler(item) {
        if (!this.tabChangeByScrollingEnabled) {
          return;
        }

        this.scrollToJumpMarkEnabled = false;
        if (item.offsetTop) {
          const relatedTab = this.tabNavigation.find((t) => t.offsetTop === item.offsetTop);
          this.$refs.jumpMarkTabNavi?.changeTab(relatedTab.id, relatedTab.id - 1);
        } else {
          this.$refs.jumpMarkTabNavi?.changeTab(0, 0);
        }

        setTimeout(() => {
          this.scrollToJumpMarkEnabled = true;
        }, this.scrollDuration);
      },
    },
  },
  methods: {
    tabChanged() {
      const offsetValue = this.tabNavigation.find(
        (t) => t.id === this.$refs.jumpMarkTabNavi?.activeId,
      )?.offsetTop;
      const targetJumpMark = this.jumpMarks.find((jm) => jm.offsetTop === offsetValue);

      if (!this.scrollToJumpMarkEnabled || !targetJumpMark) {
        return;
      }

      this.onJumpMarkClicked(targetJumpMark.element);
    },
    onJumpMarkClicked(element) {
      this.tabChangeByScrollingEnabled = false;
      this.scrollToJumpMark(element);
      setTimeout(() => {
        this.tabChangeByScrollingEnabled = true;
      }, this.scrollDuration);
    },
    async checkAnchors() {
      if (IS_SSR) {
        return;
      }

      const currentAnchor = window.location?.hash?.slice(1);

      if (!currentAnchor) {
        return;
      }

      await this.$nextTick(); // wait for header-height to potentially update
      await this.$nextTick(); // we need to wait twice, since header-height-fi itself waits for nextTick :(

      const targetJumpMark = this.jumpMarks.find((jm) => jm.anchorName === currentAnchor);

      if (!targetJumpMark?.element) {
        return;
      }

      this.scrollToJumpMark(targetJumpMark.element);

      // Reset anchor after scroll to is triggered to prevent multiple 'scrollToJumpMark' calls
      let currentSearchParams = window.location.search;
      window.history.pushState(null, null, `${currentSearchParams}`);
    },
    scrollToJumpMark(element) {
      this.$smoothScroll({
        scrollTo: element,
        duration: this.scrollDuration,
        updateHistory: false,
        offset: -(this.headerHeight + this.spacing),
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import './jump-mark-tabs-fi';
</style>
