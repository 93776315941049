<script>
export default {
  methods: {
    isVideo(item) {
      if (item.videoMp4Link?.value?.href) {
        return true;
      }

      if (item.videoWebmLink?.value?.href) {
        return true;
      }

      if (item.videoYoutubeLink?.value?.url) {
        return true;
      }

      return false;
    },
  },
};
</script>
