import { useDict } from 'composables/fischerPlugin';
import { useStore } from 'vuex';

import type { SitecoreDictionary } from '@/lib/dictPlugin';
import type { SitecoreNavigation } from '@/sitecoreState';

export const closeNavigationFlyoutFunction = (store: any) => {
  store.commit('navigation/SHOWFLYOUT', false);
  store.commit('navigation/NAVINDEX', -1);
  store.commit('navigation/GROUPDATA', {});
  store.commit('navigation/SUBELEMENTS', []);
  store.commit('navigation/SHOWBRANDAREA', true);
};

export type SubNavigation =
  | { groupList: SitecoreNavigation[]; groupLabel?: string }
  | SitecoreNavigation;

export const getNavigationGroupsFunction = (navigationSubElements: SitecoreNavigation[]) => {
  const subNavigation: SubNavigation[] = [];
  let subList: SitecoreNavigation[] = [];

  navigationSubElements.forEach((subElement) => {
    if (!subElement.isGroupPage) {
      subList.push(subElement);
    } else {
      if (subList.length > 0) {
        subNavigation.push({ groupList: subList });
        subList = [];
      }
      subNavigation.push({ groupList: subElement.subElements, groupLabel: subElement.label });
    }
  });

  if (subList.length > 0) {
    subNavigation.push({ groupList: subList });
  }

  return subNavigation;
};

export interface GroupData {
  labelSubNavigation?: string | null;
  label: string;
}
export const navigationLayerTitleFunction = (groupData: GroupData, dict: SitecoreDictionary) => {
  return groupData.labelSubNavigation
    ? groupData.labelSubNavigation
    : dict.get('global-Labels.Navigation.Overview', [groupData.label]);
};

export const useNavigation = () => {
  const store = useStore();
  const dict = useDict();

  const closeNavigationFlyout = () => closeNavigationFlyoutFunction(store);

  const navigationLayerTitle = (groupData: GroupData) =>
    navigationLayerTitleFunction(groupData, dict);

  return {
    closeNavigationFlyout,
    getNavigationGroups: getNavigationGroupsFunction,
    navigationLayerTitle,
  };
};
