<template>
  <ul
    :class="{
      'mobile-navigation-bar-fi': true,
      'mobile-navigation-bar-fi--search-active': isSearchActive,
    }"
  >
    <li
      key="search"
      ref="searchListItem"
      :class="{
        'mobile-navigation-bar-fi__item': true,
        'mobile-navigation-bar-fi__item--icon': true,
        'mobile-navigation-bar-fi__search-item': true,
        'mobile-navigation-bar-fi__search-item--hidden': isSearchActive || isSearchTransitioning,
      }"
    >
      <div
        class="mobile-navigation-bar-fi__item__icon mobile-navigation-bar-fi__search-icon"
        data-test="naviation"
        data-test-2="mobile-search-icon"
        @click="openSearch"
      >
        <icon-fi
          class="mobile-navigation-bar-fi__item__icon"
          icon="search"
        />
      </div>
    </li>
    <template v-for="navigation in serviceNavigationHeader">
      <li
        v-for="navigationElement in navigation.elements"
        :key="`${navigation.title}${navigationElement.identifier}`"
        :class="{
          'mobile-navigation-bar-fi__item': true,
          'mobile-navigation-bar-fi__item--icon': true,
          [`mobile-navigation-bar-fi__item-${navigation.title}-${navigationElement.identifier}`]: true,
        }"
      >
        <service-navigation-item-fi
          :navigation-element="navigationElement"
          :brand-background="brandBackground"
          @login="$emit('login')"
        />
      </li>
    </template>
    <li
      v-if="!showFlyout && !isCountrySelect"
      class="mobile-navigation-bar-fi__item mobile-navigation-bar-fi__item--icon"
      data-test="naviation"
      data-test-2="mobile-menu-open"
      @click="showMobileFlyout"
    >
      <icon-fi
        class="mobile-navigation-bar-fi__item__icon"
        icon="menu"
      />
    </li>
    <li
      v-else
      class="mobile-navigation-bar-fi__item mobile-navigation-bar-fi__item--icon"
      data-test="naviation"
      data-test-2="mobile-menu-close"
      @click="hideMobileFlyout"
    >
      <icon-fi
        class="mobile-navigation-bar-fi__item__icon"
        icon="close"
      />
    </li>
  </ul>
</template>

<script>
import { IconFi } from 'atoms';
import bodyScrollMixin from 'mixins/bodyScrollMixin';
import breakpointMixin from 'mixins/breakpointMixin';
import { mapGetters } from 'vuex';

import ServiceNavigationItemFi from '../service-navigation-item-fi/ServiceNavigationItemFi';

export default {
  name: 'MobileNavigationBarFi',
  components: {
    IconFi,
    ServiceNavigationItemFi,
  },
  mixins: [breakpointMixin, bodyScrollMixin],
  props: {
    serviceNavigation: {
      type: Array,
      required: true,
    },
    brandBackground: {
      type: Boolean,
    },
  },
  emits: ['login'],
  computed: {
    ...mapGetters({
      showFlyout: 'navigation/getFlyout',
      isSearchActive: 'navigation/searchActive',
      isSearchTransitioning: 'navigation/searchTransitioning',
      isSearchSuggestions: 'navigation/getShowSearchSuggest',
      isMobileFirstLvl: 'navigation/getMobileFirstLvl',
      isMobileSecondLvl: 'navigation/getMobileSubLvl',
      isCountrySelect: 'navigation/isCountrySelect',
    }),
    serviceNavigationHeader() {
      return this.serviceNavigation
        .map((item) => ({
          ...item,
          elements: item.elements.filter((element) => !element.hideFromMobile),
        }))
        .filter((item) => item.elements.length);
    },
    isMobileFirstLvlSM() {
      return (this.isMobileFirstLvl || this.isSearchSuggestions) && this.isSM;
    },
    isFlyoutActive() {
      return this.isSearchSuggestions || this.isMobileFirstLvl || this.isMobileSecondLvl;
    },
    isNavigationActive() {
      return (
        this.isSearchActive ||
        this.isMobileFirstLvl ||
        this.isMobileSecondLvl ||
        this.showFlyout ||
        this.isCountrySelect
      );
    },
  },
  watch: {
    isFlyoutActive: {
      immediate: true,
      handler(value) {
        if (value && !this.isMD) {
          this.bodyDisableScroll();
        } else {
          this.bodyEnableScroll();
        }
      },
    },
    isNavigationActive: {
      immediate: true,
      handler(value) {
        this.$store.commit('navigation/SHOWBRANDAREA', !value);
      },
    },
    isSearchActive(value) {
      if (!value) {
        this.hideMobileFlyout();
      }
    },
  },
  methods: {
    openSearch() {
      if (this.isSearchActive) {
        return;
      }

      this.$store.dispatch('navigation/setSearchInput', true);
    },
    showMobileFlyout() {
      this.$store.commit('navigation/SHOWMOBILEFIRSTLVL', true);
    },
    hideMobileFlyout() {
      this.$store.commit('navigation/IS_COUNTRY_SELECT', false);
      if (this.isSM) {
        this.$store.commit('navigation/SHOWFLYOUT', false);
      } else {
        this.$store.commit('navigation/SHOWMOBILESUBLVL', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mobile-navigation-bar-fi';
</style>
