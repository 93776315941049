import { createStore } from 'vuex';

import cart from './modules/cart';
import construction from './modules/construction';
import layout from './modules/layout';
import navigation from './modules/navigation';
import notifications from './modules/notifications';
import product from './modules/product';
import productList from './modules/product-list';
import registration from './modules/registration';
import search from './modules/search';

const isDev = IS_SHOWROOM || APP_ENVIRONMENT === 'VM';

const store = () =>
  createStore({
    devtools: isDev,
    modules: {
      product,
      productList,
      registration,
      navigation,
      layout,
      search,
      notifications,
      cart,
      construction,
      // Enable strict mode in development to get a warning
      // when mutating state outside of a mutation.
      // https://vuex.vuejs.org/guide/strict.html
      strict: isDev,
    },
  });

export default store;
