<script lang="ts">
export default {
  props: {
    modelValue: {
      type: [String, Object, Number, Boolean, Array],
      default: undefined,
    },
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: true,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: undefined,
    },
  },
  emits: ['blur', 'change', 'focus'],
  data() {
    return {
      fallback: false,
    };
  },
  computed: {
    isChecked(): boolean {
      if (this.modelValue === undefined) {
        return this.fallback;
      }

      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) >= 0;
      }

      return this.modelValue === this.value;
    },
  },
  watch: {
    isChecked(value: boolean) {
      if (value) {
        this.$emit('change', this.value);
      }
    },
  },
  methods: {
    getInput(): HTMLInputElement {
      return this.$refs.input as HTMLInputElement;
    },
    click() {
      this.getInput().click();
    },
    blurHandler(e: FocusEvent) {
      this.$emit('blur', e);
    },
    focusHandler(e: FocusEvent) {
      this.$emit('focus', e);
    },
    blur() {
      (this.$refs.input as HTMLInputElement).blur();
    },
    focus() {
      (this.$refs.input as HTMLInputElement).focus();
    },
  },
};
</script>
