<template>
  <div
    :class="{
      'select-text-input-fi': true,
      'select-text-input-fi--hover-disabled': !enableHover,
      'select-text-input-fi--disabled': disabled,
    }"
  >
    <div
      v-click-outside="closeOptions"
      class="select-text-input-fi__container"
    >
      <div
        ref="selectInput"
        :class="{
          'select-text-input-fi__input-wrapper': true,
          'select-text-input-fi__input-wrapper--open': showOptions,
        }"
      >
        <text-input-fi
          ref="selectInput"
          class="select-text-input-fi__input"
          :model-value="inputValue"
          :label="label"
          :error="optionNotFound"
          :error-message="optionNotFound ? 'NOT FOUND' : null"
          :rules="rules"
          :required="required"
          :disabled="disabled"
          :light="light"
          @focus="showOptions = true"
          @input="inputChanged"
          @keyup-enter="setOption"
        />

        <icon-fi
          icon="chevron-down"
          class="select-text-input-fi__icon"
        />
      </div>

      <teleport
        v-if="isMounted"
        to="body"
      >
        <div
          v-if="showOptions"
          ref="selectOptionsContainer"
          :style="wrapperStyle"
          class="select-text-input-fi__options"
          @mouseenter="enableHover = true"
          @mouseleave="onMouseLeave"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-for="option in filteredOptions"
            :key="option.key"
            ref="selectOption"
            :class="{
              'select-text-input-fi__option': true,
              'select-text-input-fi__option--focused': focused === option.key,
              'select-text-input-fi__option--selected': option.key === value,
            }"
            data-test="select-text-input-option"
            :data-test-2="option.name"
            @click="changeOption(option.name)"
            @mouseenter="onMouseEnter(option.key)"
            v-html="highlightText(option.name)"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </teleport>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import { mapGetters } from 'vuex';

import IconFi from '../icon-fi/IconFi';
import TextInputFi from '../text-input-fi/TextInputFi';

export default {
  name: 'SelectTextInputFi',
  components: {
    TextInputFi,
    IconFi,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      inputValue: null,
      inputValuePrev: null,
      focused: null,
      enableHover: false,
      showOptions: false,
      optionsCounter: -1,
      wrapperStyle: {},
      positionHandler: null,
      optionNotFound: false,
      focusedIndex: 0,
      selectedIndex: null,
      optionsContainerEdge: {},
      focusedOptionEdge: {},
      focusedOptionBoundings: null,
      selectedOptionEdge: {},
      popupPositioningEnabled: false,
      isMounted: false,
    };
  },
  computed: {
    ...mapGetters({
      windowScrollPos: 'layout/getWindowScrollPos',
      windowDimensions: 'layout/getWindowDimensions',
    }),
    filteredOptions() {
      let options = this.options;
      const hasKeyProps = options?.[0] && Object.hasOwnProperty.call(options[0], 'key');

      // adds an additional key property if the given options have none.
      if (!hasKeyProps) {
        options = options.map((o) => ({ ...o, key: o.value }));
      }

      if (this.inputValue && this.inputValue !== '') {
        return options.filter((option) =>
          option.name.toLowerCase().includes(this.inputValue.toLowerCase()),
        );
      }

      return options;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.inputValue = this.options.find((option) => option.value === val)?.name || '';
        this.inputValuePrev = this.inputValue;
      },
    },
    windowScrollPos: {
      deep: true,
      handler() {
        if (this.popupPositioningEnabled) {
          this.positionPopup();
        }
      },
    },
    windowDimensions: {
      deep: true,
      handler() {
        if (this.popupPositioningEnabled) {
          this.positionPopup();
        }
      },
    },
    async showOptions(value) {
      if (IS_SSR) {
        return;
      }

      if (value) {
        this.inputValue = '';
        this.enableHover = false;
        this.optionsCounter = 0;
        this.focused = this.filteredOptions[this.optionsCounter]?.key;

        if (this.optionNotFound) {
          this.optionNotFound = false;
          this.$emit('change', true);
          await this.$nextTick();
        }

        this.positionPopup();

        setTimeout(() => {
          this.calculateBoundings();

          if (
            this.$refs.selectOptionsContainer &&
            this.selectedOptionEdge?.bottom > this.optionsContainerEdge.bottom
          ) {
            this.$refs.selectOptionsContainer.scrollTop =
              this.selectedOptionEdge?.top - this.optionsContainerEdge.top - 3;
          }

          this.focused = this.filteredOptions[this.selectedIndex]?.key;
        }, 0);
      } else {
        this.optionsCounter = -1;
        if (this.inputValuePrev) {
          this.inputValue = this.inputValuePrev;
          this.optionNotFound = false;
        }
      }

      this.popupPositioningEnabled = value;
    },
  },
  mounted() {
    this.isMounted = true;
  },
  created() {
    if (IS_SSR) {
      return;
    }

    window.addEventListener('keydown', this.handleKeys);
  },
  beforeUnmount() {
    if (IS_SSR) {
      return;
    }

    window.removeEventListener('resize', this.handleKeys);
  },
  methods: {
    onMouseEnter(optionKey) {
      this.enableHover = true;
      this.focused = optionKey;
    },
    onMouseLeave() {
      this.enableHover = false;
      this.focused = this.filteredOptions?.[0]?.key;
    },
    updateScrollPosition(scrollDown) {
      this.calculateBoundings();

      if (scrollDown) {
        if (
          !this.$refs.selectOptionsContainer ||
          this.focusedOptionEdge.bottom <= this.optionsContainerEdge.bottom
        ) {
          return;
        }

        this.$refs.selectOptionsContainer.scrollTop += this.focusedOptionBoundings?.height;
      } else {
        if (
          !this.$refs.selectOptionsContainer ||
          this.focusedOptionEdge.top >= this.optionsContainerEdge.top
        ) {
          return;
        }

        this.$refs.selectOptionsContainer.scrollTop -= this.focusedOptionBoundings?.height;
      }
    },
    handleKeys(event) {
      this.focusedIndex = this.filteredOptions.indexOf(
        this.filteredOptions.find((i) => i.key === this.focused),
      );

      if (event.key === 'ArrowUp') {
        if (this.optionsCounter > -1) {
          if (this.focusedIndex) {
            this.optionsCounter = this.focusedIndex;
          }

          this.optionsCounter -= 1;
        }
      } else if (event.key === 'ArrowDown') {
        if (this.optionsCounter < this.filteredOptions.length - 1) {
          if (this.focusedIndex) {
            this.optionsCounter = this.focusedIndex;
          }

          this.optionsCounter += 1;
        }
      }

      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        this.enableHover = false;
        this.focused = this.filteredOptions[this.optionsCounter]?.key;
        this.focusedIndex = this.optionsCounter;
        this.updateScrollPosition(event.key === 'ArrowDown');
      }
    },
    highlightText(text) {
      if (this.highlight) {
        return text.replace(
          new RegExp(this.inputValue, 'i'),
          (match) => `<span class="fi-semibold">${match}</span>`,
        );
      }
      return text;
    },
    async inputChanged(event) {
      this.enableHover = false;
      this.optionNotFound = false;
      this.showOptions = true;
      this.optionsCounter = 0;
      this.inputValue = event?.target?.value || '';
      this.focused = this.filteredOptions[0]?.key;
    },
    setOption() {
      const val = this.filteredOptions.find((i) => i.key === this.focused)?.name;
      if (val) {
        this.inputValue = val;
      }
      this.changeOption(val);
      this.$refs.selectInput?.querySelector('input')?.blur();
    },
    changeOption(value) {
      this.showOptions = false;
      const option = this.options.find((el) => el.name === value);
      if (option?.value) {
        this.optionNotFound = false;
        this.$emit('change', option.value);
      } else {
        this.focusedIndex = null;
        this.selectedIndex = null;
        this.inputValuePrev = null;
        this.selectedOptionEdge = {};
        this.optionNotFound = true;
        this.$emit('change', '');
      }
    },
    positionPopup() {
      if (IS_SSR || !this.$refs?.selectInput) {
        return;
      }

      const selectBounding = this.$refs.selectInput.getBoundingClientRect();
      const input = this.$refs.selectInput.querySelector('input')?.getBoundingClientRect();
      const top = selectBounding.top + input.height;
      this.wrapperStyle.top = `${top}px`;
      this.wrapperStyle.left = `${selectBounding.left}px`;
      this.wrapperStyle.width = `${selectBounding.width}px`;
    },
    closeOptions() {
      if (this.showOptions) {
        this.showOptions = false;

        if (this.inputValue) {
          this.setOption();
        }
      }
    },
    calculateBoundings() {
      if (IS_SSR) {
        return;
      }

      if (!this.$refs.selectOptionsContainer) {
        return;
      }

      const options = this.$refs.selectOption;
      const style = window?.getComputedStyle(this.$refs.selectOptionsContainer);
      this.focusedOptionBoundings = options[this.focusedIndex]?.getBoundingClientRect();

      if (!style || !options || !this.focusedOptionBoundings) {
        return;
      }

      this.optionsContainerEdge = {
        top: parseInt(style.top, 10),
        bottom: parseInt(style.top, 10) + parseInt(style.height, 10),
      };

      this.focusedOptionEdge = {
        top: this.focusedOptionBoundings.top,
        bottom: this.focusedOptionBoundings.top + this.focusedOptionBoundings.height,
      };

      if (!this.value) {
        return;
      }

      const selectedOption = this.filteredOptions.find((i) => i.key === this.value);
      this.selectedIndex = this.filteredOptions.indexOf(selectedOption);

      if (this.selectedIndex === -1) {
        return;
      }

      this.selectedOptionBoundings = options[this.selectedIndex]?.getBoundingClientRect();

      if (!this.selectedOptionBoundings) {
        return;
      }

      this.selectedOptionEdge = {
        top: this.selectedOptionBoundings.top,
        bottom: this.selectedOptionBoundings.top + this.selectedOptionBoundings.height,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './select-text-input-fi';
</style>
