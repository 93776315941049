<template>
  <div
    :class="{
      'cloud-image-fi__wrapper': true,
      'cloud-image-fi__wrapper--full-height': fullHeight,
    }"
  >
    <res-img
      v-if="isMounted"
      class="cloud-image-fi"
      :src="finalUrl"
      :alt="alt || field?.value?.alt"
      :title="title"
      :params="cloudImageParams"
      :ratio="aspectRatio"
      :width="width || field?.value?.width"
      :height="height || field?.value?.height"
      :sizes="sizes"
      :draggable="noDrag ? 'false' : null"
      :data-test="dataTest"
      do-not-replace-u-r-l
    />
    <img
      v-else
      class="cloud-image-fi"
      :alt="alt || field?.value?.alt"
      :draggable="noDrag ? 'false' : undefined"
      :height="height || field?.value?.height"
      :title="title || undefined"
      :width="width || field?.value?.width"
      :style="
        aspectRatio
          ? {
              aspectRatio: aspectRatio,
            }
          : null
      "
      :data-test="dataTest"
    />
  </div>
</template>

<script setup lang="ts">
import { useCloudImage } from 'composables/cloudImage';
import { computed, onMounted, ref } from 'vue';
import ResImg from 'vue-cloudimage-responsive-plain';

defineOptions({
  name: 'CloudImageFi',
});
const { getProxySrc } = useCloudImage();

const props = withDefaults(
  defineProps<{
    field?: {
      value?: {
        src?: string;
        alt?: string;
        width?: string;
        height?: string;
      };
    };
    src?: string | null;
    alt?: string | null;
    title?: string | null;
    params?: string | null;
    aspectRatio?: number | null;
    ratio?: number | null;
    width?: number | string | null;
    height?: number | string | null;
    sizes?: object;
    dataTest?: string | null;
    customProxy?: boolean;
    trim?: boolean;
    doNotReplaceUrl?: boolean;
    noDrag?: boolean;
    fullHeight?: boolean;
  }>(),
  {
    alt: '',
    title: '',
    field: undefined,
    src: undefined,
    params: undefined,
    aspectRatio: undefined,
    ratio: undefined,
    width: undefined,
    height: undefined,
    sizes: undefined,
    dataTest: undefined,
  },
);

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});

const finalUrl = computed<string | null>(() => {
  const src = props.src || props.field?.value?.src;
  return src ? getProxySrc(src) : null;
});

const cloudImageParams = computed<string>(() => {
  if (props.trim) {
    if (props.params) {
      return `${props.params}&trim=5`;
    }

    return 'trim=5';
  }

  return props.params || '';
});
</script>

<style lang="scss" scoped>
@import './cloud-image-fi';
</style>
