<template>
  <login-dialog-fi
    v-if="showLoginDialog"
    ref="loginDialog"
    :init-login
    :is-login-failure="isLoginFailure"
    :authorize-redirect-query="authorizeRedirectQuery"
  />
</template>

<script setup lang="ts">
import { useFischer } from 'composables/fischerPlugin';
import { defineAsyncComponent, onMounted, ref, useTemplateRef } from 'vue';
import { useStore } from 'vuex';

import type { FischerPlugin } from '@/lib/fischerPlugin';

const store = useStore();

const LoginDialogFi = defineAsyncComponent(
  () => import('components/login-dialog-fi/LoginDialogFi.vue'),
);

const fischer = useFischer();

const loginDialog = useTemplateRef<typeof LoginDialogFi>('loginDialog');

const showLoginDialog = ref<boolean>(false);
const initLogin = ref<boolean>(true);
const isLoginFailure = ref<boolean>(false);

const login = () => {
  if (!fischer.isLoginDialogEnabled()) {
    console.error('login dialog is not enabled');
    return;
  }
  showLoginDialog.value = true;
  loginDialog.value?.open(); // when dialog was opened before
};

const authorizeRedirectQuery = ref<string>('');

const clearHash = () => {
  const urlNoParams = fischer.uri();
  try {
    window.history.replaceState(null, '', urlNoParams);
  } catch (error) {
    console.error('Could not remove search', error);
  }
};

onMounted(() => {
  const urlNoParams = fischer.uri();

  if (urlNoParams === fischer.pages.login().url) {
    return;
  }

  if (
    /(?:^#|&)brand=dialog(?::|%3a|%3A)/.test(window.location.hash) ||
    /(?:^\?|&)brand=dialog(?::|%3a|%3A)/.test(window.location.search)
  ) {
    isLoginFailure.value = fischer.query('authentication_error') === 'true';
    authorizeRedirectQuery.value = fischer.query('authorize_redirect');
    clearHash();

    initLogin.value = false;
    login();
  } else {
    const dialogPages: (keyof FischerPlugin['pages'])[] = [
      'login',
      'registration',
      'passwordForgotten',
    ];
    const dialogHash = dialogPages.find((dialog) =>
      new RegExp(`(?:^#|&)${dialog}-dialog(?:$|&)`).test(window.location.hash),
    );
    if (dialogHash) {
      clearHash();

      store.commit('navigation/TRIGGER_LOGIN', { modal: dialogHash });
      login();
    }
  }
});

defineExpose({
  login,
});
</script>
