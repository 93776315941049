<script>
import { bodyDisableScrollBody, bodyEnableScrollBody } from '@/composables/bodyScroll';

export default {
  data() {
    return {
      bodyScrollDisabled: false,
    };
  },
  beforeUnmount() {
    this.bodyEnableScroll();
  },
  methods: {
    bodyDisableScroll() {
      bodyDisableScrollBody();
      this.bodyScrollDisabled = true;
    },
    bodyEnableScroll() {
      if (!this.bodyScrollDisabled) {
        return;
      }
      bodyEnableScrollBody();
      this.bodyScrollDisabled = false;
    },
  },
};
</script>
