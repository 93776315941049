import { nanoid } from 'nanoid';

export default (): string => {
  if (IS_SSR) {
    return '';
  }
  if (IS_SHOWROOM) {
    return 'showroom';
  }
  try {
    const oldId = window.sessionStorage.getItem('eventHubSessionId');
    if (oldId) {
      return oldId;
    }
    const id = nanoid();
    window.sessionStorage.setItem('eventHubSessionId', id);
    return id;
  } catch (error) {
    console.error('error getting session-id', error);
    return '';
  }
};
