<template>
  <div
    v-if="showSupport"
    :class="{
      'support-box-fi__wrapper': true,
      'support-box-fi__wrapper--active': showCommunication,
      'support-box-fi__wrapper--navigation-active': isMobileNavigationOpen,
    }"
    @mouseenter="onMouseEvent(true)"
    @mouseleave="onMouseEvent(false)"
  >
    <div class="support-box-fi__icon-container">
      <div
        class="support-box-fi__heading-icon"
        @click="showCommunication = true"
      >
        <icon-fi
          color="white"
          icon="phone-call"
        />
      </div>
      <div
        class="support-box-fi__heading-icon support-box-fi__heading-icon-close"
        @click.capture="showSupport = false"
      >
        <icon-fi
          color="white"
          icon="close"
        />
      </div>
    </div>
    <div class="support-box-fi__icon-footer">
      <div
        class="support-box-fi__footer-title"
        @click="showCommunication = true"
      >
        {{ $dict.get('support-Box.Title') }}
      </div>
      <transition
        enter-active-class="animated fatest fadeIn"
        leave-active-class="animated fatest fadeOut"
      >
        <div
          v-if="showCommunication"
          class="support-box-fi__communication-tiles-bg-mobile"
          @click="showCommunication = false"
        />
      </transition>
      <transition name="support">
        <div
          v-if="showCommunication"
          class="support-box-fi__communication-tiles"
        >
          <span
            v-if="!isMD"
            class="support-box-fi__communication-tiles-header"
          >
            <icon-fi
              color="black"
              icon="close"
              @click="showCommunication = false"
            />
          </span>

          <div
            v-for="support in supportData"
            :key="support.Name"
            class="support-box-fi__communication-tile"
          >
            <div class="support-box-fi__support-icon">
              <icon-fi
                color="black"
                :icon="getIconName(support.Icon)"
              />
            </div>
            <div class="support-box-fi__support-container">
              <div class="support-box-fi__support-details-title">{{ support.Name }}</div>
              <div class="support-box-fi__support-detail">
                <link-fi
                  :href="support?.Number?.Url"
                  :size="14"
                  secondary
                  text-link
                >
                  {{ support?.Number?.Text }}
                </link-fi>
                <!-- <a :href="getTel(support.Number) || undefined">{{ support.Number }} </a> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script setup lang="ts">
import axios from 'axios';
import { IconFi, LinkFi } from 'components/atoms/';
import { useBreakpoints } from 'composables/breakpoint';
import { useFischer } from 'composables/fischerPlugin';
import { debounce } from 'throttle-debounce';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import getAssetUrl from '@/lib/AssetService';
const store = useStore();
type LinkData = {
  Anchor: string;
  Class: string;
  Text: string;
  Query: string;
  Title: string;
  Url: string;
  Target: string;
  TargetId: string;
  Type: string;
  Style: null | Record<string, string>;
};
type SupportData = {
  Icon: string;
  Name: string;
  Number: LinkData;
};

const fischer = useFischer();
const { isMD } = useBreakpoints();
const showCommunication = ref<boolean>(false);
const showSupport = ref<boolean>(true);
const supportData = ref<SupportData[]>([]);

const isMobileNavigationOpen = computed<boolean>(
  () => store.getters['navigation/getMobileFirstLvl'],
);

const mouseLeaveDebounce = debounce(400, (value: boolean) => {
  if (!value) {
    showCommunication.value = false;
  }
});

const onMouseEvent = (value: boolean) => {
  if (!isMD.value) {
    return;
  }
  mouseLeaveDebounce(value);
  if (value) {
    showCommunication.value = !showCommunication.value;
  }
};
const getIconName = (icon: string): string => {
  const parts = icon.split('/');
  const fileName = parts[parts.length - 1];
  const [name] = fileName.split('.');
  return name;
};
onMounted(async () => {
  let supportUrl = IS_SHOWROOM
    ? getAssetUrl('/__mocks__/data/supportMock.json')
    : `${fischer.hostUrlBase()}/supportbox/api/app?lang=${fischer.siteLanguage()}&productCategoryId=${fischer.catalogId()}`;
  try {
    const { data } = await axios.get(supportUrl);
    supportData.value = data?.ContactNumbers;
  } catch (error) {
    const reason = 'Could not load legal hints';
    console.error(reason, error);
  }
});
</script>
<style lang="scss" scoped>
@import 'support-box-fi.scss';
</style>
