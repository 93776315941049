<template>
  <grid-container-fi
    v-if="assistedService?.user"
    :class="{
      'assisted-service-module-fi': true,
    }"
    :container="false"
  >
    <div class="assisted-service-module-fi__content fi-col-span-12">
      <div class="assisted-service-module-fi__label">
        {{ $dict.get('assisted-Service-Module.Module-Label') }}
      </div>
      <div class="assisted-service-module-fi__session">
        <div
          class="assisted-service-module-fi__user-info"
          data-test="assisted-service-session-info"
        >
          <div class="assisted-service-module-fi__user-name-sm">
            {{ assistedService.user.name }}
            -
            {{ assistedService.user.customerId }}
          </div>
          <div class="assisted-service-module-fi__user-uid-sm">
            {{ assistedService.user.displayUid }}
          </div>
          <div class="assisted-service-module-fi__user-md">
            {{ assistedService.user.name }}
            -
            {{ assistedService.user.displayUid }}
            -
            {{ assistedService.user.customerId }}
          </div>
        </div>
        <div
          class="assisted-service-module-fi__quit"
          data-test="end-assisted-service-session"
          @click="onQuit"
        >
          <div class="assisted-service-module-fi__quit-text">
            {{ $dict.get('assisted-Service-Module.Quit') }}
          </div>
          <icon-fi icon="power" />
        </div>
      </div>
    </div>
  </grid-container-fi>
</template>

<script>
import { IconFi } from 'atoms';
import GridContainerFi from 'components/grid-fi/GridContainerFi';

import ClientStorage from '@/utils/clientStorage';

import assistedServiceLoginMixin from './assistedServiceLoginMixin';

export default {
  name: 'AssistedServiceModuleFi',
  components: {
    GridContainerFi,
    IconFi,
  },
  mixins: [assistedServiceLoginMixin],
  computed: {
    assistedService() {
      return this.$fischer.user.getAssistedService();
    },
  },
  created() {
    if (!IS_SSR && this.assistedService) {
      if (!this.$fischer.user.authenticated()) {
        // session expired, clear assisted service
        this.$fischer.setAssistedService();
      } else {
        this.$store.commit(
          'navigation/SET_ASSISTED_SERVICE_ACTIVE',
          Boolean(this.assistedService.user),
        );
      }
    }
  },
  methods: {
    async onQuit() {
      const assistedService = {
        ...this.assistedService,
        user: null,
      };
      try {
        const { LoginSuccessPage } = await this.postAdminTokenInSession(
          this.$fischer.user.accessToken(),
          this.$fischer.user.idToken(),
        );
        this.$fischer.setAssistedService(assistedService);
        ClientStorage.setValue('impersonatedUser', JSON.stringify(this.assistedService.user), true);
        window.location = LoginSuccessPage || '/';
      } catch (error) {
        console.error('Error ending assisted service', error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './assisted-service-module-fi';
</style>
