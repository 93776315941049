import type { FischerPlugin } from '@/lib/fischerPlugin';

export interface SlugItem {
  Slug: string;
}

export const getSlugLink = (
  item: SlugItem,
  productsUrl: string | undefined,
  fischer: FischerPlugin,
) => (item.Slug ? `${productsUrl || fischer.pages.products().url}${item.Slug}` : null);

export const useSlugLink = () => ({ getSlugLink });
