<template>
  <div
    :class="{
      'brand-area-fi': true,
      'brand-area-fi--large-logo': $fischer.largeLogo() && !isUpat,
      'brand-area-fi--upat': isUpat,
    }"
  >
    <div
      class="brand-area-fi__box"
      :class="showBrandArea ? '' : 'brand-area-fi__box--hidden'"
    >
      <div class="brand-area-fi__box__header">
        <a
          v-if="homeData && logoBrandArea?.src"
          :href="homeData.url"
          :title="homeData.title"
          :class="{
            'brand-area-fi__box__logo': true,
          }"
        >
          <ssr-cloud-image-fi
            :src="logoBrandArea.src"
            :alt="logoBrandArea.alt || logoHeader?.alt || 'Logo Image'"
            :title="logoBrandArea.title"
            :breakpoints="breakpoints"
            :unlimited-height="unlimitedHeight"
            :unlimited="unlimited"
            params="func=bound"
            class="fischer-brand-logo"
          />
        </a>

        <mobile-navigation-bar-fi
          v-if="!isSM"
          :service-navigation="serviceNavigation"
          brand-background
          class="brand-area-fi__navigation"
          @login="$emit('login')"
        />
      </div>

      <div class="brand-area-fi__box--text">
        <brand-area-content-fi
          v-if="brandAreaContent"
          :content="brandAreaContent"
          :set-headline-tags="setHeadlineTags"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SsrCloudImageFi } from 'atoms/index';
import BrandAreaContentFi from 'components/header-fi/brand-area-fi/brand-area-content-fi/BrandAreaContentFi.vue';
import MobileNavigationBarFi from 'components/header-fi/header-top-fi/mobile-navigation-bar-fi/MobileNavigationBarFi.vue';
import { useBreakpoints } from 'composables/breakpoint';
import screenWidths from 'tailwind/screenWidths';
import { computed } from 'vue';
import { useStore } from 'vuex';

import { useFischer } from '@/composables/fischerPlugin';
import type { CheckboxField, ImageFieldValue, SingleLineTextField } from '@/sitecoreFieldTypes';
import type { SitecoreNavigation } from '@/sitecoreState';

import slideFieldsToObject from './slideFieldsToObject';

defineOptions({
  name: 'BrandAreaFi',
});

defineEmits<{
  login: [value: void];
}>();

const props = withDefaults(
  defineProps<{
    fields?: {
      showHeaderMedia?: CheckboxField;
      largeLogo?: CheckboxField;
      brandAreaHeadline?: SingleLineTextField | null;
      brandAreaSubheadline?: SingleLineTextField | null;
      slideElements?: any[]; // TODO
    };
    homeData?: any; // TODO
    logoBrandArea?: ImageFieldValue | null;
    logoHeader?: ImageFieldValue | null;
    serviceNavigation: SitecoreNavigation[];
    setHeadlineTags?: boolean;
  }>(),
  {
    fields: () => ({}),
    homeData: () => ({}),
    setHeadlineTags: true,
    logoBrandArea: null,
    logoHeader: null,
  },
);

const { isSM } = useBreakpoints();

const store = useStore();

const fischer = useFischer();

const isUpat = fischer.app.isUpat();

const showBrandArea = computed<boolean>(() => store.getters['navigation/showBrandArea']);

const showHeaderMedia = computed<boolean>(() =>
  Boolean(props.fields?.showHeaderMedia?.value && props.fields.slideElements?.length),
);

const unlimitedHeight = computed<number>(() => {
  if (isUpat) {
    return 56;
  }

  return fischer.largeLogo() ? 40 : 30;
});

const unlimited = computed<number>(() => {
  if (props.logoBrandArea) {
    const width =
      typeof props.logoBrandArea.width === 'string'
        ? Number.parseInt(props.logoBrandArea?.width)
        : props.logoBrandArea.width;
    const height =
      typeof props.logoBrandArea?.height === 'string'
        ? Number.parseInt(props.logoBrandArea?.height)
        : props.logoBrandArea.height;
    if (width && height) {
      return Math.round((width / height) * unlimitedHeight.value);
    }
  }
  return 200;
});

const breakpoints = computed(() => [
  {
    mediaWidth: screenWidths.sm,
    imageHeight: 18,
    imageWidth: (18 * unlimited.value) / unlimitedHeight.value,
  },
  {
    mediaWidth: screenWidths.lg,
    imageHeight: 27,
    imageWidth: (27 * unlimited.value) / unlimitedHeight.value,
  },
]);

const brandAreaContent = computed(() => {
  const slideElements = props.fields?.slideElements;

  const slideElementFields = showHeaderMedia.value && slideElements?.[0]?.fields;
  if (slideElementFields?.headline?.value) {
    return slideFieldsToObject(slideElementFields);
  }

  if (props.fields.brandAreaHeadline?.value || props.fields.brandAreaSubheadline?.value) {
    return {
      headline: props.fields.brandAreaHeadline?.value,
      subheadline: props.fields.brandAreaSubheadline?.value,
    };
  }

  return null;
});
</script>

<style lang="scss" scoped>
@import './brand-area-fi';
</style>
