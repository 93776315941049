// @ts-ignore
import screenWidthsUntyped from 'tailwind/screenWidths';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

export interface ScreenWidth {
  xxs: number;
  xs: number;
  smDialog: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

const screenWidths = screenWidthsUntyped as ScreenWidth;

export interface WindowDimensions {
  width: number;
  height: number;
}

export const useBreakpoints = () => {
  const store = useStore();

  const windowDimensions = computed<WindowDimensions>(
    () => store.getters['layout/getWindowDimensions'] || { width: 0, height: 0 },
  );

  const windowWidth = ref<number>(0);
  const setWindowWidth = () => {
    if (IS_SSR) {
      return;
    }
    windowWidth.value = window.innerWidth;
  };
  onMounted(() => {
    setWindowWidth();
  });

  const isXXS = computed<boolean>(() => windowWidth.value >= screenWidths.xxs);
  const isXS = computed<boolean>(() => windowWidth.value >= screenWidths.xs);
  const isSmDialog = computed<boolean>(() => windowWidth.value >= screenWidths.smDialog);
  const isSM = computed<boolean>(() => windowWidth.value >= screenWidths.sm);
  const isMD = computed<boolean>(() => windowWidth.value >= screenWidths.md);
  const isLG = computed<boolean>(() => windowWidth.value >= screenWidths.lg);
  const isXL = computed<boolean>(() => windowWidth.value >= screenWidths.xl);

  watch(() => windowDimensions.value.width, setWindowWidth);

  return {
    windowDimensions,
    windowWidth,
    isXXS,
    isXS,
    isSmDialog,
    isSM,
    isMD,
    isLG,
    isXL,
    screenWidths,
  };
};
