<template>
  <div class="brand-area-content-fi__wrapper">
    <div
      :style="{
        left: `calc(${switchedBrandAreaIndex} * -100%)`,
        width: `${100 * (brandAreaContent?.length || 1)}%`,
      }"
      :class="{
        'brand-area-content-fi__slide--fade-out': switching,
        'brand-area-content-fi__slide--fade-in': switching === false,
      }"
      class="brand-area-content-fi__slide"
      @animationend="onSwitchAnimation"
    >
      <template
        v-for="brandContent in brandContents"
        :key="`key-${brandContent.headline}`"
      >
        <component
          :is="brandContent.url ? 'a' : 'div'"
          class="brand-area-content-fi"
          :class="brandContent.url ? 'brand-area-content-fi--is-link' : null"
          :href="brandContent.url ? brandContent.url : null"
        >
          <component
            :is="setHeadlineTags ? 'h1' : 'div'"
            v-if="brandContent.headline"
            class="brand-area-content-fi__headline"
          >
            {{ brandContent.headline }}
          </component>

          <component
            :is="setHeadlineTags ? 'h2' : 'div'"
            v-if="brandContent.subheadline"
            class="brand-area-content-fi__subheadline"
          >
            {{ brandContent.subheadline.replace(/\r/g, '') }}
          </component>

          <button-fi
            v-if="brandContent.isButton && brandContent.url"
            class="brand-area-content-fi__button button-fi__tertiary button-fi__tertiary--white"
            :href="brandContent.url"
          >
            {{ brandContent.buttonText }}
          </button-fi>
          <div
            v-else-if="brandContent.url"
            class="brand-area-content-fi__arrow-container"
          >
            <arrow-icon class="brand-area-content-fi__arrow" />
          </div>
        </component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowIcon, ButtonFi } from 'atoms/index';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

export interface BrandAreaContent {
  url?: string;
  headline?: string;
  subheadline?: string;
  buttonText?: string;
  isButton?: boolean;
}

defineOptions({
  name: 'BrandAreaContentFi',
});

const props = withDefaults(
  defineProps<{
    content: BrandAreaContent;
    setHeadlineTags: boolean;
  }>(),
  {
    content: () => ({}),
    setHeadlineTags: false,
  },
);

const store = useStore();

const switching = ref<null | boolean>(null);
const switchedBrandAreaIndex = ref<number>(0);

const brandAreaIndex = computed<number>(() => store.getters['navigation/getBrandAreaIndex']);
const brandAreaContent = computed<null | BrandAreaContent[]>(
  () => store.getters['navigation/getBrandAreaContent'],
);

const brandContents = computed<BrandAreaContent[]>(() => {
  if (brandAreaContent.value?.length) {
    return brandAreaContent.value;
  }

  return [props.content];
});

const onSwitchAnimation = (evt: AnimationEvent) => {
  if (evt.animationName.startsWith('fade-out')) {
    switching.value = false;
    switchedBrandAreaIndex.value = brandAreaIndex.value;
  }
};

watch(
  () => brandAreaIndex.value,
  () => {
    switching.value = true;
  },
);
</script>

<style lang="scss" scoped>
@import './brand-area-content-fi';
</style>
