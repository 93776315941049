<template>
  <div class="teaser-group-header">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="fields.headline && (fields.headline.value || fields.headline.editable)"
      class="teaser-group-header__headline fi-mb-12 fi-semibold fi-text-size-df-30"
      v-html="isEditMode ? fields.headline.editable : fields.headline.value"
    />
    <!-- eslint-enable vue/no-v-html -->
    <component
      :is="headlineTag"
      v-if="fields.paragraph && (fields.paragraph.value || fields.paragraph.editable)"
      class="teaser-group-header__paragraph fi-mb-72 fi-semibold fi-text-size-df-60"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="isEditMode ? fields.paragraph.editable : fields.paragraph.value" />
      <!-- eslint-enable vue/no-v-html -->
    </component>
  </div>
</template>

<script>
export default {
  name: 'TeaserGroupHeader',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    headlineTag: {
      type: String,
      default: 'h3',
    },
  },
};
</script>

<style lang="scss">
@import './teaser-group-header';
</style>
