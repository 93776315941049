export default function authorize(
  anonymous = false,
  authenticated = false,
  groups = [],
  membership = 'any',
  deny = false,
) {
  // Always allow access in Experience Editor
  if (this.app.isEditMode()) {
    return true;
  }

  // No access control settings defined (default): grant access
  if (!anonymous && !authenticated) {
    return true;
  }

  // Grant access only to anonymous users
  if (anonymous) {
    // User must not be authenticted to get access
    if (!this.user.authenticated()) {
      return true;
    }
  }

  // Grant access only to authenticated users
  if (authenticated) {
    // Deny anonymous users
    if (!this.user.authenticated()) {
      return false;
    }

    // Until now, access is granted
    let result = true;

    // Check, if access is narrowed by group settings
    if (Array.isArray(groups) && groups.length > 0) {
      // OR implementation:
      // Access is granted / denied if the user is member of ANY configured group
      if (!membership || membership === 'any') {
        // Set the default to false for allow, true for deny
        result = deny;
        // Check each group if it matches the users groups
        groups.forEach((group) => {
          if (this.user.groups().includes(group.fields.Value.value)) {
            // Grant / deny access...
            result = !deny;
            // ...and quit loop
            return false;
          }
          // search further...
          return true;
        });
        // AND implementation:
        // Access is granted / denied if the user is member of ALL groups
      } else if (membership === 'all') {
        // Set default inverse
        result = !deny;
        groups.forEach((group) => {
          if (!this.user.groups().includes(group.fields.Value.value)) {
            // Deny / Grant access...
            result = deny;
            // ...and quit loop
            return false;
          }
          // search further...
          return true;
        });
      }
    }
    // Return result of group matching
    return result;
  }
  // Default behaviour: deny access
  return false;
}
