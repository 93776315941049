<template>
  <div
    :class="{
      'richtext-fi': true,
      'richtext-fi--light': isThemeLight,
      'richtext-fi--dark-bg': isBgDark,
    }"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="!isEditMode && customizedContent"
      ref="text"
      @click.capture="richtextClicked"
      v-html="customizedContent"
    />
    <div
      v-if="isEditMode && htmlContent?.editable"
      v-html="htmlContent.editable"
    />
    <!-- eslint-enable vue/no-v-html -->

    <slot />
  </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from 'composables/breakpoint';
import { useFischer } from 'composables/fischerPlugin';
import type { MarkSsrOnly } from 'composables/ssrOnly';
import { computed, inject, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useStore } from 'vuex';

import type { FischerPlugin } from '@/lib/fischerPlugin';
import type { SingleLineTextField } from '@/sitecoreFieldTypes';
import smoothScroll from '@/utils/smoothScroll';

defineOptions({
  name: 'RichtextFi',
});

const inhibitMarkSsrOnly = inject<MarkSsrOnly>('inhibitMarkSsrOnly');

const props = withDefaults(
  defineProps<{
    htmlContent?: SingleLineTextField;
    isThemeLight?: boolean;
    isBgDark?: boolean;
    forceLoginOnStart?: boolean;
  }>(),
  {
    htmlContent: undefined,
    isThemeLight: false,
    isBgDark: false,
  },
);

const store = useStore();
const fischer = useFischer();
const { windowDimensions } = useBreakpoints();

const textRef = useTemplateRef<HTMLDivElement>('text');
const tables = ref<null | ReturnType<Element['querySelectorAll']>>(null);

const dialogPages: (keyof FischerPlugin['pages'])[] = [
  'login',
  'registration',
  'passwordForgotten',
];

const customizedContent = computed<undefined | string>(() => {
  let content = props.htmlContent?.value;
  if (!content) {
    return undefined;
  }
  // add container if table is used
  content = content.replace(
    /<table/g,
    '<div class="richtext-fi__table"><div class="richtext-fi__table__container"><table',
  );
  content = content.replace(/<\/table>/g, '</table></div></div>');

  // modifiy caption
  content = content.replace(/<caption/g, '<div class="richtext-fi__table__caption"');
  content = content.replace(/<\/caption>/g, '</div>');

  return content;
});
if (IS_SSR && inhibitMarkSsrOnly) {
  watch(customizedContent, (value) => {
    if (!value || !fischer.isLoginDialogEnabled()) {
      return;
    }
    dialogPages.forEach((dialogPage) => {
      const url = fischer.pages[dialogPage]().url;
      if (!url) {
        return;
      }
      const urlFull = `${fischer.hostUrlBase()}${url}`;
      if (value.includes(url) || value.includes(urlFull)) {
        inhibitMarkSsrOnly();
      }
    });
  });
}

const richtextClicked = (evt: MouseEvent) => {
  if (!fischer.isLoginDialogEnabled()) {
    return;
  }

  const target = (evt.target as HTMLAnchorElement)?.href;
  if (!target) {
    return;
  }
  dialogPages.forEach((dialogPage) => {
    const url = fischer.pages[dialogPage]().url;
    const urlFull = `${fischer.hostUrlBase()}${url}`;

    if (target === url || target === urlFull) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      console.debug(`opening ${dialogPage} dialog`);
      store.commit('navigation/TRIGGER_LOGIN', {
        modal: dialogPage,
        forceLoginOnStart: props.forceLoginOnStart,
      });
    }
  });
};

const setTablesScrollable = () => {
  tables.value?.forEach((table) => {
    if (table.getElementsByTagName('table')[0].clientWidth > table.clientWidth) {
      table.classList.remove('richtext-fi__table--not-scrollable');
      table.classList.add('richtext-fi__table--scrollable');
    } else {
      table.classList.remove('richtext-fi__table--scrollable');
      table.classList.add('richtext-fi__table--not-scrollable');
    }
  });
};

const onTableScroll = (event: Event) => {
  const table = event.target as HTMLTableElement;
  const tableWidth = table.clientWidth;
  const scrollLeft = table.scrollLeft;
  const scrollWidth = table.scrollWidth - 20;
  if (tableWidth + scrollLeft >= scrollWidth) {
    table.parentElement?.classList.add('richtext-fi__table--scroll-end');
  } else {
    table.parentElement?.classList.remove('richtext-fi__table--scroll-end');
  }
};

watch(() => windowDimensions.value.width, setTablesScrollable);

onMounted(() => {
  if (!textRef.value) {
    return;
  }

  smoothScroll(textRef.value);

  tables.value = textRef.value?.querySelectorAll('div.richtext-fi__table');
  tables.value?.forEach((table) => {
    table
      .querySelector('.richtext-fi__table__container')
      ?.addEventListener('scroll', onTableScroll);
  });
  setTablesScrollable();
});

onBeforeUnmount(() => {
  tables.value?.forEach((table) => {
    table.removeEventListener('scroll', onTableScroll);
  });
});
</script>

<style lang="scss">
@import './richtext-fi';
</style>
