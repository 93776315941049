<template>
  <div class="mobile-navigation-sub-items-fi">
    <div
      v-if="groupData.teaserImage?.src"
      class="mobile-navigation-sub-items-fi__image"
    >
      <cloud-image-fi :src="groupData.teaserImage.src" />
    </div>
    <div
      v-else
      class="mobile-navigation-sub-items-fi__top"
    />

    <ul class="mobile-navigation-sub-items-fi__list mobile-navigation-sub-items-fi__list--services">
      <li
        class="mobile-navigation-sub-items-fi__list__item mobile-navigation-sub-items-fi__list__item--group"
      >
        <a
          :href="groupData.url"
          :target="groupData.target ? groupData.target : null"
          class="mobile-navigation-sub-items-fi__list__item__wrapper"
        >
          <span class="mobile-navigation-sub-items-fi__list__item__label">
            {{ navigationLayerTitle(groupData) }}
          </span>

          <arrow-icon class="mobile-navigation-sub-items-fi__list__item__icon" />
        </a>
      </li>
    </ul>

    <div
      v-for="group in navigationGroups"
      :key="group.id"
      :class="{
        'mobile-navigation-sub-items-fi__group': true,
        'mobile-navigation-sub-items-fi__no-label': !group.groupLabel,
      }"
    >
      <span
        v-if="group.groupLabel"
        class="mobile-navigation-sub-items-fi__group-label"
        >{{ group.groupLabel }}
      </span>

      <ul class="mobile-navigation-sub-items-fi__list">
        <li
          v-for="item in group.groupList"
          :key="item.id"
          :class="{
            'mobile-navigation-sub-items-fi__list__item': true,
          }"
        >
          <a
            :href="item.url"
            :target="item.target ? item.target : null"
            class="mobile-navigation-sub-items-fi__list__item__wrapper"
          >
            <span class="mobile-navigation-sub-items-fi__list__item__label">
              {{ item.label }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ArrowIcon, CloudImageFi } from 'atoms';
import navigationMixin from 'mixins/navigationMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'MobileNavigationSubItemsFi',
  components: {
    CloudImageFi,
    ArrowIcon,
  },
  mixins: [navigationMixin],
  computed: {
    ...mapGetters({
      groupData: 'navigation/getGroupData',
      subElements: 'navigation/getSubElements',
    }),
    navigationGroups() {
      return this.getNavigationGroups(this.subElements);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mobile-navigation-sub-items-fi';
</style>
