<script>
export default {
  methods: {
    getSearchLocation(query) {
      const searchUrl = this.$fischer.pages.search().url;
      if (query) {
        return `${searchUrl}?query=${encodeURIComponent(query)}`;
      }
      return searchUrl;
    },
  },
};
</script>
