<script>
import colors from 'tailwind/colors';

export default {
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    colorClass() {
      const color = colors.typo[this.color] ? this.color : 'black';
      return `fi-text-${color}`;
    },
  },
};
</script>
