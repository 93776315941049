<script>
import JssFormsEventBus from 'jss/jss-forms-event-bus';

export default {
  computed: {
    currentElementKey() {
      return this.$el?.getAttribute('data-form-element-key') || null;
    },
  },
  mounted() {
    JssFormsEventBus.$emit('forms_init:field-conditions', this.currentElementKey);
  },
};
</script>
