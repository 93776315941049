<template>
  <component
    :is="item.fields.link?.value.href && !isVideo(item.fields) ? 'a' : 'div'"
    class="header-media-item-fi"
    :class="item.fields.link?.value.href ? 'header-media-item-fi--link' : null"
    :href="item.fields.link?.value.href ? item.fields.link.value.href : null"
  >
    <div
      v-if="!isVideo(item.fields)"
      class="header-media-item-fi__image"
    >
      <picture>
        <source
          v-if="item.fields.imageMobile?.value?.src"
          media="(max-width: 767px)"
          :srcset="
            appendParams(
              `${getProxySrc(item.fields.imageMobile.value.src)}`,
              'h=260&func=crop&org_if_sml=0',
            )
          "
        />
        <source
          v-if="item.fields.imageTablet?.value?.src"
          media="(max-width: 1439px)"
          :srcset="
            appendParams(
              `${getProxySrc(item.fields.imageTablet.value.src)}`,
              'h=386&func=crop&org_if_sml=0',
            )
          "
        />
        <img
          v-if="item.fields.image?.value?.src"
          :src="
            appendParams(
              `${getProxySrc(item.fields.image.value.src)}`,
              'h=648&func=crop&org_if_sml=0',
            )
          "
          :alt="item.fields.image.value.alt || item.fields.headline?.value || null"
        />
      </picture>
    </div>
    <template v-else>
      <video-player-fi
        class="header-media-item-fi__video"
        :fields="item.fields"
        :show-hide-delay="1000"
        :reset-video="slideChanged"
        preview-params="func=crop&org_if_sml=0"
        @video="videoEvent"
      />
    </template>
  </component>
</template>

<script>
import VideoPlayerFi from 'components/video-player-fi/VideoPlayerFi';
import breakpointMixin from 'mixins/breakpointMixin';
import cloudImageMixin from 'mixins/cloudImageMixin';
import videoMixin from 'mixins/videoMixin';

export default {
  name: 'HeaderMediaItemFi',
  components: {
    VideoPlayerFi,
  },
  mixins: [breakpointMixin, videoMixin, cloudImageMixin],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    slideChanged: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['pause-slider'],
  methods: {
    videoEvent(event) {
      if (event === 'beforePlaying') {
        this.$store.dispatch('navigation/showBrandArea', false);
        this.$emit('pause-slider', true);
      }

      if (event === 'hid') {
        this.$store.dispatch('navigation/showBrandArea', true);
        this.$emit('pause-slider', false);
      }
    },
  },
};
</script>

<style lang="scss">
@import './header-media-item-fi';
</style>
