<template>
  <div
    :class="{
      'component-wrapper': true,
      ...wrapperClasses,
    }"
  >
    <cloud-image-provider :cloud-image-config="cloudimageConfig">
      <slot />
    </cloud-image-provider>
  </div>
</template>

<script setup lang="ts">
import useComponentWrapper from 'composables/componentWrapper';
import screenWidths from 'tailwind/screenWidths';
import { useSSRContext } from 'vue';
import { CloudImageProvider } from 'vue-cloudimage-responsive-plain';

const props = defineProps<{
  forceTheme?: string;
}>();

const { themeName, wrapperClasses } = useComponentWrapper(props.forceTheme);

const cloudimageConfig = {
  token: 'ayyppajfrq',
  customDomain: 'media.fischer.group',
  params: 'org_if_sml=0',
  placeholderBackground: 'transparent',
  presets: {
    xxs: '(min-width: 0)',
    xs: `(min-width: ${screenWidths.xs}px)`,
    sm: `(min-width: ${screenWidths.sm}px)`,
    md: `(min-width: ${screenWidths.md}px)`,
    lg: `(min-width: ${screenWidths.lg}px)`,
    xl: `(min-width: ${screenWidths.xl}px)`,
  },
};

if (IS_SSR) {
  const ctx = useSSRContext();
  if (ctx) {
    ctx.theme = themeName.value;
  }
}
</script>

<style lang="scss">
@import './component-wrapper';
</style>
