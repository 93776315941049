<template>
  <div
    :class="{
      'header-fi': true,
      'header-fi--brand-area': showBrandArea,
      'header-fi--search-active': isSearchActive,
      'page-header-fi--open': navIndex !== -1,
      ...headerHeightClasses,
    }"
  >
    <div v-if="!showCheckoutHeader">
      <search-box-mobile-fi v-if="!isLG" />

      <brand-area-fi
        v-if="showBrandArea"
        :fields="fields"
        :home-data="homeData"
        :logo-brand-area="logoBrandArea"
        :service-navigation="serviceNavigation"
        :set-headline-tags="brandAreaHeadlineTags"
        @login="login"
      />

      <header-top-fi
        :home-data="homeData"
        :navigation-data="navigationData"
        :service-navigation="serviceNavigation"
        :logo-header="logoHeader"
        :handling-fee-limit="handlingFeeLimit"
        @login="login"
      />

      <div
        :class="{
          'header-fi__divider': true,
          'header-fi__divider--brand': showBrandArea,
        }"
      />

      <transition
        leave-active-class="animated fadeOutUp fast"
        enter-active-class="animated fadeInDown fast"
      >
        <navigation-flyout-fi
          v-if="(showFlyout && isSM) || isSearchSuggestions || (isCountrySelect && isSM)"
          @close="closeNavigationFlyout"
        />
      </transition>

      <mobile-navigation-flyout-fi
        :home-data="homeData"
        :navigation-data="navigationData"
        :logo-header="logoHeader"
        :service-navigation="serviceNavigation"
      />

      <header-media-fi
        v-if="showHeaderMedia"
        :fields="fields"
      />

      <breadcrumb-fi
        v-if="showBreadcrumb || showBackLink"
        :show-breadcrumb="showBreadcrumb"
        :back-link="backLink"
        :breadcrumb-data="breadcrumbData"
      />

      <toast-notification-fi v-if="!isEditMode" />
    </div>

    <div v-else>
      <header-checkout-fi
        :home-data="homeData"
        :logo-header="logoHeader"
      />

      <toast-notification-fi v-if="!isEditMode" />
    </div>

    <login-dialog-handler-fi ref="loginDialogHandler" />
  </div>
</template>

<script setup lang="ts">
// Unfortunately the banner has to be initialized here, because
// the store is not reactive in SSR mode (requesting the banner
// from the HandlingFeeBannderFi module does not propagate here)
import { BreadcrumbFi } from 'atoms/index';
import { useHandlingFeeSetup } from 'components/handling-fee-limit-banner-fi/handlingFeeSetup';
import BrandAreaFi from 'components/header-fi/brand-area-fi/BrandAreaFi.vue';
import HeaderCheckoutFi from 'components/header-fi/header-checkout-fi/HeaderCheckoutFi.vue';
import HeaderMediaFi from 'components/header-fi/header-media-fi/HeaderMediaFi.vue';
import HeaderTopFi from 'components/header-fi/header-top-fi/HeaderTopFi.vue';
import MobileNavigationFlyoutFi from 'components/header-fi/mobile-navigation-flyout-fi/MobileNavigationFlyoutFi.vue';
import NavigationFlyoutFi from 'components/header-fi/navigation-flyout-fi/NavigationFlyoutFi.vue';
import SearchBoxMobileFi from 'components/header-fi/search-box-mobile-fi/SearchBoxMobileFi.vue';
import LoginDialogHandlerFi from 'components/login-dialog-fi/LoginDialogHandlerFi.vue';
import ToastNotificationFi from 'components/notifications-fi/toast-notification-fi/ToastNotificationFi.vue';
import { useBreakpoints } from 'composables/breakpoint';
import { useHeaderHeight } from 'composables/headerHeight';
import { useNavigation } from 'composables/navigation';
import { computed, provide, ref, useTemplateRef, watch } from 'vue';
import { useStore } from 'vuex';

import { useFischer } from '@/composables/fischerPlugin';
import type { CheckboxField, ImageFieldValue, LinkFieldValue } from '@/sitecoreFieldTypes';
import type { SitecoreNavigation } from '@/sitecoreState';

import type { BreadCrumbItem } from '../atoms/breadcrumb-fi/BreadcrumbFi.vue';

defineOptions({
  name: 'HeaderFi',
});

const props = withDefaults(
  defineProps<{
    fields: {
      showBrandArea?: CheckboxField;
      showBreadcrumb?: CheckboxField;
      showHeaderMedia?: CheckboxField;
      brandAreaHeadlineTags?: CheckboxField;
      slideElements?: any[];
    };
    logoHeader: ImageFieldValue | undefined;
    logoBrandArea?: ImageFieldValue | undefined;
    homeData?: LinkFieldValue | undefined;
    navigationData: SitecoreNavigation[];
    serviceNavigation: SitecoreNavigation[];
    breadcrumbData: BreadCrumbItem[] | undefined;
  }>(),
  {
    fields: () => ({}),
    logoHeader: undefined,
    logoBrandArea: undefined,
    homeData: undefined,
    navigationData: () => [],
    serviceNavigation: () => [],
    breadcrumbData: undefined,
  },
);

const { isSM, isLG } = useBreakpoints();

const { closeNavigationFlyout } = useNavigation();

const store = useStore();

const fischer = useFischer();

const { handlingFeeLimit } = useHandlingFeeSetup();

const { headerHeightClasses } = useHeaderHeight();

const serviceNavigationI18n = ref<null | SitecoreNavigation>(null);
provide('getServiceNavigationI18n', () => serviceNavigationI18n);

const loginDialogHandler = useTemplateRef<typeof LoginDialogHandlerFi>('loginDialogHandler');

const showFlyout = computed<boolean>(() => store.getters['navigation/getFlyout']);
const navIndex = computed<number>(() => store.getters['navigation/getNavIndex']);
const isSearchSuggestions = computed<boolean>(
  () => store.getters['navigation/getShowSearchSuggest'],
);
const backLink = computed<any>(() => store.getters['navigation/getBackLink']);
const isSearchActive = computed<boolean>(() => store.getters['navigation/searchActive']);
const isCheckout = computed<boolean>(() => store.getters['navigation/isCheckout']);
const isCountrySelect = computed<boolean>(() => store.getters['navigation/isCountrySelect']);

const showBrandArea = computed<boolean>(() => Boolean(props.fields.showBrandArea?.value));

const showBreadcrumb = computed<boolean>(() =>
  Boolean(props.fields.showBreadcrumb?.value && props.breadcrumbData?.length),
);
const showBackLink = computed<boolean>(() => Boolean(backLink.value?.url));
const showHeaderMedia = computed<boolean>(() =>
  Boolean(props.fields.showHeaderMedia?.value && props.fields.slideElements?.length),
);
const isSlider = computed<boolean>(() =>
  Boolean(props.fields.slideElements?.length && props.fields.slideElements.length > 1),
);

const brandAreaHeadlineTags = computed<boolean>(() => {
  if (showHeaderMedia.value && isSlider.value) {
    return false;
  }

  return Boolean(props.fields.brandAreaHeadlineTags?.value);
});

const showCheckoutHeader = computed<boolean>(
  () =>
    fischer.pages.checkout().url !== undefined &&
    fischer.uri() === fischer.pages.checkout().url &&
    isCheckout.value,
);

const login = () => {
  store.commit('navigation/TRIGGER_LOGIN');
};
watch(
  () => store.getters['navigation/triggerLogin'],
  (value) => {
    if (value) {
      loginDialogHandler.value?.login();
    }
  },
  {
    immediate: true,
  },
);
</script>

<style lang="scss">
@import './header-fi-unscoped';
</style>

<style lang="scss" scoped>
@import './header-fi';
</style>
