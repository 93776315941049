<template>
  <div
    v-if="showFlyout || isSearchSuggestions || isCountrySelect"
    key="subnavi"
    v-click-outside="clickOutside"
    :class="{
      'navigation-flyout-fi': true,
      'navigation-flyout-fi--search': isSearchSuggestions,
      'navigation-flyout-fi--country-select': isCountrySelect,
    }"
  >
    <transition
      mode="out-in"
      leave-active-class="animated fadeOut fastest"
      enter-active-class="animated fadeIn fastest"
    >
      <div :key="groupData.id">
        <div
          v-if="!isSearchSuggestions && isCountrySelect && !isSM"
          class="navigation-flyout-fi__mobile-header"
        >
          <div
            class="navigation-flyout-fi__mobile-header__back"
            @click="backToFirstLvl"
          >
            <icon-fi
              class="navigation-flyout-fi__mobile-header__icon"
              icon="arrow-left"
              size="2"
            />
          </div>

          <div class="navigation-flyout-fi__mobile-header__title">
            <template v-if="isCountrySelect">
              {{ $dict.get('global-Labels.Country-Selection.Mobile-Title') }}
            </template>
            <template v-else>
              {{ groupData.label }}
            </template>
          </div>

          <div
            class="navigation-flyout-fi__mobile-header__close"
            @click="hideFlyout"
          >
            <icon-fi
              class="navigation-flyout-fi__mobile-header__icon"
              icon="close"
              size="2"
            />
          </div>
        </div>
        <grid-container-fi
          :container="!isSearchSuggestions"
          class="navigation-flyout-fi__content"
        >
          <div
            v-if="!(isProducts || isCompany || isSearchSuggestions || isCountrySelect) && isMD"
            class="navigation-flyout-fi__image fi-col-start-1 fi-col-end-6 lg:fi-col-start-2"
          >
            <navigation-image-fi />
          </div>

          <div
            class="navigation-flyout-fi__container fi-col-start-1 fi-col-end-13 xxl:fi-col-end-12"
            :class="{
              'xxl:fi-col-start-2':
                (isProducts || isCompany || isCountrySelect) && !isSearchSuggestions,
              'xxl:fi-col-end-13': isSearchSuggestions,
              'md:fi-col-start-6 md:fi-pl-42': !(
                isProducts ||
                isCompany ||
                isSearchSuggestions ||
                isCountrySelect
              ),
              'navigation-flyout-fi__container--country-select': isCountrySelect,
            }"
          >
            <search-suggestions-fi v-if="isSearchSuggestions">
              <div
                class="navigation-flyout-fi__close"
                @click="closeSubnavigation()"
              >
                <span>{{ $dict.get('global-Labels.Navigation.Close') }}</span>
                <icon-fi
                  :icon="'chevron-up'"
                  class="navigation-flyout-fi__close--icon"
                />
              </div>
            </search-suggestions-fi>

            <template v-else-if="isCountrySelect">
              <div class="navigation-flyout-fi__actions">
                <div class="navigation-flyout-fi__country">
                  <country-selection-locale-fi
                    is-navigation
                    @show-country-selection="hideFlyout"
                  />
                </div>
                <div
                  class="navigation-flyout-fi__close navigation-flyout-fi__close--country-selection"
                  @click="closeSubnavigation()"
                >
                  <span>{{ $dict.get('global-Labels.Navigation.Close') }}</span>
                  <icon-fi
                    :icon="'chevron-up'"
                    class="navigation-flyout-fi__close--icon"
                  />
                </div>
              </div>
            </template>

            <template v-else>
              <div class="navigation-flyout-fi__actions">
                <div
                  v-if="groupData"
                  class="navigation-flyout-fi__links"
                >
                  <template v-if="groupData.url && !isCompany">
                    <span
                      class="navigation-flyout-fi__links--item button-fi__text--secondary button-fi__text--18"
                    >
                      <link-fi
                        :href="groupData.url"
                        :size="18"
                      >
                        {{ navigationLayerTitle(groupData) }}
                      </link-fi>
                    </span>
                  </template>
                  <template v-if="!groupData.url && !isCompany">
                    <headline-fi
                      tag="span"
                      :size="18"
                      class="navigation-flyout-fi__links--item"
                    >
                      {{ groupData.label }}
                    </headline-fi>
                  </template>

                  <template v-if="isProducts">
                    <link-fi
                      v-for="item in productHeaderNavigation"
                      :key="item.id"
                      :href="item.url"
                      :size="18"
                      class="navigation-flyout-fi__links--item"
                    >
                      {{ item.label }}
                    </link-fi>
                  </template>

                  <template v-if="isCompany">
                    <link-fi
                      v-for="item in companyHeaderNavigation"
                      :key="item.id"
                      :href="item.url"
                      :size="18"
                      class="navigation-flyout-fi__links--item"
                    >
                      {{ item.label }}
                    </link-fi>
                  </template>
                </div>
                <div
                  class="navigation-flyout-fi__close"
                  @click="closeSubnavigation()"
                >
                  <span>{{ $dict.get('global-Labels.Navigation.Close') }}</span>
                  <icon-fi
                    :icon="'chevron-up'"
                    class="navigation-flyout-fi__close--icon"
                  />
                </div>
              </div>

              <div
                v-if="!isProducts && !isCompany && !isMD"
                class="navigation-flyout-fi__container__image"
              >
                <navigation-image-fi />
              </div>

              <div
                class="navigation-flyout-fi__subnavigation"
                :class="[
                  isProducts ? 'navigation-flyout-fi__subnavigation--products' : null,
                  isCompany ? 'navigation-flyout-fi__subnavigation--company' : null,
                ]"
              >
                <navigation-subnavigation-fi />
              </div>
            </template>
          </div>
        </grid-container-fi>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { HeadlineFi, IconFi, LinkFi } from 'atoms/index';
import CountrySelectionLocaleFi from 'components/country-selection-locale-fi/CountrySelectionLocaleFi.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import { computed, defineAsyncComponent, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

import { useBreakpoints } from '@/composables/breakpoint';
import { useNavigation } from '@/composables/navigation';
import type { SitecoreNavigation } from '@/sitecoreState';

import NavigationImageFi from './navigation-image-fi/NavigationImageFi.vue';
import NavigationSubnavigationFi from './navigation-subnavigation-fi/NavigationSubnavigationFi.vue';

const SearchSuggestionsFi = defineAsyncComponent(
  () => import('components/search/search-suggestions-fi/SearchSuggestionsFi.vue'),
);

defineOptions({
  name: 'NavigationFlyoutFi',
});

const emit = defineEmits<{
  close: [value: void];
}>();

const { isSM, isMD } = useBreakpoints();
const { navigationLayerTitle } = useNavigation();
const store = useStore();

const subElements = computed<SitecoreNavigation[]>(
  () => store.getters['navigation/getSubElements'],
);
const showFlyout = computed<boolean>(() => store.getters['navigation/getFlyout']);
const groupData = computed<SitecoreNavigation>(() => store.getters['navigation/getGroupData']);
const isProducts = computed<boolean>(() => store.getters['navigation/isProducts']);
const isCompany = computed<boolean>(() => store.getters['navigation/isCompany']);
const isSearchSuggestions = computed<boolean>(
  () => store.getters['navigation/getShowSearchSuggest'],
);
const isCountrySelect = computed<boolean>(() => store.getters['navigation/isCountrySelect']);

onBeforeUnmount(() => {
  store.commit('navigation/SHOWFLYOUT', false);
  store.commit('navigation/NAVINDEX', -1);
  if (isSearchSuggestions.value) {
    store.commit('navigation/SHOWBRANDAREA', true);
  }
});

const closeSubnavigation = () => {
  store.commit('navigation/ISPRODUCTS', false);
  store.commit('navigation/ISCOMPANY', false);
  store.commit('navigation/SHOWBRANDAREA', true);
  store.commit('navigation/SHOWFLYOUT', false);
  store.commit('navigation/NAVINDEX', -1);
  store.commit('navigation/GROUPDATA', {});
  store.commit('navigation/SUBELEMENTS', []);
  store.commit('navigation/IS_COUNTRY_SELECT', false);
  store.dispatch('navigation/showSearchSuggest', false);
};

// return max 3 elements
const productHeaderNavigation = computed<SitecoreNavigation[]>(() => subElements.value.slice(0, 3));

const companyHeaderNavigation = computed<SitecoreNavigation[]>(() => {
  const headerLinks: SitecoreNavigation[] = [];

  subElements.value.forEach((element: SitecoreNavigation) => {
    // return max 3 elements
    if (!element.isProxy && headerLinks.length < 3) {
      headerLinks.push(element);
    }
  });

  return headerLinks;
});

const backToFirstLvl = () => {
  store.commit('navigation/SUBELEMENTS', []);
  store.commit('navigation/GROUPDATA', {});
  store.commit('navigation/NAVINDEX', -1);
  store.commit('navigation/SHOWFLYOUT', false);
  store.commit('navigation/SHOWMOBILEFIRSTLVL', true);
  store.commit('navigation/IS_COUNTRY_SELECT', false);
};

const hideFlyout = () => {
  store.commit('navigation/SHOWBRANDAREA', true);
  store.commit('navigation/SHOWFLYOUT', false);
  store.commit('navigation/IS_COUNTRY_SELECT', false);
};

const clickOutside = (e: MouseEvent | null) => {
  const target = e?.target as HTMLElement | undefined;
  if (!target) {
    return;
  }

  const navListItem = target.closest('li');
  if (
    target.className !== 'navigation-bar-fi__item--text' &&
    navListItem &&
    !navListItem.classList.contains('navigation-bar-fi__item-I18N-selectCountry')
  ) {
    emit('close');
  }
};
</script>

<style lang="scss" scoped>
@import './navigation-flyout-fi';
</style>
