const lowerCaseKeys = (object, recursive) => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  if (Array.isArray(object) && recursive) {
    return object.map((entry) => lowerCaseKeys(entry, true));
  }
  const result = {};
  Object.entries(object).forEach(([key, value]) => {
    const lowerCaseKey = key.replace(
      /^(.)(.*)/,
      (_match, first, rest) => `${first.toLowerCase()}${rest}`,
    );
    result[lowerCaseKey] = recursive ? lowerCaseKeys(value, true) : value;
  });
  return result;
};

export default lowerCaseKeys;
