<template>
  <splide-slide :class="['carousel-slide-fi', theme ? `carousel-slide-fi--theme-${theme}` : '']">
    <slot />
  </splide-slide>
</template>

<script>
import { SplideSlide } from '@splidejs/vue-splide';

export default {
  name: 'CarouselSlideFi',
  components: {
    SplideSlide,
  },
  props: {
    theme: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import './carousel-slide-fi.scss';
</style>
