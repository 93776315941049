import GET_SEARCH_SUGGESTIONS_NG from 'gql/getSearchSuggestionsNg.gql';
import { throttle } from 'throttle-debounce';

import getEventHubSessionId from '@/lib/getEventHubSessionId';

const getSearchSuggestionsThrottled = throttle(
  400,
  async ({ query, dispatch, commit, getters, rootGetters }) => {
    const apollo = getters.getApollo;
    if (!apollo) {
      console.error('No apollo for search!');
      return;
    }

    if (query.length < 2 && !rootGetters['navigation/isSearch']) {
      dispatch('setShowSuggestionsFlyout', false);
      return;
    }

    const getSearchSuggestionsByChannel = async (channel) => {
      const {
        data: { getSearchSuggestionsNg },
      } = await apollo.query({
        query: GET_SEARCH_SUGGESTIONS_NG,
        variables: {
          query,
          channelSuffix: channel,
          factFinderChannel: getters.factFinderChannel,
          sid: getEventHubSessionId(),
        },
      });

      const products = getSearchSuggestionsNg.filter((item) => item.type === 'productName');
      const isRequestedPageAlreadyOpened = window.location.pathname.includes(
        products[0]?.attributesObject?.Slug,
      );

      if (
        /^\d{4,6}$/.test(getters.getQuery) &&
        products?.length === 1 &&
        getSearchSuggestionsNg.filter((item) => item.type === 'WebsiteContent')?.length === 0
      ) {
        if (
          getters.getQuery.padStart(6, '0') === products[0]?.attributesObject.ID &&
          !isRequestedPageAlreadyOpened
        ) {
          const redirectUrl = getters.productUrlBase + products[0]?.attributesObject?.Slug;

          if (getters.getQueryEnter) {
            window.location.href = redirectUrl;
          } else {
            dispatch('setProductRedirectUrl', redirectUrl);
          }
        } else {
          dispatch('setProductRedirectUrl', null);
        }

        if (getters.getQueryEnter && isRequestedPageAlreadyOpened) {
          dispatch('navigation/showFlyout', false, { root: true });
          dispatch('navigation/showSearchSuggest', false, { root: true });
          dispatch('setProductRedirectUrl', null);
          dispatch('setQuery', '');
        }
      }

      if (getters.getQueryEnter) {
        dispatch('setQueryEnter', false);
      }

      return getSearchSuggestionsNg;
    };

    const [catalogSuggestions, contentSuggestions] = await Promise.all([
      getters.hasProducts ? getSearchSuggestionsByChannel('catalog') : [],
      getSearchSuggestionsByChannel('content'),
    ]);
    const searchSuggestions = [...catalogSuggestions, ...contentSuggestions];

    const isFlyoutShown = rootGetters['navigation/getFlyout'];
    const isSearchSuggestShown =
      rootGetters['navigation/getShowSearchSuggest'] && getters['getQuery'] !== query;
    const currentQueryTrimmed = getters.getQueryTrimmed;
    const currentSuggestionsQuery = getters.suggestionsQuery;

    if (query === currentQueryTrimmed && getters.hasProducts) {
      commit('SET_SEARCH_SUGGESTIONS', { suggestions: searchSuggestions, query });
      if (!(isFlyoutShown && isSearchSuggestShown) && currentSuggestionsQuery !== query) {
        dispatch('setShowSuggestionsFlyout', true);
      }
    } else if (!currentQueryTrimmed && isFlyoutShown) {
      dispatch('setShowSuggestionsFlyout', false);
    }
  },
);

const search = {
  namespaced: true,
  state: () => ({
    query: '',
    queryTrimmed: '',
    queryEnter: false,
    searchSuggestions: [],
    suggestionsQuery: '',
    requestSuggestions: true,
    inhibitSuggestions: false,

    apollo: null,
    locale: '',
    brandId: '',
    factFinderChannel: '',
    hasProducts: false,
    productUrlBase: '',
    productRedirectUrl: '',
  }),

  mutations: {
    SET_QUERY(state, query = '') {
      state.query = query;
      state.queryTrimmed = query.trim();
    },
    SET_QUERY_ENTER(state, queryEnter) {
      state.queryEnter = queryEnter;
    },
    SET_APOLLO(state, apollo) {
      state.apollo = apollo;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
    SET_BRAND_ID(state, brandId) {
      state.brandId = brandId;
    },
    SET_FACT_FINDER_CHANNEL(state, factFinderChannel) {
      state.factFinderChannel = factFinderChannel;
    },
    SET_HAS_PRODUCTS(state, hasProducts) {
      state.hasProducts = hasProducts;
    },
    SET_SEARCH_SUGGESTIONS(state, { suggestions, query = '' }) {
      state.searchSuggestions = suggestions;
      state.suggestionsQuery = query;
    },
    SET_REQUEST_SUGGESTIONS(state, requestSuggestions) {
      state.requestSuggestions = requestSuggestions;
    },
    INHIBIT_SUGGESTIONS(state, inhibitSuggestions) {
      state.inhibitSuggestions = inhibitSuggestions;
    },
    SET_PRODUCT_URL_BASE(state, productUrlBase) {
      state.productUrlBase = productUrlBase;
    },
    SET_PRODUCT_REDIRECT_URL(state, productRedirectUrl) {
      state.productRedirectUrl = productRedirectUrl;
    },
  },

  actions: {
    setQueryData(
      { commit },
      { apollo, brandId, factFinderChannel, locale, hasProducts, productUrlBase },
    ) {
      commit('SET_APOLLO', apollo);
      commit('SET_BRAND_ID', brandId);
      commit('SET_LOCALE', locale);
      commit('SET_HAS_PRODUCTS', hasProducts);
      commit('SET_FACT_FINDER_CHANNEL', factFinderChannel);
      commit('SET_PRODUCT_URL_BASE', productUrlBase);
    },
    setShowSuggestionsFlyout({ dispatch }, value) {
      dispatch('navigation/showSearchSuggest', value, { root: true });
      dispatch('navigation/showFlyout', value, { root: true });
    },
    setQuery({ commit, dispatch, getters, rootGetters }, queryParam) {
      const query = typeof queryParam === 'string' ? queryParam : queryParam.value || '';

      commit('SET_QUERY', query);
      dispatch('setQueryEnter', false);
      dispatch('setProductRedirectUrl', '');

      const showSuggestions =
        !queryParam.noShowSuggestions &&
        getters.requestSuggestions &&
        getters.getQueryTrimmed !== '';

      if (!showSuggestions) {
        commit('INHIBIT_SUGGESTIONS', true);
        const isSearchSuggestShown = rootGetters['navigation/getShowSearchSuggest'];
        if (isSearchSuggestShown) {
          dispatch('setShowSuggestionsFlyout', false);
        }
        commit('INHIBIT_SUGGESTIONS', false);
        return;
      }

      getSearchSuggestionsThrottled({
        query: getters.getQueryTrimmed, // pass current value, do not re-get after throtteling or the value may have changed
        dispatch,
        commit,
        getters,
        rootGetters,
      });
    },
    setQueryEnter({ commit }, value) {
      commit('SET_QUERY_ENTER', value);
    },
    SetRequestSuggestions({ commit }, value) {
      commit('SET_REQUEST_SUGGESTIONS', value);
    },
    setProductUrlBase({ commit }, value) {
      commit('SET_PRODUCT_URL_BASE', value);
    },
    setProductRedirectUrl({ commit }, value) {
      commit('SET_PRODUCT_REDIRECT_URL', value);
    },
  },

  getters: {
    factFinderChannel: (state) => state.factFinderChannel,
    getQuery: (state) => state.query,
    getQueryTrimmed: (state) => state.queryTrimmed,
    getQueryEnter: (state) => state.queryEnter,

    getSearchSuggestions: (state) => state.searchSuggestions,
    suggestionsQuery: (state) => state.suggestionsQuery,
    getApollo: (state) => state.apollo,
    getBrandId: (state) => state.brandId,
    getLocale: (state) => state.locale,
    hasProducts: (state) => state.hasProducts,
    productUrlBase: (state) => state.productUrlBase,
    productRedirectUrl: (state) => state.productRedirectUrl,
    requestSuggestions: (state) => state.requestSuggestions,
    inhibitSuggestions: (state) => state.inhibitSuggestions,
  },
};

export default search;
