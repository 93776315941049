<template>
  <component
    :is="headlineTag"
    :class="['headline-fi', `fi-text-size-df-${size}`, colorClass]"
  >
    <slot>
      <!-- eslint-disable vue/no-v-html -->
      <template v-if="html">
        <span v-html="html" />
      </template>
      <!-- eslint-enable vue/no-v-html -->
    </slot>
  </component>
</template>

<script>
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import typographyMixin from 'mixins/typographyMixin';

export default {
  name: 'HeadlineFi',
  components: {
    GridContainerFi,
  },
  mixins: [typographyMixin],
  props: {
    tag: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 48,
    },
    field: {
      type: Object,
      default: null,
    },
    htmlContent: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'h9', 'div', 'span'],
    };
  },
  computed: {
    html() {
      if (this.htmlContent) {
        return this.htmlContent;
      }
      if (!this.field) {
        return null;
      }
      return this.field.editable || this.field.value;
    },
    headlineTag() {
      let tag = 'h2';

      if (this.allowedTags.includes(this.tag)) {
        tag = this.tag;
      }

      return tag;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './headline-fi.scss';
</style>
