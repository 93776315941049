import { useCookies } from 'composables/cookies';
import { useFischer } from 'composables/fischerPlugin';
import { useSsrCookies } from 'composables/ssrCookies';
import { computed } from 'vue';
import type { VueCookies } from 'vue-cookies';

import { type FischerPlugin } from '@/lib/fischerPlugin';

export const cookieName = 'handlingfeelimit';

export const clearHandlingFeeLimitCookieFunction = (cookies: VueCookies) => {
  if (IS_SSR) {
    return;
  }
  cookies.remove(cookieName);
};

export const handlingFeeLimitCookieIdFunction = (fischer: FischerPlugin) => {
  const customerId = fischer.user.customerId();
  if (!customerId) {
    return null;
  }

  return customerId;
};

export const getHandlingFeeLimitCookieFunction = (
  getCookie: VueCookies['get'],
  cookies: VueCookies,
  fischer: FischerPlugin,
  handlingFeeLimitCookieId: string | null,
) => {
  if (!fischer.user.accessToken()) {
    return null;
  }
  const cookie = getCookie(cookieName);
  if (!cookie) {
    return null;
  }

  if (cookie.id !== handlingFeeLimitCookieId) {
    clearHandlingFeeLimitCookieFunction(cookies);
    return null;
  }
  return cookie.value;
};

export const setHandlingFeeLimitCookieFunction = (
  handlingFeeLimitFormatted: any,
  handlingFeeLimitCookieId: string | null,
  cookies: VueCookies,
) => {
  if (!handlingFeeLimitCookieId) {
    console.error('missing handling fee cookie id');
    return;
  }
  if (!IS_SSR) {
    cookies.set(
      cookieName,
      {
        id: handlingFeeLimitCookieId,
        value: handlingFeeLimitFormatted,
      },
      0,
      undefined,
      undefined,
      undefined,
      'Strict',
    );
  }
};

export const useHandlingFeeCookie = () => {
  const fischer = useFischer();
  const { cookies } = useCookies();
  const { getCookie } = useSsrCookies();

  const handlingFeeLimitCookieId = computed<string | null>(() =>
    handlingFeeLimitCookieIdFunction(fischer),
  );

  const clearHandlingFeeLimitCookie = () => clearHandlingFeeLimitCookieFunction(cookies);
  const getHandlingFeeLimitCookie = () =>
    getHandlingFeeLimitCookieFunction(getCookie, cookies, fischer, handlingFeeLimitCookieId.value);
  const setHandlingFeeLimitCookie = (handlingFeeLimitFormatted: any) =>
    setHandlingFeeLimitCookieFunction(
      handlingFeeLimitFormatted,
      handlingFeeLimitCookieId.value,
      cookies,
    );

  return {
    clearHandlingFeeLimitCookie,
    getHandlingFeeLimitCookie,
    setHandlingFeeLimitCookie,
    handlingFeeLimitCookieId,
  };
};
