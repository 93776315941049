<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isSingleLocale: 'navigation/isSingleLocale',
      currentLocaleData: 'navigation/getCurrentLocaleData',
    }),
    localeData() {
      return this.currentLocaleData;
    },
    localizations() {
      return this.localeData?.country?.localizations || [];
    },
    currentLocale() {
      return this.localeData?.locales?.find((l) => l.current);
    },
  },
  methods: {
    async getLocaleData() {
      if (!this.currentLocaleData) {
        const api = IS_SHOWROOM
          ? '/__mocks__/sitecoreApi/api/i18n/locales.json'
          : `/api/i18n/locales/${this.$fischer.pageId()}`;
        const params = {
          pageId: this.$fischer.pageId(),
          language: this.$fischer.siteLanguage(),
        };
        const { data } = await axios.get(api, IS_SHOWROOM ? null : { params });

        this.$store.commit('navigation/SET_CURRENT_LOCALE_DATA', data);
      }

      const isSingleLocale = this.localizations.length <= 1;
      this.$store.commit('navigation/IS_SINGLE_LOCALE', isSingleLocale);
    },
    countrySelectUrl(path) {
      const url = IS_SHOWROOM
        ? `${window.location.origin}/country-selection`
        : `${window.location.origin}${path}`;
      if ((!IS_SHOWROOM && !path) || window.location.href === url) {
        return null;
      }

      return url;
    },
  },
};
</script>
