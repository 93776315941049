<template>
  <div class="navigation-products-fi-wrapper">
    <loading-indicator
      v-if="$apolloData.loading"
      variant="spinnerLocal"
      class="navigation-products-fi__loading-indicator spinner-local--rebrush"
    />

    <ul
      v-else
      class="navigation-products-fi"
    >
      <li
        v-for="item in getAllCategories"
        :id="`fi-navigation-${item.CategoryId}`"
        :ref="'productItem'"
        :key="item.CateogryId"
        class="navigation-products-fi__item"
      >
        <a
          :href="getHref(item)"
          class="navigation-products-fi__item--link"
          data-test="category-link"
          @click="onClick($event, item)"
        >
          <navigation-products-item-fi :item="item" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { LoadingIndicator } from 'atoms';
import GETALLCATEGORIES from 'gql/getAllCategories.gql';
import eventHubMixin from 'mixins/eventHubMixin';
import { mapGetters } from 'vuex';

import NavigationProductsItemFi from './navigation-products-item-fi/NavigationProductsItemFi';

export default {
  name: 'NavigationProducts',
  components: {
    NavigationProductsItemFi,
    LoadingIndicator,
  },
  mixins: [eventHubMixin],
  data() {
    return {
      getAllCategories: [],
    };
  },
  computed: {
    ...mapGetters({
      groupData: 'navigation/getGroupData',
    }),
  },
  apollo: {
    getAllCategories: {
      query: GETALLCATEGORIES,
      variables() {
        return {
          baseSiteId: this.$fischer.baseSiteId(),
          language: this.$fischer.catalogLocale(),
        };
      },
    },
  },
  methods: {
    getHref(item) {
      return this.groupData.url + item.Slug;
    },
    onClick(evt, item) {
      this.sendClickToEventHub(
        {
          clickSource: 'navigation',
          familyId: item.CategoryId,
          pageTitle: item.CategoryName,
          query: `[category]:${item.CategoryName}`,
        },
        evt,
        this.getHref(item),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import './navigation-products-fi';
</style>
