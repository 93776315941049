<template>
  <div class="navigation-items-fi">
    <div
      v-for="group in navigationGroups"
      :key="group.id"
      :class="{
        'navigation-items-fi__group': true,
        'navigation-items-fi__no-label': !group.groupLabel,
      }"
    >
      <span
        v-if="group.groupLabel"
        class="navigation-items-fi__group-label"
        >{{ group.groupLabel }}
      </span>
      <ul class="navigation-items-fi__list">
        <li
          v-for="item in group.groupList"
          :key="item.id"
          class="navigation-items-fi__list-item"
        >
          <a
            :href="item.url"
            :target="item.target ? item.target : null"
            :rel="item.target === '_blank' ? 'noopener noreferrer' : null"
            :data-test-2="item.label"
            data-test="navigation-group"
            class="navigation-items-fi__list-item--link"
          >
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import navigationMixin from 'mixins/navigationMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationItemsFi',
  mixins: [navigationMixin],
  computed: {
    ...mapGetters({
      subElements: 'navigation/getSubElements',
    }),
    navigationGroups() {
      return this.getNavigationGroups(this.subElements);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './navigation-items-fi';
</style>
