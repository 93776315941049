<template>
  <div
    :class="{
      'slide-up-down-fi': true,
      'slide-up-down-fi--open': modelValue,
    }"
    :style="{
      transitionDuration: duration !== undefined ? String(duration) : undefined,
    }"
    :tabindex="modelValue ? undefined : '-1'"
    :aria-hidden="modelValue"
    @transitionend="onTransitionEnd"
    @transitionstart="onTransitionStart"
  >
    <div
      :class="{
        'slide-up-down-fi__inner': true,
        'slide-up-down-fi__inner--opened': modelValue && !inTransition,
      }"
    >
      <slot :is-open="modelValue" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    duration?: string | number | undefined;
  }>(),
  {
    duration: undefined,
  },
);

const emit = defineEmits<{
  'open-start': [value: void];
  'open-end': [value: void];
  'close-start': [value: void];
  'close-end': [value: void];
}>();

const inTransition = ref<boolean>(false);
const onTransitionStart = (event: TransitionEvent) => {
  if (event.propertyName !== 'grid-template-rows') {
    return;
  }
  inTransition.value = true;
  if (props.modelValue) {
    emit('open-start');
  } else {
    emit('close-start');
  }
};

const onTransitionEnd = (event: TransitionEvent) => {
  if (event.propertyName !== 'grid-template-rows') {
    return;
  }
  inTransition.value = false;
  if (props.modelValue) {
    emit('open-end');
  } else {
    emit('close-end');
  }
};
</script>

<style scoped lang="scss">
@import './slide-up-down-fi';
</style>
