<template>
  <div
    :class="{
      'search-box-fi': true,
      'search-box-fi--desktop': isDesktop,
      'search-box-fi--active': isFocused || isSearchSuggestions,
    }"
  >
    <text-input-fi
      :id="id"
      ref="input"
      :model-value="query"
      class="search-box-fi__input no-highlight"
      name="search"
      :label="isDesktop ? $dict.get('search.Placeholder') : null"
      :placeholder="isDesktop ? null : $dict.get('search.Placeholder')"
      :icon="isDesktop ? 'search' : null"
      :clear="isDesktop"
      type="search"
      data-test="search-input"
      white
      @update:model-value="change"
      @focus="onFocus"
      @blur="onBlur"
      @keyup-enter="keyupEnter"
      @clear="clearSearch"
    />
  </div>
</template>

<script>
import { TextInputFi } from 'atoms';
import SearchLocationMixin from 'components/search/searchLocationMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchBoxFi',
  components: {
    TextInputFi,
  },
  mixins: [SearchLocationMixin],
  props: {
    isDesktop: {
      type: Boolean,
    },
    id: {
      type: String,
      default: 'search-box',
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    ...mapGetters({
      query: 'search/getQuery',
      productRedirectUrl: 'search/productRedirectUrl',
      isSearchSuggestions: 'navigation/getShowSearchSuggest',
      isSearchPage: 'navigation/isSearch',
    }),
  },
  methods: {
    clearSearch() {
      setTimeout(() => {
        this.$store.commit('navigation/SHOWFLYOUT', false);
        this.$store.dispatch('navigation/showSearchSuggest', false);
        this.$store.commit('search/SET_SEARCH_SUGGESTIONS', { suggestions: [], query: '' });
      }, 350);
    },
    keyupEnter(queryParam) {
      if (this.productRedirectUrl === null) {
        this.$store.commit('navigation/SHOWFLYOUT', false);
        this.$store.dispatch('navigation/showSearchSuggest', false);
        this.$store.dispatch('search/setQuery', '');
        return;
      }

      if (!this.productRedirectUrl) {
        this.$store.dispatch('search/setQueryEnter', true);
      } else {
        // This redirect is triggered only if the search query is finished and a redirect url is set.
        // Otherwise the redirect to the product page is triggered within store/search
        window.location.href = this.productRedirectUrl;
        return;
      }

      const query = queryParam.trim();
      if (this.isSearchPage) {
        this.$store.commit('navigation/SHOWFLYOUT', false);
        this.$store.dispatch('navigation/showSearchSuggest', false);
        this.$store.commit('search/SET_SEARCH_SUGGESTIONS', { suggestions: query, query });
        return;
      }
      if (query === '') {
        return;
      }
      window.location = this.getSearchLocation(query);
    },
    focus() {
      this.$refs.input.focus();
    },
    onFocus() {
      if (!this.isSM) {
        return;
      }
      this.isFocused = true;

      if (!this.$store.getters['search/inhibitSuggestions']) {
        this.$store.dispatch('navigation/setSearchFocused', true);
        if (this.query.trim()) {
          this.$store.dispatch('search/setShowSuggestionsFlyout', true);
        }
        this.$store.dispatch('search/setQuery', this.query); // make sure suggetions are being loaded
      }
    },
    onBlur() {
      this.isFocused = false;
      this.$store.dispatch('navigation/setSearchFocused', false);
    },
    change(value) {
      if (this.query !== value) {
        this.$store.dispatch('search/setQuery', value);
      }

      if (value === '') {
        this.clearSearch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './search-box-fi';
</style>

<style lang="scss">
@import './search-box-fi-unscoped';
</style>
