import { sendEventFunction } from 'composables/eventHub';
import type { VueCookies } from 'vue-cookies';

import type { FischerPlugin } from './lib/fischerPlugin';

let lastDepth = 0;

const scrollPageEvent = ($fischer: FischerPlugin, $cookies: VueCookies) => {
  try {
    setTimeout(() => {
      sendScrollEvent($fischer, $cookies);
    }, 100);
    window.addEventListener('scroll', () => sendScrollEvent($fischer, $cookies), {
      passive: true,
    });
  } catch (error) {
    console.error('Could not send scroll-page event', error);
  }
};

const getScrollDepth = () => {
  const scrollSteps = [25, 50, 75, 100];
  const scrollPercentage =
    Math.min(1, (window.innerHeight + window.scrollY) / document.body.offsetHeight) * 100;
  let depth = 0;

  scrollSteps.forEach((step: number) => {
    if (scrollPercentage >= step) {
      depth = step;
    }
  });

  return depth;
};

const sendScrollEvent = ($fischer: FischerPlugin, $cookies: VueCookies) => {
  const scrollDepth = getScrollDepth();
  if (scrollDepth > lastDepth) {
    lastDepth = scrollDepth;
    sendEventFunction($fischer, $cookies, 'custom', {}, 'scroll', { depth: scrollDepth });
  }
};

export default scrollPageEvent;
