<template>
  <span
    ref="tooltipIcon"
    :class="{
      'tooltip-fi': true,
      'tooltip-fi--disabled': disabled,
    }"
    @mouseover="openTooltip"
    @mouseleave="closeTooltip"
    @click.stop="toggleTooltip"
  >
    <slot name="trigger">
      <icon-fi
        class="tooltip-fi__icon"
        icon="info"
      />
    </slot>

    <!-- Show HTML tooltip -->
    <teleport
      v-if="isMounted && wasOpened"
      to="body"
    >
      <component-wrapper>
        <transition
          enter-active-class="animated fadeIn faster"
          leave-active-class="animated fadeOut faster"
        >
          <div
            v-if="showTooltip && !disabled"
            ref="tooltipRef"
            :style="tooltipStyle"
            :area-hidden="!showTooltip"
            :class="{
              'tooltip-fi__flyout': true,
              'tooltip-fi__flyout--wide': wide,
              [customClass]: customClass,
            }"
          >
            <div
              :style="tooltipArrowStyle"
              class="tooltip-fi__flyout__arrow"
            />
            <!-- eslint-disable vue/no-v-html -->
            <div class="tooltip-fi__flyout__content">
              <slot>
                <!-- eslint-disable-next-.ine vue/no-v-html -->
                <div v-html="tooltip" />
              </slot>
            </div>
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </transition>
      </component-wrapper>
    </teleport>
  </span>
</template>

<script setup lang="ts">
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import { nextTick, onMounted, reactive, ref, useTemplateRef } from 'vue';

import IconFi from '../icon-fi/IconFi.vue';

const props = withDefaults(
  defineProps<{
    tooltip?: string;
    customClass?: string;
    disabled?: boolean;
    wide?: boolean;
  }>(),
  {
    tooltip: '',
    customClass: '',
    disabled: false,
    wide: false,
  },
);
const showTooltip = ref(false);
const tooltipStyle = reactive<Record<string, string>>({});
const tooltipArrowStyle = reactive<Record<string, string>>({});
const isMounted = ref(false);
const wasOpened = ref(false);

const tooltipIconRef = useTemplateRef<HTMLSpanElement>('tooltipIcon');
const tooltipRef = useTemplateRef<HTMLDivElement>('tooltipRef');

onMounted(() => {
  isMounted.value = true;
});

const closeTooltip = () => {
  showTooltip.value = false;
};

const toggleTooltip = () => {
  if (showTooltip.value) {
    closeTooltip();
  } else {
    openTooltip();
  }
};

async function openTooltip() {
  if (props.disabled) {
    closeTooltip();
    return;
  }

  if (!tooltipIconRef.value) {
    console.error('Missing ref for tooltip');
    return;
  }

  showTooltip.value = true;
  wasOpened.value = true;
  await nextTick();

  const width = props.wide ? 800 : 300;
  const screenMargin = 10;
  const rotationOffset = 10; // rotating the arrow 'moves' it half its width
  const arrowTipRight = 50; // position of the arrow from the right of the bubble

  const clientRect = tooltipIconRef.value.getBoundingClientRect();
  const documentWidth = window.document.body.clientWidth;

  const tooltipLeft = Math.min(
    Math.max(screenMargin, clientRect.left - width + arrowTipRight + rotationOffset),
    Math.max(documentWidth - width - screenMargin, screenMargin),
  );

  tooltipStyle.top = `${clientRect.top - 8}px`;
  tooltipStyle.left = `${tooltipLeft}px`;
  tooltipArrowStyle.left = `${clientRect.left - tooltipLeft + clientRect.width / 2 - rotationOffset}px`;

  await nextTick();

  const tooltipRect = tooltipRef.value?.getBoundingClientRect();
  if (!tooltipRect) {
    console.error('got no tooltip ref');
    return;
  }

  if (tooltipRect.top < 0) {
    tooltipStyle.top = `${clientRect.top + tooltipRect.height + 44}px`;
    tooltipArrowStyle.bottom = 'unset';
    tooltipArrowStyle.top = '-8px';
  }
}
</script>

<style lang="scss" scoped>
@import './tooltip-fi';
</style>
