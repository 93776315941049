export default {
  brand: 'rgb(var(--color-brand))',
  'brand-highlight': 'rgb(var(--color-brand-highlight))',
  'brand-selected': 'rgb(var(--color-brand-selected))',
  black: 'rgb(var(--color-black))',
  white: 'rgb(var(--color-white))',
  grey: 'rgb(var(--color-grey))',
  'grey-20': 'rgb(var(--color-grey-20))',
  'grey-40': 'rgb(var(--color-grey-40))',
  'grey-70': 'rgb(var(--color-grey-70))',
  'grey-dark': 'rgb(var(--color-grey-dark))',
  enunciate: 'rgb(var(--color-enunciate))',
  'box-shadow': 'var(--color-box-shadow)',
  'box-shadow-strong': 'var(--color-box-shadow-strong)',
  transparent: 'transparent',
  typo: {
    brand: 'rgb(var(--color-typo-brand))',
    black: 'rgb(var(--color-typo-black))',
    white: 'rgb(var(--color-typo-white))',
    'grey-dark': 'rgb(var(--color-typo-grey-dark))',
  },
  status: {
    error: 'rgb(var(--color-status-error))',
    success: 'rgb(var(--color-status-success))',
    warn: 'rgb(var(--color-status-warn))',
    'warn-dark': 'rgb(var(--color-status-warn-dark))',
    disabled: 'rgb(var(--color-status-disabled))',
  },
  showroom: {
    green: 'rgb(137, 240, 197)',
  },
  poi: {
    initial: 'rgb(var(--color-poi-initial))',
    installed: 'rgb(var(--color-poi-installed))',
    checked: 'rgb(var(--color-poi-checked))',
  },
  // needed for "fi-bg-xxx" classes
  background: {
    brand: 'rgba(var(--color-brand), var(--tw-bg-opacity))',
    'brand-highlight': 'rgba(var(--color-brand-highlight), var(--tw-bg-opacity))',
    'brand-selected': 'rgba(var(--color-brand-selected), var(--tw-bg-opacity))',
    black: 'rgba(var(--color-black), var(--tw-bg-opacity))',
    white: 'rgba(var(--color-white), var(--tw-bg-opacity))',
    grey: 'rgba(var(--color-grey), var(--tw-bg-opacity))',
    'grey-20': 'rgba(var(--color-grey-20), var(--tw-bg-opacity))',
    'grey-40': 'rgba(var(--color-grey-40), var(--tw-bg-opacity))',
    'grey-70': 'rgba(var(--color-grey-70), var(--tw-bg-opacity))',
    'grey-dark': 'rgba(var(--color-grey-dark), var(--tw-bg-opacity))',
    enunciate: 'rgba(var(--color-enunciate), var(--tw-bg-opacity))',
    error: 'rgba(var(--color-status-error), var(--tw-bg-opacity))',
    success: 'rgba(var(--color-status-success), var(--tw-bg-opacity))',
    warn: 'rgba(var(--color-status-warn), var(--tw-bg-opacity))',
    'warn-dark': 'rgb(var(--color-status-warn-dark))',
    disabled: 'rgba(var(--color-status-disabled), var(--tw-bg-opacity))',
    green: 'rgba(137, 240, 197, var(--tw-bg-opacity))',
    'poi-initial': 'rgba(var(--color-poi-initial), var(--tw-bg-opacity))',
    'poi-installed': 'rgba(var(--color-poi-installed), var(--tw-bg-opacity))',
    'poi-checked': 'rgba(var(--color-poi-checked), var(--tw-bg-opacity))',
  },
};
