<template>
  <teleport
    v-if="!IS_SSR && toastNotifications?.length"
    to="body"
  >
    <component-wrapper
      :class="{
        'toast-notification-fi': true,
        'toast-notification-fi--offset-top-large': isSnackbarVisible,
        ...headerHeightClasses,
      }"
    >
      <grid-container-fi>
        <toast-notification-item-fi
          v-for="toast in toastNotifications"
          :key="toast.name"
          :toast="toast"
        />
      </grid-container-fi>
    </component-wrapper>
  </teleport>
</template>

<script setup lang="ts">
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import ToastNotificationItemFi from 'components/notifications-fi/toast-notification-fi/toast-notification-item-fi/ToastNotificationItemFi.vue';
import ShopEventBus from 'components/shop-modules/shop-event-bus';
import { useHeaderHeight } from 'composables/headerHeight';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

defineOptions({
  name: 'ToastNotificationFi',
});

const { headerHeightClasses } = useHeaderHeight();
const store = useStore();

const isSnackbarVisible = ref<boolean>(false);
const toastNotifications = computed(() => store.getters['notifications/getToastNotifications']);

let timeOut: number | undefined;
onMounted(() => {
  ShopEventBus.$on('ProductAddToCart', (value: boolean) => {
    if (value === true && timeOut === undefined) {
      isSnackbarVisible.value = true;

      timeOut = window.setTimeout(() => {
        timeOut = undefined;
        isSnackbarVisible.value = false;
      }, 5000);
    } else if (timeOut !== undefined) {
      clearTimeout(timeOut);
      timeOut = undefined;
    }
  });
});
</script>

<style lang="scss">
@import './toast-notification-fi';
</style>
