<template>
  <sweet-modal
    v-if="isMounted && (!lazy || hasBeenOpened)"
    :ref="(ref) => (modal = ref)"
    :class="{
      'dialog-modal-fi': true,
      'dialog-modal-fi--large-padding': largePadding,
      [`dialog-modal-fi--theme-${theme}`]: theme,
      'dialog-modal-fi--transparent-bg': transparentBackground,
      'dialog-modal-fi--auto-width': autoWidth,
      'dialog-modal-fi--wide': wide,
      'dialog-modal-fi--full-screen': fullScreen,
      'dialog-modal-fi--deligate-padding': deligatePadding,
      'dialog-modal-fi--narrow-1': narrow1,
      'dialog-modal-fi--is-mobile-fullscreen': mobileFullscreen,
    }"
    overlay-theme="dark"
    :enable-mobile-fullscreen="mobileFullscreen && theme !== 'bottom-layer-xs'"
    :blocking="preventCloseOnClickOutside"
    :pulse-on-block="pulseOnBlock"
    :hide-close-button="hideCloseButton"
    @open="setBodyHeight"
    @close="beforeClose"
  >
    <template
      v-if="$slots['box-action']"
      #box-action
    >
      <slot name="box-action" />
    </template>
    <template v-if="$slots.header">
      <div
        ref="modalHeader"
        class="dialog-modal-fi__header"
      >
        <slot name="header" />
      </div>
    </template>

    <div
      v-if="$slots.body"
      ref="modalBody"
      class="dialog-modal-fi__body"
    >
      <slot name="body" />
    </div>

    <template v-if="$slots.footer">
      <div
        ref="modalFooter"
        class="dialog-modal-fi__footer"
      >
        <slot name="footer" />
      </div>
    </template>
  </sweet-modal>
</template>

<script>
import breakpointMixin from 'mixins/breakpointMixin';
import { mapGetters } from 'vuex';

import SweetModal from './sweet-modal/SweetModal';

export default {
  name: 'DialogModalFi',
  components: {
    SweetModal,
  },
  mixins: [breakpointMixin],
  props: {
    onlyBodyScrollable: {
      type: Boolean,
      default: false,
    },
    mobileFullscreen: {
      type: Boolean,
      default: true,
    },
    largePadding: {
      type: Boolean,
      default: false,
    },
    preventCloseOnClickOutside: {
      type: Boolean,
      default: false,
    },
    noMaxHeight: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    pulseOnBlock: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    transparentBackground: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    lazy: Boolean,
    wide: Boolean,
    fullScreen: Boolean,
    deligatePadding: Boolean,
    narrow1: Boolean,
  },
  emits: ['after-open-transition', 'after-close-transition', 'close', 'default', 'fullscreen'],
  data() {
    return {
      isFullscreen: false,
      isDefault: true,
      hasBeenOpened: false,
      modal: null,
      resizeListenerEnabled: false,
      isMounted: false,
    };
  },
  computed: {
    ...mapGetters({
      windowDimensions: 'layout/getWindowDimensions',
    }),
    isBottomLayerTheme() {
      return this.theme === 'bottom-layer' || this.theme === 'bottom-layer-xs';
    },
  },
  watch: {
    windowDimensions: {
      deep: true,
      handler() {
        if (!this.resizeListenerEnabled) {
          return;
        }

        this.setBodyHeight();
      },
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    async open() {
      if (IS_SSR) {
        return false;
      }

      if (this.onlyBodyScrollable && !this.resizeListenerEnabled) {
        this.resizeListenerEnabled = true;
      }

      if (this.lazy && !this.hasBeenOpened) {
        this.hasBeenOpened = true;
        await this.$nextTick();
      } else {
        if (!this.modal) {
          // most likely bubbling event
          return;
        }
      }

      this.modal.open();
      await this.$nextTick();

      // emit after open modal transition
      setTimeout(() => {
        this.$emit('after-open-transition');
      }, 300);

      if (this.isBottomLayerTheme && !this.useSmallLayer()) {
        return false;
      }

      return true;
    },
    useSmallLayer() {
      return window.innerWidth < 600 && this.theme !== 'bottom-layer-xs';
    },
    isOpen() {
      return this.modal?.is_open;
    },
    beforeClose() {
      this.$emit('close');
    },
    close() {
      if (!this.hasBeenOpened && this.lazy) {
        return;
      }
      this.modal.close();

      // emit after close modal transition
      setTimeout(() => {
        this.$emit('after-close-transition');
      }, 300);
    },
    async setBodyHeight() {
      if (IS_SSR) {
        return;
      }

      if (this.$refs.modalBody && this.useSmallLayer()) {
        if (!this.isFullscreen) {
          this.$emit('fullscreen');
          this.isFullscreen = true;
          this.isDefault = false;

          this.$refs.modalBody.style.maxHeight = '';
          await this.$nextTick();
        }
      } else if (!this.isDefault) {
        this.$emit('default');
        this.isDefault = true;
        this.isFullscreen = false;
      }

      if (this.onlyBodyScrollable) {
        await this.$nextTick();
        let modalPadding = this.isMD ? 48 + 30 : 48 + 18;
        if (this.largePadding && this.isLG) {
          modalPadding = 144;
        }

        const modalHeight = this.mobileFullscreen && !this.isSM ? '100vh' : '90vh';
        const headerHeight = this.$refs.modalHeader ? this.$refs.modalHeader.offsetHeight + 30 : 0;
        const footerHeight = this.$refs.modalFooter ? this.$refs.modalFooter.offsetHeight + 30 : 0;
        const bodyHeight = `calc(${modalHeight} - ${headerHeight + footerHeight + modalPadding}px)`;

        if (this.$refs.modalBody && !this.noMaxHeight) {
          this.$refs.modalBody.style.maxHeight = bodyHeight;

          await this.$nextTick();
          setTimeout(() => {
            const modal = document.querySelector('.sweet-modal.is-visible');
            if (!modal) {
              return;
            }
            const modalBoundingHeight = modal.getBoundingClientRect().height;
            const modalOffsetTopBottom = this.useSmallLayer()
              ? window.innerHeight - modalBoundingHeight
              : 0;
            const sweetContent = document.querySelector('.sweet-modal.is-visible .sweet-content');
            const sweetContentHeight = sweetContent.getBoundingClientRect().height;
            const bodyHeightWithOffset = `calc(${modalHeight} - ${
              modalOffsetTopBottom + headerHeight + footerHeight + modalPadding
            }px)`;

            if (this.useSmallLayer() && modalBoundingHeight < sweetContentHeight) {
              this.$refs.modalBody.style.maxHeight = bodyHeightWithOffset;
            }

            // calculation for CAD frame
            const { cadFrame } = this.$parent.$refs;
            if (cadFrame) {
              cadFrame.style.height = `calc(${modalHeight} - ${
                modalBoundingHeight < sweetContentHeight
                  ? modalOffsetTopBottom + modalPadding
                  : modalPadding
              }px)`;
            }
          }, 200);
        }
      }
    },
    scrollToTop() {
      if (this.$refs.modalBody) {
        this.$refs.modalBody.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss">
@import './dialog-modal-fi';
</style>
