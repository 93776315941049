const ClientStorage = {
  setValue(name, value, isSession) {
    if (IS_SSR) {
      return false;
    }
    try {
      const s = JSON.stringify(value);
      if (isSession) {
        sessionStorage.setItem(name, s);
      } else {
        localStorage.setItem(name, s);
      }
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return false;
    }
  },

  getValue(name, isSession) {
    let s = false;
    if (IS_SSR) {
      return s;
    }

    try {
      s = isSession ? sessionStorage.getItem(name) : localStorage.getItem(name);
      if (s) {
        return JSON.parse(s);
      }

      return null;
    } catch (e) {
      return s;
    }
  },

  removeValue(name, isSession) {
    if (IS_SSR) {
      return;
    }
    if (isSession) {
      sessionStorage.removeItem(name);
    } else {
      localStorage.removeItem(name);
    }
  },
};

export default ClientStorage;
