<template>
  <div
    ref="marker-container"
    :class="{
      'tab-navigation-fi': true,
      'tab-navigation-fi--line-hidden': lineHidden,
      'tab-navigation-fi--shadow-left': showShadowLeft,
      'tab-navigation-fi--shadow-right': showShadowRight,
      'tab-navigation-fi--no-spacing-below': noSpacingBelow,
      'tab-navigation-fi--left': left,
      'tab-navigation-fi--full-width-tabs': fullWidthTabs,
      'tab-navigation-fi--grid': grid,
      [`tab-navigation-fi--theme-${theme}`]: theme,
    }"
  >
    <component
      :is="grid ? 'grid-columns-fi' : 'div'"
      ref="tabsContainer"
      :grid-base-class="grid ? 'fi-gap-zero' : null"
      class="tab-navigation-fi__tabs-container"
    >
      <splide
        ref="tabNaviSlider"
        :options="slickSliderSettings"
        :has-track="false"
        class="tab-navigation-fi__tabs"
        @splide:dragging="handleShadows"
      >
        <splide-track>
          <splide-slide
            v-for="(item, idx) in tabNavigation"
            :key="item.id"
            :class="{
              'tab-navigation-fi__tab': true,
              'tab-navigation-fi__tab--active': active === item.id,
              'marker-mixin-marked': active === item.id,
              'tab-navigation-fi__tab--last': idx === tabNavigation.length - 1,
              'tab-navigation-fi__tab--hidden': item.hidden,
              'tab-navigation-fi__tab--icon': item.icon,
            }"
            @click="changeTab(item.id, idx)"
          >
            <span class="tab-navigation-fi__tab-content marker-mixin-content">
              <icon-fi
                v-if="item.icon"
                class="tab-navigation-fi__tab-icon"
                :icon="item.icon"
              />
              <!-- eslint-disable vue/no-v-html -->
              <template v-if="item.text && (item.text.editable || item.text.value)">
                <span
                  v-if="isEditMode && item.text.editable"
                  v-html="item.text.editable"
                />
                <span
                  v-else-if="!isEditMode && item.text.value"
                  v-html="item.text.value"
                />
              </template>
              <!-- eslint-enable vue/no-v-html -->
              <template v-else>
                {{ item.text }}
              </template>
            </span>
          </splide-slide>
          <li
            ref="marker"
            class="tab-navigation-fi__marker"
          />
        </splide-track>
      </splide>
    </component>

    <div
      class="tab-navigation-fi__arrow tab-navigation-fi__arrow-prev"
      @click="goPrev"
    >
      <icon-fi icon="chevron-left" />
    </div>

    <div
      class="tab-navigation-fi__arrow tab-navigation-fi__arrow-next"
      @click="goNext"
    >
      <icon-fi icon="chevron-right" />
    </div>

    <slot name="note" />

    <template v-if="!isJumpMarkNavigation">
      <div
        v-if="contentLoading"
        :class="{
          'tab-navigation-fi__content': true,
          'tab-navigation-fi__content--dark': dark,
          'tab-navigation-fi__loading': true,
        }"
      >
        <loading-indicator
          variant="spinnerLocal"
          class="spinner-local--rebrush"
          :dark="true"
        />
      </div>

      <transition-group
        v-else
        enter-active-class="fade-in"
        leave-active-class="fade-out"
      >
        <template
          v-for="item in tabNavigation"
          :key="item.id"
        >
          <div
            v-if="item.id === active || hideOnly"
            v-show="!hideOnly || active === item.id"
          >
            <component
              :is="grid ? 'grid-columns-fi' : 'div'"
              :class="{
                'tab-navigation-fi__content': true,
                'tab-navigation-fi__content--dark': dark,
              }"
            >
              <slot
                :name="`tab-content-${item.id}`"
                :is-active="active === item.id"
              />
            </component>
          </div>
        </template>
      </transition-group>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import { IconFi, LoadingIndicator } from 'atoms/index';
import GridColumnsFi from 'components/grid-fi/GridColumnsFi.vue';
import { useMarker } from 'composables/marker';
import { computed, nextTick, onMounted, ref, useTemplateRef, watch } from 'vue';

import type { SingleLineTextField } from '@/sitecoreFieldTypes';

export interface TabNavigation {
  id: number;
  hidden: boolean;
  icon: string;
  text: SingleLineTextField;
}

defineOptions({
  name: 'TabNavigationFi',
});

const props = withDefaults(
  defineProps<{
    tabNavigation: TabNavigation[];
    contentLoading?: boolean;
    lineHidden?: boolean;
    refreshTabs?: boolean;
    initId?: number;
    noSpacingBelow?: boolean;
    enableScrollIfOutOfBoundsOnly?: boolean;
    theme?: string;
    hideOnly?: boolean;
    left?: boolean;
    dark?: boolean;
    fullWidthTabs?: boolean;
    grid?: boolean;
  }>(),
  {
    tabNavigation: () => [],
    initId: 0,
    theme: '',
  },
);

const emit = defineEmits<{
  change: [value: TabNavigation];
}>();

const { animateMarker } = useMarker();

const activeId = ref<number | null>(null);
const showShadowLeft = ref<boolean>(false);
const showShadowRight = ref<boolean>(false);
const showShadowWidth = ref<number>(0);

const tabNaviSlider = useTemplateRef<Splide>('tabNaviSlider');
const tabsContainer = useTemplateRef<HTMLDivElement | typeof GridColumnsFi>('tabsContainer');

const slickSliderSettings = {
  arrows: false,
  autoWidth: true,
};

const active = computed<number>(() => {
  if (activeId.value || activeId.value === 0) {
    return activeId.value;
  }

  return props.initId;
});

const activeItem = computed<TabNavigation | undefined>(() =>
  props.tabNavigation?.find((item) => item.id === active.value),
);

const tabs = computed<any[]>(() => tabNaviSlider.value?.splide?.Components?.Slides?.get());

const isJumpMarkNavigation = computed(() => props.theme === 'jump-marks');

const goPrev = () => {
  tabNaviSlider.value?.splide?.go('<');
  setTimeout(handleShadows, 500);
};

const goNext = () => {
  tabNaviSlider.value?.splide?.go('>');
  setTimeout(handleShadows, 500);
};

const executeRefreshTabs = async () => {
  if (IS_SSR) {
    return;
  }
  await nextTick();
  animateMarker();
};

watch(() => props.refreshTabs, executeRefreshTabs);
watch(() => props.tabNavigation, executeRefreshTabs, { deep: true });

const changeTab = async (id: number, idx: number) => {
  const isDirectionRight = activeId.value === null || activeId.value < id;
  const nextIndex: number = isDirectionRight ? idx : idx - 1;
  handleNavigationPosition(nextIndex, isDirectionRight);

  activeId.value = id;

  if (!props.enableScrollIfOutOfBoundsOnly) {
    setPosition(idx);
  }

  await nextTick();
  animateMarker();
  await nextTick();
  handleShadows();
  emit('change', props.tabNavigation[id]);
};

const setPosition = (index: number) => tabNaviSlider.value?.splide?.go(index);

const handleNavigationPosition = (nextIndex: number, isDirectionRight: boolean) => {
  if (!props.enableScrollIfOutOfBoundsOnly || IS_SSR) {
    return;
  }

  const activeSlideElement = tabs.value?.[nextIndex]?.slide;
  const nextSlideElement = tabs.value?.[isDirectionRight ? nextIndex + 1 : nextIndex]?.slide;

  if (!activeSlideElement) {
    return;
  }

  const tabSliderDimensions = tabNaviSlider.value?.$el?.getBoundingClientRect();
  const activeSlideDimensions = activeSlideElement.getBoundingClientRect();
  const nextSlideEdgeRight =
    nextSlideElement?.getBoundingClientRect().left +
    nextSlideElement?.getBoundingClientRect().width;

  const tabSliderEdge = {
    left: tabSliderDimensions.left,
    right: tabSliderDimensions.left + tabSliderDimensions.width,
  };
  const activeSlideEdge = {
    left: activeSlideDimensions.left,
    right: activeSlideDimensions.left + activeSlideDimensions.width,
  };

  if (activeSlideEdge.right > tabSliderEdge.right) {
    // Update Tabs position in direction right
    setPosition(nextIndex - 1);
  } else if (
    activeSlideEdge.left < tabSliderEdge.left &&
    activeSlideEdge.right !== tabSliderEdge.left
  ) {
    // Update Tabs position in direction left
    let width = activeSlideDimensions.width + showShadowWidth.value;

    tabs.value.every((item, idx) => {
      width += item.slide.offsetWidth;

      // add the width until the sum is greater than the maximum available space
      if (width > tabSliderEdge.right && nextSlideEdgeRight < tabSliderEdge.left) {
        setPosition((activeId.value || 0) - 1 - idx);
        return false;
      }

      return true;
    });
  }
};

const handleShadows = () => {
  if (IS_SSR) {
    return;
  }
  const tabSliderDimensions = tabNaviSlider.value?.$el?.getBoundingClientRect();
  if (!tabSliderDimensions) {
    return;
  }

  const firstTab = tabs.value?.[0]?.slide;
  const lastTab = tabs.value?.[tabs.value.length - 1]?.slide;

  if (firstTab?.getBoundingClientRect().left < tabSliderDimensions.left) {
    showShadowLeft.value = true;
  } else {
    showShadowLeft.value = false;
  }

  if (
    lastTab?.getBoundingClientRect().left + lastTab?.getBoundingClientRect().width >
    tabSliderDimensions.left + tabSliderDimensions.width
  ) {
    showShadowRight.value = true;
  } else {
    showShadowRight.value = false;
  }

  if (!tabsContainer.value) {
    return;
  }
  // timeout is necessary because there is a css transition on the shadow pseudo elements
  setTimeout(() => {
    if (!tabsContainer.value) {
      console.debug('no tabs container found');
      return;
    }
    const tabsElement: HTMLElement =
      // @ts-ignore TODO
      tabsContainer.value.$el !== undefined ? tabsContainer.value.$el : tabsContainer.value;
    const shadowWidthLeft = parseInt(window?.getComputedStyle(tabsElement, ':before').width, 10);
    const shadowWidthRight = parseInt(window?.getComputedStyle(tabsElement, ':after').width, 10);
    showShadowWidth.value = shadowWidthLeft || shadowWidthRight;
  }, 650);
};

onMounted(async () => {
  window.addEventListener('load', animateMarker);

  await nextTick();
  animateMarker();

  // Unfortunately necessary
  setTimeout(() => {
    handleShadows();
  }, 0);
});

defineExpose({ activeItem, changeTab, activeId, active });
</script>

<style lang="scss">
@import './tab-navigation-fi-unscoped';
</style>

<style scoped lang="scss">
@import './tab-navigation-fi';
</style>
