import '@/assets/globalStyles.scss';

import type { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { DefaultApolloClient } from '@vue/apollo-composable';
import vClickOutside from 'click-outside-vue3';
import componentFactory from 'componentFactory';
import type { MarkSsrOnly } from 'composables/ssrOnly';
import ssrCookieMixin from 'mixins/ssrCookieMixin';
import upperCaseKeys from 'utils/upperCaseKeys';
import { type App, createApp as createVueApp, createSSRApp, h } from 'vue';
import VueCookies from 'vue-cookies';
import VueSmoothScroll from 'vue3-smooth-scroll';
import type { Store } from 'vuex';

import type { MarkSsrError } from '@/../server/server';
import dictPlugin from '@/lib/dictPlugin';
import fischerPlugin, { createFischerStore, type FischerPluginStore } from '@/lib/fischerPlugin';
import createStore from '@/store/createStore';
import VueLazyload from '@/vue-lazyload/src';

import createApolloProvider from './createApolloProvider';
import intersect from './directives/intersect';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin.js';
import loadLayoutData from './loadLayoutData';
import setupDictionary from './setupDictionary';
import type {
  InitialState,
  SitecoreDeploymentEnv,
  SitecoreRendering,
  SitecoreState,
} from './sitecoreState';

const importShowroomOverrides = () =>
  import('components/dev/showroom-settings/localStorageSettings');
const importSitecorePlugin = () => import('@sitecore-jss/sitecore-jss-vue');

const addRouterToApp = async (app: App) => {
  const { createRouter } = await import('./showroom-backend/router.js');
  const router = createRouter();
  app.use(router);
};

if (
  IS_SHOWROOM &&
  !import.meta.env.VITE_NO_SHOWROOM_COOKIEBOT &&
  !document.getElementById('Cookiebot')
) {
  const script = document.createElement('script');
  script.id = 'Cookiebot';
  script.src = 'https://consent.cookiebot.com/uc.js';
  script.setAttribute('type', 'text/javascript');
  script.dataset.cbid = 'f77b284f-3975-46d3-81b3-df53f1839764';
  script.dataset.blockingmode = 'auto';
  document.head.appendChild(script);
}

// creates breakpoint helper element and appends it to the body
function createBreakpointHelper() {
  if (!IS_SSR) {
    const hasBeakpointHelperElement = document.getElementById('breakpoint-helper');
    if (!hasBeakpointHelperElement) {
      const breakpointHelperElement = document.createElement('div');
      breakpointHelperElement.setAttribute('id', 'breakpoint-helper');
      document.body.appendChild(breakpointHelperElement);
    }
  }
}

interface AppState {
  rehydrate?: boolean;
  sitecore?: SitecoreState;
  store: Store<any>;
  prefetch?: any;
  markSsrOnly?: MarkSsrOnly;
  inhibitMarkSsrOnly?: MarkSsrOnly;
  reloadEditorOnAdd?: () => void;
  graphQLProvider?: typeof ApolloProvider<any>;
  fischerPluginStore: FischerPluginStore;
}
// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export async function createApp(appState: AppState): Promise<null | App<Element>> {
  if (!IS_SSR) {
    const fieldKey = appState?.sitecore?.dataSource?.['conditions-field-key']?.value;
    if (fieldKey) {
      const formElement = document.querySelector(
        `.jss-forms-element[conditions-field-key="${fieldKey}"]`,
      );

      // Class 'jss-init-disabled' is added on start dragging elements (see 'FormDesignBoard.js' in fischer-website sitecore project)
      if (formElement?.classList?.contains('jss-init-disabled')) {
        return null;
      }
    }
  }

  const {
    sitecore,
    graphQLProvider,
    markSsrOnly,
    inhibitMarkSsrOnly,
    reloadEditorOnAdd,
    store,
    prefetch,
    rehydrate,
    fischerPluginStore,
  } = appState;
  const {
    rendering = { enableXPE: false, componentName: '' },
    dataSource,
    customData,
    data: { isEditMode = false } = {},
    placeholders = {},
  } = sitecore || {};
  const componentName =
    isEditMode && !rendering.enableXPE ? 'ExperienceEditorComponent' : rendering.componentName;

  const component = await (componentName && componentFactory(componentName));
  const fields = dataSource || {};
  interface ReducedRendering {
    enableXPE: boolean;
    componentName: string;
  }
  const props: {
    fields?: any;
    customData?: any;
    markSsrOnly?: MarkSsrOnly;
    reloadEditorOnAdd?: AppState['reloadEditorOnAdd'];
    rendering?: SitecoreRendering | ReducedRendering;
    placeholders?: any;
  } = {};
  if (component?.props) {
    if (component.props.fields) {
      props.fields = fields;
    }
    if (component.props.customData) {
      props.customData = customData;
    }
    if (component.props.markSsrOnly) {
      props.markSsrOnly = markSsrOnly;
    }
    if (component.props.reloadEditorOnAdd) {
      props.reloadEditorOnAdd = reloadEditorOnAdd;
    }
    if (component.props.rendering) {
      props.rendering = rendering;
    }
    if (component.props.placeholders) {
      props.placeholders = placeholders;
    }
  }
  const vueOptions = !IS_SHOWROOM
    ? component || { render: () => h('div', `component not found ${componentName}`) }
    : (await import('./showroom-backend/AppRoot.vue')).default;

  const ssrApp = IS_SSR || rehydrate;
  const app = ssrApp ? createSSRApp(vueOptions, props) : createVueApp(vueOptions, props);

  app.use(vClickOutside);
  app.use(SitecoreJssStorePlugin);
  app.use(fischerPlugin, fischerPluginStore);
  app.use(store);
  app.use(VueCookies);
  app.use(dictPlugin);
  app.use(graphQLProvider);
  app.use(VueLazyload, { lazyComponent: true });
  app.use(VueSmoothScroll);
  app.provide(DefaultApolloClient, graphQLProvider.defaultClient);
  app.provide('fischerPlugin', app.config.globalProperties.$fischer);
  app.provide('dictPlugin', app.config.globalProperties.$dict);
  app.provide('sitecoreState', sitecore);
  app.provide('inhibitMarkSsrOnly', props.markSsrOnly && inhibitMarkSsrOnly);

  if (IS_SHOWROOM || (APP_ENVIRONMENT === 'VM' && !IS_SSR)) {
    // @ts-ignore
    window.showroom = {
      $fischer: app.config.globalProperties.$fischer,
      $store: app.config.globalProperties.$store,
      $cookies: app.config.globalProperties.$cookies,
      $dict: app.config.globalProperties.$dict,
    };

    const { SitecoreJssPlaceholderPlugin } = await importSitecorePlugin();
    app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
    await addRouterToApp(app);
  }
  if (!IS_SHOWROOM) {
    app.provide('fields', fields);
  }
  app.provide('componentFactory', componentFactory);

  app.provide('prefetch', prefetch || null);

  app.directive('intersect', intersect);

  app.config.globalProperties.isAppView =
    ssrCookieMixin.methods.getCookie.apply(app.config.globalProperties, ['app-view']) === 'true';

  app.config.globalProperties.isFite = app.config.globalProperties.$fischer.brandId() === 'FITE';
  app.config.globalProperties.isUpat = app.config.globalProperties.$fischer.brandId() === 'UPAT';
  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  app.config.globalProperties.IS_SSR = IS_SSR;

  app.config.globalProperties.IS_SHOWROOM = IS_SHOWROOM;
  app.config.globalProperties.isEditMode = isEditMode;

  store.dispatch('search/setQueryData', {
    apollo: graphQLProvider.defaultClient,
    brandId: app.config.globalProperties.$fischer.brandId(),
    factFinderChannel: app.config.globalProperties.$fischer.factFinderChannel(),
    locale: app.config.globalProperties.$fischer.catalogLocale(),
    hasProducts: Boolean(app.config.globalProperties.$fischer.pages.products().url),
    productUrlBase: app.config.globalProperties.$fischer.pages.products().url,
  });

  if (!IS_SSR) {
    store.dispatch('cart/initialize', {
      apollo: graphQLProvider.defaultClient,
      fischer: app.config.globalProperties.$fischer,
    });
  }

  // having to do this is pretty ugly, but fixing it would require forking the whole maps thing
  app.config.globalProperties.$gmapOptions = {
    key: app.config.globalProperties.$fischer.gmapApiKey(),
    libraries: 'places',
  };

  if (IS_SHOWROOM) {
    const { applyStoredSettings } = await importShowroomOverrides();
    applyStoredSettings(app.config.globalProperties.$fischer);
  }

  if (!IS_SSR) {
    window.addEventListener(
      'resize',
      () =>
        store.dispatch('layout/setWindowDimensions', {
          height: window.innerHeight,
          width: window.innerWidth,
        }),
      true,
    );

    window.addEventListener(
      'scroll',
      () =>
        store.dispatch('layout/setWindowScrollPos', {
          x: window.scrollX,
          y: window.scrollY,
        }),
      true,
    );
  }

  return app;
}

// make data format compatible to new layout service
const transformClassicLayoutData = (state?: InitialState) => {
  if (!state?.sitecore?.settings?.shop?.sellingUnits) {
    return;
  }
  const { shop } = state.sitecore.settings;
  shop.sellingUnits = upperCaseKeys(shop.sellingUnits);
};

export async function setupAppPage({
  initialState,
  apolloStates,
  noLayoutData,
  markSsrError,
}: {
  initialState?: InitialState;
  apolloStates?: Array<NormalizedCacheObject> | undefined;
  noLayoutData?: boolean;
  markSsrError?: MarkSsrError;
}): Promise<{
  graphQLProvider: typeof ApolloProvider<any>;
  store: any;
  layoutSucceeded: boolean;
  fischerPluginStore: FischerPluginStore;
}> {
  const sitecoreData = initialState?.sitecore?.data || {};
  const {
    pageId,
    language: languageSitecore,
    host,
    uri,
    isSecureConnection,
    sitecoreDeploymentEnv,
  }: {
    pageId?: string;
    language?: string;
    host?: string;
    uri?: string;
    isSecureConnection?: boolean;
    sitecoreDeploymentEnv?: SitecoreDeploymentEnv;
  } = sitecoreData;

  const language = IS_SHOWROOM
    ? JSON.parse(window.localStorage.getItem('showroomSettings') || '{}')?.translations?.[0] ||
      'de-DE'
    : languageSitecore;

  Sentry.setTag('APP environment', APP_ENVIRONMENT);
  Sentry.setTag('APP host', host);
  Sentry.setTag('APP uri', uri);
  Sentry.setTag('APP language', language);
  Sentry.setTag('APP ssrMode', IS_SSR);

  const graphQLProvider = await createApolloProvider(apolloStates, sitecoreDeploymentEnv);

  const baseUrl = `http${isSecureConnection ? 's' : ''}://${host}`;

  const store = createStore();

  const fischerPluginStore = createFischerStore();

  if (noLayoutData) {
    store.dispatch('layout/setLayoutData', initialState);
  }

  const [, layoutSucceeded] = await Promise.all([
    noLayoutData || !language
      ? null
      : setupDictionary(language, graphQLProvider, initialState, baseUrl),
    noLayoutData
      ? true
      : setupLayoutData(
          store,
          baseUrl,
          pageId,
          language,
          initialState,
          VueCookies,
          fischerPluginStore,
          markSsrError,
        ),
    createBreakpointHelper(),
  ]);

  return {
    graphQLProvider,
    store,
    layoutSucceeded,
    fischerPluginStore,
  };
}

const setupLayoutData = async (
  store: Store<any>,
  baseUrl: string,
  pageId: string | undefined,
  lang: string | undefined,
  state: InitialState | undefined,
  $cookies: any,
  fischerPluginStore: FischerPluginStore,
  markSsrError?: MarkSsrError,
) => {
  try {
    if (IS_SSR && !IS_SHOWROOM) {
      transformClassicLayoutData(state);
      fischerPluginStore.setData(state, state, markSsrError);
      store.dispatch('layout/setLayoutData', state);
      return true;
    }

    const isEditMode = state?.sitecore?.data?.isEditMode;
    const siteName = isEditMode && new URLSearchParams(window.location.search).get('sc_site');
    const layoutData = await (window.document.location.search.includes('sc_formmode=edit')
      ? loadLayoutData(baseUrl, undefined, undefined, $cookies, siteName)
      : loadLayoutData(baseUrl, pageId, lang, $cookies, siteName));

    fischerPluginStore.setData(layoutData, state || (IS_SHOWROOM ? layoutData : undefined));
    store.dispatch('layout/setLayoutData', layoutData);

    return true;
  } catch (error) {
    if (error instanceof Error && String(error.message).toLowerCase() === 'request aborted') {
      console.info('request was aborted, ie. user most likely navigated away');
    } else {
      console.error('Could not fetch layout data');
      Sentry.withScope((scope) => {
        scope.setFingerprint(['get layoutData']);
        Sentry.captureException(error);
      });
      console.error(error);
    }
    return false;
  }
};
