import { computed, onMounted, useTemplateRef, watch } from 'vue';
import { useStore } from 'vuex';

import type { WindowDimensions } from './breakpoint';

export const useMarker = () => {
  const store = useStore();

  const windowDimensions = computed<WindowDimensions>(
    () => store.getters['layout/getWindowDimensions'],
  );

  const marker = useTemplateRef<HTMLLIElement>('marker');
  const markerContainer = useTemplateRef<HTMLDivElement>('marker-container');

  let initialMarkerPosition: Promise<void> | null = null;
  const animateMarker = async () => {
    if (IS_SSR) {
      return;
    }
    await initialMarkerPosition;

    if (!marker.value || !markerContainer.value) {
      return;
    }
    let activeItem = markerContainer.value.querySelector('.marker-mixin-marked') as HTMLElement;
    if (!activeItem) {
      activeItem = markerContainer.value.querySelector(
        '.navigation-bar-fi__item--active',
      ) as HTMLElement;
    }

    const oldWidth = Number.parseFloat(marker.value.style.width);
    if (activeItem) {
      const content = activeItem.querySelector('.marker-mixin-content');
      let left, width;
      if (content) {
        const contentClientRect = content.getBoundingClientRect();
        const parentNode = marker.value.parentNode as HTMLElement | null; // we know this is the case because of our html structure
        const parentClientRect = parentNode?.getBoundingClientRect();

        width = contentClientRect.width;
        left = contentClientRect.x - (parentClientRect?.x || 0);
      } else {
        width = activeItem.offsetWidth;
        left = activeItem.offsetLeft;
      }
      if (!oldWidth) {
        initialMarkerPosition = (async () => {
          if (!marker.value) {
            console.error('could not locate marker');
            return;
          }
          marker.value.style.transition = 'none';
          await new Promise((resolve) => window.requestAnimationFrame(resolve));
          marker.value.style.left = `${left}px`;
          await new Promise((resolve) => window.requestAnimationFrame(resolve));
          marker.value.style.transition = '';
          await new Promise((resolve) => window.requestAnimationFrame(resolve));
        })();
        await initialMarkerPosition;
      }
      marker.value.style.left = `${left}px`;
      marker.value.style.width = `${width}px`;
    } else if (oldWidth) {
      marker.value.style.width = '0px';
    }
  };

  watch(windowDimensions.value, animateMarker, { deep: true });
  onMounted(animateMarker);

  return { animateMarker };
};
