import Emitter from 'tiny-emitter';
const emitter = new Emitter();

export default IS_SSR
  ? {
      // NOTE: best NOT to use this in SSR since it will have to be cleaned up or cause memory-leaks
      $on: () => null,
      $once: () => null,
      $off: () => null,
      $emit: () => null,
    }
  : {
      $on: (...args) => emitter.on(...args),
      $once: (...args) => emitter.once(...args),
      $off: (...args) => emitter.off(...args),
      $emit: (...args) => emitter.emit(...args),
    };
