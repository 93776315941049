import clientStorage from './clientStorage';

const idsKey = 'IDSSession';
const modificationsKey = 'IDSCartModifications';

function getIsIds() {
  // do not return null or undefined, since this will be part of lots of graphql queries
  return Boolean(clientStorage.getValue(idsKey, true));
}

function markAsIDS(params) {
  clientStorage.setValue(idsKey, params || {}, true);
}

function markAsNotIDS() {
  clientStorage.removeValue(idsKey, true);
}

function storeCartModifications(modifications) {
  clientStorage.setValue(modificationsKey, modifications, true);
}

function getAndRemoveCartModifications() {
  const modifications = clientStorage.getValue(modificationsKey, true);
  if (modifications) {
    clientStorage.removeValue(modificationsKey, true);
  }
  return modifications;
}

export {
  getAndRemoveCartModifications,
  getIsIds,
  idsKey,
  markAsIDS,
  markAsNotIDS,
  storeCartModifications,
};
