<template>
  <div class="navigation-companies-fi">
    <ul class="navigation-companies-fi__list">
      <li
        v-for="item in companies"
        :key="item.id"
        class="navigation-companies-fi__list-item"
      >
        <a
          :href="item.url"
          :target="item.target ? item.target : null"
          :rel="item.target === '_blank' ? 'noopener noreferrer' : null"
          :data-test-2="item.label"
          data-test="company"
          class="navigation-companies-fi__list-item--link"
        >
          <cloud-image-fi
            :src="item.teaserImage?.src"
            :alt="item.label"
            class="navigation-companies-fi__list-item__image"
          />

          <div
            class="navigation-companies-fi__list-item__label fi-text-18 fi-semibold lg:fi-text-21"
          >
            <span class="fi-mr-9">
              {{ item.label }}
            </span>
            <arrow-icon class="navigation-companies-fi__arrow" />
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ArrowIcon, CloudImageFi } from 'atoms';
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationCompaniesFi',
  components: {
    ArrowIcon,
    CloudImageFi,
  },
  computed: {
    ...mapGetters({
      subElements: 'navigation/getSubElements',
    }),
    companies() {
      const companiesElements = [];

      this.subElements.forEach((element) => {
        if (element.isProxy) {
          companiesElements.push(element);
        }
      });

      return companiesElements;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './navigation-companies-fi';
</style>
