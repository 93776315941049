import { useFischer } from 'composables/fischerPlugin';

import type { FischerPlugin } from '@/lib/fischerPlugin';

export type LocalizedValueParam = string | number;
export const getLocalizedValue = (fischer: FischerPlugin, value: LocalizedValueParam) =>
  Number(value).toLocaleString(fischer.siteLanguage() || undefined);

export const getValueWithoutSeparator = (value: string | number) =>
  Number(String(value).replace(/[.,]/g, ''));

export const useLocalizedValue = () => {
  const fischer = useFischer();
  return {
    getLocalizedValue: (value: LocalizedValueParam) => getLocalizedValue(fischer, value),
    getValueWithoutSeparator: (value: LocalizedValueParam) => getValueWithoutSeparator(value),
  };
};
