<template>
  <iframe
    v-if="videoSrc"
    :src="videoSrc"
    frameborder="0"
    allow="autoplay"
    quality="high"
    allowfullscreen
  />
</template>

<script>
export default {
  name: 'YoukuPlayer',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    videoSrc() {
      const match = this.src && /id_(\w+)/.exec(this.src);
      if (!match) {
        console.debug('could not parse youku id', this.src);
        return null;
      }
      const id = match[1];
      return `https://player.youku.com/embed/${id}?autoplay=true`;
    },
  },
};
</script>
