import { sendEventFunction } from 'composables/eventHub';
import type { VueCookies } from 'vue-cookies';
import { onCLS, onFCP, onTTFB } from 'web-vitals';

import type { FischerPlugin } from './lib/fischerPlugin';

interface AppPerformance {
  timeToAppInit?: number;
  serverResponseTime?: number;
  firstContentfulPaint?: number;
  cls?: number;
  layoutService?: number;
}

interface PageEvent {
  productId?: string;
  familyId?: string;
  pageTitle: string;
  performance?: AppPerformance;
}

const monitorPerformance = async (data: PageEvent) => {
  if (!window.performance) {
    return;
  }
  try {
    const start = window.performance.timing?.requestStart || undefined;

    const appPerformance: AppPerformance = {
      timeToAppInit: start ? Number(new Date()) - start : undefined,
    };
    data.performance = appPerformance;

    const gatherTTFBData = new Promise<void>((resolve) => {
      try {
        onTTFB((metric) => {
          appPerformance.serverResponseTime = metric.value;
          resolve();
        });
      } catch (error) {
        console.error('Could not measure ttfb', error);
        resolve();
      }
      setTimeout(() => resolve(), 100); // cancel if not supported
    });

    const gatherClsData = new Promise<void>((resolve) => {
      try {
        onCLS((metric) => {
          appPerformance.cls = metric.value;
          resolve();
        });
      } catch (error) {
        console.error('Could not measure cls', error);
        resolve();
      }
      setTimeout(() => resolve(), 100); // cancel if not supported
    });

    const gatherFcpData = new Promise<void>((resolve) => {
      try {
        onFCP((metric) => {
          appPerformance.firstContentfulPaint = metric.value;
          resolve();
        });
      } catch (error) {
        console.error('Could not measure fcp', error);
        resolve();
      }
      setTimeout(() => resolve(), 100); // cancel if not supported
    });

    // add this when metric available in view event
    //const gatherInpData = new Promise<void>((resolve) => {
    //  try {
    //    onINP((metric) => {
    //      appPerformance.timeToInteraction = metric.value;
    //      resolve();
    //    });
    //  } catch (error) {
    //    console.error('Could not measure inp', error);
    //    resolve();
    //  }
    //  setTimeout(() => resolve(), 100); // cancel if not supported
    //});

    let resourceObserver: PerformanceObserver | undefined;
    const gatherLayoutData = new Promise<void>((resolve) => {
      const apiRegexp = new RegExp(`^(?:${window.location.origin})?\\/api\\/layout\\b`);
      resourceObserver = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          if (apiRegexp.test(entry.name)) {
            appPerformance.layoutService = Math.ceil(entry.duration);
          }
        });
        resolve();
      });
      resourceObserver.observe({ type: 'resource', buffered: true });
      setTimeout(() => resolve(), 100); // cancel if not supported
    });

    await gatherClsData;
    await gatherLayoutData;
    await gatherFcpData;
    await gatherTTFBData;

    resourceObserver?.disconnect();
  } catch (error) {
    console.error('Could not gather performance data');
  }
};

const viewPageEvent = async ($fischer: FischerPlugin, $cookies: VueCookies) => {
  try {
    const data = {
      productId: $fischer.isProductsPage() ? $fischer.catalogId() || undefined : undefined,
      familyId: $fischer.isFamilyPage() ? $fischer.catalogId() || undefined : undefined,
      pageTitle: window.document.title,
    };

    await monitorPerformance(data);

    sendEventFunction($fischer, $cookies, 'view_page', data);
  } catch (error) {
    console.error('Could not send view-page event', error);
  }
};

export default viewPageEvent;
