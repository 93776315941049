import { computed } from 'vue';
import { useStore } from 'vuex';

export const useHeaderPage = (props: any) => {
  const store = useStore();

  const breadcrumbData = computed(() => {
    let data = [];
    if (cData.value?.breadcrumb) {
      data = cData.value.breadcrumb;
    }
    return data;
  });

  const logoHeader = computed(() => {
    let data = {};
    if (cData.value?.logoHeader) {
      data = cData.value.logoHeader;
    }
    return data;
  });

  const logoBrandArea = computed(() => {
    let data = {};
    if (cData.value?.logoBrandArea) {
      data = cData.value.logoBrandArea;
    }
    return data;
  });

  const cData = computed<any>(() => {
    if (IS_SSR) {
      return props.customData;
    }

    return store.getters['layout/headerData'];
  });

  const homeData = computed(() => {
    let data: any = {};
    if (cData.value?.navigation?.home) {
      data = { ...cData.value.navigation.home };
      delete data.subElements;

      if (IS_SHOWROOM && window.location.pathname.startsWith('/fixperience')) {
        data = {
          ...data,
          url: '/fixperience',
        };
      }
    }
    return data;
  });

  return {
    breadcrumbData,
    logoHeader,
    logoBrandArea,
    cData,
    homeData,
  };
};
