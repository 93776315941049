import { useQuery } from '@vue/apollo-composable';
import { useFischer } from 'composables/fischerPlugin';
import GET_HANDLING_FEE_LIMIT from 'gql/getHandlingFeeLimit.gql';
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

import { useHandlingFeeCookie } from './handlingFeeCookie';

export const useHandlingFeeSetup = () => {
  const store = useStore();
  const fischer = useFischer();

  const { clearHandlingFeeLimitCookie, getHandlingFeeLimitCookie, setHandlingFeeLimitCookie } =
    useHandlingFeeCookie();

  const handlingFeeLimit = ref<any>(null); // XXX
  const handlingFeeBannerScrollHandlerEnabled = ref<boolean>(false);

  const requestHandlingFeeBanner = computed<boolean>(
    () => store.getters['navigation/requestHandlingFeeBanner'],
  );
  const enableHandlingFeeBanner = computed<boolean>(
    () => store.getters['navigation/enableHandlingFeeBanner'],
  );
  const isScrolledOutHandlingFeeBanner = computed<boolean>(
    () => store.getters['navigation/isScrolledOutHandlingFeeBanner'],
  );

  watchEffect(() => {
    if (IS_SSR || !handlingFeeBannerScrollHandlerEnabled.value) {
      return;
    }

    const scrollPosition = store.getters['layout/getWindowScrollPos'];
    const hideThreshold = isScrolledOutHandlingFeeBanner.value ? 250 : 300; // make sure we do not cycle between 'scrolled in' and 'scrolled out' because we are at the edge
    const hideBanner = scrollPosition.y > hideThreshold;
    if (hideBanner !== isScrolledOutHandlingFeeBanner.value) {
      store.commit('navigation/IS_SCROLLED_OUT_HANDLING_FEE_BANNER', hideBanner);
    }
  });

  const setHandlingFeeLimit = (value: any) => {
    handlingFeeLimit.value = value;
    if (value && value !== '-1') {
      store.commit('navigation/ENABLE_HANDLING_FEE_BANNER', true);
    }
  };

  if (fischer.shop.isClosedShop() && fischer.user.accessToken()) {
    setHandlingFeeLimit(getHandlingFeeLimitCookie());
    if (enableHandlingFeeBanner.value) {
      const currentUrl = fischer.uri();
      if (
        fischer.isCategoryPage() ||
        fischer.isFamilyPage() ||
        fischer.isVariantPage() ||
        fischer.pages.products().url === currentUrl ||
        fischer.pages.cart().url === currentUrl ||
        fischer.pages.checkout().url === currentUrl
      ) {
        store.commit('navigation/REQUEST_HANDLING_FEE_BANNER', true);

        if (!IS_SSR) {
          handlingFeeBannerScrollHandlerEnabled.value = true;
        }
      }
    }
  } else {
    clearHandlingFeeLimitCookie();
  }

  const enabled = computed<boolean>(() =>
    Boolean(
      !IS_SSR &&
        handlingFeeLimit.value === null &&
        fischer.shop.isClosedShop() &&
        (fischer.user.accessToken() || fischer.user.getAssistedService()?.user),
    ),
  );
  const { result, onError } = useQuery(
    GET_HANDLING_FEE_LIMIT,
    {
      baseSiteId: fischer.baseSiteId(),
      userId: fischer.user.id(),
      accessToken: fischer.user.accessToken(),
    },
    { enabled },
  );

  onError((error: any) => {
    console.error('could not get handling fee limit', error);
  });

  watchEffect(() => {
    const handlingFeeLimitFormatted = result.value?.getHandlingFeeLimit?.handlingFeeLimitFormatted;
    if (handlingFeeLimitFormatted) {
      setHandlingFeeLimitCookie(handlingFeeLimitFormatted);
      setHandlingFeeLimit(handlingFeeLimitFormatted);
    }
  });

  return {
    handlingFeeLimit,
    requestHandlingFeeBanner,
  };
};
