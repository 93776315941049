<template>
  <component
    :is="linkTag"
    :href="!disabled ? resolvedHref : null"
    :target="target || field.value.target"
    :class="[
      'link-fi',
      `fi-text-size-df-${size}`,
      disabled ? 'link-fi--disabled' : '',
      primary ? 'link-fi--primary' : '',
      secondary ? 'link-fi--secondary' : '',
      textLink ? 'link-fi--text-link' : '',
      thin ? 'link-fi--thin' : '',
      reverse ? 'link-fi--reverse' : '',
      theme ? `link-fi--theme-${theme}` : '',
    ]"
    :rel="getRel"
    :data-test="dataTest"
    :data-test-2="dataTest2"
    :data-test-3="dataTest3"
    @click="click"
    @mousedown="mousedown"
    @animationend="activeAnimationEnd"
  >
    <template v-if="reverse">
      <div
        v-if="!textLink"
        :class="`fi-text-size-df-${size} link-fi__arrow`"
      >
        <arrow-icon />
      </div>
      <span
        v-for="ripple in Object.keys(ripples)"
        :key="ripple"
        :style="ripples[ripple].style"
        :class="ripples[ripple].cssClass"
        class="link-fi__ripple"
        @animationend="(evt) => animationEnd(evt, ripple)"
      />
      <slot>
        {{ slotDefaultText }}
      </slot>
    </template>
    <template v-else>
      <slot>
        {{ slotDefaultText }}
      </slot>
      <div
        v-if="!textLink"
        :class="`fi-text-size-df-${size} link-fi__arrow`"
      >
        <arrow-icon />
      </div>
      <span
        v-for="ripple in Object.keys(ripples)"
        :key="ripple"
        :style="ripples[ripple].style"
        :class="ripples[ripple].cssClass"
        class="link-fi__ripple"
        @animationend="(evt) => animationEnd(evt, ripple)"
      />
    </template>
  </component>
</template>

<script>
import ArrowIcon from 'atoms/arrow-icon/ArrowIcon';
import formsConditionalFieldMixin from 'mixins/formsConditionalFieldMixin';

export default {
  name: 'LinkFi',
  components: {
    ArrowIcon,
  },
  mixins: [formsConditionalFieldMixin],
  props: {
    field: {
      type: Object,
      default: () => ({ value: {} }),
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 18,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    textLink: {
      type: Boolean,
      default: false,
    },
    thin: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    rel: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: 'a',
    },
    external: {
      type: Boolean,
      dafault: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: null,
    },
    dataTest: {
      type: String,
      default: null,
    },
    dataTest2: {
      type: String,
      default: null,
    },
    dataTest3: {
      type: String,
      default: null,
    },
  },
  emits: ['link-click'],
  data() {
    return {
      ripples: {},
      rippleForActive: null,
    };
  },
  computed: {
    resolvedHref() {
      return this.href || this.field.value.href;
    },
    slotDefaultText() {
      return this.field.value.text || this.href || this.field.value.href;
    },
    getRel() {
      const rels = [];

      if (this.rel) {
        rels.push(this.rel);
      }

      if (
        (this.external && this.target === '_blank') ||
        (this.field.value.url && this.field.value.target === '_blank')
      ) {
        rels.push('noopener');
        rels.push('noreferrer');
      }

      return rels.length ? rels.join(' ') : null;
    },
    linkTag() {
      if (this.disabled) {
        return 'span';
      }
      return this.tag;
    },
  },
  methods: {
    click(event) {
      this.mousedown(event);
      this.$emit('link-click', event, this.resolvedHref); // not emitting 'click', because we want the link to work normally
    },
    mousedown({ type, layerX, layerY }) {
      if (type !== 'mousedown') {
        return;
      }
      if (Object.keys(this.ripples).some((ripple) => !this.ripples[ripple].cssClass)) {
        return;
      }
      const style = `left: calc(-50% + ${layerX}px); top: calc(-50% + ${layerY}px);`;
      this.ripples.timeStamp = {
        style,
        cssClass: null,
      };
    },
    animationEnd(evt, ripple) {
      if (evt.animationName.startsWith('scaleOut')) {
        delete this.ripples[ripple];
        return;
      }
      if (this.rippleForActive || !this.isActive) {
        this.ripples[ripple].cssClass = 'scaling-out';
        return;
      }
      this.rippleForActive = ripple;
    },
    activeAnimationEnd({ animationName }) {
      if (animationName.startsWith('active')) {
        this.isActive = true;
      } else if (animationName.startsWith('inactive')) {
        this.isActive = false;
        if (this.rippleForActive) {
          this.ripples[this.rippleForActive].cssClass = 'scaling-out';
          this.rippleForActive = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './link-fi.scss';
</style>
