import * as Sentry from '@sentry/vue';

export default function initSentry(app) {
  let beforeUnload = false;
  if (!IS_SSR) {
    window.addEventListener('beforeunload', () => {
      beforeUnload = true;
    });
  }

  Sentry.init({
    app,
    release: APP_ENVIRONMENT === 'PROD' ? FRONTEND_RELEASE : undefined,
    environment: APP_ENVIRONMENT,
    dsn: 'https://0745d0e0adc24364a1150a244bf9b0b8@sentry.io/2522235',
    ignoreErrors: [
      "Can't find variable: _AutofillCallbackHandler", // error in instagram browser
    ],
    denyUrls: [
      'https://www.google-analytics.com/analytics.js',
      /sitecore\/shell\//,
      /sitecore%20modules\//,
      /^https?:\/\/(?:www\.)?fischer\.it\//,
    ],
    beforeSend(event, hint) {
      if (event.message?.includes('TypeError: error loading dynamically imported module')) {
        return null;
      }
      if (event.message?.includes('TypeError: Importing a module script failed')) {
        return null;
      }
      if (window.location.search.includes('debugSentry=true')) {
        window.debugSentry = { event, hint };
      }
      if (beforeUnload) {
        console.debug('before unload fired');
        return null;
      }
      if (hint?.originalException?.stack) {
        const isGoogleTagManager = /\(\/gtm\.js:/;
        if (isGoogleTagManager.test(hint.originalException.stack)) {
          return null;
        }
      }
      const cancelException = event?.exception?.values?.some((exception) => {
        return exception?.stacktrace?.frames?.some((frame) => frame.filename?.includes('/gtm.js'));
      });
      if (cancelException) {
        return null;
      }
      return event;
    },
  });
  Sentry.attachErrorHandler(app, { logErrors: true });
}
