import axios from 'axios';
import { type App, reactive } from 'vue';

import type { MarkSsrError } from '@/../server/server';
import type {
  SitecoreContext,
  SitecoreData,
  SitecorePage,
  SitecoreSettings,
  SitecoreUser,
} from '@/sitecoreState';
import lowerCaseKeys from '@/utils/lowerCaseKeys';
import parseTokenData, { type IdTokenData } from '@/utils/parseTokenData';

import authorize from './authorize';

export type FischerPluginStore = {
  settings: SitecoreSettings | any;
  context: SitecoreData | any;
  sitecoreContext: SitecoreContext | any;
  user: SitecoreUser | any;
  idTokenData: IdTokenData | null | undefined;
  dev: {
    markSsrError?: MarkSsrError;
  };
  setData(data: any, state: any, markSsrError?: MarkSsrError): void;
};

type fischerPluginPage = {
  url: string;
};

interface AccessPrivilege {
  uid: string;
}

interface AssistedServiceUser {
  uid: string;
  activated: boolean;
  customerId: string;
  deactivationDate: any;
  displayUid: string;
  fanclubnumber: string | null;
  firstName: string;
  fullname: string;
  fullName: string;
  lastName: string;
  lastLogin: string;
  name: string;
  company?: {
    distributionChannel?: string;
    division?: string;
  };
  roles: Array<string>;
  accessPrivileges: Array<AccessPrivilege>;
}

export type SsrCookiesObject = Record<string, string>;

type SsrCookies = null | undefined | SsrCookiesObject;

type AssistedServiceData = {
  token: string;
  expiresIn: string;
  idToken: string;
  loginSuccessPage: string;
  user: AssistedServiceUser | undefined;
};

// Unfortunately not IE11 compatible, even if we transpile it: import { Base64 } from 'js-base64';
const Base64 = {
  decode: function decode(str: string): string {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
  },
};

const assistedServiceItemName = 'AssistedService';
const setAssistedService = (App: App, assistedService?: AssistedServiceData) => {
  if (IS_SSR) {
    return;
  }
  const expiresIn = assistedService?.expiresIn;
  if (!expiresIn) {
    window.localStorage.removeItem(assistedServiceItemName);
    App.config.globalProperties.$cookies.remove(assistedServiceItemName);
    return;
  }

  const expires = `${expiresIn}s`;
  App.config.globalProperties.$cookies.set(
    assistedServiceItemName,
    'true',
    expires,
    undefined,
    undefined,
    undefined,
    'Strict',
  );

  window.localStorage.setItem(assistedServiceItemName, JSON.stringify(assistedService));
};

const clearAssistedService = (App: App): null => {
  try {
    window.localStorage.removeItem(assistedServiceItemName);
  } catch (error) {
    // noop
  }
  App.config.globalProperties.$cookies.remove(assistedServiceItemName);
  return null;
};

const getAssistedService = (App: App): AssistedServiceData | null => {
  if (IS_SSR) {
    return null;
  }
  let valueString: string | undefined | null;
  try {
    valueString = window.localStorage.getItem(assistedServiceItemName);
  } catch (error) {
    console.debug('Could not read Assisted Service from localStorage', error);
  }
  if (!valueString) {
    return null;
  }

  let value: AssistedServiceData | undefined;
  try {
    value = JSON.parse(valueString) as AssistedServiceData;
  } catch (error) {
    console.error('Could not parse AssistedService', error, valueString);
  }
  if (!value) {
    console.info('Assisted Service invalid');
    return clearAssistedService(App);
  }

  const idTokenOriginal = App.config.globalProperties.$fischer.user.idTokenOriginal();

  if (!IS_SHOWROOM) {
    const userEmail = App.config.globalProperties.$fischer.user.email();
    if (userEmail === value.user?.uid) {
      if (!idTokenOriginal) {
        // impersonated session
        return value;
      }
      console.info('Assisted Service session replaced by actual user');
      return clearAssistedService(App);
    }
    if (!idTokenOriginal) {
      console.info('Session expired, clearing Assisted Service');
      return clearAssistedService(App);
    }

    const groups = App.config.globalProperties.$fischer.user.groups();
    if (
      groups &&
      !['asagentgroup', 'customermanagergroup'].every((group) => groups.includes(group))
    ) {
      console.info('Assisted Service no longer active in sitecore');
      return clearAssistedService(App);
    }
  }

  const isActive = App.config.globalProperties.$cookies.get(assistedServiceItemName) === 'true';
  if (!isActive) {
    console.info('Assisted Service cookie no longer active');
    return clearAssistedService(App);
  }
  return value;
};

export interface FischerPlugin {
  store: FischerPluginStore;
  settings(): any;
  catalogSettings(): any;
  shopSettings(): any;
  siteSettings(): any;
  cartId(): string;
  setCartId(id: string): void;
  siteLanguage(): string;
  languageIsoCode(): string;
  language_639_1(): string;
  brandId(): string;
  brandName(): string;
  catalogLocale(): string;
  baseSiteId(): string;
  factFinderChannel(): string;
  useGtins(): boolean;
  isCatalogPage(): boolean;
  isCM(): boolean;
  isProductsPage(): boolean;
  isRootCategoryPage(): boolean;
  isCategoryPage(): boolean;
  isFamilyPage(): boolean;
  isVariantPage(): boolean;
  catalogId(defaultId?: string): string | null;
  catalogIdPath(): string;
  catalogUrl(url: string): string;
  localRetailers(): boolean;
  hostUrlBase(): string;
  hostname(): string;
  isSecureConnection(): boolean;
  redirectUrl(id: string | number): string;
  theme(): string;
  lengthUnit(): string;
  shop: {
    shopType(): string;
    isRetailConnect(): boolean;
    isRetailConnectCart(): boolean;
    isOnlineShop(): boolean;
    isClosedShop(): boolean;
    supportsCart(): boolean;
    useEco(): boolean;
    hasAvailabilityStatusOnlyPermission(): boolean;
    hasAvailabilityOnlyGroup(): boolean;
    hasPurchasePermissionWithoutPrices(): boolean;
  };
  pageId(): string;
  pageTemplate(): string;
  uri(): string;
  query(key: string): string;
  query(): object | null;
  hash(key: string): string | null | undefined;
  httpStatus(): number;
  postBody(key?: string, noDecode?: boolean): string | null;
  ssrCookies(): SsrCookies;
  gmapApiKey(): string;
  recaptchaKey(): string;
  cookieBotId(): string;
  enableAnalytics(): boolean;
  isEventHubEnabled(): boolean | undefined;
  isLoginDialogEnabled(): boolean;
  getSiteConfig(key: string, defaultValue: any): any;
  isYoukuPlayer(): boolean;
  largeLogo(): boolean;
  constructionEnableThirdPartyProducts(): boolean;
  isChatbotEnabled(): boolean;
  isSupportBoxEnabled(): boolean;
  pages: {
    assistedService(): SitecorePage;
    products(): SitecorePage;
    companies(): SitecorePage;
    registration(): SitecorePage;
    registrationSuccess(): SitecorePage;
    activation(): SitecorePage;
    setPassword(): SitecorePage;
    login(): SitecorePage | fischerPluginPage;
    loginSuccessPage(): SitecorePage | fischerPluginPage;
    logout(): SitecorePage | fischerPluginPage;
    logoutSuccess(): SitecorePage | fischerPluginPage;
    myAccount(): SitecorePage;
    myAccountAddressOverview(): SitecorePage;
    passwordForgotten(): SitecorePage;
    cart(): SitecorePage;
    shipment(): SitecorePage;
    checkout(): SitecorePage;
    orderHistory(): SitecorePage;
    orderDetails(): SitecorePage;
    downloadCenter(): SitecorePage | fischerPluginPage;
    search(): SitecorePage;
    constructionDocumentation(): SitecorePage;
    constructionThirdPartyOverview(): SitecorePage;
    constructionEJDocuments(): SitecorePage;
  };
  user: {
    getAssistedService(): AssistedServiceData | null;
    getAssistedServiceUser(): any;
    getAssistedServiceEmail(): string | null;
    accessToken(): string;
    setAccessToken(accessToken: string, exp: string): void;
    name(): string;
    email(): string;
    customerId(): string;
    distributionChannel(): string | undefined;
    division(): string | undefined;
    idToken(): string;
    idTokenOriginal(): string;
    idTokenData(): IdTokenData | null;
    isIdTokenValid(): boolean;
    groups(): string[];
    id(): string;
    idOriginal(): string;
    authenticated(): boolean;
    isDiy(): boolean;
    isProfi(): boolean;
    raw(): SitecoreUser;
    targetGroup(): string | null;
    subTargetGroup(): unknown | null;
    myfischerStartPageUrl(): string;
    myfischerIsDefaultStartPage(): boolean;
    trackingId(): string;
  };
  app: {
    isAppView(): boolean;
    isUpat(): boolean;
    isFite(): boolean;
    isEditMode(): boolean;
  };
  authorize(
    anonymous: boolean,
    authenticated: boolean,
    groups: Array<string>,
    membership: string,
    deny: boolean,
  ): boolean;
  setAssistedService(value?: AssistedServiceData): void;
  assistedService(app: App): AssistedServiceData | null;
  showroomOverrides: any;
}

export const createFischerStore: () => FischerPluginStore = () => {
  // NOTE: try to NOT mock anything here, see public/__mocks__/sitecoreApi/api/layout/mockedLayoutData.json
  return {
    // Various settings from Sitecore
    settings: {},

    // The actual context from the request
    context: {},

    // The Sitecore context
    sitecoreContext: {},

    // User related data
    user: {},

    // decoded idToken
    idTokenData: undefined,

    dev: {},

    setData(data: any, state: any, markSsrError?: MarkSsrError): void {
      this.context = state?.sitecore.data || {};
      this.settings = data.sitecore.settings || {};
      this.sitecoreContext = state.sitecore.context || {};
      this.user = data.sitecore.user || {};

      if (!this.settings.catalog) {
        this.settings.catalog = {};
      }

      if (!this.settings.shop) {
        this.settings.shop = {};
      }

      if (!this.settings.site) {
        this.settings.site = {};
      }

      if (IS_SSR) {
        this.dev.markSsrError = markSsrError;
      }

      if (IS_SHOWROOM) {
        this.context = reactive(this.context);
        this.settings = reactive(this.settings);
        this.sitecoreContext = reactive(this.sitecoreContext);
        this.user = reactive(this.user);
      }
    },
  };
};

// Vue plugins must export a function named 'install'
function install(App: App, store: FischerPluginStore): void {
  // "standard" convention for Vue plugins to ensure they are only installed once.
  if (App.config.globalProperties.$fischer) {
    return;
  }

  const showroomOverrides: any | null =
    IS_SHOWROOM || (APP_ENVIRONMENT === 'VM' && !IS_SSR)
      ? {
          setContext: (key: string, value: any) => {
            store.context[key] = value;
          },
          setContext2: (key: string, value: any) => (store.context[key] = value),
          setCatalogSetting: (key: string, value: any) => {
            store.settings[key] = value;
          },
          setShopSetting: (key: string, value: any) => {
            store.settings.shop[key] = value;
          },
          setTheme: (key: string, value: any) => {
            store.settings.site[key] = value;
          },
          setUser: (key: string, value: any) => {
            store.user[key] = value;
          },
          localDistLoginTokenData: () => {
            const idToken = App.config.globalProperties.$cookies.get('IdToken');
            if (!idToken) {
              return {};
            }
            return parseTokenData(idToken);
          },
          async postAceessTokenToVM(url: string) {
            if (IS_SHOWROOM) {
              console.error('only for VM');
              return;
            }
            if (!url) {
              console.error('missing authorize callback url');
              return;
            }
            const [, hash] = url.split('#');
            if (!hash) {
              console.error('missing hash');
              return;
            }
            const search = new URLSearchParams(hash);
            const accessToken = search.get('access_token');
            if (!accessToken) {
              console.error('missing access token');
              return;
            }
            const idToken = search.get('id_token');
            if (!idToken) {
              console.error('missing id token');
              return;
            }

            try {
              const result = await fetch('/webapi/shop/userapi/Login', {
                method: 'POST',
                body: JSON.stringify({
                  User: {
                    AccessToken: accessToken,
                    IdToken: idToken,
                    IsIdsSession: false,
                  },
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              if (result?.status === 200) {
                console.info('success -> reloading page');
                window.location.reload();
              } else {
                console.error('failed', result?.status);
              }
            } catch (error) {
              console.error(error);
            }
          },
        }
      : null;

  const $fischer: FischerPlugin = {
    store,
    // Some convenience methods for accessing important data easily
    settings() {
      return store.settings;
    },
    catalogSettings() {
      return store.settings.catalog;
    },
    shopSettings() {
      return store.settings.shop;
    },
    siteSettings() {
      return store.settings.site;
    },
    cartId(): string {
      const siteCoreCartId = store.user.cartId;
      if (siteCoreCartId) {
        return siteCoreCartId;
      }
      if (IS_SHOWROOM) {
        if (App.config.globalProperties.$cookies.get('AccessToken')) {
          return 'current';
        }
        if (App.config.globalProperties.$cookies.get('CartId')) {
          return App.config.globalProperties.$cookies.get('CartId');
        }
      }
      return '';
    },
    async setCartId(id: string) {
      store.user.cartId = id;
      if (IS_SHOWROOM) {
        App.config.globalProperties.$cookies.set(
          'CartId',
          id,
          undefined,
          undefined,
          undefined,
          undefined,
          'Strict',
        );
        return;
      }
      try {
        await axios.post('/webapi/shop/cartsapi/setcart', {
          Cart: {
            cartId: id,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    siteLanguage(): string {
      return store.context.language;
    },
    languageIsoCode(): string {
      const siteLanguage = this.siteLanguage();
      if (siteLanguage === 'da') {
        return 'DK';
      }
      return siteLanguage.split('-')[1];
    },
    language_639_1(): string {
      return this.siteLanguage().split('-')[0];
    },
    brandId(): string {
      if (IS_SHOWROOM) {
        const devBrand = App.config.globalProperties.$cookies.get('devBrand');
        if (devBrand) {
          return devBrand;
        }
      }
      return store.settings.catalog.brandId;
    },
    brandName(): string {
      if (IS_SHOWROOM && this.brandId() === 'FITE') {
        return 'fischertechnik';
      }
      return store.settings.site.brandName || '';
    },
    catalogLocale(): string {
      return store.settings.catalog.locale;
    },
    baseSiteId(): string {
      if (IS_SHOWROOM && this.brandId() === 'FITE') {
        return 'FITE-INT';
      }
      return store.settings.shop.baseSiteId;
    },
    factFinderChannel(): string {
      const fromStore = store.settings.site.factFinderChannel;
      if (fromStore) {
        return fromStore;
      }
      const baseSiteId = this.baseSiteId();
      const locale = this.catalogLocale();
      if (!(baseSiteId && locale)) {
        return '';
      }
      const base = baseSiteId.replace(/-.*/, '');
      return `${base}-${locale}`;
    },
    useGtins(): boolean {
      return Boolean(store.settings.shop.useGTIN);
    },
    isCatalogPage(): boolean {
      return Boolean(store.context.isCatalogPage);
    },
    isCM(): boolean {
      return store.context.isContentManagement || false;
    },
    isProductsPage(): boolean {
      return Boolean(store.context.isProductsPage || store.context.isVariantPage);
    },
    isRootCategoryPage(): boolean {
      return Boolean(store.context.isRootCategoryPage);
    },
    isCategoryPage(): boolean {
      if (IS_SHOWROOM && !IS_SSR && window.location.pathname.includes('/product-category')) {
        return true;
      }

      return Boolean(store.context.isCategoryPage);
    },
    isFamilyPage(): boolean {
      return Boolean(store.context.isFamilyPage);
    },
    isVariantPage(): boolean {
      return Boolean(store.context.isVariantPage);
    },
    catalogId(defaultId?: string): string | null {
      if (store.context.catalogId) {
        return store.context.catalogId.toString();
      }
      if (IS_SHOWROOM && defaultId) {
        return defaultId;
      }
      return null;
    },
    catalogIdPath(): string {
      // returns: Standardbefestigungen/Kunsstoffdübel/Duopower - '1001341446/1001341448/1001341458'
      return store.context.catalogIdPath || '';
    },
    catalogUrl(url: string): string {
      let { catalogUrl } = store.settings.catalog;

      if (url) {
        let relativeUrl = url;
        if (relativeUrl.startsWith('/')) {
          relativeUrl = relativeUrl.substring(1);
        }
        catalogUrl = catalogUrl.concat(relativeUrl);
      }
      return catalogUrl;
    },
    localRetailers(): boolean {
      return (
        Boolean(store.settings.site.localRetailers) &&
        !this.shop.hasAvailabilityStatusOnlyPermission()
      );
    },
    hostUrlBase(): string {
      return `http${this.isSecureConnection() ? 's' : ''}://${this.hostname()}`;
    },
    hostname(): string {
      if (IS_SHOWROOM) {
        return window.location.host;
      }
      return store.context.host;
    },
    isSecureConnection(): boolean {
      if (IS_SHOWROOM) {
        return window.location.protocol === 'https:';
      }
      return Boolean(store.context.isSecureConnection);
    },
    redirectUrl(id: string | number): string {
      return store.settings.catalog.redirectUrl + id;
    },
    theme(): string {
      const theme = store.settings?.site?.theme || 'fiwe';
      return theme === 'fide' ? 'fiwe' : theme; // sitecore still calls it fide
    },
    lengthUnit(): string {
      return store.settings.site.lengthUnit || 'km';
    },
    shop: {
      shopType(): string {
        const assistedService = getAssistedService(App);
        if (assistedService && !assistedService.user) {
          return 'NoShop';
        }
        return store.settings.shop.shopType || '';
      },
      isRetailConnect(): boolean {
        return (
          this.shopType() === 'RetailConnect' &&
          !this.hasAvailabilityOnlyGroup() &&
          !this.hasPurchasePermissionWithoutPrices()
        );
      },
      isRetailConnectCart(): boolean {
        return (
          this.shopType() === 'RetailConnectCart' &&
          !this.hasAvailabilityOnlyGroup() &&
          !this.hasPurchasePermissionWithoutPrices()
        );
      },
      isOnlineShop(): boolean {
        return this.shopType() === 'OnlineShop' || this.hasPurchasePermissionWithoutPrices();
      },
      isClosedShop(): boolean {
        return this.shopType() === 'ClosedOnlineShop' || this.hasPurchasePermissionWithoutPrices();
      },
      supportsCart(): boolean {
        return ['ClosedOnlineShop', 'RetailConnectCart', 'OnlineShop'].includes(this.shopType());
      },
      useEco(): boolean {
        return store.context.language === 'fr-FR';
      },
      hasAvailabilityStatusOnlyPermission(): boolean {
        if (this.isOnlineShop() || this.isClosedShop()) {
          return false;
        }
        return this.hasAvailabilityOnlyGroup();
      },
      hasAvailabilityOnlyGroup(): boolean {
        const groups = App.config.globalProperties.$fischer.user.groups();

        if (!groups || !Array.isArray(groups) || !groups.length) {
          return false;
        }

        return groups.includes('ACCESS-PRIVILEGE-SHOW-AVAILABILITY');
      },
      hasPurchasePermissionWithoutPrices(): boolean {
        const groups = App.config.globalProperties.$fischer.user.groups();

        if (!groups || !Array.isArray(groups) || !groups.length) {
          return false;
        }

        if (groups.includes(`${store.settings.shop.baseSiteId}-shop`)) {
          return false;
        }

        return groups.includes('ACCESS-PRIVILEGE-PURCHASING-WITHOUT-PRICES');
      },
    },
    pageId(): string {
      return store.context.pageId.replace('{', '').replace('}', '');
    },
    pageTemplate(): string {
      return store.context.pageTemplate || '';
    },
    uri(): string {
      if (IS_SHOWROOM) {
        return window.location.pathname;
      }
      return store.context.uri;
    },
    query(key?: string) {
      if (!IS_SSR) {
        const search = new URLSearchParams(document.location.search);
        if (typeof key === 'string') {
          return search.get(key);
        }

        const q: Record<string, string> = {};
        search.forEach((value: string, searchKey: string) => {
          q[searchKey] = value;
        });
        return q;
      }

      const q = store.context?.query;
      if (!q) {
        console.error('query not in store');
        if (['TEST', 'UAT'].includes(APP_ENVIRONMENT)) {
          if (!store.dev.markSsrError) {
            console.error('Could not mark ssr error', JSON.stringify(store));
          }
          store.dev.markSsrError?.('missing query data');
        }
        return key ? {} : '';
      }

      if (key) {
        if (Object.preventExtensions.hasOwnProperty.call(q, key)) {
          return q[key];
        }
        return null;
      }
      return q;
    },
    hash(key: string) {
      if (!IS_SSR && key) {
        const hashes = window.location.hash.slice(1);
        return new URLSearchParams(hashes).get(key);
      }

      return null;
    },
    httpStatus(): number {
      return store.context.httpStatus || 0;
    },
    postBody(key: string, noDecode: boolean): string | null {
      if (IS_SHOWROOM) {
        if (!key) {
          // eslint-disable-next-line no-console
          console.log('postBody without key not mocked');
          return null;
        }
        const value64 = new URLSearchParams(document.location.search).get(key);
        if (value64 === undefined || value64 === null) {
          return null;
        }
        if (noDecode) {
          return value64;
        }
        return Base64.decode(value64);
      }
      if (store.context.postBody) {
        const q = store.context.postBody;
        if (key) {
          if (q && Object.preventExtensions.hasOwnProperty.call(q, key)) {
            const value64 = q[key];
            if (noDecode) {
              return value64;
            }
            return Base64.decode(value64);
          }
          return null;
        }
        return q;
      }
      return null;
    },
    // you probably do not want to call this directly
    // use mixins/ssrCookieMixin instead
    ssrCookies(): SsrCookies {
      return store.context.cookies;
    },
    gmapApiKey(): string {
      return store.settings.site.googleMapsApiKey || '';
    },
    recaptchaKey(): string {
      return store.settings.site.recaptchaKey || '';
    },
    cookieBotId(): string {
      return store.settings.site.cookieBotId || '';
    },
    enableAnalytics(): boolean {
      if (IS_SSR) {
        return false;
      }

      if (IS_SHOWROOM) {
        return true;
      }
      return store.settings.site.enableAnalytics;
    },
    isEventHubEnabled(): boolean | undefined {
      return store.settings.site.enableEventHub;
    },
    isLoginDialogEnabled(): boolean {
      return store.settings.site.enableLocalSSOLayer && !App.config.globalProperties.isAppView;
    },
    getSiteConfig(key: string, defaultValue: any) {
      const value = store.settings.site[key];
      if (value !== undefined) {
        return value;
      }
      return defaultValue;
    },
    isYoukuPlayer(): boolean {
      return store.settings.site.youku;
    },
    largeLogo(): boolean {
      return store.settings.site.largeLogo;
    },
    constructionEnableThirdPartyProducts(): boolean {
      return store.settings.site.constructionEnableThirdPartyProducts;
    },
    isChatbotEnabled(): boolean {
      if (IS_SHOWROOM) {
        return true;
      }
      return store.settings.site.enableChatBot;
    },
    isSupportBoxEnabled(): boolean {
      if (IS_SHOWROOM) {
        return true;
      }
      return store.settings.site.enableSupportBox;
    },
    pages: {
      assistedService(): SitecorePage {
        return lowerCaseKeys(store.settings.site.adminLoginPage || {});
      },
      products(): SitecorePage {
        return lowerCaseKeys(store.settings.site.productsPage || {});
      },
      companies(): SitecorePage {
        return lowerCaseKeys(store.settings.site.companyPage || {});
      },
      registration(): SitecorePage {
        return lowerCaseKeys(store.settings.site.registrationPage || {});
      },
      registrationSuccess(): SitecorePage {
        return lowerCaseKeys(store.settings.site.registrationSuccessPage || {});
      },
      activation(): SitecorePage {
        return store.settings.site.activationPage;
      },
      setPassword(): SitecorePage {
        return lowerCaseKeys(store.settings.site.setPasswordPage || {});
      },
      login(): SitecorePage {
        return lowerCaseKeys(store.settings.site.loginPage || {});
      },
      loginSuccessPage(): SitecorePage | fischerPluginPage {
        const assistedService = getAssistedService(App);
        if (assistedService && !assistedService.user) {
          return {
            url: assistedService.loginSuccessPage,
          };
        }
        return lowerCaseKeys(store.settings.site.loginSuccessPage || {});
      },
      logout(): SitecorePage | fischerPluginPage {
        return lowerCaseKeys(store.settings.site.logoutPage || {});
      },
      logoutSuccess(): SitecorePage | fischerPluginPage {
        if (IS_SHOWROOM) {
          return { url: '/my-account/logout-success' };
        }
        return lowerCaseKeys(store.settings.site.logoutSuccessPage || {});
      },
      myAccount(): SitecorePage {
        return lowerCaseKeys(store.settings.site.myAccountPage || {});
      },
      myAccountAddressOverview(): SitecorePage {
        return lowerCaseKeys(store.settings.site.myAccountAddressOverviewPage || {});
      },
      passwordForgotten(): SitecorePage {
        return lowerCaseKeys(store.settings.site.passwordForgottenPage || {});
      },
      cart(): SitecorePage {
        return lowerCaseKeys(store.settings.site.cartPage || {});
      },
      shipment(): SitecorePage {
        return lowerCaseKeys(store.settings.site.shipmentPage || {});
      },
      checkout(): SitecorePage {
        return lowerCaseKeys(store.settings.site.checkoutPage || {});
      },
      orderHistory(): SitecorePage {
        return lowerCaseKeys(store.settings.site.orderHistoryPage || {});
      },
      orderDetails(): SitecorePage {
        return lowerCaseKeys(store.settings.site.orderDetailsPage || {});
      },
      downloadCenter(): SitecorePage | fischerPluginPage {
        if (IS_SHOWROOM) {
          return { url: '/download-center-fi' };
        }
        return lowerCaseKeys(store.settings.site.downloadCenterPage || {});
      },
      search(): SitecorePage {
        return lowerCaseKeys(store.settings.site.searchPage || {});
      },
      constructionDocumentation(): SitecorePage {
        return lowerCaseKeys(store.settings.site.constructionDocumentationPage || {});
      },
      constructionThirdPartyOverview(): SitecorePage {
        return lowerCaseKeys(store.settings.site.constructionThirdPartyPage || {});
      },
      constructionEJDocuments(): SitecorePage {
        return lowerCaseKeys(store.settings.site.constructionEJDocumentsPage || {});
      },
    },
    user: {
      getAssistedService(): AssistedServiceData | null {
        const assistedService = getAssistedService(App);
        if (!assistedService) {
          return null;
        }
        if (!(this.authenticated() && assistedService.token)) {
          // session expired
          setAssistedService(App, assistedService);
          return null;
        }
        return assistedService;
      },
      getAssistedServiceUser(): any {
        const assistedService = this.getAssistedService();
        if (!assistedService) {
          return null;
        }
        return parseTokenData(assistedService.idToken);
      },
      getAssistedServiceEmail(): string | null {
        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.user.uid;
        }

        return null;
      },
      accessToken(): string {
        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.token;
        }

        if (IS_SHOWROOM) {
          if (App.config.globalProperties.$cookies.get('AccessToken')) {
            return App.config.globalProperties.$cookies.get('AccessToken');
          }
        }

        return store.user.accessToken || '';
      },
      setAccessToken(accessToken: string, exp: string): void {
        if (IS_SHOWROOM) {
          App.config.globalProperties.$cookies.set(
            'AccessToken',
            accessToken,
            exp,
            undefined,
            undefined,
            undefined,
            'Strict',
          );
        } else {
          store.user.accessToken = accessToken;
        }
      },
      name(): string {
        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.user.name;
        }
        const idToken = this.idTokenData();
        return idToken?.name || '';
      },
      email(): string {
        if (IS_SHOWROOM) {
          return showroomOverrides.localDistLoginTokenData().email;
        }

        return store.user.email || '';
      },
      customerId(): string {
        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.user.customerId;
        }

        if (IS_SHOWROOM) {
          return showroomOverrides.localDistLoginTokenData().sapCustomerId;
        }

        return store.user.customerId || '';
      },
      distributionChannel(): string | undefined {
        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.user.company?.distributionChannel;
        }
        const idToken = this.idTokenData();
        return idToken?.distributionChannel;
      },
      division(): string | undefined {
        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.user.company?.division;
        }
        const idToken = this.idTokenData();
        return idToken?.division;
      },
      idToken(): string {
        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.idToken;
        }

        return this.idTokenOriginal();
      },
      idTokenOriginal(): string {
        if (IS_SHOWROOM) {
          const idToken = App.config.globalProperties.$cookies.get('IdToken');
          if (idToken) {
            return idToken;
          }
        }
        return store.user.idToken || '';
      },
      idTokenData(): IdTokenData | null {
        if (store.idTokenData !== undefined) {
          return store.idTokenData;
        }
        const idToken = this.idToken();
        const data = idToken ? (parseTokenData(idToken) as IdTokenData) : null;
        store.idTokenData = data;
        return data;
      },
      isIdTokenValid(): boolean {
        const exp = this.idTokenData()?.exp;
        return Boolean(exp && exp * 1000 < Date.now());
      },
      groups(): string[] {
        if (IS_SHOWROOM) {
          const assistedService = this.getAssistedService();
          if (assistedService?.user) {
            return [
              ...assistedService.user.accessPrivileges.map((acl: any) => acl.uid),
              ...assistedService.user.roles,
            ];
          }
          if (this.authenticated()) {
            const tokenData = showroomOverrides.localDistLoginTokenData();
            if (tokenData?.groups) {
              return tokenData.groups;
            }
          }
        }
        return store.user.groups || [];
      },
      id(): string {
        const id = this.idOriginal();
        if (!id) {
          return 'anonymous';
        }

        const assistedService = this.getAssistedService();
        if (assistedService?.user) {
          return assistedService.user.uid;
        }

        return id;
      },
      idOriginal(): string {
        if (IS_SHOWROOM) {
          if (this.authenticated()) {
            return showroomOverrides.localDistLoginTokenData().sub;
          }
          return 'anonymous';
        }
        return store.user.userId || '';
      },
      authenticated(): boolean {
        if (IS_SHOWROOM) {
          if (App.config.globalProperties.$cookies.get('AccessToken')) {
            return true;
          }
          return false;
        }
        return Boolean(store.user.authenticated);
      },
      isDiy(): boolean {
        return Boolean(store.user.isDiy);
      },
      isProfi(): boolean {
        return Boolean(store.user.isProfi);
      },
      raw(): SitecoreUser {
        return store.user;
      },
      targetGroup(): string | null {
        return store.user.targetGroup || null;
      },
      subTargetGroup(): unknown | null {
        return store.user.subTargetGroup || null;
      },
      myfischerStartPageUrl(): string {
        return store.user.startPageUrl || '';
      },
      myfischerIsDefaultStartPage(): boolean {
        return Boolean(store.user.isDefaultStartPage);
      },
      trackingId(): string {
        return store.user.trackingId || '';
      },
    },
    app: {
      isAppView(): boolean {
        return App.config.globalProperties.isAppView;
      },
      isUpat(): boolean {
        return App.config.globalProperties.isUpat;
      },
      isFite(): boolean {
        return App.config.globalProperties.isFite;
      },
      isEditMode(): boolean {
        return App.config.globalProperties.isEditMode;
      },
    },
    authorize,
    setAssistedService: (value?: AssistedServiceData) => setAssistedService(App, value),
    assistedService: () => getAssistedService(App),
    showroomOverrides,
  };

  App.config.globalProperties.$fischer = $fischer;
}

export default { install };
