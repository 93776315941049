<template>
  <div
    :class="{
      'text-input-fi': true,
      'text-input-fi--focused': isFocused && !readonly,
      'text-input-fi--active': isActiveFormElement || isAutofilled,
      'text-input-fi--with-input': inputValue.length,
      'text-input-fi--disabled': disabled,
      'text-input-fi--readonly': readonly,
      'text-input-fi--error': showError,
      'text-input-fi--icon': icon,
      'text-input-fi--clear-plus-icon': clearPlusIcon,
      'text-input-fi--small': small,
      'text-input-fi--large': large,
      'text-input-fi--light': light,
      'text-input-fi--clear': clearActive,
      'text-input-fi--prefix': prefix,
      'text-input-fi--transparent': transparent,
      'text-input-fi--white': white,
      'text-input-fi--growable': isGrowable,
    }"
    :data-form-element-key="dataFormElementKey"
    :data-form-element-id="dataFormItemId"
  >
    <div class="text-input-fi__field">
      <label-fi
        class="text-input-fi__label"
        :label="label"
        :html-label="htmlLabel"
        :for="id"
        :mandatory="required"
        :limit-80="prefix || icon"
        :no-ellipsis="noEllipsis && !isActiveFormElement && !isAutofilled"
      />
      <span
        v-if="placeholder && !inputValue"
        class="text-input-fi__placeholder"
      >
        {{ placeholder }}
      </span>
      <growable-text-input-fi
        v-if="isGrowable"
        :id="id ? id : null"
        ref="input"
        v-model="inputValue"
        :class="localClasses"
        :name="name"
        :type="inputType"
        :maxlength="maxlength"
        :required="required"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :readonly="readonly"
        :aria-required="required ? true : null"
        :data-test="dataTest"
        :data-test-2="dataTest2"
        :data-test-3="dataTest3"
        :tabindex="disabled || readonly ? '-1' : null"
        @focus="onFocusInput"
        @change.stop="inputChanged"
        @keyup.enter="onKeyupEnter"
        @keydown="onKeyDown"
        @keyup="onKeyUp"
        @blur="onBlur"
        @input="onInput"
        @animationend="checkAutofillAnimation"
      />
      <!-- NOTE: it seems, we can not use <component :is="..." /> here,
        because the v-model will not work in the 'input' case.
        Please take care to mirror any changes to the growable-input-fi -->
      <input
        v-else
        :id="id"
        ref="input"
        v-model="inputValue"
        :class="localClasses"
        :name="name"
        :type="inputType"
        :maxlength="maxlength"
        :required="required"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :readonly="readonly"
        :aria-required="required ? true : undefined"
        :data-test="dataTest"
        :data-test-2="dataTest2"
        :data-test-3="dataTest3"
        :tabindex="disabled || readonly ? '-1' : undefined"
        @focus="onFocusInput"
        @change.stop="inputChanged"
        @keyup.enter="onKeyupEnter"
        @keydown="onKeyDown"
        @keyup="onKeyUp"
        @blur="onBlur"
        @input="onInput"
        @animationend="checkAutofillAnimation"
      />
      <label
        v-if="prefix"
        :for="id"
        class="text-input-fi__prefix"
      >
        {{ prefix }}
      </label>
      <div class="text-input-fi__icons">
        <div
          v-if="inputValue.length && clear && !readonly && !disabled"
          ref="clearInputButton"
          class="text-input-fi__icon"
          @click="clearInput"
        >
          <icon-fi :icon="clearIcon" />
        </div>
        <div
          v-if="icon && !isPasswordInput && (!clearActive || clearPlusIcon || readonly || disabled)"
          class="text-input-fi__icon"
          @click.stop="iconClicked"
        >
          <icon-fi
            :icon="icon"
            :data-test="icon === 'check' ? 'success-icon' : null"
            :color="iconColor"
          />
        </div>
        <tooltip-fi
          v-if="tooltipText"
          class="text-input-fi__tooltip"
          :tooltip="tooltipText"
        />
        <div
          v-if="isPasswordInput"
          class="text-input-fi__icon"
          @click.stop="toggleShowPassword"
        >
          <icon-fi :icon="showPassword ? 'eye-off' : 'eye'" />
        </div>
      </div>
    </div>
    <template v-if="showError">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="htmlError"
        :class="{
          'text-input-fi__error-text': true,
          'text-input-fi__error-text--absolute': errorAbsolute,
        }"
        v-html="errorText"
      />
      <!-- eslint-enable vue/no-v-html -->
      <div
        v-else
        :class="{
          'text-input-fi__error-text': true,
          'text-input-fi__error-text--absolute': errorAbsolute,
        }"
      >
        {{ errorText }}
      </div>
    </template>
    <template v-else-if="infoText && (!focusInfoText || isFocused)">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="htmlInfoText"
        class="text-input-fi__info-text"
        v-html="infoText"
      />
      <!-- eslint-enable vue/no-v-html -->
      <div
        v-else
        class="text-input-fi__info-text"
      >
        {{ infoText }}
      </div>
    </template>
    <div
      v-else-if="maxlength && showMaxLength"
      class="text-input-fi__length"
    >
      {{
        $dict.get('online-Shop.Global-Labels.Input-Max-Length', [
          String(inputValue.length),
          String(maxlength),
        ])
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
import GrowableTextInputFi from 'atoms/growable-text-input-fi/GrowableTextInputFi.vue';
import IconFi from 'atoms/icon-fi/IconFi.vue';
import LabelFi from 'atoms/label-fi/LabelFi.vue';
import TooltipFi from 'atoms/tooltip-fi/TooltipFi.vue';
import useJssFormsConditionalField, {
  type DataFormElementKeyProps,
} from 'composables/jssFormsConditionalField';
import { useLocalizedValue } from 'composables/localizedValue';
import {
  useValidateFormElement,
  type ValidateFormElementProps,
} from 'composables/validateFormElement';
import { computed, ref, useTemplateRef, watch } from 'vue';

defineOptions({
  name: 'TextInputFi',
});

const props = withDefaults(
  defineProps<
    {
      arrowKeyControlEnabled?: boolean;
      autocomplete?: string;
      clear?: boolean;
      clearIcon?: string;
      clearPlusIcon?: boolean;
      dataTest2?: string | undefined;
      dataTest3?: string | undefined;
      dataTest?: string | undefined;
      disableError?: boolean;
      disabled?: boolean;
      error?: boolean;
      errorAbsolute?: boolean;
      errorMessage?: string | boolean | undefined;
      focusInfoText?: boolean;
      htmlError?: boolean;
      htmlInfoText?: boolean;
      htmlLabel?: string | undefined;
      icon?: string | undefined;
      iconColor?: string | undefined;
      id?: string;
      infoText?: string;
      isGrowable?: boolean;
      label?: string;
      large?: boolean;
      light?: boolean;
      max?: number;
      maxlength?: string | number | undefined;
      min?: number;
      modelValue?: string | null;
      name?: string;
      noEllipsis?: boolean;
      onlyEmitIfValid?: boolean;
      placeholder?: string | undefined;
      prefix?: string | undefined;
      readonly?: boolean;
      required?: boolean;
      selectInputOnFocus?: boolean;
      showLocalizedValues?: boolean;
      showMaxLength?: boolean;
      small?: boolean;
      tooltipText?: string;
      transparent?: boolean;
      type?: 'text' | 'password';
      white?: boolean;
    } & DataFormElementKeyProps &
      ValidateFormElementProps
  >(),
  {
    errorMessage: undefined,
    htmlLabel: undefined,
    icon: undefined,
    iconColor: undefined,
    maxlength: undefined,
    placeholder: undefined,
    prefix: undefined,
    autocomplete: 'off',
    clearIcon: 'delete-text',
    dataFormElementKey: undefined,
    id: '',
    infoText: '',
    label: '',
    max: 100,
    min: 0,
    modelValue: null,
    name: '',
    tooltipText: '',
    type: 'text',
    dataTest: undefined,
    dataTest2: undefined,
    dataTest3: undefined,
  },
);

const emit = defineEmits<{
  'keydown-arrow-down': [value: string];
  'keydown-arrow-up': [value: string];
  'keyup-enter': [value: string];
  'update:modelValue': [value: string];
  'validation-changed': [value: boolean];
  autofilled: [value: boolean];
  blur: [value: string, originalEvent: FocusEvent];
  change: [value: string];
  clear: [value: void];
  focus: [value: void];
  iconClicked: [value: string];
  keydown: [value: string];
  keyup: [value: string];
}>();

const inputRef = useTemplateRef<HTMLInputElement | typeof GrowableTextInputFi>('input');
const clearInputButtonRef = useTemplateRef('clearInputButton');
const inputType = ref<string>('text');
const inputValue = ref<string>('');
const isFocused = ref<boolean>(false);
const isPasswordInput = ref<boolean>(false);
const showPassword = ref<boolean>(false);
const clearActive = ref<boolean>(false);
const isAutofilled = ref<boolean>(false);
const isActiveFormElement = ref<boolean>(false);

useJssFormsConditionalField(props);
const { getLocalizedValue, getValueWithoutSeparator } = useLocalizedValue();
const { resetErrorState, validate, valid, errorText, isError, errorBucket } =
  useValidateFormElement({
    props,
    value: inputValue,
  });

const showError = computed<boolean>(() => isError.value && !props.disableError);

const updateValue = (value: string | null) => {
  if (value) {
    inputValue.value = props.showLocalizedValues ? getLocalizedValue(value) : value;
  } else {
    inputValue.value = '';
  }
  clearActive.value = Boolean(props.clear && inputValue.value.length);
  setIsActiveFormElement();
};

const localClasses = computed<string[]>(() => {
  const localClasses = ['input-fi', 'text-input-fi__input'];
  if (props.white) {
    localClasses.push('--color-white');
  }
  if (props.label || props.htmlLabel) {
    localClasses.push('input-label-fi');
  }
  if (showError.value) {
    localClasses.push('input-fi--error');
  }
  return localClasses;
});

const setIsActiveFormElement = () => {
  isActiveFormElement.value = Boolean(inputValue.value.length);
};

watch(
  () => props.modelValue,
  (newValue) => updateValue(newValue),
);

watch(() => props.errorMessage, validate);

watch(() => props.rules, validate);

watch(valid, (newValue) => {
  emit('validation-changed', newValue);
});

watch(inputValue, () => {
  isAutofilled.value = false;
  setIsActiveFormElement();
});

watch(isAutofilled, (newValue) => {
  emit('autofilled', newValue);
});

const checkAutofillAnimation = async (event: AnimationEvent) => {
  if (event.animationName === 'onAutoFillStart') {
    isActiveFormElement.value = true;
    isAutofilled.value = true;
  }
};

const initInput = () => {
  inputType.value = props.type;

  if (inputType.value === 'password') {
    isPasswordInput.value = true;
  }

  if (props.value || props.value === '') {
    updateValue(props.value);
    validate();
  }
};

const focus = () => inputRef.value?.focus?.();
const blur = () => inputRef.value?.blur?.();

const onFocusInput = (event: Event) => {
  if (props.readonly) {
    return;
  }
  if (props.showLocalizedValues) {
    inputValue.value = getLocalizedValue(inputValue.value);
  }

  isFocused.value = true;
  isActiveFormElement.value = true;
  isAutofilled.value = false;

  if (props.selectInputOnFocus) {
    (event.target as undefined | HTMLInputElement)?.select();
  }

  emit('focus');
};

const onKeyUp = () => {
  emit('keyup', inputValue.value);
};

const onKeyDown = (event: KeyboardEvent) => {
  if (props.arrowKeyControlEnabled) {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        emit('keydown-arrow-up', inputValue.value);
        break;
      case 'ArrowDown':
        event.preventDefault();
        emit('keydown-arrow-down', inputValue.value);
        break;
      default:
        break;
    }
  }
  emit('keydown', inputValue.value);
};

const onKeyupEnter = (event: KeyboardEvent) => {
  if (event?.shiftKey) {
    return;
  }
  isFocused.value = false;
  emit('keyup-enter', inputValue.value);
};

const onBlur = (evt: FocusEvent) => {
  let currentValue = inputValue.value;
  if (props.trim) {
    currentValue = currentValue.trim();
    if (currentValue !== inputValue.value) {
      emit('update:modelValue', currentValue);
    }
  }

  if (props.showLocalizedValues) {
    const parsedCurrentValue = getValueWithoutSeparator(inputValue.value);

    if (parsedCurrentValue >= props.max) {
      currentValue = String(props.max);
    } else if (parsedCurrentValue <= props.min) {
      currentValue = String(props.min);
    }
  }

  // do not blur if clearInputButton was clicked
  if (clearInputButtonRef.value) {
    const { currentTarget } = evt as { currentTarget: HTMLElement | null };
    if (
      currentTarget?.closest &&
      currentTarget?.closest('.text-input-fi__icon') === clearInputButtonRef.value
    ) {
      // a nextTick does not seem to suffice :(
      setTimeout(focus, 100);
      return;
    }
  }

  isFocused.value = false;

  if (!inputValue.value) {
    isActiveFormElement.value = false;
  }

  if (currentValue !== inputValue.value) {
    updateValue(currentValue);
  }

  emit('blur', inputValue.value, evt);

  if (props.showLocalizedValues) {
    inputValue.value = getLocalizedValue(currentValue);
  }
};

const onInput = (event: Event) => {
  if (props.modelValue === undefined || props.modelValue === null) {
    return;
  }

  if (props.onlyEmitIfValid && props.rules && !valid.value) {
    return;
  }

  emit('update:modelValue', (event.target as HTMLInputElement)?.value);
};

const inputChanged = () => {
  if (props.onlyEmitIfValid && props.rules && !valid.value) {
    return;
  }

  emit('change', inputValue.value);
};

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  inputType.value = showPassword.value ? 'text' : 'password';
};

const clearInput = () => {
  isActiveFormElement.value = false;
  if (props.modelValue === undefined || props.modelValue === null) {
    inputValue.value = '';
  } else {
    emit('update:modelValue', '');
  }

  emit('clear');
};

const iconClicked = () => emit('iconClicked', inputValue.value);

if (props.modelValue !== undefined && props.modelValue !== null) {
  inputValue.value = props.showLocalizedValues
    ? getLocalizedValue(props.modelValue)
    : String(props.modelValue);
}
initInput();

defineExpose({
  blur,
  errorBucket,
  focus,
  resetErrorState,
  validate,
  valid,
});
</script>

<style lang="postcss">
@import './text-input-fi.scss';
</style>
