<template>
  <cloud-image-fi
    v-if="navImage"
    :src="navImage"
    :alt="`${navIndex}`"
    class="navigation-image-fi"
  />
</template>

<script>
import { CloudImageFi } from 'atoms';
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationImageFi',
  components: {
    CloudImageFi,
  },
  computed: {
    ...mapGetters({
      groupData: 'navigation/getGroupData',
      showFlyout: 'navigation/getFlyout',
      navIndex: 'navigation/getNavIndex',
    }),
    navImage() {
      if (this.groupData.teaserImage?.src) {
        return this.groupData.teaserImage.src;
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './navigation-image-fi';
</style>
