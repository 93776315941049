<template>
  <div class="mobile-navigation-sub-country-select-fi">
    <div class="mobile-navigation-sub-country-select-fi__header">
      <headline-fi
        v-if="title"
        :size="21"
        class="mobile-navigation-sub-country-select-fi__title"
        tag="div"
        color="brand"
        :html-content="title"
      />
      <headline-fi
        v-if="subtitle"
        :size="36"
        class="mobile-navigation-sub-country-select-fi__subtitle"
        tag="h1"
        :html-content="subtitle"
      />
    </div>
    <country-selection-locale-fi
      is-navigation
      @show-country-selection="$emit('show-country-selection')"
    />
  </div>
</template>

<script>
import { HeadlineFi } from 'atoms';
import CountrySelectionLocaleFi from 'components/country-selection-locale-fi/CountrySelectionLocaleFi';
import navigationMixin from 'mixins/navigationMixin';

export default {
  name: 'MobileNavigationSubCountrySelectFi',
  components: {
    HeadlineFi,
    CountrySelectionLocaleFi,
  },
  mixins: [navigationMixin],
  emits: ['show-country-selection'],
  computed: {
    title() {
      return this.$dict.get('global-Labels.Country-Selection.Title');
    },
    subtitle() {
      return this.$dict.get('global-Labels.Country-Selection.Subtitle');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mobile-navigation-sub-country-select-fi';
</style>
