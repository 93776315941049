const registration = {
  namespaced: true,
  state: () => ({
    targetGroup: '',
    targetGroupTitle: '', // For Datalayer only
    targetGroupNewsletter: '',
    dateOfBirth: null,
    dateOfBirthRaw: null,

    salutation: null,
    title: null,
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    parentEmail: '',
    parentEmailRequired: true,

    subTargetGroup: null,
    subTargetGroupTitle: '',
    country: null,
    company: '',
    customerNumber: '',
    street: '',
    streetNumber: '',
    zipcode: '',
    city: '',
    customerWebsite: '',
    validateAddress: false,

    password: '',
    passwordRepeat: '',
    termsAndConditions: false,
    advertisingConsent: false,
    isCaptchaError: false,
    captchaResponse: '',
  }),

  mutations: {
    SET_TARGETGROUP(state, targetGroup) {
      state.targetGroup = targetGroup;
    },
    SET_TARGETGROUPTITLE(state, targetGroupTitle) {
      state.targetGroupTitle = targetGroupTitle;
    },
    SET_TARGETGROUPNEWSLETTER(state, targetGroupNewsletter) {
      state.targetGroupNewsletter = targetGroupNewsletter;
    },
    SET_DATEOFBIRTH(state, dateOfBirth) {
      state.dateOfBirth = dateOfBirth;
    },
    SET_DATEOFBIRTHRAW(state, dateOfBirthRaw) {
      state.dateOfBirthRaw = dateOfBirthRaw;
    },

    SET_SALUTATION(state, salutation) {
      state.salutation = salutation;
    },
    SET_TITLE(state, title) {
      state.title = title;
    },
    SET_FIRSTNAME(state, firstname) {
      state.firstname = firstname;
    },
    SET_LASTNAME(state, lastname) {
      state.lastname = lastname;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_PHONE(state, phone) {
      state.phone = phone;
    },
    SET_PARENTEMAIL(state, parentEmail) {
      state.parentEmail = parentEmail;
    },
    SET_PARENTEMAILREQUIRED(state, parentEmailRequired) {
      state.parentEmailRequired = parentEmailRequired;
    },

    SET_SUBTARGETGROUP(state, subTargetGroup) {
      state.subTargetGroup = subTargetGroup;
    },
    SET_SUBTARGETGROUP_TITLE(state, subTargetGroupTitle) {
      state.subTargetGroupTitle = subTargetGroupTitle;
    },
    SET_COUNTRY(state, country) {
      state.country = country;
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
    SET_CUSTOMERNUMBER(state, customerNumber) {
      state.customerNumber = customerNumber;
    },
    SET_STREET(state, street) {
      state.street = street;
    },
    SET_STREETNUMBER(state, streetNumber) {
      state.streetNumber = streetNumber;
    },
    SET_ZIPCODE(state, zipcode) {
      state.zipcode = zipcode;
    },
    SET_CITY(state, city) {
      state.city = city;
    },
    SET_CUSTOMERWEBSITE(state, customerWebsite) {
      state.customerWebsite = customerWebsite;
    },
    SET_VALIDATEADDRESS(state, validateAddress) {
      state.validateAddress = validateAddress;
    },

    SET_PASSWORD(state, password) {
      state.password = password;
    },
    SET_PASSWORDREPEAT(state, passwordRepeat) {
      state.passwordRepeat = passwordRepeat;
    },
    SET_TERMSANDCONDITIONS(state, termsAndConditions) {
      state.termsAndConditions = termsAndConditions;
    },
    SET_ADVERTISINGCONSENT(state, advertisingConsent) {
      state.advertisingConsent = advertisingConsent;
    },
    SET_ISCAPTCHAERROR(state, isCaptchaError) {
      state.isCaptchaError = isCaptchaError;
    },
    SET_CAPTCHARESPONSE(state, captchaResponse) {
      state.captchaResponse = captchaResponse;
    },
    RESET_REGISTRATION_DATA(state) {
      state.targetGroup = '';
      state.targetGroupTitle = '';
      state.targetGroupNewsletter = '';
      state.dateOfBirth = null;
      state.dateOfBirthRaw = null;

      state.salutation = null;
      state.title = null;
      state.firstname = '';
      state.lastname = '';
      state.email = '';
      state.phone = '';
      state.parentEmail = '';
      state.parentEmailRequired = true;

      state.subTargetGroup = null;
      state.subTargetGroupTitle = '';
      state.country = null;
      state.company = '';
      state.customerNumber = '';
      state.street = '';
      state.streetNumber = '';
      state.zipcode = '';
      state.city = '';
      state.customerWebsite = '';
      state.validateAddress = false;

      state.password = '';
      state.passwordRepeat = '';
      state.termsAndConditions = false;
      state.advertisingConsent = false;
      state.isCaptchaError = false;
      state.captchaResponse = '';
    },
  },

  getters: {
    getTargetGroup: (state) => state.targetGroup,
    getTargetGroupTitle: (state) => state.targetGroupTitle,
    getTargetGroupNewsletter: (state) => state.targetGroupNewsletter,
    getDateOfBirth: (state) => state.dateOfBirth,
    getDateOfBirthRaw: (state) => state.dateOfBirthRaw,

    getSalutation: (state) => state.salutation,
    getTitle: (state) => state.title,
    getFirstname: (state) => state.firstname,
    getLastname: (state) => state.lastname,
    getEmail: (state) => state.email,
    getPhone: (state) => state.phone,
    getParentEmail: (state) => state.parentEmail,
    getParentEmailRequired: (state) => state.parentEmailRequired,

    getSubTargetGroup: (state) => state.subTargetGroup,
    getSubTargetGroupTitle: (state) => state.subTargetGroupTitle,
    getCountry: (state) => state.country,
    getCompany: (state) => state.company,
    getCustomerNumber: (state) => state.customerNumber,
    getStreet: (state) => state.street,
    getStreetNumber: (state) => state.streetNumber,
    getZipcode: (state) => state.zipcode,
    getCity: (state) => state.city,
    getCustomerWebsite: (state) => state.customerWebsite,
    getValidateAddress: (state) => state.validateAddress,

    getPassword: (state) => state.password,
    getPasswordRepeat: (state) => state.passwordRepeat,
    getTermsAndConditions: (state) => state.termsAndConditions,
    getAdvertisingConsent: (state) => state.advertisingConsent,
    getIsCaptchaError: (state) => state.isCaptchaError,
    getCaptchaResponse: (state) => state.captchaResponse,
  },

  actions: {
    setTargetGroup({ commit }, targetGroup) {
      commit('SET_TARGETGROUP', targetGroup);
    },
    setTargetGroupTitle({ commit }, targetGroupTitle) {
      commit('SET_TARGETGROUPTITLE', targetGroupTitle);
    },
    setTargetGroupNewsletter({ commit }, targetGroupNewsletter) {
      commit('SET_TARGETGROUPNEWSLETTER', targetGroupNewsletter);
    },
    setDateOfBirth({ commit }, dateOfBirth) {
      commit('SET_DATEOFBIRTH', dateOfBirth);
    },
    setDateOfBirthRaw({ commit }, dateOfBirthRaw) {
      commit('SET_DATEOFBIRTHRAW', dateOfBirthRaw);
    },

    setSalutation({ commit }, salutation) {
      commit('SET_SALUTATION', salutation);
    },
    setTitle({ commit }, title) {
      commit('SET_TITLE', title);
    },
    setFirstname({ commit }, firstname) {
      commit('SET_FIRSTNAME', firstname);
    },
    setLastname({ commit }, lastname) {
      commit('SET_LASTNAME', lastname);
    },
    setEmail({ commit }, email) {
      commit('SET_EMAIL', email);
    },
    setPhone({ commit }, phone) {
      commit('SET_PHONE', phone);
    },
    setParentEmail({ commit }, parentEmail) {
      commit('SET_PARENTEMAIL', parentEmail);
    },
    setParentEmailRequired({ commit }, parentEmailRequired) {
      commit('SET_PARENTEMAILREQUIRED', parentEmailRequired);
    },

    setSubTargetGroup({ commit }, subTargetGroup) {
      commit('SET_SUBTARGETGROUP', subTargetGroup);
    },
    setSubTargetGroupTitle({ commit }, subTargetGroupTitle) {
      commit('SET_SUBTARGETGROUP_TITLE', subTargetGroupTitle);
    },
    setCountry({ commit }, country) {
      commit('SET_COUNTRY', country);
    },
    setCompany({ commit }, company) {
      commit('SET_COMPANY', company);
    },
    setCustomerNumber({ commit }, customerNumber) {
      commit('SET_CUSTOMERNUMBER', customerNumber);
    },
    setStreet({ commit }, street) {
      commit('SET_STREET', street);
    },
    setStreetNumber({ commit }, streetNumber) {
      commit('SET_STREETNUMBER', streetNumber);
    },
    setZipcode({ commit }, zipcode) {
      commit('SET_ZIPCODE', zipcode);
    },
    setCity({ commit }, city) {
      commit('SET_CITY', city);
    },
    setCustomerWebsite({ commit }, customerWebsite) {
      commit('SET_CUSTOMERWEBSITE', customerWebsite);
    },
    setValidateAddress({ commit }, validateAddress) {
      commit('SET_VALIDATEADDRESS', validateAddress);
    },

    setPassword({ commit }, password) {
      commit('SET_PASSWORD', password);
    },
    setPasswordRepeat({ commit }, passwordRepeat) {
      commit('SET_PASSWORDREPEAT', passwordRepeat);
    },
    setTermsAndConditions({ commit }, termsAndConditions) {
      commit('SET_TERMSANDCONDITIONS', termsAndConditions);
    },
    setAdvertisingConsent({ commit }, advertisingConsent) {
      commit('SET_ADVERTISINGCONSENT', advertisingConsent);
    },
    setIsCaptchaError({ commit }, isCaptchaError) {
      commit('SET_ISCAPTCHAERROR', isCaptchaError);
    },
    setCaptchaResponse({ commit }, captchaResponse) {
      commit('SET_CAPTCHARESPONSE', captchaResponse);
    },
  },
};

export default registration;
