<template>
  <component-wrapper>
    <grid-container-fi
      :class="{
        'teaser-group-fi': true,
        'teaser-group-fi--dark': fields.darkBackground?.value,
        'teaser-group-fi--medium': fields.variantMedium?.value,
        'teaser-group-fi--small': fields.variantSmall?.value,
      }"
    >
      <div
        v-if="
          !fields.tileHeader?.value &&
          (fields.headline?.value || fields.paragraph?.value || isEditMode)
        "
        class="teaser-group-fi__items fi-col-span-12 xl:fi-col-span-8 xl:fi-col-start-3"
      >
        <teaser-group-header
          headline-tag="h2"
          class="teaser-group-fi__item teaser-group-fi__item--headline"
          :fields="fields"
        />
      </div>
      <div class="teaser-group-fi__items fi-col-span-12 xl:fi-col-span-8 xl:fi-col-start-3">
        <teaser-group-header
          v-if="
            fields.tileHeader?.value &&
            (fields.headline?.value || fields.paragraph?.value || isEditMode)
          "
          class="teaser-group-fi__item"
          :fields="fields"
        />
        <div
          v-for="(teaser, idx) in fields.teaserElements"
          :key="idx"
          class="teaser-group-fi__item"
        >
          <teaser-tile-fi
            :fields="teaser.fields"
            :grouped-fields="fields"
          />
          <div
            v-if="isEditMode && fields.teaserElements.length === 0"
            class="fi-col-span-12"
          >
            no content
          </div>
        </div>
      </div>
    </grid-container-fi>
  </component-wrapper>
</template>

<script setup lang="ts">
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import TeaserGroupHeader from 'components/teaser/teaser-group-fi/teaser-group-header/TeaserGroupHeader.vue';
import { type SsrOnlyProps, useSsrOnly } from 'composables/ssrOnly';
import { defineAsyncComponent } from 'vue';

const TeaserTileFi = defineAsyncComponent(() => import('pages/teaser-tile-fi/TeaserTileFi'));

defineOptions({
  name: 'TeaserGroupFi',
});

const props = defineProps<{ fields: any } & SsrOnlyProps>();

useSsrOnly(props);
</script>

<style lang="scss">
@import './teaser-group-fi';
</style>
