import { useFischer } from 'composables/fischerPlugin';
import { computed } from 'vue';

import type { FischerPlugin } from '@/lib/fischerPlugin';

export const cloudImageDomain = 'https://media.fischer.group/v7/';

export const replacements = {
  'https://ugfds005.blob.core.windows.net/media': '_pim-media-prod_',
  ...(APP_ENVIRONMENT === 'PROD'
    ? {
        'https://media-prod.ugfischer.com/-/media': '_media-prod_',
        'https://media-prod.fischer.group/-/media': '_media-prod_',

        'https://media-prod-cm.ugfischer.com/-/media': '_cm-media-prod_',
        'https://media-prod-cm.fischer.group/-/media': '_cm-media-prod_',
      }
    : {}),
  ...(APP_ENVIRONMENT === 'UAT'
    ? {
        'https://media-uat.ugfischer.com/-/media': '_media-uat_',
        'https://media-uat.fischer.group/-/media': '_media-uat_',

        'https://media-uat-cm.ugfischer.com/-/media': '_cm-media-uat_',
        'https://media-uat-cm.fischer.group/-/media': '_cm-media-uat_',
      }
    : {}),
  ...(APP_ENVIRONMENT !== 'UAT' && APP_ENVIRONMENT !== 'PROD'
    ? {
        'https://media-int.ugfischer.com/-/media': '_media-int_',
        'https://media-int.fischer.group/-/media': '_media-int_',
      }
    : {}),
};

export const isCloudimageAlias = (url: string): boolean => {
  return url.startsWith('_') || false;
};

export const appendParams = (src: string, newParams: string): string => {
  return `${src}${String(src).includes('?') ? '&' : '?'}${newParams}`;
};

export const getLocalMediaRegex = (fischer: FischerPlugin): RegExp => {
  const hostname = fischer.hostname() || '';
  return new RegExp(`^(?:https?://(?:www\\.)?${hostname})?/-/media`);
};

export const getLocalHostAlias = (fischer: FischerPlugin): string => {
  const isContentManagement = fischer.isCM();

  if (APP_ENVIRONMENT === 'PROD') {
    return isContentManagement ? '_cm-media-prod_' : '_media-prod_';
  }
  if (APP_ENVIRONMENT === 'UAT') {
    return isContentManagement ? '_cm-media-uat_' : '_media-uat_';
  }
  if (APP_ENVIRONMENT === 'SHOWROOM') {
    return '_media_showroom_';
  }
  return '_media-int_';
};

export const useCloudImage = () => {
  const fischer = useFischer();

  const hostnameRegex = computed<RegExp>(() => {
    const hostname = fischer.hostname() || '';
    return new RegExp(`^https?://(?:www\\.)?${hostname}`);
  });

  const localHostAlias = computed<string>(() => {
    return getLocalHostAlias(fischer);
  });

  const getCloudImageAlias = (url: string | null | undefined): string => {
    if (!url) {
      return '';
    }

    if (isCloudimageAlias(url)) {
      return url;
    }

    if (IS_SHOWROOM && (url.startsWith('/data/media') || url.startsWith('/img/'))) {
      return `${localHostAlias.value}${url}`;
    }

    if (
      IS_SHOWROOM &&
      (url.startsWith('https://www.fischer.de/de-de/-/media/national/samples/social/') ||
        url === 'https://www.fischer.de/de-de/-/media/national/samples/logos/logo-footer.ashx')
    ) {
      return url;
    }

    const localMediaRegex = getLocalMediaRegex(fischer);
    if (localMediaRegex.test(url)) {
      return url.replace(localMediaRegex, localHostAlias.value);
    }
    if (hostnameRegex.value.test(url)) {
      const shortened = url.replace(hostnameRegex.value, '').replace(localMediaRegex, '');
      return `${localHostAlias.value}${shortened}`;
    }
    for (const key in replacements) {
      if (url.startsWith(key)) {
        // @ts-ignore we know replacement exists
        const replacement: string = replacements[key];
        const replaced = url.replace(key, replacement);
        console.debug('replacing', key, replaced);
        return replaced;
      }
    }
    console.debug('Unhandled url', url, fischer.hostname(), fischer.uri());
    return url;
  };

  const getProxySrc = (url: string): string | null => {
    if (!url) {
      return null;
    }
    if (url.startsWith(cloudImageDomain)) {
      return url;
    }
    const alias = getCloudImageAlias(url);
    return `${cloudImageDomain}${alias}`;
  };

  return {
    getProxySrc,
    getCloudImageAlias,
    appendParams,
    localHostAlias,
    hostnameRegex,
    isCloudimageAlias,
    cloudImageDomain,
  };
};
