import { computed, type ComputedRef, type Ref, ref, watch } from 'vue';

export type Value = string | undefined | null;

export type RuleResult = string | boolean;
export type Rule = RuleResult | ((value: Value) => RuleResult);

export interface ValidateFormElementProps {
  value?: Value;
  rules?: Rule[] | undefined;
  trim?: boolean;
  errorMessage?: string;
  error?: any;
}

export const useValidateFormElement = ({
  props,
  watchValue = true,
  value,
}: {
  props: ValidateFormElementProps;
  watchValue?: boolean;
  value?: Ref<Value, Value> | ComputedRef<Value>;
  trim?: boolean | Ref<boolean, boolean> | ComputedRef<boolean>;
}) => {
  const valid = ref<boolean>(false);
  const errorBucket = ref<string[]>([]);
  const errorText = ref<string | null>(null);

  const getValue = computed<Value>(() => (value ? value.value : props.value));

  const isError = computed<boolean>(() => Boolean(props.error || errorText.value));

  const validate = (): boolean => {
    let value = getValue.value;
    if (value && props.trim) {
      value = String(value).trim();
    }
    errorBucket.value = [];
    props.rules?.forEach((rule) => {
      const valid = typeof rule === 'function' ? rule(value) : rule;

      if (valid === false || typeof valid === 'string') {
        errorBucket.value.push(valid || '');
      } else if (typeof valid !== 'boolean') {
        console.error(
          `Rules should return a string or boolean, received '${typeof valid}' instead`,
          this,
        );
      }
    });
    if (props.errorMessage) {
      errorText.value = props.errorMessage;
      valid.value = false;
    } else {
      errorText.value = errorBucket.value?.[0];
      valid.value = errorBucket.value.length === 0;
    }

    return valid.value;
  };

  const resetErrorState = (): void => {
    errorBucket.value = [];
    errorText.value = '';
    valid.value = true;
  };

  watch(getValue, () => {
    if (watchValue) {
      validate();
    }
  });

  return {
    resetErrorState,
    validate,
    valid,
    errorText,
    errorBucket,
    isError,
  };
};
