<template>
  <div
    class="loading-indicator"
    :class="{
      'loading-indicator--dark': dark,
      'loading-indicator--transparent': transparent,
      'in-relative': inRelative,
    }"
  >
    <div
      v-if="variant === 'spinner'"
      class="spinner-icon-wrapper"
    >
      <div class="spinner-icon" />
    </div>

    <div
      v-if="variant === 'spinnerLocal'"
      :class="spinoff ? `spinner-local__wrapper--${spinoff}` : ''"
      class="spinner-local__wrapper"
    >
      <div
        :class="spinoff ? `spinner-local__circle--${spinoff}` : null"
        class="spinner-local__circle"
      />
    </div>

    <div
      v-if="variant === 'spinnerCentered'"
      class="spinner-icon-wrapper spinner-icon-wrapper--centered"
    >
      <div class="spinner-icon spinner-icon--centered" />
    </div>

    <div
      v-if="isFullPage"
      :class="{
        'spinner-full-page__wrapper': true,
        'spinner-full-page__wrapper--cover': variant === 'spinnerFullPageCover',
      }"
    >
      <div class="spinner-full-page__circle" />
    </div>

    <div
      v-if="variant === 'spinnerCard'"
      :class="spinoff ? `spinner-card__wrapper--${spinoff}` : ''"
      class="spinner-card__wrapper"
    >
      <div
        :class="spinoff ? `spinner-card__circle--${spinoff}` : null"
        class="spinner-card__circle"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    dark: Boolean,
    transparent: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'spinner',
    },
    spinoff: {
      type: String,
      default: null,
    },
  },
  computed: {
    inRelative() {
      return this.spinoff && /^in-relative-/.test(this.spinoff);
    },
    isFullPage() {
      return this.variant === 'spinnerFullPage' || this.variant === 'spinnerFullPageCover';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './loading-indicator';
</style>
