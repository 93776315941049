import type { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { captureMessage } from '@sentry/browser';
import type { App, ComponentPublicInstance } from 'vue';
import type { Store } from 'vuex';

import { createApp, setupAppPage } from '@/createApp';
import type { FischerPluginStore } from '@/lib/fischerPlugin';
import smoothScroll, { scrollToHref } from '@/utils/smoothScroll';

import initSentry from './initSentry';
import processEventQueue from './processEventQueue';
import scrollPageEvent from './scrollPageEvent';
import type { InitialState } from './sitecoreState';
import viewPageEvent from './viewPageEvent';

const importEditorWorkaround = () => import('./editorWorkaround');

/* eslint-disable no-var */
declare global {
  var firstState: InitialState | undefined;
  var debugMountedApps: any[];
  var appInitializer: Promise<any>;
  var jssDataResolver: (value: any) => void;
  var jssData: Promise<any>;
  var hydrateAllApps: () => Promise<void>;
}
/* eslint-enable no-var */

// eslint falsely applies vue rules

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide the window.__JSS_STATE__ object
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.

  SSR is initiated from /server/server.ts.
*/

function getDOMState(rootElement: Element, className: string) {
  try {
    let state = null;
    const jssRawJson = rootElement.getElementsByClassName(className)[0];
    if (jssRawJson) {
      state = JSON.parse(jssRawJson.innerHTML);
    }

    return state;
  } catch (err) {
    return null;
  }
}

const getApolloStates = (): NormalizedCacheObject[] | undefined => {
  const statesJson = window.document.querySelectorAll('.__APOLLO_STATE__');
  if (!statesJson.length) {
    return undefined;
  }
  return Array.from(statesJson).map((json) => JSON.parse(json.innerHTML) as NormalizedCacheObject);
};

export interface MountResult {
  app: App<Element>;
  mount: () => ComponentPublicInstance<any>;
  mounted: ComponentPublicInstance<any> | null;
}

const initApp = async (
  root: Element,
  initialState: InitialState | undefined,
  prefetch: any | undefined,
  jsPreload: Promise<any[]> | undefined,
  rehydrate: boolean | undefined,
  graphQLProvider: typeof ApolloProvider<any>,
  store: Store<any>,
  fischerPluginStore: FischerPluginStore,
) => {
  try {
    if (initialState?.ssrErrors) {
      console.error('There where ssr errors', initialState.ssrErrors);
    }

    try {
      await jsPreload;
    } catch (error) {
      console.error('Error during prefetch', error);
    }

    const app = await createApp({
      sitecore: initialState?.sitecore,
      prefetch,
      graphQLProvider,
      store,
      rehydrate,
      fischerPluginStore,
    });

    if (!app) {
      return null;
    }

    app.config.errorHandler = (error: any, _instance: any, info: string) => {
      const componentName = initialState?.sitecore?.rendering?.componentName;
      const msg = `${componentName}: ${error} ${info}`;
      console.error(msg);
      captureMessage(msg);
    };

    app.config.warnHandler = (error: any, _instance: any, info: string) => {
      const componentName = initialState?.sitecore?.rendering?.componentName;
      const msg = `${componentName}: ${error} ${info}`;
      // eslint-disable-next-line no-console
      console.warn(msg);
      captureMessage(msg);
    };

    const fieldKey = initialState?.sitecore?.dataSource?.['conditions-field-key']?.value;
    if (fieldKey) {
      const formElement = document.querySelector(
        `.jss-forms-element[conditions-field-key="${fieldKey}"]`,
      );
      // Class 'jss-init-disabled' is added on start dragging elements (see 'FormDesignBoard.js' in fischer-website sitecore project)
      if (formElement?.classList?.contains('jss-init-disabled')) {
        return null;
      }
    }

    const result: MountResult = {
      app: app,
      mount: () => {
        result.mounted = app.mount(root);
        return result.mounted;
      },
      mounted: null,
    };

    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const initAllApps = async () => {
  const apolloStates = getApolloStates();
  let fischerPluginStore: FischerPluginStore;
  let graphQLProvider: typeof ApolloProvider<any>;
  let store: Store<any>;
  let layoutSucceeded: boolean = false;

  window.hydrateAllApps = async () => {
    const jssComponents = document.querySelectorAll('.jss-component:not(.inited)');
    if (jssComponents.length > 0) {
      const apps: [
        Element,
        InitialState | undefined,
        any,
        Promise<any[]> | undefined,
        boolean | undefined,
      ][] = [];
      const editorPreloads = [];
      for (let i = 0; i < jssComponents.length; i += 1) {
        const jssComponent = jssComponents[i];
        jssComponent.classList.add('inited');
        const root = jssComponent.getElementsByClassName('root')[0];
        const ssrAttribute = jssComponent
          .getElementsByClassName('__JSS_STATE__')[0]
          ?.getAttribute('data-ssr');

        const initialState =
          (getDOMState(jssComponent, '__JSS_STATE__') as InitialState) || undefined;
        const rehydrate =
          (!IS_SHOWROOM &&
            !initialState?.sitecore?.data?.isEditMode &&
            !ssrAttribute &&
            ssrAttribute !== 'false') ||
          undefined;
        const prefetch = getDOMState(jssComponent, '__PREFETCH__');
        const jsPreloadFiles: [string] = getDOMState(jssComponent, '__JS_PRELOAD__');
        const jsPreload = jsPreloadFiles
          ? Promise.all(jsPreloadFiles.map((file: string) => import(/* @vite-ignore */ file)))
          : undefined;
        editorPreloads.push(jsPreload);

        if (initialState || IS_SHOWROOM) {
          apps.push([root, initialState, prefetch, jsPreload, rehydrate]);
        }
      }

      // setup the page to the first app, since all apps should result in the same page

      if (!window.firstState) {
        window.firstState = apps[0][1];

        ({ graphQLProvider, store, layoutSucceeded, fischerPluginStore } = await setupAppPage({
          initialState: window.firstState,
          apolloStates,
        }));
      }

      if (layoutSucceeded) {
        const appContextsWithNull = await Promise.all(
          apps.map((app) => initApp(...app, graphQLProvider, store, fischerPluginStore)),
        );
        // this can be removed with typescript 5.5

        function notEmpty<TValue>(value: TValue | null): value is TValue {
          return value !== null;
        }
        const appContexts: MountResult[] = appContextsWithNull.filter(notEmpty);

        if (['TEST', 'UAT', 'PROD'].includes(APP_ENVIRONMENT) && FRONTEND_RELEASE) {
          if (appContexts.length) {
            const appsArray = appContexts.map((context) => context.app);
            // @ts-ignore
            appsArray.config = {}; // bug in Sentry?
            initSentry(appsArray);
          }
        }
        const isEditMode = window.firstState?.sitecore?.data?.isEditMode;
        if (isEditMode) {
          try {
            // give apps the best chance to mount at tbe same time
            await Promise.all(editorPreloads);
          } catch (error) {
            console.error('Error during prefetch', error);
          }
        }
        const mountedApps = appContexts.map((context) => context.mount());
        window.debugMountedApps = await Promise.all(mountedApps);
        if (isEditMode) {
          const editorWorkaround = await importEditorWorkaround();
          editorWorkaround.default();
        } else {
          const firstApp = appContexts[0].app as any;
          if (!firstApp) {
            console.error('eventhub view-page has no app');
            return;
          }
          const $fischer = firstApp.$fischer || firstApp.config?.globalProperties?.$fischer;
          const $cookies = firstApp.$cookies || firstApp.config?.globalProperties?.$cookies;
          if (!$fischer || !$cookies) {
            console.error('missing properties for events');
            return;
          }

          viewPageEvent($fischer, $cookies);
          scrollPageEvent($fischer, $cookies);
          processEventQueue($fischer, $cookies);
        }
      } else {
        console.info('Did not receive layout, not initializing apps');
      }
    }
  };
  await window.hydrateAllApps();

  return async (element: Element, state: InitialState) => {
    const formsAppPage = setupAppPage({ initialState: state, apolloStates, noLayoutData: true });
    const { graphQLProvider, store } = await formsAppPage;

    const context = await initApp(
      element,
      state,
      undefined,
      undefined,
      false,
      graphQLProvider,
      store,
      fischerPluginStore,
    );
    if (!context) {
      console.error('did not create app');
      return null;
    }
    context.mount();
    return context;
  };
};

if (!window.appInitializer) {
  window.jssData = new Promise((resolve) => {
    window.jssDataResolver = resolve;
  });
  window.appInitializer = initAllApps();
  window.appInitializer.then(() => {
    // jump to anchor in url, delay to undo the browser's automatic scrolling
    scrollToHref(String(window.location));
    smoothScroll(document);
  });
} else {
  console.debug('appInitializer already present');
}
