<template>
  <div class="video-player-fi">
    <template v-if="!isEditMode">
      <div
        class="video-player-fi__preview"
        :style="aspectRatio ? `aspect-ratio: ${aspectRatio}` : null"
      >
        <template v-if="!isModal && !isThumbnail">
          <div
            v-if="
              (!isVideoYouku && (videoLoaded || videoAutoPlay)) ||
              (isVideoYouku && isVideoYoukuActive)
            "
            :class="{
              'video-player-fi__video-container': true,
              'video-player-fi__video-container--show':
                showVideo || videoAutoPlay || isVideoYoukuActive,
            }"
          >
            <div
              v-if="isVideoYoutube"
              ref="youtubeVideo"
              class="video-player-fi__iframe-wrapper"
            >
              <you-tube
                ref="youtube"
                class="video-player-fi__iframe-video"
                :src="videoYoutubeLink"
                :vars="ytVars"
                @playing="videoPlaying"
                @paused="videoPaused"
                @ended="videoEnded"
                @ready="onYoutubeReady"
              />
            </div>

            <div
              v-else-if="isVideoYouku"
              ref="youkuVideo"
              class="video-player-fi__iframe-wrapper"
            >
              <youku-player
                ref="youku"
                class="video-player-fi__iframe-video"
                :src="videoYoukuLink"
                @ready="onYoutubeReady"
              />
            </div>

            <video
              v-else
              ref="html5Video"
              width="auto"
              height="100%"
              :controls="videoControls"
              :autoplay="videoAutoPlay"
              :muted="videoMuted"
              :loop="videoLoop"
              class="video-player-fi__video"
              @pause="videoPaused"
              @ended="videoEnded"
            >
              <slot
                v-if="$slots.responsiveVideoSource"
                name="responsiveVideoSource"
              />
              <source
                :src="getLink('videoWebmLink').url"
                type="video/webm"
              />
              <source
                :src="getLink('videoMp4Link').url"
                type="video/mp4"
              />
              {{ $dict.get('video.No-Browser-Support') }}
            </video>
          </div>
        </template>

        <div class="video-player-fi__overlay">
          <div
            ref="videoPlayButton"
            :class="{
              'video-player-fi__play-button': true,
              'video-player-fi__play-button--small': smallPlayButton,
            }"
            @click="playButtonClicked"
          >
            <icon-fi
              v-if="playCount === 0"
              icon="play"
            />

            <icon-fi
              v-else
              icon="repeat"
            />
          </div>
        </div>

        <slot
          v-if="$slots.previewImage"
          name="previewImage"
        />
        <template v-else-if="overlayBreakpointsDesktop">
          <ssr-cloud-image-fi
            class="video-player-fi__image fi-hidden lg:fi-inline-block"
            :breakpoints="overlayBreakpointsDesktop"
            :unlimited="unlimited"
            :aspect-ratio="aspectRatio"
            :field="fields.image"
            :alt="fields?.imageAlt?.value"
            :title="fields?.imageTitle?.value"
          />
          <ssr-cloud-image-fi
            class="video-player-fi__image fi-hidden md:fi-inline-block lg:fi-hidden"
            :breakpoints="overlayBreakpointsTablet"
            :unlimited="unlimitedTablet"
            :aspect-ratio="aspectRatio"
            :field="fields.imageTablet || fields.image"
            :alt="fields?.imageAlt?.value"
            :title="fields?.imageTitle?.value"
          />
          <ssr-cloud-image-fi
            class="video-player-fi__image fi-inline-block md:fi-hidden"
            :breakpoints="overlayBreakpointsMobile"
            :unlimited="unlimitedMobile"
            :aspect-ratio="aspectRatio"
            :field="fields.imageMobile || fields.image"
            :alt="fields?.imageAlt?.value"
            :title="fields?.imageTitle?.value"
          />
        </template>
        <template v-else-if="!isMounted">
          <cloud-image-fi
            class="video-player-fi__image fi-hidden lg:fi-inline-block"
            :field="fields.image"
            :alt="fields?.imageAlt?.value"
            :title="fields?.imageTitle?.value"
            :aspect-ratio="aspectRatio"
          />
          <cloud-image-fi
            class="video-player-fi__image fi-hidden md:fi-inline-block lg:fi-hidden"
            :field="fields.imageTablet || fields.image"
            :alt="fields?.imageAlt?.value"
            :title="fields?.imageTitle?.value"
            :aspect-ratio="aspectRatio"
          />
          <cloud-image-fi
            class="video-player-fi__image fi-inline-block md:fi-hidden"
            :field="fields.imageMobile || fields.image"
            :alt="fields?.imageAlt?.value"
            :title="fields?.imageTitle?.value"
            :aspect-ratio="aspectRatio"
          />
        </template>
        <cloud-image-fi
          v-else
          class="video-player-fi__image"
          :src="getImage()"
          :alt="fields?.imageAlt?.value"
          :title="fields?.imageTitle?.value"
          :params="previewParams ? previewParams : 'org_if_sml=0'"
          :sizes="overlaySizes"
          :do-not-replace-url="doNotReplaceUrl"
          :aspect-ratio="aspectRatio"
        />
      </div>

      <div
        v-if="videoTag || videoHeadline"
        class="video-player-fi__text"
      >
        <div
          v-if="videoTag"
          class="video-player-fi__badge badge-fi"
        >
          {{ videoTag }}
        </div>
        <div
          v-if="videoHeadline"
          class="video-player-fi__headline"
        >
          {{ videoHeadline }}
        </div>
      </div>

      <teleport
        v-if="isMounted && isModal"
        to="body"
      >
        <component-wrapper>
          <dialog-modal-fi
            ref="videoModal"
            class="modal-fi__video"
            lazy
            @close="pausedVideo"
          >
            <template #body>
              <div class="video-player-fi__modal">
                <div
                  v-if="isVideoYoutube"
                  ref="youtubeVideo"
                  class="video-player-fi__iframe-wrapper"
                >
                  <you-tube
                    ref="youtube"
                    :src="videoYoutubeLink"
                    :vars="ytVars"
                    class="video-player-fi__iframe-video"
                    @playing="videoPlaying"
                    @paused="videoPaused"
                    @ended="videoEnded"
                    @ready="onYoutubeReady"
                  />
                </div>

                <div
                  v-else-if="isVideoYouku"
                  ref="youkuVideo"
                  class="video-player-fi__iframe-wrapper"
                >
                  <youku-player
                    v-if="isVideoYoukuActive"
                    ref="youku"
                    :src="videoYoukuLink"
                    class="video-player-fi__iframe-video"
                    @ready="onYoutubeReady"
                  />
                </div>

                <video
                  v-else
                  ref="html5Video"
                  width="auto"
                  height="100%"
                  :controls="videoControls"
                  :autoplay="videoAutoPlay"
                  :muted="videoMuted"
                  :loop="videoLoop"
                  class="video-player-fi__video"
                  @pause="videoPaused"
                  @ended="videoEnded"
                >
                  <source
                    :src="getLink('videoWebmLink').url"
                    type="video/webm"
                  />
                  <source
                    :src="getLink('videoMp4Link').url"
                    type="video/mp4"
                  />
                  {{ $dict.get('video.No-Browser-Support') }}
                </video>
              </div>
            </template>
          </dialog-modal-fi>
        </component-wrapper>
      </teleport>
    </template>
    <template v-else>
      <div
        class="video-player-fi__preview"
        :style="aspectRatio ? `aspect-ratio: ${aspectRatio}` : null"
      >
        <div class="video-player-fi__overlay video-player-fi__overlay--xpe">
          <div class="video-player-fi__play-button">
            <icon-fi
              v-if="playCount === 0"
              icon="play"
            />

            <icon-fi
              v-else
              icon="repeat"
            />
          </div>
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="fields.videoTag || fields.videoHeadline"
          class="video-player-fi__text"
        >
          <div
            class="video-player-fi__badge badge-fi"
            v-html="fields.videoTag?.editable"
          />
          <div
            class="video-player-fi__headline"
            v-html="fields.videoHeadline?.editable"
          />
        </div>

        <div class="video-player-fi__image video-player-fi__image--xpe">
          <div v-html="fields.image?.editable" />
        </div>
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </template>
  </div>
</template>

<script>
import { DialogModalFi, IconFi, SsrCloudImageFi } from 'atoms';
import CloudImageFi from 'components/atoms/cloud-image-fi/CloudImageFi';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper';
import breakpointMixin from 'mixins/breakpointMixin';

import screenWidths from '@/tailwind/screenWidths';
import { getLinkData } from '@/utils/linkHelper';
import youtubeId from '@/utils/youtube';

import YoukuPlayer from './YoukuPlayer';
import YouTube from './YouTube';

export default {
  name: 'VideoPlayerFi',
  components: {
    DialogModalFi,
    IconFi,
    ComponentWrapper,
    CloudImageFi,
    YouTube,
    YoukuPlayer,
    SsrCloudImageFi,
  },
  mixins: [breakpointMixin],
  props: {
    fields: {
      type: Object,
      required: true,
    },
    previewParams: {
      type: String,
      default: null,
    },
    showHideDelay: {
      type: Number,
      default: 500,
    },
    modalDelay: {
      type: Number,
      default: 1000,
    },
    resetVideo: {
      type: Boolean,
      default: false,
    },
    pauseVideo: {
      type: Boolean,
      default: false,
    },
    overlaySizes: {
      type: Object,
      default: null,
    },
    overlayBreakpoints: {
      type: Array,
      default: null,
    },
    unlimited: {
      type: Number,
      default: null,
    },
    doNotReplaceUrl: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    smallPlayButton: Boolean,
    noYoukuPlayer: Boolean,
    isThumbnail: Boolean,
  },
  emits: ['video'],
  data() {
    return {
      videoLoaded: false,
      showVideo: false,
      videoRef: null,
      isHidden: false,
      playCount: 0,
      isPlaying: false,
      isMounted: false,

      unlimitedMobile: null,
      unlimitedTablet: null,
      overlayBreakpointsDesktop: null,
      overlayBreakpointsTablet: null,
      overlayBreakpointsMobile: null,

      isVideoYoukuActive: false,
    };
  },
  computed: {
    ytVars() {
      return {
        autoplay: this.videoAutoPlay,
        controls: this.videoControls,
        loop: this.videoLoop,
        rel: 0,
        enablejsapi: 1,
      };
    },
    isModal() {
      return this.fields?.modal?.value;
    },
    videoControls() {
      return this.fields?.controls?.value;
    },
    videoAutoPlay() {
      return this.fields?.autoplay?.value;
    },
    videoMuted() {
      return this.fields?.muted?.value;
    },
    videoLoop() {
      return this.fields?.loop?.value;
    },
    videoTag() {
      return this.fields?.videoTag?.value;
    },
    videoHeadline() {
      return this.fields?.videoHeadline?.value;
    },
    isVideoYouku() {
      const videoLink = this.getLink('videoYoutubeLink').url;
      if (this.$fischer.isYoukuPlayer() && !this.noYoukuPlayer && videoLink) {
        return true;
      }
      return false;
    },
    isVideoYoutube() {
      if (this.isVideoYouku) {
        return false;
      }

      const youTube = this.getLink('videoYoutubeLink').url;

      if (youTube) {
        return true;
      }

      return false;
    },
    videoYoutubeLink() {
      const youTubeLink = this.getLink('videoYoutubeLink').url;
      return youtubeId(youTubeLink);
    },
    videoYoukuLink() {
      // if Youku is enabled in site settings, 'videoYoutubeLink' field is used for the Youku link
      return this.getLink('videoYoutubeLink').url;
    },
    videoYoutubeControls() {
      const controls = this.fields?.controls ? this.fields.controls.value : false;

      if (controls) {
        return 1;
      }
      return 0;
    },
    videoPauseOnScroll() {
      return this.fields?.pause ? this.fields.pause.value : false;
    },
  },
  watch: {
    resetVideo(value) {
      if ((value && this.videoRef) || (value && this.isVideoYoukuActive)) {
        this.videoEnded();
      }
    },
    pauseVideo(value) {
      if (value && this.isVideoYoukuActive) {
        this.videoEnded();
      } else if (value && this.videoRef) {
        if (this.videoControls) {
          this.pausedVideo();
        } else {
          this.videoEnded();
        }
      }
    },
  },
  mounted() {
    this.isMounted = true;
  },
  created() {
    if (!this.overlayBreakpoints || !this.overlayBreakpoints.length) {
      return;
    }
    const sortedBreakpoints = [...this.overlayBreakpoints].sort(
      (a, b) => a.mediaWidth - b.mediaWidth,
    );

    this.overlayBreakpointsMobile = [];
    this.overlayBreakpointsTablet = [];
    this.overlayBreakpointsDesktop = [];
    sortedBreakpoints.forEach((breakpoint) => {
      if (breakpoint.mediaWidth < screenWidths.sm) {
        this.overlayBreakpointsMobile.push(breakpoint);
        return;
      }
      if (breakpoint.mediaWidth < screenWidths.md) {
        this.overlayBreakpointsTablet.push(breakpoint);
        return;
      }
      this.overlayBreakpointsDesktop.push(breakpoint);
    });

    if (this.overlayBreakpointsMobile.length) {
      this.unlimitedMobile = this.overlayBreakpointsMobile.pop().mediaWidth;
    } else {
      this.unlimitedMobile =
        this.overlayBreakpointsTablet[0]?.mediaWidth ||
        this.overlayBreakpointsDesktop[0]?.mediaWidth ||
        this.unlimited;
    }

    if (this.overlayBreakpointsTablet.length) {
      this.unlimitedTablet = this.overlayBreakpointsTablet.pop().mediaWidth;
    } else {
      this.unlimitedTablet = this.overlayBreakpointsDesktop[0]?.mediaWidth || this.unlimited;
    }
  },
  methods: {
    playButtonClicked() {
      if (this.isModal) {
        this.showModal();
      } else if (this.videoLoaded) {
        this.toggleShowVideo();
      } else {
        this.loadVideo();
      }
    },
    loadVideo() {
      if (!this.videoLoaded) {
        this.videoLoaded = true;
      }

      if (this.isVideoYoutube) {
        return; // wait for ready state
      }

      this.toggleShowVideo();
    },
    toggleShowVideo() {
      if (this.isThumbnail) {
        return;
      }
      if (this.showVideo) {
        this.showVideo = false;
      } else {
        // workaround for transition - nexttick dont work!?
        setTimeout(() => {
          this.showVideo = true;
        }, 0);
        this.$emit('video', 'beforePlaying');
        // wait for transitions
        setTimeout(() => {
          this.playVideo();
        }, this.showHideDelay);
      }
    },
    showModal() {
      if (this.isEditMode || this.isThumbnail) {
        return;
      }

      if (this.isVideoYouku) {
        this.isVideoYoukuActive = true;
      }

      this.$refs.videoModal.open();

      setTimeout(() => {
        this.loadVideo();
      }, this.showHideDelay);
    },
    hideModal() {
      this.$refs.videoModal.close();
    },
    getLink(name) {
      return getLinkData(this.fields, name);
    },
    onYoutubeReady() {
      this.toggleShowVideo();
    },
    playVideo() {
      if (this.isThumbnail) {
        return;
      }
      if (this.isVideoYoutube) {
        this.videoRef = this.$refs.youtube?.player;
        if (typeof this.videoRef?.playVideo !== 'function') {
          setTimeout(() => this.playVideo(), 250);
          return;
        }
        this.videoRef.playVideo();
      } else if (this.isVideoYouku) {
        this.isVideoYoukuActive = true;
      } else {
        this.videoRef = this.$refs.html5Video;
        this.videoRef?.play();
      }

      // pause video on scroll --> TODO: needs to be modified as it kicks in to early!
      // this.pauseVideo(videoRef);
    },
    pausedVideo() {
      if (this.isThumbnail) {
        return;
      }
      if (this.isVideoYoutube) {
        this.videoRef.pauseVideo();
      } else if (this.isVideoYouku) {
        this.isVideoYoukuActive = false;
      } else {
        this.videoRef.pause();
      }
      this.videoPaused();
    },
    videoPlaying() {
      this.isPlaying = true;
      this.$emit('video', 'playing');
    },
    videoPaused() {
      this.isPlaying = false;
      this.$emit('video', 'paused');
    },
    videoEnded() {
      this.isPlaying = false;
      if (this.isModal) {
        // hide modal once video ended
        // wait before hiding
        setTimeout(() => {
          this.hideModal();
        }, this.modalDelay);
      }
      // update temporary play count
      this.playCount += 1;

      // exit fullscreen mode if fullscreen
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }

      // emit video state
      this.$emit('video', 'ended');
      // wait before hiding
      setTimeout(() => {
        this.showVideo = false;
        this.$emit('video', 'hid');

        // reset video to begin
        if (this.isVideoYoutube) {
          this.videoRef.pauseVideo();
          this.videoRef.seekTo(0);
        } else if (this.isVideoYouku) {
          this.isVideoYoukuActive = false;
        } else {
          this.videoRef.pause();
          this.videoRef.currentTime = 0;
        }
      }, this.modalDelay);
    },
    getImage() {
      // image desktop
      if (this.isMD && this.fields.image?.value?.src) {
        return this.fields.image.value.src;
      }
      // image tablet
      if (this.isSM && this.fields.imageTablet?.value?.src) {
        return this.fields.imageTablet.value.src;
      }

      // image mobile
      if (this.fields.imageMobile?.value?.src) {
        return this.fields.imageMobile.value.src;
      }

      // image fallback to desktop if no tablet or mobile
      if (this.fields.image?.value?.src) {
        return this.fields.image.value.src;
      }

      // if no image data
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './video-player-fi';
</style>
