import fetch from 'cross-fetch';

type StringKeyedObject = {
  [key: string]: any; // XXX: any type
};

const getLayoutdataUrl = (
  baseUrl: string,
  pageId: string | undefined,
  lang: string | undefined,
  siteName?: string,
): string => {
  let url = '/api/layout';
  if (IS_SHOWROOM) {
    url = `/__mocks__/sitecoreApi${url}/mockedLayoutData.json`;
  } else {
    url = `${baseUrl}${url}`;
  }

  url = `${url}?pageId=${encodeURIComponent(pageId || '')}&lang=${encodeURIComponent(lang || '')}`;

  if (siteName) {
    url = `${url}&siteName=${siteName}`;
  }

  return url;
};

const addMyFischerDevIcons = (layoutData: any, accessToken: string | null | undefined) => {
  if (!IS_SHOWROOM) {
    return;
  }
  if (!accessToken) {
    return;
  }

  layoutData.ServiceNavigation = (layoutData.ServiceNavigation || []).filter(
    (element: any) => element.title !== 'Authentifizierung',
  );
  layoutData.ServiceNavigation.push(
    {
      title: 'Benutzer',
      elements: [
        {
          title: 'My Account',
          link: {
            text: 'My Account',
            url: '/my-account/dashboard',
          },
          identifier: 'myAccount',
        },
      ],
    },
    {
      title: 'Logout',
      elements: [
        {
          title: 'Logout',
          link: {
            text: 'Logout',
            url: '/my-account/logout',
          },
          identifier: 'logout',
          hideFromMobile: true,
        },
      ],
    },
  );
};

const addShopDevIcons = (layoutData: any, accessToken: string | null | undefined) => {
  if (!IS_SHOWROOM) {
    return;
  }
  const { shopType } = JSON.parse(localStorage.getItem('showroomSettings') || '{}');
  if (!accessToken && shopType !== 'RetailConnectCart') {
    return;
  }
  if (shopType === 'ClosedOnlineShop') {
    layoutData.ServiceNavigation.push({
      title: 'Service',
      elements: [
        {
          title: 'Merkliste',
          link: {
            text: 'Merkliste',
            url: '/memo-list',
          },
          identifier: 'memolist',
          hideFromMobile: true,
        },
      ],
    });
  }
  if (['RetailConnectCart', 'ClosedOnlineShop', 'OnlineShop'].includes(shopType)) {
    layoutData.ServiceNavigation.push({
      title: 'Warenkorb',
      elements: [
        {
          title: 'Warenkorb',
          link: {
            text: 'Warenkorb',
            url: '/cart',
          },
          identifier: 'retailConnectCart',
        },
      ],
    });
  }
};

export default async (
  baseUrl: string,
  pageId: string | undefined,
  lang: string | undefined,
  $cookies: any,
  siteName?: string,
): Promise<StringKeyedObject> => {
  const layoutDataResponse = await fetch(getLayoutdataUrl(baseUrl, pageId, lang, siteName));
  const layoutData = await layoutDataResponse.json();

  if (IS_SHOWROOM) {
    const devSite = $cookies.get('devSite');
    if (devSite) {
      const merge = (dest: StringKeyedObject, source: StringKeyedObject) => {
        Object.entries(source).forEach(([key, value]) => {
          if (typeof value === 'object') {
            if (!dest[key]) {
              dest[key] = {};
            }
            merge(dest[key], value);
          } else {
            dest[key] = value;
          }
        });
      };
      merge(layoutData, devSite.value);
    }

    const data = await (await fetch('/__mocks__/customData/footer.json')).json();
    layoutData.Footer = data.customData.footer;

    const headerMockUrl =
      window.location.pathname === '/header'
        ? '/__mocks__/customData/navigation.json'
        : '/__mocks__/customData/navigationShowroom.json';
    const headerData = await (await fetch(headerMockUrl)).json();
    layoutData.Breadcrumb = headerData.customData.breadcrumb;
    layoutData.LogoBrandArea = headerData.customData.logoBrandArea;
    layoutData.LogoHeader = headerData.customData.logoHeader;
    layoutData.Navigation = headerData.customData.navigation;
    layoutData.ServiceNavigation = headerData.customData.serviceNavigation;

    const accessToken = $cookies.get('AccessToken');
    addShopDevIcons(layoutData, accessToken);
    addMyFischerDevIcons(layoutData, accessToken);
  }
  return layoutData;
};

export { getLayoutdataUrl };
