import { useFischer } from 'composables/fischerPlugin';
import fetch from 'cross-fetch';
import { inject } from 'vue';
import type { VueCookies } from 'vue-cookies';

import type { FischerPlugin } from '@/lib/fischerPlugin';
import getEventHubSessionId from '@/lib/getEventHubSessionId';

export type EventHubDeviceInfo = {
  screenResolutionWidth: number;
  screenResolutionHeight: number;
};

export type EventHubParameters = {
  appId: string;
  appView?: boolean | undefined;
  isTest?: boolean;
  baseSiteId: string;
  brand: string;
  clientId: string;
  deviceInfo: EventHubDeviceInfo;
  distributionChannel?: string;
  division?: string;
  domainId: string;
  isAllowedToBuy: boolean;
  locale: string;
  pageTemplate: string;
  referrer?: string;
  sessionId: string;
  shopType: string;
  targetGroup?: string;
  type: string;
  url: string;
  userId: string;
  customType?: string;
  attributes?: object;
};

export type ClickEventData = {
  url: string;
  pageTitle?: string;
  productId?: string;
  familyId?: string;
  page?: number;
  pageSize?: number;
  position?: number;
  query?: string;
  score?: number;
  productTitle?: string;
  clickSource?: string;
};

export const eventhubClickQueueId = 'eventhub-click-queue';

export const makeEventHubParameters = (
  fischer: FischerPlugin,
  cookies: VueCookies | undefined,
  type: string,
  customType?: string,
  attributes?: object,
): EventHubParameters => {
  const params: EventHubParameters = {
    type,

    appId: 'fischer_website',
    baseSiteId: fischer.baseSiteId(),
    brand: fischer.brandId(),
    clientId: fischer.user.trackingId(),
    deviceInfo: {
      screenResolutionWidth: window.screen.width,
      screenResolutionHeight: window.screen.height,
    },
    distributionChannel: fischer.user.distributionChannel(),
    division: fischer.user.division(),
    domainId: window.location.origin,
    isAllowedToBuy: fischer.shop.isClosedShop(),
    locale: fischer.catalogLocale(),
    pageTemplate: fischer.pageTemplate(),
    referrer: window.document.referrer || undefined,
    sessionId: getEventHubSessionId(),
    shopType: fischer.shop.shopType(),
    targetGroup: fischer.user.targetGroup() || undefined,
    url: String(window.location),
    userId: fischer.user.email(),
    customType: customType,
    attributes: attributes,
  };
  if (fischer.app.isAppView()) {
    params.appView = true;
  }

  if (cookies?.get('testautomation')) {
    params.isTest = true;
  }

  return params;
};

export const sendEventFunction = async (
  fischer: any,
  cookies: VueCookies | undefined,
  type: string,
  message: object = {},
  customType?: string,
  attributes?: object,
): Promise<void> => {
  if (!IS_SSR && fischer.isEventHubEnabled()) {
    const params: EventHubParameters = {
      ...makeEventHubParameters(fischer, cookies, type, customType, attributes),
      ...message,
    };
    try {
      if ((IS_SHOWROOM || APP_ENVIRONMENT === 'VM') && import.meta.env.VITE_DISABLE_TRACKING) {
        // eslint-disable-next-line no-console
        console.log('eventhub', params);
        return;
      }
      await fetch(`${import.meta.env.VITE_API_URL}/events/v1/events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
    } catch (error) {
      console.error('could not send event');
      console.error(error);
    }
  }
};

export const sendClickToEventHub = (
  fischer: FischerPlugin,
  cookies: VueCookies | undefined,
  clickEvent: ClickEventData,
  mouseEvent: MouseEvent,
  href: string,
) => {
  try {
    if (mouseEvent?.detail === 2) {
      // double-click - do not track again
      return;
    }
    const finishedEvent = {
      ...makeEventHubParameters(fischer, cookies, 'click'),
      ...clickEvent,
    };

    // @ts-ignore
    const haveCookieBot = window.Cookiebot && !window.Cookiebot.consent?.statistics;
    if (haveCookieBot) {
      if (mouseEvent && href) {
        mouseEvent.preventDefault();
        mouseEvent.stopImmediatePropagation();
        return sendEventFunction(fischer, cookies, 'click', finishedEvent).then(() => {
          window.location.href = href;
        });
      } else {
        console.error('missing href or event');
        return;
      }
    }

    const oldClickEvents = window.sessionStorage.getItem(eventhubClickQueueId);
    let queue: Array<ClickEventData> = [];
    if (oldClickEvents) {
      queue = JSON.parse(oldClickEvents) as Array<ClickEventData>;
    }
    queue.push(finishedEvent);
    window.sessionStorage.setItem(eventhubClickQueueId, JSON.stringify(queue));
  } catch (error) {
    console.error('error processing event', error);
  }
};

export type SendEvent = (
  type: string,
  message: object,
  customType?: string,
  attributes?: object,
) => Promise<void>;

export const useEventHub = () => {
  const fischer: FischerPlugin = useFischer();
  const cookies: VueCookies | undefined = inject('$cookies');
  const sendEvent: SendEvent = (
    type: string,
    message: object = {},
    customType?: string,
    attributes?: object,
  ) => sendEventFunction(fischer, cookies, type, message, customType, attributes);

  return {
    sendEvent,
    sendClickToEventHub: (clickEvent: ClickEventData, mouseEvent: MouseEvent, href: string) =>
      sendClickToEventHub(fischer, cookies, clickEvent, mouseEvent, href),
  };
};
