<template>
  <div class="mobile-navigation-sub-companies-fi">
    <ul
      class="mobile-navigation-sub-companies-fi__list mobile-navigation-sub-companies-fi__list--services"
    >
      <li
        class="mobile-navigation-sub-companies-fi__item mobile-navigation-sub-companies-fi__item--group"
      >
        <a
          :href="groupData.url"
          :target="groupData.target ? groupData.target : null"
          class="mobile-navigation-sub-companies-fi__item__wrapper"
        >
          <span class="mobile-navigation-sub-companies-fi__item__label">
            {{ navigationLayerTitle(groupData) }}
          </span>

          <arrow-icon class="mobile-navigation-sub-companies-fi__item__icon" />
        </a>
      </li>
      <li
        v-for="item in companyLinks"
        :id="`fi-navigation-${item.CategoryId}`"
        :key="item.CateogryId"
        class="mobile-navigation-sub-companies-fi__item mobile-navigation-sub-companies-fi__item--page"
      >
        <a
          :href="groupData.url + item.slug"
          :target="item.target ? item.target : null"
          class="mobile-navigation-sub-companies-fi__item__wrapper"
        >
          <span class="mobile-navigation-sub-companies-fi__item__label">
            {{ item.label }}
          </span>

          <arrow-icon class="mobile-navigation-sub-companies-fi__item__icon" />
        </a>
      </li>
    </ul>
    <ul class="mobile-navigation-sub-companies-fi__list">
      <li
        v-for="item in companies"
        :key="item.id"
        class="mobile-navigation-sub-companies-fi__item"
      >
        <a
          :href="item.url"
          :target="item.target ? item.target : null"
          class="mobile-navigation-sub-companies-fi__item__wrapper"
        >
          <cloud-image-fi
            :src="item.teaserImage?.src"
            :alt="item.label"
            class="mobile-navigation-sub-companies-fi__item__image"
          />

          <div class="mobile-navigation-sub-companies-fi__item__label fi-text-16 fi-semibold">
            <span class="fi-mr-9">
              {{ item.label }}
            </span>
            <arrow-icon class="navigation-companies-fi__arrow" />
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ArrowIcon, CloudImageFi } from 'atoms';
import navigationMixin from 'mixins/navigationMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'MobileNavigationSubItemsFi',
  components: {
    ArrowIcon,
    CloudImageFi,
  },
  mixins: [navigationMixin],
  data() {
    return {
      companiesHeaderLinks: [],
      companiesLinks: [],
    };
  },
  computed: {
    ...mapGetters({
      groupData: 'navigation/getGroupData',
      subElements: 'navigation/getSubElements',
    }),
    companies() {
      return this.companiesLinks;
    },
    companyLinks() {
      return this.companiesHeaderLinks;
    },
  },
  created() {
    this.subElements.forEach((element) => {
      if (element.isProxy) {
        this.companiesLinks.push(element);
      } else {
        this.companiesHeaderLinks.push(element);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import './mobile-navigation-sub-companies-fi';
</style>
