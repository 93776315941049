import { eventhubClickQueueId, sendEventFunction } from 'composables/eventHub';
import type { VueCookies } from 'vue-cookies';

import type { FischerPlugin } from '@/lib/fischerPlugin';

export default async ($fischer: FischerPlugin, $cookies: VueCookies) => {
  try {
    const eventQueueJson = window.sessionStorage.getItem(eventhubClickQueueId);
    if (!eventQueueJson) {
      return;
    }
    const eventQueue = JSON.parse(eventQueueJson);

    while (eventQueue.length) {
      const event = eventQueue.pop();
      await sendEventFunction($fischer, $cookies, 'click', event);
      if (eventQueue.length) {
        window.sessionStorage.setItem(eventhubClickQueueId, JSON.stringify(eventQueue));
      }
    }
    window.sessionStorage.removeItem(eventhubClickQueueId);
  } catch (error) {
    console.error('error processing event queue', error);
  }
};
