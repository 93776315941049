const productList = {
  namespaced: true,
  state: () => ({
    products: [],
    loadingQuantityInputs: {},
    maxHeadings: 0,
    shopType: 'default',
    approvalsEnabled: false,
  }),

  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_LOADING_QUANTITY_INPUTS(state, loadingQuantityInputs) {
      state.loadingQuantityInputs = loadingQuantityInputs;
    },
    SET_MAX_HEADINGS(state, maxHeadings) {
      state.maxHeadings = maxHeadings;
    },
    SET_SHOP_TYPE(state, shopType) {
      state.shopType = shopType;
    },
    SET_APPROVALS_ENABLED(state, approvalsEnabled) {
      state.approvalsEnabled = approvalsEnabled;
      localStorage.setItem('productList:approvalsEnabled', approvalsEnabled);
    },
  },

  actions: {
    setProducts({ commit }, products) {
      commit('SET_PRODUCTS', products);
    },
    setLoadingQuantityInputs({ commit }, loadingQuantityInputs) {
      commit('SET_LOADING_QUANTITY_INPUTS', loadingQuantityInputs);
    },
    setMaxHeadings({ commit }, maxHeadings) {
      commit('SET_MAX_HEADINGS', maxHeadings);
    },
    setShopType({ commit }, shopType) {
      commit('SET_SHOP_TYPE', shopType);
    },
    setApprovalsEnabled({ commit }, approvalsEnabled) {
      commit('SET_APPROVALS_ENABLED', approvalsEnabled);
    },
  },

  getters: {
    getProducts: (state) => state.products,
    getLoadingQuantityInputs: (state) => state.loadingQuantityInputs,
    getMaxHeadings: (state) => state.maxHeadings,
    getShopType: (state) => state.shopType,
    getApprovalsEnabled: (state) => state.approvalsEnabled,
  },
};

export default productList;
