<template>
  <component-wrapper>
    <grid-container-fi
      :class="{
        'teaser-icon-fi': true,
        'teaser-icon-fi--dark': fields.darkBackground?.value,
        'teaser-icon-fi--xpe': isEditMode,
      }"
      data-test="teaser-icon-component"
      :data-test-2="provideTargetGroup"
    >
      <div class="fi-col-start-1 fi-col-end-13 md:fi-col-start-2 md:fi-col-end-12">
        <template v-if="(isSlider && slicedTeasers) || (isEditMode && isSlider)">
          <carousel-fi
            theme="teaser-icon"
            clipped-right
            no-arrows
          >
            <carousel-slide-fi
              v-for="(teaserIconElement, index) in slicedTeasers"
              :key="index"
              theme="teaser-icon"
            >
              <teaser-icon-element-fi
                class="teaser-icon-element-fi--carousel-slide"
                :icon="teaserIconElement.fields?.icon"
                :link="teaserIconElement.fields?.link"
                :is-news="isNewsItem(teaserIconElement.fields)"
                :dark="fields.darkBackground?.value"
              />
            </carousel-slide-fi>
          </carousel-fi>

          <div
            v-if="isEditMode && fields.teaserIconElements?.length > maxTeasers"
            class="teaser-icon-fi__xpe-hint"
          >
            <icon-fi :icon="'alert-triangle'" />
            {{ $options.name }}: Max. {{ maxTeasers }} Teaser Icon Elements are allowed
          </div>
        </template>
        <div
          v-else
          class="teaser-icon-fi__container"
        >
          <teaser-icon-element-fi
            v-for="(teaserIconElement, index) in teaserElements"
            :key="index"
            :icon="teaserIconElement.fields?.icon"
            :link="teaserIconElement.fields?.link"
            :is-news="isNewsItem(teaserIconElement.fields)"
            :dark="fields.darkBackground?.value"
          />
        </div>
      </div>
    </grid-container-fi>
  </component-wrapper>
</template>

<script>
import { CarouselFi, CarouselSlideFi, IconFi } from 'atoms';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper';
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import TeaserIconElementFi from 'components/teaser-icon-element-fi/TeaserIconElementFi';
import breakpointMixin from 'mixins/breakpointMixin';

export default {
  name: 'TeaserIconFi',
  components: {
    ComponentWrapper,
    GridContainerFi,
    TeaserIconElementFi,
    IconFi,
    CarouselFi,
    CarouselSlideFi,
  },
  mixins: [breakpointMixin],
  inject: ['fields'],
  data() {
    return {
      maxTeasers: 3,
      isSlideable: true,
      isMounted: false,
    };
  },
  computed: {
    provideTargetGroup() {
      if (!this.isMounted) {
        return;
      }

      return window.location.pathname?.length > 1 &&
        this.$fischer.user.myfischerStartPageUrl()?.includes(window.location.pathname)
        ? this.$fischer.user.targetGroup()
        : null;
    },
    isSlider() {
      return Boolean(this.fields?.useSlider?.value);
    },
    slicedTeasers() {
      if (this.teaserElements?.length) {
        return this.fields.teaserIconElements.slice(0, this.maxTeasers);
      }

      return null;
    },
    teaserElements() {
      return this.fields?.teaserIconElements?.filter((teaserElement) => {
        if (!this.isMounted && teaserElement.fields.Groups?.length) {
          // BUG: groups currently not available in ssr
          return false;
        }
        return this.$fischer.authorize(
          teaserElement.fields.Anonymous ? teaserElement.fields.Anonymous.value : false,
          teaserElement.fields.Authenticated ? teaserElement.fields.Authenticated.value : false,
          teaserElement.fields.Groups ? teaserElement.fields.Groups : false,
          teaserElement.fields['Group Membership']
            ? teaserElement.fields['Group Membership'].fields.Value.value
            : '',
        );
      });
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    isNewsItem(teaserFields) {
      return Boolean(teaserFields?.isNews?.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './teaser-icon-fi';
</style>

<style lang="scss">
@import './teaser-icon-fi-unscoped';
</style>
