import type { FieldMergeFunction, KeyArgsFunction } from '@apollo/client/cache/inmemory/policies';
import {
  ApolloClient,
  type ApolloLink,
  createHttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client/index';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { mergeDeep } from '@apollo/client/utilities';
import { createApolloProvider as createApolloOptions } from '@vue/apollo-option';
import { fetch, fetchOptions } from 'apolloFetchOptions';
import stringifyStable from 'fast-json-stable-stringify';

import type { SitecoreDeploymentEnv } from '@/sitecoreState';
import sha256 from '@/utils/sha256Digest';

let cachedLink: ApolloLink | undefined;
let linkColor: SitecoreDeploymentEnv;
const getLink = (color: SitecoreDeploymentEnv = ''): ApolloLink => {
  if (linkColor !== color) {
    const apolloConfig =
      (IS_SHOWROOM && import.meta.env.VITE_APOLLO_CONFIG) ||
      `${import.meta.env.VITE_API_URL}/graphql-rebrush/${color}`;

    // (input: RequestInfo | URL, init?: RequestInit)

    const httpLink = createHttpLink({
      uri: apolloConfig,
      fetch,
      fetchOptions,
    });

    // disable persistent queries for showroom (because its easier to debug) and http (dev VMs)
    cachedLink =
      IS_SHOWROOM || (!IS_SSR && !window.crypto.subtle)
        ? httpLink
        : createPersistedQueryLink({
            sha256,
            useGETForHashedQueries: true,
          }).concat(httpLink);
    linkColor = color;
  }

  return cachedLink as ApolloLink; // typescript does not know that cachedLink is no longer undefined, but we made sure with the color check
};

const merge: FieldMergeFunction = (existing, incoming) => {
  if (!existing) {
    return incoming;
  }
  return [...existing, ...incoming];
};

const keyArgsWithoutPage: KeyArgsFunction = (variables) => {
  const clone = { ...variables };
  delete clone.page;
  delete clone.currentPage;
  return stringifyStable(clone);
};

const factFinderSearch = {
  keyArgs: keyArgsWithoutPage,
  merge: (existing: any, incoming: any) => {
    if (!existing) {
      return incoming;
    }
    const result = {
      ...existing,
    };

    if (incoming.masters?.hits) {
      if (existing.masters?.hits) {
        result.masters = {
          ...existing.masters,
          hits: [...existing.masters.hits, ...incoming.masters.hits],
        };
      } else {
        result.masters = incoming.masters;
      }
    }

    if (incoming.variants?.hits) {
      if (existing.variants?.hits) {
        result.variants = {
          ...existing.variants,
          hits: [...existing.variants.hits, ...incoming.variants.hits],
        };
      } else {
        result.variants = incoming.variants;
      }
    }

    return result;
  },
};

const cacheOptions = {
  typePolicies: {
    DocumentListNg: {
      fields: {
        documents: {
          merge,
        },
      },
    },
    AssistedServiceSearchResponse: {
      fields: {
        entries: {
          merge,
        },
        pagination: {
          merge: (_existing: any, incoming: any) => incoming,
        },
      },
    },
    Query: {
      fields: {
        assistedServiceSearch: {
          keyArgs: keyArgsWithoutPage,
        },
        assistedServiceCompanySearch: {
          keyArgs: keyArgsWithoutPage,
        },
        searchFactFinderNgWebsite: factFinderSearch,
        searchFactFinderNg: factFinderSearch,
        getDocumentListNg: {
          keyArgs: keyArgsWithoutPage,
        },
      },
    },
  },
};

const createApolloProvider = async (
  states?: Array<NormalizedCacheObject>,
  color?: SitecoreDeploymentEnv,
) => {
  const cache = new InMemoryCache(cacheOptions);

  if (!IS_SSR && states) {
    cache.restore(mergeDeep(...states));
  }

  const apolloClient = new ApolloClient({
    cache,
    link: getLink(color),
    ssrMode: IS_SSR,
    assumeImmutableResults: true,
    connectToDevTools:
      !IS_SSR &&
      (window.location.search.includes('showApollo') || IS_SHOWROOM || APP_ENVIRONMENT === 'VM'),
  });

  return createApolloOptions({
    defaultClient: apolloClient,
  });
};

export default createApolloProvider;
