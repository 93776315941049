const logDebug = (...msg: any[]) => {
  // @ts-ignore
  if (window.debugScrolling) {
    console.debug(...msg);
  }
};

const scrollToElement = (element: HTMLElement) => {
  const header = document.querySelector('.header-top-fi');
  const offset = header ? header.getBoundingClientRect().height : 80;
  const top =
    element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset;
  logDebug('scrolling', { top, offset });
  window.scrollTo({
    behavior: 'smooth',
    top,
  });
};

const scrollToHref = (href: string) => {
  logDebug('scrollToHref', href);
  const [, target] = href.split('#');
  if (!target) {
    logDebug('no target');
    return false;
  }
  const anchor: HTMLElement | null =
    document.getElementById(target) || document.querySelector(`[name="${target}"]`);
  if (!anchor) {
    logDebug('anchor not found', anchor);
    return false;
  }
  const targetElement: HTMLElement = anchor.closest('.jss-component') || anchor;
  scrollToElement(targetElement);
  return true;
};

const bindSmoothScroll = (scope: HTMLElement | Document = document) => {
  const links: NodeListOf<HTMLAnchorElement> = scope.querySelectorAll(
    'a[href*="#"]:not(.smooth-scroll)',
  );
  logDebug('binding links', links);
  if (!links.length) {
    return;
  }
  const [currentRef] = String(window.location).split('#');
  const currentStart = `${currentRef}#`;
  logDebug('current start', currentStart);
  links.forEach((link) => {
    if (
      !(link.href.startsWith(currentStart) || link.href.startsWith('#')) ||
      link.href === currentStart
    ) {
      logDebug('skipping', link.href);
      return;
    }
    link.classList.add('smooth-scroll');
    link.addEventListener('click', (event) => {
      if (scrollToHref(link.href)) {
        logDebug('scrolling, inhibiting click');
        event.preventDefault();
      } else {
        logDebug('not scrolling');
      }
    });
  });
};

export default bindSmoothScroll;
export { scrollToHref, scrollToElement };
