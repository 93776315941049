<template>
  <select-options-wrapper-fi
    ref="wrapper"
    :name="nameCleaned"
    :has-selection="hasSelection"
    @close="$emit('close')"
    @clear-selection="clearSelection"
  >
    <div
      v-for="element in elements"
      :key="`${element.key || element.name || element.text}${element.selected}`"
      :class="{
        'facet-list-fi__item': true,
        'facet-list-fi__item--checked': element.selected,
      }"
    >
      <checkbox-fi
        v-model="element.selected"
        :disabled="element.disabled"
        :class="{
          'checkbox-fi--hidden-checkbox': !multiselect,
          'checkbox-fi--full-width': true,
          'checkbox-fi--no-margin': true,
          'facet-list-fi__checkbox': true,
          'facet-list-fi__checkbox--disabled': element.disabled,
        }"
        :variant="element.disabled ? 'disabled' : null"
        :name="element.key || element.name"
        @update:model-value="(value) => onChange(value, element)"
      >
        <div
          :class="{
            'facet-list-fi__checkbox-content': true,
            'facet-list-fi__checkbox-content--hidden-checkbox': !multiselect,
          }"
        >
          <span data-test="facet-list-checkbox">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="element.name || element.text" />

            <span
              v-if="unit"
              class="facet-list-fi__unit"
            >
              {{ unit }}
            </span>

            <span
              v-if="
                element.count || element.count === 0 || element.totalHits || element.totalHits === 0
              "
              class="facet-list-fi__count"
            >
              ({{ element.count !== undefined ? element.count : element.totalHits }})
            </span>
          </span>

          <icon-fi
            v-if="!isSM && element.selected"
            icon="check"
          />
        </div>
      </checkbox-fi>
    </div>
  </select-options-wrapper-fi>
</template>

<script>
import CheckboxFi from 'atoms/checkbox-fi/CheckboxFi.vue';
import IconFi from 'atoms/icon-fi/IconFi';
import SelectOptionsWrapperFi from 'atoms/select-options-wrapper-fi/SelectOptionsWrapperFi';
import factFinderNgNameCleaner from 'components/search-fact-finder-fi/factFinderNgNameCleaner';
import breakpointMixin from 'mixins/breakpointMixin';

export default {
  name: 'FacetListFi',
  components: {
    CheckboxFi,
    IconFi,
    SelectOptionsWrapperFi,
  },
  mixins: [breakpointMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
    elements: {
      type: Array,
      required: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'change', 'clear-selection'],
  computed: {
    nameCleaned() {
      return factFinderNgNameCleaner(this.name);
    },
    hasSelection() {
      return this.elements.some((element) => element.selected);
    },
  },
  methods: {
    async close() {
      this.$refs.wrapper.close();
    },
    onChange(value, element) {
      this.$emit('change', { value, element });
      if (!this.multiselect) {
        this.close();
      }
    },
    clearSelection() {
      this.$emit('clear-selection');
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
@import './facet-list-fi';
</style>
