import { onBeforeUnmount, ref } from 'vue';

export const bodyDisableScrollBody = () => {
  if (IS_SSR) {
    return;
  }

  const { body } = document;
  body.style.top = `-${window.scrollY}px`;
  body.style.height = '';
  body.classList.add('scrolling-disabled');
};

export const bodyEnableScrollBody = () => {
  if (IS_SSR) {
    return;
  }
  const { body } = document;
  const scrollY = body.style.top;
  body.style.top = '';
  body.classList.remove('scrolling-disabled');

  window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

export const useBodyScroll = () => {
  const bodyScrollDisabled = ref<boolean>(false);

  const bodyDisableScroll = () => {
    bodyDisableScrollBody();
    bodyScrollDisabled.value = true;
  };

  const bodyEnableScroll = () => {
    if (!bodyScrollDisabled.value) {
      return;
    }
    bodyEnableScrollBody();
    bodyScrollDisabled.value = false;
  };

  onBeforeUnmount(() => {
    bodyEnableScroll();
  });

  return {
    bodyScrollDisabled,
    bodyDisableScroll,
    bodyEnableScroll,
  };
};
