<script>
import { getCookieFunction } from 'composables/ssrCookies';

export default {
  methods: {
    getCookie(key) {
      return getCookieFunction(key, this.$fischer, this.$cookies);
    },
  },
};
</script>
