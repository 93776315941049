import ClientStorage from '@/utils/clientStorage';

const getRootCategory = (path) => {
  if (path) {
    return path.split('/')[0];
  }
  return null;
};

const loadFilter = (brandId, path) => {
  const root = ClientStorage.getValue(brandId, true);

  if (root?.facets) {
    const categoryId = getRootCategory(path);

    return root.facets[categoryId];
  }

  return null;
};

const updateFilter = (brandId, path, value) => {
  try {
    const root = ClientStorage.getValue(brandId, true) || {};

    if (!Object.prototype.hasOwnProperty.call(root, 'facets')) {
      root.facets = {};
    }

    const categoryId = getRootCategory(path);

    if (Array.isArray(value) && value.length > 0) {
      // 'value' should be an array of filters and should contain at least one...
      root.facets[categoryId] = value;
    } else if (Object.prototype.hasOwnProperty.call(root.facets, categoryId)) {
      // ...otherwise remove the category completely
      delete root.facets[categoryId];
    }

    ClientStorage.setValue(brandId, root, true);
  } catch (e) {
    console.error(e);
    ClientStorage.removeValue(brandId, true);
    return false;
  }

  return true;
};

const product = {
  namespaced: true,
  state: () => ({
    activeFilters: [],
    isFilterSet: false,
    activeFacets: [],
  }),

  mutations: {
    SET_FILTER(state, filters) {
      state.activeFilters = filters || [];
    },
    SET_IS_FILTER(state, filter) {
      state.isFilterSet = filter;
    },
    SET_ACTIVE_FACETS(state, facets) {
      state.activeFacets = facets;
    },
  },

  getters: {
    apiFilters: (state) =>
      state.activeFilters.map((filter) => {
        const activeFacet = state.activeFacets.find((facet) => facet.id === filter.id);
        const unit = activeFacet ? activeFacet.unit : undefined;
        if (filter.minValue && filter.maxValue) {
          return {
            id: filter.id,
            value: null,
            minValue: parseInt(filter.minValue, 10),
            maxValue: parseInt(filter.maxValue, 10),
            unit,
          };
        }
        return {
          id: filter.id,
          value: filter.value,
          minValue: null,
          maxValue: null,
          unit,
        };
      }),
    getFilterSet: (state) => state.isFilterSet,
    visibleFilters: (state) => {
      const visibleFilters = [];

      state.activeFilters.forEach((activeFilter) => {
        const activeFacet = state.activeFacets.find((o) => o.id === activeFilter.id);
        let filter = activeFilter;
        if (!activeFacet) {
          const wildCardFacet = /(.*)\*$/.exec(filter.id);
          if (!wildCardFacet) {
            return;
          }
          const [, idStart] = wildCardFacet;
          const actualActiveFacet = state.activeFacets.find((o) => o.id.startsWith(idStart));
          if (!actualActiveFacet) {
            return;
          }
          if (state.activeFilters.some((o) => o.id === actualActiveFacet.id)) {
            // also manually set, so ignore
            return;
          }
          filter = {
            ...filter,
            id: actualActiveFacet.id,
            name: actualActiveFacet.name,
          };
        }
        if (filter.name) {
          visibleFilters.push(filter);
        } else {
          visibleFilters.push({
            ...filter,
            name: activeFacet.name,
          });
        }
      });

      return visibleFilters;
    },
    activeFacets: (state) => state.activeFacets,
  },

  actions: {
    filterSet({ commit }) {
      commit('SET_IS_FILTER', true);
    },
    addFilter({ commit, state }, args) {
      // remove old filter
      let filters = state.activeFilters.filter((filter) => filter.id !== args.value.id);

      // push new value to filter array
      filters.push(args.value);

      // client storage handling
      if (updateFilter(args.brandId, args.path, filters, true)) {
        // set new filter array to state
        commit('SET_FILTER', filters);
      }
    },

    removeFilter({ commit, state }, args) {
      // remove filter from array
      const filters = state.activeFilters.filter((filter) => {
        if (filter.id === args.value.id) {
          return false;
        }
        const wildCardFacet = /(.*)\*$/.exec(filter.id);
        if (wildCardFacet) {
          const [, idStart] = wildCardFacet;
          return !args.value.id.startsWith(idStart);
        }

        return true;
      });

      // client storage handling
      if (updateFilter(args.brandId, args.path, filters, true)) {
        // set new filter array to state
        commit('SET_FILTER', filters);
      }
    },

    clearFilter({ commit }, args) {
      if (updateFilter(args.brandId, args.path, null, true)) {
        commit('SET_FILTER', []);
      }
    },

    resetFilters({ dispatch }, args) {
      dispatch('clearFilter', args);
    },

    loadFilter({ commit, dispatch }, args) {
      try {
        const filters = loadFilter(args.brandId, args.path);

        if (filters) {
          commit('SET_FILTER', filters);
        } else {
          dispatch('resetFilters', args);
        }
      } catch (e) {
        console.error(e);
        dispatch('discardFilters', args);
      }
    },

    discardFilters({ commit }, args) {
      ClientStorage.removeValue(args.brandId, true);
      commit('SET_FILTER', []);
    },
  },
};

export default product;
