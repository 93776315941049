export default (value: string | undefined) => {
  if (!value) {
    return value;
  }
  const optionalSeparator = '~~';
  return value
    .replace(/&shy;/g, optionalSeparator)
    .replace(/&#173;/g, optionalSeparator)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(new RegExp(optionalSeparator, 'g'), '&shy;');
};
