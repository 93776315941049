<script>
import screenWidths from 'tailwind/screenWidths';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      windowWidth: 0,
    };
  },
  computed: {
    ...mapGetters({
      windowDimensions: 'layout/getWindowDimensions',
    }),
    isXXS() {
      return this.windowWidth >= screenWidths.xxs;
    },
    isXS() {
      return this.windowWidth >= screenWidths.xs;
    },
    isSmDialog() {
      return this.windowWidth >= screenWidths.smDialog;
    },
    isSM() {
      return this.windowWidth >= screenWidths.sm;
    },
    isMD() {
      return this.windowWidth >= screenWidths.md;
    },
    isLG() {
      return this.windowWidth >= screenWidths.lg;
    },
    isXL() {
      return this.windowWidth >= screenWidths.xl;
    },
  },
  watch: {
    windowDimensions: {
      deep: true,
      handler() {
        this.updateWindowSize();
      },
    },
  },
  mounted() {
    this.updateWindowSize();
  },
  methods: {
    updateWindowSize() {
      if (IS_SSR) {
        return;
      }

      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
