<template>
  <label
    :class="labelClass"
    :for="idLocal"
    data-test="checkbox-label"
    @click.capture.prevent.stop="clickHandlerWrapper"
  >
    <component
      :is="inputWrapperTag"
      :autofocus="autofocus"
      :tabindex="tabindex"
      class="checkbox-fi__input-wrapper fi-pointer-events-none"
      @keydown="onWrapperKeydown"
    >
      <input
        :id="idLocal"
        ref="input"
        :checked="isChecked"
        :disabled="disabled"
        :name="name"
        :readonly="readonly"
        :required="required"
        :tabindex="tabindex !== undefined ? -1 : undefined"
        :value="value"
        class="checkbox-fi__input"
        type="checkbox"
        data-test="checkbox-input"
        :data-test-2="dataTest"
        :date-test-3="dataTest2"
        @blur="blurHandler"
        @focus="focusHandler"
        @click.capture.prevent.stop="clickHandlerWrapper"
      />
    </component>
    <component
      :is="labelTag"
      ref="label"
      class="checkbox-fi__label"
      @click="click"
    >
      <slot>
        {{ label }}
      </slot>
      <span
        v-if="showAsterisk"
        class="checkbox-fi__label-asterisk"
      >
        *</span
      >
    </component>
  </label>
</template>

<script lang="ts">
import binaryInputMixin from 'mixins/binaryInputMixin.vue';
import { defineComponent } from 'vue';

import checkboxMixin from './checkboxMixin.vue';

export default defineComponent({
  name: 'CheckboxFi',
  mixins: [binaryInputMixin, checkboxMixin],
  props: {
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: undefined,
    },
    tabindex: {
      type: [String, Number],
      default: undefined,
    },
    indeterminate: {
      type: [Boolean, String],
      default: undefined,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
    wrapperTag: {
      type: String,
      default: 'label',
    },
    inputWrapperTag: {
      type: String,
      default: 'span',
    },
    labelTag: {
      type: String,
      default: 'span',
    },
    label: {
      type: [String, Number],
      default: undefined,
    },
    variant: {
      type: String,
      default: undefined,
    },
    showAsterisk: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: null,
    },
    dataTest2: {
      type: String,
      default: null,
    },
  },
  emits: ['update:indeterminate'],
  data() {
    return {
      idLocal: '',
    };
  },
  computed: {
    labelClass() {
      if (this.variant) {
        return `checkbox-fi checkbox-fi--${this.variant}`;
      }
      return 'checkbox-fi';
    },
  },
  watch: {
    indeterminate(indeterminate) {
      this.setIndeterminate(indeterminate);
    },
  },
  created() {
    this.idLocal = this.id || `cbx${String(Math.random()).replace('.', '')}`;
  },
  methods: {
    setIndeterminate(indeterminate: boolean) {
      const input = this.getInput();

      input.indeterminate = indeterminate;

      // Emit update event to prop
      this.$emit('update:indeterminate', indeterminate);
    },
  },
});
</script>

<style lang="scss" scoped>
@import './checkbox-fi';
</style>
