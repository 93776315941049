import { inject } from 'vue';
import type { VueCookies } from 'vue-cookies';

export const useCookies = () => {
  const cookies = inject<VueCookies>('$cookies');

  if (!cookies) {
    throw new Error('missing injection $cookies');
  }

  return { cookies };
};
