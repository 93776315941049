<template>
  <div
    v-if="activated || isSearchTransitioning"
    :style="style"
    class="search-box-mobile-fi"
    @transitionend="transitionEnd"
  >
    <div class="search-box-mobile-fi__icon search-box-mobile-fi__icon-placeholder">
      <icon-fi icon="search" />
    </div>
    <div
      :style="placeholderBrandAreaStyle"
      class="search-box-mobile-fi__icon search-box-mobile-fi__icon-placeholder search-box-mobile-fi__icon-placeholder--brand-area"
    >
      <icon-fi
        :style="placeholderIconBrandAreaStyle"
        icon="search"
      />
    </div>
    <div
      :class="{
        'search-box-mobile-fi__overlay': true,
        'search-box-mobile-fi__overlay--visible': visible,
      }"
    >
      <div class="search-box-mobile-fi__icon">
        <icon-fi icon="search" />
      </div>
      <div class="search-box-mobile-fi__search-wrapper">
        <search-box-fi
          id="search-box-mobile-navigation"
          ref="searchBox"
          class="search-box-mobile-fi__search-box"
        />
        <div
          class="search-box-mobile-fi__item search-box-mobile-fi__search-close"
          @click="closeSearch"
        >
          <icon-fi
            class="search-box-mobile-fi__item__icon"
            icon="close"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconFi } from 'atoms/index';
import SearchBoxFi from 'components/search/search-box-fi/SearchBoxFi.vue';
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue';
import { useStore } from 'vuex';

import { useBreakpoints } from '@/composables/breakpoint';

interface PlaceholderBrandAreaStyle {
  display?: string;
  top?: string;
  bottom?: string;
}
interface PlaceholderIconBrandAreaStyle {
  display?: string;
  position?: string;
  top?: string;
}

const visible = ref<boolean>(false);
const activated = ref<boolean>(false);
const style = ref({});
const placeholderBrandAreaStyle = ref<PlaceholderBrandAreaStyle>({});
const placeholderIconBrandAreaStyle = ref<PlaceholderIconBrandAreaStyle>({});

const { isSM } = useBreakpoints();
const store = useStore();

const isSearchActive = computed<boolean>(() => store.getters['navigation/searchActive']);
const isSearchTransitioning = computed<boolean>(() => store.getters['navigation/searchActive']);

const searchBox = useTemplateRef<typeof SearchBoxFi>('searchBox');

let animationPromise: () => void | undefined;
const setupAnimationPromise = () => {
  if (IS_SSR) {
    return Promise.resolve();
  }

  return new Promise<void>((resolve) => {
    if (animationPromise) {
      animationPromise();
    }

    animationPromise = resolve;
    setTimeout(resolve, 1000); // safeguard if the animation gets lost
  });
};

watch(
  isSearchActive,
  async (value) => {
    if (value === visible.value) return;

    store.dispatch('navigation/setSearchTransitioning', true);
    const animationEndPromise = setupAnimationPromise();
    setBrandAreaOverlayStyle();
    const left = getSearchIconLeft();
    style.value = { left: `${left}px` };
    visible.value = value;
    if (value) {
      activated.value = true;

      await nextTick();
      if (searchBox.value && !store.getters['search/inhibitSuggestions']) {
        searchBox.value.focus();
      }

      style.value = {};

      await animationEndPromise;
    } else {
      await nextTick();
      await animationEndPromise;
      activated.value = false;
    }

    store.dispatch('navigation/setSearchTransitioning', false);
  },
  { immediate: true },
);

const setBrandAreaOverlayStyle = () => {
  placeholderBrandAreaStyle.value = {};
  placeholderIconBrandAreaStyle.value = {};

  if (isSM.value) {
    return;
  }

  const brandLeft = document.querySelector('.brand-area-fi');
  if (!brandLeft) return;

  const { top, height } = brandLeft.getBoundingClientRect();
  const areaEnd = top + height;

  if (areaEnd < 0) return;

  placeholderBrandAreaStyle.value.display = 'block';

  if (areaEnd < 64) {
    placeholderBrandAreaStyle.value.top = 'unset';
    placeholderBrandAreaStyle.value.bottom = `${64 - areaEnd}px`;
    placeholderIconBrandAreaStyle.value.display = 'none';
  } else if (top < 0) {
    placeholderBrandAreaStyle.value.top = 'unset';
    placeholderBrandAreaStyle.value.bottom = '0px';
    placeholderIconBrandAreaStyle.value.top = `${top}px`;
    placeholderIconBrandAreaStyle.value.position = 'relative';
  } else {
    placeholderBrandAreaStyle.value.top = `${top}px`;
  }
};

const transitionEnd = ({ propertyName }: { propertyName: string }) => {
  if (propertyName === 'left') {
    setupAnimationPromise();
  }
};

const getSearchIconLeft = () => {
  const element = document.querySelector('.mobile-navigation-bar-fi__search-icon');
  return element?.getBoundingClientRect().x || 0;
};

const closeSearch = () => {
  store.dispatch('search/setQuery', { value: '', noShowSuggestions: true });
  store.dispatch('navigation/setSearchInput', false);
  store.commit('search/SET_SEARCH_SUGGESTIONS', { suggestions: [], query: '' });
};
</script>

<style scoped lang="scss">
@import './search-box-mobile-fi';
</style>

<style lang="scss">
@import './search-box-mobile-fi-unscoped';
</style>
