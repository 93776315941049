const navigation = {
  namespaced: true,
  state: () => ({
    showFlyout: false,
    showMobileFirstLvl: false,
    showMobileSubLvl: false,
    showSearchSuggest: false,
    enableHandlingFeeBanner: false,
    requestHandlingFeeBanner: false,
    isScrolledOutHandlingFeeBanner: false,
    isAssistedServiceActive: false,
    navIndex: -1,
    groupData: {},
    subElements: [],
    isProducts: false,
    isCompany: false,
    isCountrySelect: false,
    isCountrySelectPage: false,
    isSingleLocale: true,
    isCheckout: true,
    brandAreaContent: null,
    brandAreaIndex: 0,
    showBrandArea: true,
    searchInput: false,
    searchFocused: false,
    searchTransitioning: false,
    isSearchPage: false,
    backLink: null,
    backLinkClicked: false,
    currentLocaleData: null,
    jumpMarks: [],
    activeJumpMark: {},
    productBreadcrumb: [],
    triggerLogin: 0,
    triggerLoginModal: '',
    triggerLoginForceStart: false,
  }),

  mutations: {
    TRIGGER_LOGIN(state, args = {}) {
      const { modal = '', forceLoginOnStart = false } = args;
      state.triggerLogin += 1;
      state.triggerLoginModal = modal;
      if (state.triggerLoginForceStart !== 'ALWAYS') {
        state.triggerLoginForceStart = forceLoginOnStart;
      }
    },
    RESET_LOGIN(state, args = {}) {
      const { modal = '', forceLoginOnStart = false } = args;
      state.triggerLoginModal = modal;
      if (state.triggerLoginForceStart !== 'ALWAYS') {
        state.triggerLoginForceStart = forceLoginOnStart;
      }
    },
    SHOWFLYOUT(state, showFlyout) {
      state.showFlyout = showFlyout;
    },
    SHOWMOBILEFIRSTLVL(state, showMobileFirstLvl) {
      state.showMobileFirstLvl = showMobileFirstLvl;
    },
    SHOWMOBILESUBLVL(state, showMobileSubLvl) {
      state.showMobileSubLvl = showMobileSubLvl;
    },
    SHOW_SEARCH_SUGGEST(state, showSearchSuggest) {
      state.showSearchSuggest = Boolean(showSearchSuggest);
    },
    ENABLE_HANDLING_FEE_BANNER(state, enableHandlingFeeBanner) {
      state.enableHandlingFeeBanner = Boolean(enableHandlingFeeBanner);
    },
    REQUEST_HANDLING_FEE_BANNER(state, requestHandlingFeeBanner) {
      state.requestHandlingFeeBanner = Boolean(requestHandlingFeeBanner);
    },
    IS_SCROLLED_OUT_HANDLING_FEE_BANNER(state, isScrolledOutHandlingFeeBanner) {
      state.isScrolledOutHandlingFeeBanner = Boolean(isScrolledOutHandlingFeeBanner);
    },
    NAVINDEX(state, navIndex) {
      state.navIndex = navIndex;
      if (navIndex !== -1) {
        state.showSearchSuggest = false;
      }
    },
    GROUPDATA(state, groupData) {
      state.groupData = groupData;
    },
    SUBELEMENTS(state, subElements) {
      state.subElements = subElements;
    },
    ISPRODUCTS(state, isProducts) {
      state.isProducts = Boolean(isProducts);
    },
    ISCOMPANY(state, isCompany) {
      state.isCompany = Boolean(isCompany);
    },
    IS_COUNTRY_SELECT(state, isCountrySelect) {
      state.isCountrySelect = Boolean(isCountrySelect);
    },
    IS_COUNTRY_SELECT_PAGE(state, isCountrySelectPage) {
      state.isCountrySelectPage = Boolean(isCountrySelectPage);
    },
    IS_SINGLE_LOCALE(state, isSingleLocale) {
      state.isSingleLocale = Boolean(isSingleLocale);
    },
    ISCHECKOUT(state, isCheckout) {
      state.isCheckout = Boolean(isCheckout);
    },
    SET_BRANDAREA_INDEX(state, brandAreaIndex) {
      state.brandAreaIndex = brandAreaIndex;
    },
    BRANDAREACONTENT(state, brandAreaContent) {
      state.brandAreaContent = brandAreaContent;
    },
    SET_BRANDAREACONTENT(state, brandAreaContent) {
      state.brandAreaContent = brandAreaContent;
    },
    SHOWBRANDAREA(state, showBrandArea) {
      state.showBrandArea = showBrandArea;
    },
    SET_SEARCH_INPUT(state, value) {
      state.searchInput = value;
    },
    SET_SEARCH_FOCUSED(state, value) {
      state.searchFocused = value;
    },
    SET_SEARCH_TRANSITIONING(state, value) {
      state.searchTransitioning = value;
    },
    SET_IS_SEARCH_PAGE(state, value) {
      state.isSearchPage = value;
    },
    SET_BACKLINK(state, backLink) {
      state.backLink = backLink;
    },
    SET_BACKLINK_CLICKED(state, backLinkClicked) {
      state.backLinkClicked = backLinkClicked;
    },
    SET_ASSISTED_SERVICE_ACTIVE(state, isAssistedServiceActive) {
      state.isAssistedServiceActive = isAssistedServiceActive;
    },
    SET_CURRENT_LOCALE_DATA(state, currentLocaleData) {
      state.currentLocaleData = currentLocaleData;
    },
    SET_JUMP_MARK(state, jumpMark) {
      state.jumpMarks.push(jumpMark);
      state.jumpMarks.sort((a, b) => a.offsetTop - b.offsetTop);
    },
    SET_ACTIVE_JUMP_MARK(state, activeJumpMark) {
      state.activeJumpMark = activeJumpMark;
    },
    SET_PRODUCT_BREADCRUMB(state, productBreadcrumb) {
      state.productBreadcrumb = productBreadcrumb;
    },
  },

  actions: {
    showFlyout({ commit }, showFlyout) {
      commit('SHOWFLYOUT', showFlyout);
    },
    setBrandAreaContent({ commit }, brandAreaContent) {
      commit('SET_BRANDAREACONTENT', brandAreaContent);
    },
    showBrandArea({ commit }, showBrandArea) {
      commit('SHOWBRANDAREA', showBrandArea);
    },
    showSearchSuggest({ commit }, showSearchSuggest) {
      commit('SHOW_SEARCH_SUGGEST', showSearchSuggest);
      if (showSearchSuggest) {
        commit('NAVINDEX', -1);
      }
    },
    enableHandlingFeeBanner({ commit }, enableHandlingFeeBanner) {
      commit('ENABLE_HANDLING_FEE_BANNER', enableHandlingFeeBanner);
    },
    requestHandlingFeeBanner({ commit }, requestHandlingFeeBanner) {
      commit('REQUEST_HANDLING_FEE_BANNER', requestHandlingFeeBanner);
    },
    isScrolledOutHandlingFeeBanner({ commit }, isScrolledOutHandlingFeeBanner) {
      commit('IS_SCROLLED_OUT_HANDLING_FEE_BANNER', isScrolledOutHandlingFeeBanner);
    },
    setSearchInput({ commit }, value) {
      commit('SET_SEARCH_INPUT', value);
    },
    setSearchFocused({ commit }, value) {
      commit('SET_SEARCH_FOCUSED', value);
    },
    setSearchTransitioning({ commit }, value) {
      commit('SET_SEARCH_TRANSITIONING', value);
    },
    setIsSearchPage({ commit }, value) {
      commit('SET_IS_SEARCH_PAGE', value);
    },
    setBackLink({ commit }, backLink) {
      commit('SET_BACKLINK', backLink);
    },
    backLinkClicked({ commit }, backLinkClicked) {
      commit('SET_BACKLINK_CLICKED', backLinkClicked);
    },
    setAssistedServiceActive({ commit }, isAssistedServiceActive) {
      commit('SET_ASSISTED_SERVICE_ACTIVE', isAssistedServiceActive);
    },
    setJumpMark({ commit }, jumpMark) {
      commit('SET_JUMP_MARK', jumpMark);
    },
    setActiveJumpMark({ commit }, activeJumpMark) {
      commit('SET_ACTIVE_JUMP_MARK', activeJumpMark);
    },
  },

  getters: {
    getFlyout: (state) => state.showFlyout,
    getMobileFirstLvl: (state) => state.showMobileFirstLvl,
    getMobileSubLvl: (state) => state.showMobileSubLvl,
    getNavIndex: (state) => state.navIndex,
    getGroupData: (state) => state.groupData,
    getSubElements: (state) => state.subElements,
    getShowSearchSuggest: (state) => state.showSearchSuggest,
    getBrandAreaContent: (state) => state.brandAreaContent,
    getBrandAreaIndex: (state) => state.brandAreaIndex,
    getBackLink: (state) => state.backLink || { text: '', url: '', clickEvent: false },
    getCurrentLocaleData: (state) => state.currentLocaleData,
    getJumpMarks: (state) => state.jumpMarks,
    getActiveJumpMark: (state) => state.activeJumpMark,
    isProducts: (state) => state.isProducts,
    isCompany: (state) => state.isCompany,
    isCountrySelect: (state) => state.isCountrySelect,
    isCountrySelectPage: (state) => state.isCountrySelectPage,
    isSingleLocale: (state) => state.isSingleLocale,
    isCheckout: (state) => state.isCheckout,
    isSearch: (state) => state.isSearchPage,
    showBrandArea: (state) => state.showBrandArea && !state.showSearchSuggest,
    enableHandlingFeeBanner: (state) => state.enableHandlingFeeBanner,
    requestHandlingFeeBanner: (state) => state.requestHandlingFeeBanner,
    isScrolledOutHandlingFeeBanner: (state) => state.isScrolledOutHandlingFeeBanner,
    showHandlingFeeBanner: (state) =>
      state.enableHandlingFeeBanner &&
      state.requestHandlingFeeBanner &&
      !state.isScrolledOutHandlingFeeBanner,
    searchActive: (state) => state.searchInput || state.showSearchSuggest || state.isSearchPage,
    searchTransitioning: (state) => state.searchTransitioning,
    searchFocused: (state) => state.searchFocused,
    isBackLinkClicked: (state) => state.backLinkClicked,
    isAssistedServiceActive: (state) => state.isAssistedServiceActive,
    productBreadcrumb: (state) => state.productBreadcrumb,
    triggerLogin: (state) => state.triggerLogin,
    triggerLoginModal: (state) => state.triggerLoginModal,
    triggerLoginForceStart: (state) => state.triggerLoginForceStart,
  },
};

export default navigation;
