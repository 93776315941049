<template>
  <div
    ref="headerTopContainer"
    :class="{
      'header-top-fi': true,
      'header-top-fi--search-active': isSearchActive,
      'header-top-fi--ssr': notMounted,
      'header-top-fi--large-logo': largeLogo && !isUpat,
      'header-top-fi--upat': isUpat,
    }"
  >
    <assisted-service-module-fi />
    <handling-fee-limit-banner-fi :handling-fee-limit="handlingFeeLimit" />

    <div class="header-top-fi__container">
      <div class="header-top-fi__left">
        <a
          v-if="logoHeader?.src && homeData"
          :href="homeData.url"
          :title="homeData.title"
          class="header-top-fi__logo"
        >
          <logo-header-fi
            :logo-header="logoHeader"
            :large="$fischer.largeLogo()"
          />
        </a>

        <div class="header-top-fi__navigation">
          <navigation-bar-fi
            class="header-top-fi__navigation-bar"
            :navigation-data="navigationData"
            :service-navigation="serviceNavigation"
            @login="$emit('login')"
          />
          <mobile-navigation-bar-fi
            :service-navigation="serviceNavigation"
            class="header-top-fi__mobile-navigation-bar"
            @login="$emit('login')"
          />
        </div>
      </div>

      <div class="header-top-fi__right">
        <search-box-fi
          id="search-box-header-top"
          is-desktop
        />
      </div>
    </div>

    <template v-if="jumpMarks?.length">
      <jump-mark-tabs-fi
        v-show="!showFlyout"
        ref="jumpMarkTabs"
      />
    </template>

    <snackbar-fi
      v-if="
        $fischer.shop.isClosedShop() ||
        $fischer.shop.isOnlineShop() ||
        $fischer.shop.isRetailConnectCart()
      "
    />
  </div>
</template>

<script setup lang="ts">
import { LogoHeaderFi } from 'atoms/index';
import AssistedServiceModuleFi from 'components/assisted-service-module-fi/AssistedServiceModuleFi.vue';
import HandlingFeeLimitBannerFi from 'components/handling-fee-limit-banner-fi/HandlingFeeLimitBannerFi.vue';
import MobileNavigationBarFi from 'components/header-fi/header-top-fi/mobile-navigation-bar-fi/MobileNavigationBarFi.vue';
import NavigationBarFi from 'components/header-fi/header-top-fi/navigation-bar-fi/NavigationBarFi.vue';
import JumpMarkTabsFi from 'components/jump-mark-tabs-fi/JumpMarkTabsFi.vue';
import SearchBoxFi from 'components/search/search-box-fi/SearchBoxFi.vue';
import SnackbarFi from 'components/shop-modules/snackbar-fi/SnackbarFi.vue';
import { useFischer } from 'composables/fischerPlugin';
import { useHeader } from 'composables/header';
import { computed, onMounted, ref, useTemplateRef } from 'vue';
import { useStore } from 'vuex';

import type { ImageFieldValue } from '@/sitecoreFieldTypes';

defineOptions({
  name: 'HeaderTopFi',
});

withDefaults(
  defineProps<{
    handlingFeeLimit?: string | null;
    logoHeader?: ImageFieldValue;
    homeData?: any;
    navigationData?: any[];
    serviceNavigation: any[];
  }>(),
  {
    handlingFeeLimit: null,
    logoHeader: () => ({}),
    homeData: () => ({}),
    navigationData: () => [],
  },
);

defineEmits<{
  login: [value: void];
}>();

const headerRef = useTemplateRef<HTMLDivElement>('headerTopContainer');

const store = useStore();
const fischer = useFischer();
useHeader(headerRef);

const notMounted = ref<boolean>(true);
onMounted(() => {
  notMounted.value = false;
});

const showFlyout = computed<boolean>(() => store.getters['navigation/getFlyout']);
const isSearchActive = computed<boolean>(() => store.getters['navigation/searchActive']);
const jumpMarks = computed<any[]>(() => store.getters['navigation/getJumpMarks']);

const largeLogo = computed<boolean>(() => fischer.largeLogo());
</script>

<style lang="scss" scoped>
@import './header-top-fi';
</style>
