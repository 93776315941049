export default {
  xxs: 320,
  xs: 480,
  smDialog: 601,
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1800,
  xxl: 1920,
};
