import type { Field } from '@sitecore-jss/sitecore-jss';
import type { App } from 'vue';

type Replacements = Array<string>;

interface Options {
  noDevFallback?: boolean;
}

export interface DictionaryPhrases {
  [Key: string]: string;
}

interface Store {
  debugShownKeys: any;
  defaultValues: DictionaryPhrases;
  dictIDCounter: number;
  phrases: DictionaryPhrases;
  showDict: null | boolean;
}

export interface SitecoreDictionary {
  get(key: string, replacements?: Replacements, options?: Options): string;
  isProduction(): boolean;
  replace(string: string, replacements: Replacements): string;
  field(field: Field, replacements: Replacements): string;
  getPhrases(): DictionaryPhrases;
}

const store: Store = {
  debugShownKeys: {},
  defaultValues: {},
  dictIDCounter: 0,
  phrases: {},
  showDict: null,
};

// Vue plugins must export a function named 'install'
function install(app: App) {
  if (app.config.globalProperties.$dict) {
    return;
  }

  const $dict: SitecoreDictionary = {
    isProduction() {
      return ['PROD', 'TEST', 'UAT'].includes(APP_ENVIRONMENT);
    },
    replace(string: string, replacements: Replacements): string {
      if (!Array.isArray(replacements)) {
        return string;
      }
      let replaced = string;
      for (let i = 0; i < replacements.length; i += 1) {
        replaced = replaced.replace(`{${i}}`, replacements[i]);
      }
      return replaced;
    },
    field(field: Field, replacements: Replacements) {
      if (!field?.value) {
        if (!IS_SHOWROOM && !this.isProduction()) {
          return '(!)';
        }
        return '';
      }
      return this.replace(String(field.value), replacements);
    },
    get(key: string, replacements: Replacements, options: Options): string {
      let s: string;
      if (store.phrases[key]) {
        s = String(store.phrases[key]);
      } else {
        if (this.isProduction()) {
          s = '';
        } else {
          s = store.defaultValues[key];
          if (!s) {
            if (!options?.noDevFallback) {
              s = key;
            }
          } else if (!IS_SHOWROOM) {
            s += '(!)';
          }
        }
      }

      if (store.showDict === null) {
        store.showDict =
          !IS_SSR && app.config.globalProperties.$fischer?.query('showDict') === 'true';
      }

      if (store.showDict) {
        let dictID = store.debugShownKeys[key];
        if (!dictID) {
          store.dictIDCounter += 1;
          dictID = store.dictIDCounter;
          store.debugShownKeys[key] = dictID;
          // eslint-disable-next-line no-console
          console.log(
            `[${dictID}] %c${key}%c:%c '${s}'`,
            'font-weight: 600;',
            '',
            'font-style: italic;',
          );
        }
        s = `[${dictID}] ${s}`;
      }

      s = this.replace(s, replacements);

      return s;
    },
    getPhrases() {
      return store.phrases;
    },
  };

  app.config.globalProperties.$dict = $dict;
}

function setData(phrases: DictionaryPhrases) {
  store.dictIDCounter = 0;
  store.debugShownKeys = {};
  store.showDict = null;

  if (phrases) {
    store.phrases = phrases;
  }
}

export default { install, setData };
