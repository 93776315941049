import { useFischer } from 'composables/fischerPlugin';
import { computed } from 'vue';

import type { FischerPlugin } from '@/lib/fischerPlugin';

export const computeWrapperClasses = (themeName: string, isAppView: boolean = false) => ({
  // NOTE: list all classes plainly here or they might be optimized away
  'component-wrapper--fiwe': themeName === 'fiwe',
  'component-wrapper--fite': themeName === 'fite',
  'component-wrapper--upat': themeName === 'upat',
  'component-wrapper--app-view': isAppView,
});

export const computeThemeName = (
  fischer: FischerPlugin | undefined,
  forceTheme: string | null | undefined,
): string => forceTheme || fischer?.theme() || 'fiwe';

export default (forceTheme: string | null | undefined) => {
  const fischer = useFischer();

  const themeName = computed(() => computeThemeName(fischer, forceTheme));

  const wrapperClasses = computed(() =>
    computeWrapperClasses(themeName.value, fischer?.app.isAppView()),
  );

  return { themeName, wrapperClasses };
};
