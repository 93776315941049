<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['generateAccordionId', 'isAccordionOpen', 'toggleAccordionPanelById'],
  props: {
    ignoreOthers: {
      type: Boolean,
      default: false,
    },
    scrollTo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['accordion-item-toggle'],
  data() {
    return {
      accordionId: null,
      fallbackOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      headerHeight: 'layout/getHeaderHeight',
    }),
    isOpen() {
      if (!this.ignoreOthers && this.isAccordionOpen) {
        return this.isAccordionOpen(this.accordionId);
      }
      return this.fallbackOpen;
    },
  },
  created() {
    if (!this.generateAccordionId) {
      return;
    }
    this.accordionId = this.generateAccordionId();
  },
  methods: {
    toggleAccordion(duration = 500) {
      if (!this.ignoreOthers && this.toggleAccordionPanelById) {
        this.toggleAccordionPanelById(this.accordionId);
      } else {
        this.fallbackOpen = !this.fallbackOpen;
      }

      if (this.scrollTo && this.isOpen) {
        if (!this.ignoreOthers) {
          setTimeout(() => {
            this.scrollToItem(duration);
          }, 700);
        } else {
          this.scrollToItem(duration);
        }
      }

      this.$emit('accordion-item-toggle', this.isOpen);
    },
    scrollToItem(duration) {
      this.$smoothScroll({
        scrollTo: this.$refs.accordionItemHeader,
        duration: duration,
        updateHistory: false,
        offset: -this.headerHeight,
      });
    },
  },
};
</script>
