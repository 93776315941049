// copied from vuetify
import type { VNode } from 'vue';
import type { VNodeDirective } from 'vue/types/vnode';

type ObserveHandler = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
  isIntersecting: boolean,
) => void;

interface ObserveVNodeDirective extends Omit<VNodeDirective, 'modifiers'> {
  value?:
    | ObserveHandler
    | { handler: ObserveHandler; options?: IntersectionObserverInit; postpone?: Promise<any> };
  modifiers?: {
    once?: boolean;
    quiet?: boolean;
  };
}

async function inserted(el: HTMLElement, binding: ObserveVNodeDirective, vnode: VNode) {
  if (typeof window === 'undefined' || !('IntersectionObserver' in window)) return;

  const modifiers = binding.modifiers || {};
  const value = binding.value;
  const { handler, options, postpone } =
    typeof value === 'object' ? value : { handler: value, options: {}, postpone: null };
  if (postpone) {
    await postpone;
  }
  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[] = [], observer: IntersectionObserver) => {
      const _observe = el._observe?.[vnode.ctx!.uid];
      if (!_observe) return; // Just in case, should never fire

      const isIntersecting = entries.some((entry) => entry.isIntersecting);

      // If is not quiet or has already been
      // initted, invoke the user callback
      if (
        handler &&
        (!modifiers.quiet || _observe.init) &&
        (!modifiers.once || isIntersecting || _observe.init)
      ) {
        handler(entries, observer, isIntersecting);
      }

      if (isIntersecting && modifiers.once) unbind(el, binding, vnode);
      else _observe.init = true;
    },
    options,
  );

  el._observe = Object(el._observe);
  el._observe![vnode.ctx!.uid] = { init: false, observer };

  observer.observe(el);
}

function unbind(el: HTMLElement, _binding: ObserveVNodeDirective, vnode: VNode) {
  const observe = el._observe?.[vnode.ctx!.uid];
  if (!observe) return;

  observe.observer.unobserve(el);
  delete el._observe![vnode.ctx!._uid];
}

export const Intersect = {
  mounted: inserted,
  unmounted: unbind,
};

export default Intersect;
