<template>
  <span
    :class="{
      'locale-item-fi': true,
      'locale-item-fi--has-multi-languages': multiLanguages,
      'locale-item-fi--active': multiLanguages && locale.code === currentCode,
    }"
  >
    <component
      :is="multiLanguages && isNotCurrentLocale ? 'a' : 'span'"
      :href="href"
      class="locale-item-fi__name"
    >
      {{ locale.language }}
    </component>
  </span>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import type { Locale } from 'composables/countrySelect';
import { useFischer } from 'composables/fischerPlugin';
import { useSlugLink } from 'composables/slugLink';
import GET_CATEGORY_SLUG_BY_ID from 'gql/getCategorySlugById.gql';
import GET_PRODUCT_SLUG_BY_ID from 'gql/getProductSlugById.gql';
import { computed, ref, watchEffect } from 'vue';

import { useCookies } from '@/composables/cookies';
import loadLayoutData from '@/loadLayoutData';

const props = defineProps<{
  locale: Locale;
  currentCode: string;
  multiLanguages: boolean;
}>();

const { getSlugLink } = useSlugLink();
const { cookies } = useCookies();
const fischer = useFischer();

const toHref = async (slug: string | null, layoutData: null | Promise<any>) => {
  if (!slug) {
    return null;
  }

  try {
    const productsUrl = (await layoutData)?.sitecore.settings.site.productsPage.Url;
    return getSlugLink({ Slug: slug }, productsUrl, fischer);
  } catch (error) {
    console.error('Could not get item slug', error);
    return null;
  }
};

const apiLang = computed(() => {
  if (fischer.app.isFite() && props.locale.code === 'en') {
    return 'en_GB';
  }
  return props.locale.code.replace(/-/, '_');
});

const getLayoutDataPromise = () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return loadLayoutData(baseUrl, fischer.pageId(), props.locale.code, cookies);
};

const isCategory = fischer.isCategoryPage() || fischer.isFamilyPage();
const isProduct = fischer.isProductsPage();
const isNotCurrentLocale = computed<boolean>(() => props.locale.code !== props.currentCode);
const layoutData = computed(() =>
  isNotCurrentLocale.value && (isCategory || isProduct) ? getLayoutDataPromise() : null,
);

const href = ref<string | null>(null);

watchEffect(() => {
  if (layoutData.value === null) {
    href.value = props.locale.url;
  }
});

const { onResult: onResultProduct, onError: onErrorProduct } = useQuery(
  GET_PRODUCT_SLUG_BY_ID,
  {
    baseSiteId: fischer.baseSiteId(),
    productId: fischer.catalogId(),
    apiLang,
  },
  {
    enabled: computed(() => !IS_SSR && isProduct && layoutData.value !== null),
  },
);
onErrorProduct((error: any) => console.error('Could not get layout for country-selection', error));

onResultProduct(async (result) => {
  const slug = result.data?.getCatalogProductDataById?.Slug;
  href.value = await toHref(slug, layoutData.value);
});

const { onResult: onResultCategory, onError: onErrorCategory } = useQuery(
  GET_CATEGORY_SLUG_BY_ID,
  {
    baseSiteId: fischer.baseSiteId(),
    categoryId: fischer.catalogId(),
    apiLang,
  },
  {
    enabled: computed(() => !IS_SSR && isCategory && layoutData.value !== null),
  },
);

onResultCategory(async (result) => {
  const slug = result.data?.getCatalogCategoryDataById?.Slug;
  href.value = await toHref(slug, layoutData.value);
});

onErrorCategory((error: any) =>
  console.error('Could not get category slug for country selection', error),
);
</script>

<style lang="scss" scoped>
@import './locale-item-fi.scss';
</style>
