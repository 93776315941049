<template>
  <component-wrapper>
    <div
      class="header-media-fi"
      :class="{
        'header-media-fi--slider': fields.slideElements && fields.slideElements.length > 1,
        'header-media-fi--breadcrumb': fields.showBreadcrumb?.value,
      }"
      :slider-settings="sliderSettings"
    >
      <carousel-fi
        v-if="fields.slideElements && fields.slideElements.length > 1"
        ref="carousel"
        :settings="sliderSettings"
        theme="header-media"
        @before-change="beforeSlideChanged"
        @after-change="afterSlideChanged"
      >
        <carousel-slide-fi
          v-for="(slide, index) in filteredSlideElements"
          :key="'header-media-fi__slide-' + index"
        >
          <header-media-item-fi
            v-if="!slide.fields.textRight"
            :item="slide"
            :slide-changed="slideIsChanging"
            @pause-slider="pauseSlider"
          />
          <header-media-text-item-fi
            v-else
            :item="slide"
            :slide-changed="slideIsChanging"
            @pause-slider="pauseSlider"
          />
        </carousel-slide-fi>
      </carousel-fi>

      <template v-else>
        <header-media-item-fi
          v-if="!fields.slideElements?.[0].fields.textRight"
          :item="fields.slideElements?.[0]"
        />

        <header-media-text-item-fi
          v-else
          :item="fields.slideElements?.[0]"
        />
      </template>
    </div>
  </component-wrapper>
</template>

<script>
import { CarouselFi, CarouselSlideFi } from 'atoms';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper';
import slideFieldsToObject from 'components/header-fi/brand-area-fi/slideFieldsToObject';
import HeaderMediaItemFi from 'components/header-fi/header-media-fi/header-media-item-fi/HeaderMediaItemFi';
import HeaderMediaTextItemFi from 'components/header-fi/header-media-fi/header-media-text-item-fi/HeaderMediaTextItemFi';
import videoMixin from 'mixins/videoMixin';

export default {
  name: 'HeaderMediaFi',
  components: {
    ComponentWrapper,
    HeaderMediaItemFi,
    HeaderMediaTextItemFi,
    CarouselFi,
    CarouselSlideFi,
  },
  mixins: [videoMixin],
  inject: ['fields'],
  data() {
    return {
      maxSlideElements: 5,
      slideIsChanging: false,
    };
  },
  computed: {
    flyout() {
      return this.$store.getters['navigation/getFlyout'];
    },
    sliderSettings() {
      return {
        autoplay: Boolean(this.fields.autoPlay?.value),
        interval: 6400,
      };
    },
    filteredSlideElements() {
      // cut to max slides
      if (this.fields.slideElements) {
        return this.fields.slideElements.slice(0, this.maxSlideElements);
      }
      return {};
    },
  },
  watch: {
    flyout(value) {
      if (!this.sliderSettings.autoplay || !this.$refs.carousel) {
        return;
      }
      if (value) {
        this.$refs.carousel.pause();
      } else {
        this.$refs.carousel.play();
      }
    },
  },
  created() {
    const slideElements = this.fields.slideElements.map((element) => {
      const currentSlideElement = element.fields;
      return slideFieldsToObject(currentSlideElement);
    });

    this.$store.dispatch('navigation/setBrandAreaContent', slideElements);
  },
  methods: {
    beforeSlideChanged({ newSlideIndex }) {
      this.slideIsChanging = true;

      const currentSlideElement = this.fields.slideElements[newSlideIndex].fields;

      this.$store.commit('navigation/SET_BRANDAREA_INDEX', newSlideIndex);

      if (this.isVideo(currentSlideElement)) {
        this.$store.dispatch('navigation/showBrandArea', true);
      }
    },
    afterSlideChanged() {
      this.slideIsChanging = false;
    },
    pauseSlider(value) {
      if (!this.sliderSettings.autoplay || !this.$refs.carousel) {
        return;
      }

      if (value) {
        this.$refs.carousel.pause();
      } else {
        this.$refs.carousel.play();
      }
    },
  },
};
</script>

<style lang="scss">
@import './header-media-fi-unscoped';
</style>
