<template>
  <div
    v-if="hasBeenActive"
    :class="{
      'snackbar-fi': true,
      'snackbar-fi--active': isActive,
      'snackbar-fi--top-position': topPosition,
    }"
    data-test="snackbar"
    :data-test-2="isActive"
    @mouseenter="lockTransition"
    @mouseleave="setTimeoutForSnackbar(1000)"
  >
    <component
      :is="!isCartOrApp ? 'a' : 'div'"
      class="snackbar-fi__wrapper"
      :href="$fischer.pages.cart().url"
      rel="nofollow"
    >
      <grid-container-fi>
        <div class="fi-col-start-1 fi-col-end-13 lg:fi-col-start-2 lg:fi-col-end-12">
          <div class="snackbar-fi__content-wrapper">
            <div class="snackbar-fi__content">
              <div class="snackbar-fi__counter">
                <icon-fi
                  class="snackbar-fi__icon"
                  icon="shopping-cart"
                />
                <span class="snackbar-fi__counter-count">{{ countProducts }}</span>
              </div>

              <div
                v-if="$dict.get('online-Shop.Product.Added-to-Cart')"
                class="snackbar-fi__message"
              >
                {{ $dict.get('online-Shop.Product.Added-to-Cart') }}
              </div>
            </div>

            <div
              v-if="!isCartOrApp"
              class="snackbar-fi__controls"
            >
              <div
                class="snackbar-fi__link button-fi__text--tertiary button-fi__text--16 lg:button-fi__text--18"
              >
                <span class="button-fi__text fi-text-16 lg:fi-text-18">
                  <span class="button-fi__text-span">
                    {{ $dict.get('online-Shop.Product.View-Cart') }}
                  </span>
                  <arrow-icon />
                </span>
              </div>
            </div>
          </div>
        </div>
      </grid-container-fi>
    </component>
  </div>
</template>

<script>
import { ArrowIcon, IconFi } from 'atoms';
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import ShopEventBus from 'components/shop-modules/shop-event-bus';

export default {
  name: 'SnackbarFi',
  components: {
    ArrowIcon,
    IconFi,
    GridContainerFi,
  },
  props: {
    topPosition: {
      type: Boolean,
    },
  },
  data() {
    return {
      countProducts: 0,
      isActive: false,
      timeOut: null,
      hasBeenActive: false,
    };
  },
  computed: {
    isCartOrApp() {
      return this.isAppView || this.$fischer.uri() === this.$fischer.pages.cart().url;
    },
  },
  mounted() {
    ShopEventBus.$on('ProductAddToCart', async (value) => {
      if (!this.hasBeenActive) {
        this.hasBeenActive = true;
        await Promise.all([this.$nextTick(), new Promise((resolve) => setTimeout(resolve, 10))]);
      }
      if (value === true && !this.timeOut) {
        this.isActive = value;

        this.setTimeoutForSnackbar();
      } else {
        clearTimeout(this.timeOut);
        this.isActive = false;
        this.timeOut = null;
      }
    });

    ShopEventBus.$on('ShoppingcartCounter', (value) => {
      this.countProducts = value;
    });
  },
  methods: {
    lockTransition() {
      if (!this.isActive) {
        return;
      }

      clearTimeout(this.timeOut);
      this.timeOut = null;
    },
    setTimeoutForSnackbar(delay = 5000) {
      this.timeOut = setTimeout(() => {
        this.isActive = false;
        this.timeOut = null;
      }, delay);
    },
  },
};
</script>

<style lang="scss">
@import './snackbar-fi-unscoped';
</style>

<style scoped lang="scss">
@import './snackbar-fi';
</style>
