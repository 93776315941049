<template>
  <div
    :class="{
      'carousel-fi': true,
      'carousel-fi--no-pagination': !carouselSettings.pagination,
      'carousel-fi--destroyed': isDestroyed,
      'carousel-fi--clipped-right': clippedRight,
      'carousel-fi--ssr': !isMounted,
      [`carousel-fi--theme-${theme}`]: theme,
    }"
  >
    <splide
      ref="carousel"
      :options="carouselSettings"
      :has-track="false"
      @splide:mounted="onMounted"
      @splide:move="beforeChange"
      @splide:moved="afterChange"
      @splide:click="click"
      @splide:active="active"
      @splide:destroy="isDestroyed = true"
    >
      <splide-track class="carousel-fi__track">
        <slot />
      </splide-track>

      <div
        v-if="hasArrows && isActive"
        class="carousel-fi__arrows"
      >
        <div
          :class="{
            'carousel-fi__arrow-wrapper': true,
            'carousel-fi__arrow-wrapper--prev': true,
            'carousel-fi__arrow-wrapper--disabled': isPrevDisabled,
          }"
          @click="prev"
        >
          <slot name="prevArrow">
            <div class="carousel-fi__arrow">
              <arrow-icon />
            </div>
          </slot>
        </div>

        <div
          :class="{
            'carousel-fi__arrow-wrapper': true,
            'carousel-fi__arrow-wrapper--next': true,
            'carousel-fi__arrow-wrapper--disabled': isNextDisabled,
          }"
          @click="next"
        >
          <slot name="nextArrow">
            <div class="carousel-fi__arrow">
              <arrow-icon />
            </div>
          </slot>
        </div>
      </div>

      <slot name="footer" />

      <ul
        v-if="carouselSettings.pagination"
        class="carousel-fi__pagination splide__pagination"
      ></ul>
    </splide>
  </div>
</template>

<script>
import '@splidejs/vue-splide/css/core';

import { Splide, SplideTrack } from '@splidejs/vue-splide';
import ArrowIcon from 'atoms/arrow-icon/ArrowIcon';
import breakpointMixin from 'mixins/breakpointMixin';
import screenWidths from 'tailwind/screenWidths';

export default {
  name: 'CarouselFi',
  components: {
    Splide,
    SplideTrack,
    ArrowIcon,
  },
  mixins: [breakpointMixin],
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    clippedRight: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    noArrows: {
      type: Boolean,
      default: false,
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    /*
      Normally there is no need to pass the carousel data, but
      sometimes in synced mode (Thumbnail Slider) the carousels length is zero.
      (That means the real length is not detected)
    */
    items: {
      type: Array,
      default: () => [],
    },
    thumbnailCarouselRef: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'after-change',
    'before-change',
    'destroyed',
    'edge',
    'remounted',
    'slide-click',
    'swipe',
  ],
  data() {
    return {
      defaultSettings: {
        rewind: true,
        rewindSpeed: 1000,
        speed: 1000,
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: true,
        mediaQuery: 'min',
        lazyLoad: 'nearby',
      },
      isPrevDisabled: false,
      isNextDisabled: false,
      destroyed: false,
      instance: null,
      isMounted: false,
    };
  },
  computed: {
    isDestroyed: {
      get() {
        let state = this.destroyed;
        if (this.clippedRight) {
          state = this.isMD;
        }

        if (this.theme === 'retailer-slider') {
          state = !this.isSmDialog;
        }

        return state;
      },
      set(state) {
        this.destroyed = state;
      },
    },
    clippedRightSettings() {
      return {
        rewind: false,
        pagination: false,
        breakpoints: {
          [screenWidths.sm]: {
            perPage: 2,
          },
          [screenWidths.md]: {
            destroy: true,
          },
        },
      };
    },
    carouselSettings() {
      const defaultSettings = this.clippedRight
        ? { ...this.defaultSettings, ...this.clippedRightSettings }
        : this.defaultSettings;

      const carouselSettings = { ...defaultSettings, ...this.settings };
      if (carouselSettings?.rewind && carouselSettings.type !== 'fade') {
        carouselSettings.type = 'loop';
      }

      if (this.noPagination) {
        carouselSettings.pagination = false;
      }

      return carouselSettings;
    },
    itemsLength() {
      return this.items?.length;
    },
    isActive() {
      return (
        this.instance &&
        (this.instance.length || this.itemsLength) > this.getActiveOption('perPage')
      );
    },
    hasArrows() {
      return !(
        this.noArrows ||
        (this.settings && this.getActiveOption('arrows', this.settings) === false)
      );
    },
  },
  watch: {
    isDestroyed: {
      immediate: true,
      handler(state) {
        if (state) {
          this.$emit('destroyed');
        } else {
          this.$emit('remounted');
        }
      },
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    prev() {
      if (this.thumbnailCarouselRef) {
        this.thumbnailCarouselRef.prev();
        return;
      }

      this.instance.go('<');
    },
    next() {
      if (this.thumbnailCarouselRef) {
        this.thumbnailCarouselRef.next();
        return;
      }

      this.instance.go('>');
    },
    go(index) {
      this.instance?.go(index);
    },
    getIndex() {
      if (IS_SSR) {
        return undefined;
      }
      return this.instance?.splide?.index;
    },
    refresh() {
      this.instance.splide.refresh();
    },
    beforeChange(context, newIndex, prevIndex) {
      this.$emit('before-change', { oldSlideIndex: prevIndex, newSlideIndex: newIndex });
    },
    afterChange(context, newIndex, prevIndex) {
      this.$emit('after-change', { oldSlideIndex: prevIndex, newSlideIndex: newIndex });
    },
    click(context, slideContext) {
      this.$emit('slide-click', { index: slideContext.index, element: slideContext.slide });
    },
    active(context, { index }) {
      this.$emit('swipe', index);

      if (this.getActiveOption('rewind') || !this.instance) {
        return;
      }

      this.isNextDisabled = false;
      this.isPrevDisabled = false;

      if (index + this.getActiveOption('perPage') === (this.instance.length || this.itemsLength)) {
        this.isNextDisabled = true;
      } else if (index === 0) {
        this.isPrevDisabled = true;
      }

      if (this.isPrevDisabled || this.isNextDisabled) {
        this.$emit('edge', { left: this.isPrevDisabled, right: this.isNextDisabled });
      }
    },
    pause() {
      if (!this.instance) {
        return;
      }

      const { Autoplay } = this.instance.splide.Components;

      if (!Autoplay) {
        return;
      }

      Autoplay.pause();
    },
    play() {
      if (!this.instance) {
        return;
      }

      const { Autoplay } = this.instance.splide.Components;

      if (!Autoplay) {
        return;
      }

      Autoplay.play();
    },
    getActiveOption(name, settings) {
      if (!this.instance) {
        return null;
      }

      let setting = null;
      const options = settings ? settings : this.instance.options;

      setting = options[name];
      if (options.breakpoints) {
        Object.entries(options.breakpoints).forEach(([key, value]) => {
          if (this.windowWidth >= parseInt(key)) {
            if (typeof value[name] === 'undefined') {
              return;
            }

            setting = value[name];
          }
        });
      }

      return setting;
    },
    onMounted() {
      this.instance = this.$refs.carousel;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './carousel-fi.scss';
</style>

<style lang="scss">
@import './carousel-fi-unscoped.scss';
</style>
