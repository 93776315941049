<template>
  <render v-if="json" />
</template>

<script setup lang="ts">
import { h } from 'vue';

const props = defineProps<{
  json: string | null | undefined;
}>();

const render = () =>
  h('script', {
    innerHTML: props.json,
    type: 'application/ld+json',
  });
</script>
