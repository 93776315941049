import lowerCaseKeys from 'utils/lowerCaseKeys';

const layout = {
  namespaced: true,
  state: () => ({
    pageId: null,
    headerData: {},
    footerData: {},
    productDetailHeadMounted: false,
    sitecore: null,
    headerHeight: 0,
    windowDimensions: {
      width: 0,
      height: 0,
    },
    windowScrollPos: {
      x: 0,
      y: 0,
    },
  }),

  mutations: {
    SET_HEADER_DATA(state, headerData) {
      state.headerData = headerData;
    },
    SET_FOOTER_DATA(state, footerData) {
      state.footerData = footerData;
    },
    SET_PRODUCT_DETAIL_HEAD_MOUNTED(state, productDetailHeadMounted) {
      state.productDetailHeadMounted = productDetailHeadMounted;
    },
    SET_SITECORE(state, sitecore) {
      state.sitecore = sitecore;
    },
    SET_HEADER_HEIGHT(state, headerHeight) {
      state.headerHeight = headerHeight;
    },
    SET_WINDOW_DIMENSIONS(state, windowDimensions) {
      state.windowDimensions = windowDimensions;
    },
    SET_WINDOW_SCROLL_POS(state, windowScrollPos) {
      state.windowScrollPos = windowScrollPos;
    },
  },

  actions: {
    setLayoutData({ commit }, layoutData) {
      if (!layoutData?.sitecore) {
        console.error('Received no layout data');
        return;
      }

      commit('SET_SITECORE', layoutData.sitecore);
      commit('SET_FOOTER_DATA', lowerCaseKeys(layoutData.Footer, true));
      commit(
        'SET_HEADER_DATA',
        lowerCaseKeys(
          {
            breadcrumb: layoutData.Breadcrumb,
            logoBrandArea: layoutData.LogoBrandArea,
            logoHeader: layoutData.LogoHeader,
            navigation: layoutData.Navigation,
            serviceNavigation: layoutData.ServiceNavigation,
          },
          true,
        ),
      );
    },
    setProductDetailHeadMounted({ commit }, productDetailHeadMounted) {
      commit('SET_PRODUCT_DETAIL_HEAD_MOUNTED', productDetailHeadMounted);
    },
    setHeaderHeight({ commit }, headerHeight) {
      commit('SET_HEADER_HEIGHT', headerHeight);
    },
    setWindowDimensions({ commit }, windowDimensions) {
      commit('SET_WINDOW_DIMENSIONS', windowDimensions);
    },
    setWindowScrollPos({ commit }, windowScrollPos) {
      commit('SET_WINDOW_SCROLL_POS', windowScrollPos);
    },
  },

  getters: {
    getPageId: (state) => state.pageId,
    getFooterData: (state) => state.footerData,
    headerData: (state) => state.headerData,
    getProductDetailHeadMounted: (state) => state.productDetailHeadMounted,
    getHeaderHeight: (state) => state.headerHeight,
    getWindowDimensions: (state) => state.windowDimensions,
    getWindowScrollPos: (state) => state.windowScrollPos,
    sitecore: (state) => state.sitecore,
  },
};

export default layout;
