<template>
  <div
    v-if="cartPositionsCount"
    class="header-cart-fi"
  >
    {{ cartPositionsCount }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderCartFi',
  computed: {
    ...mapGetters({
      cartPositionsCount: 'cart/positionsCount',
    }),
  },
  created() {
    this.$store.dispatch('cart/initialize', {
      apollo: this.$apollo,
      fischer: this.$fischer,
    });
  },
};
</script>

<style scoped lang="scss">
@import './header-cart-fi';
</style>
