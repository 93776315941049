<template>
  <component-wrapper v-if="!isAppView">
    <footer
      :class="{
        'footer-fi': true,
        'footer-fi--logo-large': largeLogo && !isUpat,
        'footer-fi--upat': isUpat,
      }"
    >
      <grid-container-fi
        class="footer-fi__links"
        grid-class="fi-gap-y-zero"
      >
        <div
          v-for="(item, index) in filteredFooterLinks"
          :key="`footer-link-${index}`"
          :class="[
            'footer-fi__links--container fi-col-span-full',
            index === 0 ? 'footer-fi__links--first' : null,
            index !== 3 ? 'md:fi-col-span-3' : 'footer-fi__links--last md:fi-col-span-3',
          ]"
        >
          <ul class="footer-fi__links__list">
            <li>
              <div
                v-if="item.title || item.displayName"
                class="footer-fi__links__list--header"
                @click="setTabActive(index)"
              >
                <span class="footer-fi__links__list--groupname fi-text-size-df-18">
                  {{ item.title || item.displayName }}
                </span>
                <div
                  v-if="!isMD"
                  class="footer-fi__links--toggle"
                  :class="activeTabs[index] ? 'active' : null"
                >
                  <icon-fi :icon="'chevron-down'" />
                </div>
              </div>

              <component
                :is="itemComponent"
                :model-value="isMD ? null : activeTabs[index]"
                :duration="isMD ? null : 700"
              >
                <ul
                  v-if="item.elements?.length"
                  class="footer-fi__links__list--grouplist"
                >
                  <template v-for="(subitem, subindex) in item.elements">
                    <li
                      v-if="
                        !isTelphoneLink(subitem) &&
                        subitem.link &&
                        (subitem.link.url || subitem.link.href) &&
                        (subitem.link.text || subitem.displayName)
                      "
                      :key="`footer-sublink-${subindex}`"
                      class="footer-fi__links__list--grouplist--item"
                    >
                      <a
                        :href="subitem.link.url || subitem.link.href"
                        :target="subitem.link.target"
                        :rel="
                          subitem.link.url && subitem.link.target === '_blank'
                            ? 'noopener noreferrer'
                            : undefined
                        "
                        :tabindex="activeTabs[index] ? undefined : '-1'"
                        class="footer-fi--link"
                      >
                        {{ subitem.link.text ? subitem.link.text : subitem.displayName }}
                      </a>
                    </li>
                  </template>

                  <template v-for="(subitem, subindex) in getPhoneLinks(item.elements)">
                    <li
                      v-if="subindex === 0"
                      :key="`footer-sublink-heading-${subindex}`"
                      class="footer-fi__phone-links-heading"
                    >
                      <p class="fi-font-semibold">
                        {{ $dict.get('footer-Links.Telephone.heading') }}
                      </p>
                    </li>
                    <li
                      v-if="
                        subitem.link &&
                        (subitem.link.url || subitem.link.href) &&
                        (subitem.link.text || subitem.displayName)
                      "
                      :key="`footer-sublink-telephone-${subindex}`"
                      class="footer-fi__links__list--grouplist--item"
                    >
                      <div class="footer-fi__phone-links-container">
                        <div class="footer-fi__phone-links-icon">
                          <icon-fi icon="ic-phone-big" />
                        </div>
                        <div class="footer-fi__phone-links-text-container">
                          <div
                            v-if="subitem.link.title"
                            class="footer-fi__links__list--groupname footer-fi__phone-links-title"
                          >
                            {{ subitem.link.title }}:
                          </div>

                          <div class="footer-fi__phone-link-telphone-links">
                            <a
                              :href="getTelHref(subitem.link.url || subitem.link.href)"
                              :tabindex="activeTabs[index] ? undefined : '-1'"
                              class="footer-fi--link"
                            >
                              {{ subitem.link.text ? subitem.link.text : subitem.displayName }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </template>
                </ul>
              </component>
            </li>
          </ul>
          <div
            v-if="index === 0 && hasFooterLogo"
            class="footer-fi__logo footer-fi__logo--md"
          >
            <img
              :src="logoSrc"
              :alt="footerData?.logo.alt"
              :title="footerData?.logo.title"
              :width="footerLogoWidth || undefined"
              :height="footerLogoHeight"
              class="fischer-brand-logo"
            />
          </div>
        </div>

        <grid-container-fi
          v-if="hasFooterLogo || socialMediaLinks"
          class="footer-fi__row"
          :container="false"
        >
          <div
            v-if="hasFooterLogo"
            class="footer-fi__logo footer-fi__logo--mobile"
          >
            <img
              :src="logoSrc"
              :alt="footerData?.logo.alt"
              :title="footerData?.logo.title"
              :width="footerLogoWidth || undefined"
              :height="footerLogoHeight"
              class="fischer-brand-logo"
            />
          </div>
          <div
            v-if="socialMediaLinks"
            class="footer-fi__socials"
          >
            <span
              v-if="fields?.socialLabel"
              class="footer-fi__socials--label"
            >
              {{ fields.socialLabel.value }}
            </span>
            <ul
              v-if="socialMediaLinks"
              class="footer-fi__socials--list"
            >
              <template v-for="social in socialMediaLinks">
                <li
                  v-if="social.link?.url && social.logo?.src"
                  :key="social.id"
                >
                  <a
                    :href="social.link.url"
                    :target="social.link.target"
                    :rel="social.link.target === '_blank' ? 'noopener noreferrer' : undefined"
                    :aria-label="social.displayName"
                    class="footer-fi--link"
                  >
                    <cloud-image-fi
                      :src="getSrc(social.logo.src)"
                      params="func=bound"
                      :alt="social.displayName"
                      height="24"
                      width="24"
                    />
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </grid-container-fi>
      </grid-container-fi>

      <div class="footer-fi__legals">
        <grid-container-fi>
          <div
            v-if="footerData?.legalLinks?.elements"
            class="fi-col-span-full"
          >
            <ul class="footer-fi__legals--links">
              <li>
                {{ footerData.legalLinks.title }}
              </li>
              <template v-for="item in footerData.legalLinks.elements">
                <li
                  v-if="item?.link?.url && getLinkText(item)"
                  :key="item.id"
                >
                  <a
                    :href="item.link.url"
                    class="footer-fi--link"
                  >
                    {{ getLinkText(item) }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </grid-container-fi>
      </div>
    </footer>
  </component-wrapper>
</template>

<script setup lang="ts">
import { CloudImageFi, IconFi, SlideUpDownFi } from 'atoms/index';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import { useBreakpoints } from 'composables/breakpoint';
import { useCloudImage } from 'composables/cloudImage';
import { useFischer } from 'composables/fischerPlugin';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import { getTelHref } from '@/composables/linkHelper';
import type { ImageFieldValue, LinkFieldValue } from '@/sitecoreFieldTypes';

const fischer = useFischer();
const { appendParams, getProxySrc } = useCloudImage();
const { isMD } = useBreakpoints();
const store = useStore();

const props = withDefaults(
  defineProps<{
    fields: {
      socialLabel?: {
        title?: string;
        displayName?: string;
        value?: string;
      };
    };
    customData?: {
      footer?: FooterData;
    };
  }>(),
  {
    fields: () => ({}),
    customData: undefined,
  },
);

interface SitecoreLinkElement {
  id: string;
  logo?: ImageFieldValue;
  link?: LinkFieldValue;
  displayName?: string;
  title: string;
  icon?: string;
  elements?: Array<SitecoreLinkElement>;
}

interface SitecoreLinks {
  elements?: Array<SitecoreLinkElement>;
  title?: string;
}

interface FooterData {
  footer: object;
  footerLinks?: SitecoreLinks;
  logo: ImageFieldValue;
  socialMediaLinks?: SitecoreLinks;
  legalLinks?: SitecoreLinks;
}

const activeTabs = ref<Array<boolean>>([]);

const footerData = computed<FooterData | undefined>(() => {
  if (IS_SSR && props.customData?.footer) {
    return props.customData.footer;
  } else {
    return store.getters['layout/getFooterData'];
  }
});

const socialMediaLinks = computed<Array<SitecoreLinkElement> | undefined>(
  () => footerData.value?.socialMediaLinks?.elements,
);

const itemComponent = computed(() => (isMD.value ? 'div' : SlideUpDownFi));

const largeLogo = computed<boolean>(() => {
  return fischer.largeLogo() || false;
});

const filteredFooterLinks = computed<Array<SitecoreLinkElement>>(() => {
  const elements = footerData.value?.footerLinks?.elements;
  return elements ? elements.slice(0, 4) : [];
});

const hasFooterLogo = computed<boolean>(() => Boolean(footerData.value?.logo?.src));

const getSrc = (src: string): string => {
  if (!IS_SHOWROOM) {
    return src;
  }
  return `https://www.fischer.de/de-de${src}`;
};

const footerLogoHeight = computed<number>(() => {
  if (fischer.app.isUpat()) {
    return 44;
  }

  return largeLogo.value ? 36 : 30;
});

const logoSrc = computed<string>(() =>
  appendParams(
    getProxySrc(getSrc(footerData.value?.logo?.src || '')) || '',
    `h=${footerLogoHeight.value}&func=bound`,
  ),
);

const footerLogoWidth = computed<number | null>(() => {
  const logo = footerData.value?.logo;
  if (logo?.width && logo.height) {
    return Math.round(
      (Number.parseFloat(logo.width) / Number.parseFloat(logo.height)) * footerLogoHeight.value,
    );
  }
  return null;
});

const isTelphoneLink = (item: SitecoreLinkElement): boolean | undefined =>
  item.link?.url?.includes('tel:') || item.link?.href?.includes('tel:');

const getLinkText = (item: SitecoreLinkElement): string | undefined =>
  item.link?.text || item.displayName;

const setTabActive = (index: number): void => {
  const wasActive = activeTabs.value[index];
  activeTabs.value = new Array(4).fill(false);
  activeTabs.value[index] = !wasActive;
};
const getPhoneLinks = (items: SitecoreLinkElement[]) => {
  return items.filter(isTelphoneLink);
};
</script>

<style lang="scss" scoped>
@import './footer-fi';
</style>
