const notifications = {
  namespaced: true,
  state: () => ({
    toastNotifications: [],
  }),

  mutations: {
    SETTOASTNOTIFICATIONS(state, toastNotifications) {
      state.toastNotifications = toastNotifications;
    },
  },

  actions: {
    openToastNotification({ commit, state }, notification) {
      const newNotifications = [...state.toastNotifications];
      const newNotification = { ...notification };

      if (!newNotification.name || newNotification.name === '') {
        const randomId = Math.floor(Math.random() * 100);
        newNotification.name = `toast-notification--${randomId}`;
      }
      newNotifications.push(newNotification);

      commit('SETTOASTNOTIFICATIONS', newNotifications);
    },
    openGeneralErrorNotification({ commit, state }, { vm, dict }) {
      const newNotifications = [...state.toastNotifications];
      const newNotification = {
        name: `general-error-notification--${newNotifications.length}`,
        message: {
          value: vm
            ? vm.$dict.get('online-Shop.Global-Labels.Error-Notification')
            : dict.get('online-Shop.Global-Labels.Error-Notification'),
        },
        type: 'error',
        icon: 'alert-triangle',
        persist: true,
      };
      newNotifications.push(newNotification);

      commit('SETTOASTNOTIFICATIONS', newNotifications);
    },
    closeToastNotification({ commit, state }, name) {
      const newNotifications = [...state.toastNotifications];
      const obj = newNotifications.find((x) => x.name === name);
      const index = newNotifications.indexOf(obj);

      newNotifications.splice(index, 1);

      commit('SETTOASTNOTIFICATIONS', newNotifications);
    },
    clearToastNotifications({ commit }) {
      const newNotifications = [];
      commit('SETTOASTNOTIFICATIONS', newNotifications);
    },
  },

  getters: {
    getToastNotifications: (state) => state.toastNotifications,
  },
};

export default notifications;
