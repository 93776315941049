<template>
  <component-wrapper>
    <grid-container-fi
      :class="{
        'text-media-fi': true,
        'text-media-fi--dark': isDark,
      }"
    >
      <div class="fi-col-start-1 fi-col-end-13 md:fi-col-start-2 md:fi-col-end-12">
        <div
          :class="{
            'text-media-fi__container': true,
            'text-media-fi__container--reverse': Boolean(fields.imageLeft?.value),
          }"
        >
          <div class="text-media-fi__text">
            <richtext-fi
              :html-content="fields.text"
              :is-bg-dark="isDark"
            />
          </div>
          <div class="text-media-fi__image">
            <div
              v-if="isEditMode"
              class="text-media-fi__editable"
            >
              <div
                v-if="isVideo(fields)"
                class="video-player-fi__overlay"
              >
                <div class="video-player-fi__play-button">
                  <icon-fi icon="play" />
                </div>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="fi-mb-9"
                v-html="fields.image?.editable"
              />
              <!-- eslint-enable vue/no-v-html -->
            </div>
            <template v-else>
              <video-player-fi
                v-if="isVideo(fields)"
                :fields="fields"
              />

              <ssr-cloud-image-fi
                v-else
                :field="fields.image"
                :breakpoints="breakpoints"
                :alt="fields.imageAlt?.value"
                :title="fields.imageTitle?.value"
                unlimited="743"
              />
            </template>

            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="isEditMode && fields.imageCaption"
              class="text-media-fi__caption"
              v-html="fields.imageCaption.editable"
            />
            <div
              v-if="!isEditMode && fields.imageCaption?.value"
              ref="caption"
              class="text-media-fi__caption"
              v-html="fields.imageCaption.value"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </grid-container-fi>
  </component-wrapper>
</template>

<script setup lang="ts">
import { IconFi, RichtextFi, SsrCloudImageFi } from 'atoms/index';
import type { Breakpoint } from 'atoms/ssr-cloud-image-fi/SsrCloudImageFi.vue';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import VideoPlayerFi from 'components/video-player-fi/VideoPlayerFi.vue';
import { type MarkSsrOnly, useSsrOnly } from 'composables/ssrOnly';
import { useVideo, type VideoFields } from 'composables/video';
import { computed, onMounted, ref } from 'vue';

import type { CheckboxField, ImageField, SingleLineTextField } from '@/sitecoreFieldTypes';
import smoothScroll from '@/utils/smoothScroll';

const { isVideo } = useVideo();
const onCreatedMarkAsSsrOnly = (originalOnCreatedMarkAsSsrOnly: () => void) => {
  if (IS_SSR && !isVideo(props.fields)) {
    originalOnCreatedMarkAsSsrOnly();
  }
};

const props = withDefaults(
  defineProps<{
    markSsrOnly?: MarkSsrOnly;
    fields: {
      image?: ImageField;
      imageCaption?: SingleLineTextField;
      imageTitle?: SingleLineTextField;
      imageAlt?: SingleLineTextField;
      imageLeft?: CheckboxField;
      dark?: CheckboxField;
      text?: SingleLineTextField;
    } & VideoFields;
  }>(),
  {
    fields: () => ({}),
    markSsrOnly: undefined,
  },
);

useSsrOnly(props, onCreatedMarkAsSsrOnly);

const breakpoints: Array<Breakpoint> = [
  {
    mediaWidth: 480,
    imageWidth: 444,
  },
  {
    mediaWidth: 768,
    imageWidth: 708,
  },
  {
    mediaWidth: 1023,
    imageWidth: 963,
  },
  {
    mediaWidth: 1232,
    imageWidth: 456,
  },
  {
    mediaWidth: 1440,
    imageWidth: 543,
  },
  {
    mediaWidth: 1680,
    imageWidth: 560,
  },
];

const caption = ref<HTMLDivElement | null>(null);

const isDark = computed(() => {
  return props.fields.dark?.value;
});

onMounted(() => {
  if (caption.value) {
    // @ts-ignore
    smoothScroll(caption.value);
  }
});
</script>

<style lang="scss">
@import './text-media-fi';
</style>
