<script>
import ssrCookieMixin from 'mixins/ssrCookieMixin';

import {
  clearHandlingFeeLimitCookieFunction,
  getHandlingFeeLimitCookieFunction,
  handlingFeeLimitCookieIdFunction,
  setHandlingFeeLimitCookieFunction,
} from './handlingFeeCookie';

export { cookieName } from './handlingFeeCookie';

function clearHandlingFeeLimitCookie() {
  clearHandlingFeeLimitCookieFunction(this.$cookies);
}

export default {
  mixins: [ssrCookieMixin],
  computed: {
    handlingFeeLimitCookieId() {
      return handlingFeeLimitCookieIdFunction(this.$fischer);
    },
  },
  methods: {
    getHandlingFeeLimitCookie() {
      return getHandlingFeeLimitCookieFunction(
        this.getCookie,
        this.$fischer,
        this.handlingFeeLimitCookieId,
      );
    },
    setHandlingFeeLimitCookie(handlingFeeLimitFormatted) {
      setHandlingFeeLimitCookieFunction(
        handlingFeeLimitFormatted,
        this.handlingFeeLimitCookieId,
        this.$cookies,
      );
    },
  },
};

export { clearHandlingFeeLimitCookie };
</script>
