<script>
import { computeThemeName, computeWrapperClasses } from '@/composables/componentWrapper';

export default {
  props: {
    forceTheme: {
      type: String,
      default: null,
    },
  },
  computed: {
    wrapperClasses() {
      return computeWrapperClasses(this.themeName, this.isAppView);
    },
    themeName() {
      return computeThemeName(this.$fischer, this.forceTheme);
    },
  },
};
</script>
