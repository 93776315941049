<template>
  <grid-container-fi
    v-if="requestHandlingFeeBanner"
    class="handling-fee-limit-banner-fi"
    grid-class="fi-h-full"
    :container="false"
  >
    <div class="handling-fee-limit-banner-fi__content fi-col-span-12">
      <span class="handling-fee-limit-banner-fi__text">
        {{ $dict.get('handling-Fee-Banner.Banner', [handlingFeeLimit]) }}
      </span>
      <icon-fi
        class="handling-fee-limit-banner-fi__close"
        icon="close"
        @click="close"
      />
    </div>
  </grid-container-fi>
</template>

<script>
import { IconFi } from 'atoms';
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import { mapGetters } from 'vuex';

import handlingFeeCookieMixin from './handlingFeeCookieMixin';

export default {
  name: 'HandlingFeeLimitBannerFi',
  components: {
    GridContainerFi,
    IconFi,
  },
  mixins: [handlingFeeCookieMixin],
  props: {
    handlingFeeLimit: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      requestHandlingFeeBanner: 'navigation/requestHandlingFeeBanner',
    }),
  },
  methods: {
    close() {
      this.setHandlingFeeLimitCookie('-1');
      this.$store.commit('navigation/ENABLE_HANDLING_FEE_BANNER', false);
    },
  },
};
</script>

<style scoped lang="scss">
@import './handling-fee-limit-banner-fi';
</style>
