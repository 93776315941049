import GET_CART_POSITIONS_COUNT from 'gql/getCartPositionsCount.gql';
import { getIsIds } from 'utils/idsTools';

const storageKey = 'CartPositionsCount';

const setupLocalStorageEventListener = (commit) => {
  if (IS_SSR) {
    return;
  }
  try {
    window.addEventListener('storage', ({ key, newValue }) => {
      if (key === storageKey) {
        commit('SET_POSITIONS_COUNT', newValue);
      }
    });
  } catch (error) {
    // largely these can be ignored, most likely private tab
    if (!IS_SHOWROOM) {
      console.error(error);
    }
  }
};

const notifyLocalStorage = (state) => {
  if (IS_SSR) {
    return;
  }
  try {
    const oldValue = Number(window.localStorage.getItem(storageKey));
    if (oldValue !== state.positionsCount) {
      window.localStorage.setItem(storageKey, state.positionsCount);
    }
  } catch (error) {
    // largely these can be ignored, most likely private tab
    if (!IS_SHOWROOM) {
      console.error(error);
    }
  }
};

const cart = {
  namespaced: true,
  state: () => ({
    positionsCount: null,
    apollo: null,
    fischer: null,
    lastModifiedItems: [],
    csvImport: [],
    invalidToken: false,
  }),

  mutations: {
    SET_POSITIONS_COUNT(state, positionsCount) {
      state.positionsCount = positionsCount;
      notifyLocalStorage(state);
    },
    SET_APOLLO(state, apollo) {
      state.apollo = apollo;
    },
    SET_FISCHER(state, fischer) {
      state.fischer = fischer;
    },
    SET_LAST_MODIFIED_ITEMS(state, lastModifiedItems) {
      state.lastModifiedItems = lastModifiedItems;
    },
    SET_CSV_IMPORT(state, csvImport) {
      state.csvImport = csvImport;
    },
    SET_TOKEN_INVALID(state, tokenValid) {
      state.invalidToken = tokenValid;
    },
  },

  actions: {
    async initialize({ commit, getters, dispatch }, { apollo, fischer }) {
      if (getters.apollo) {
        // already initialized, skip
        return;
      }
      commit('SET_APOLLO', apollo);
      commit('SET_FISCHER', fischer);

      setupLocalStorageEventListener(commit);

      await dispatch('refetchPositionsCount');
    },

    async refetchPositionsCount({ commit, state }) {
      if (IS_SSR) {
        return;
      }

      const apollo = state.apollo;
      if (!apollo) {
        console.error('No apollo to fetch cart positions count!');
      }
      const $fischer = state.fischer;

      if (!$fischer.shop.supportsCart()) {
        return;
      }

      const userId = $fischer.user.id();
      const cartId = $fischer.cartId();
      const accessToken = $fischer.user.accessToken();
      if (!cartId && (userId === 'anonymous' || !accessToken)) {
        return;
      }
      if (cartId && userId === 'anonymous' && !cartId.includes('-')) {
        return;
      }

      const assistedService = $fischer.user.getAssistedService();
      if (assistedService && !assistedService.user) {
        return;
      }
      try {
        const {
          data: { getCartPositionsCount },
        } = await apollo.query({
          query: GET_CART_POSITIONS_COUNT,
          variables: {
            baseSiteId: $fischer.baseSiteId(),
            accessToken,
            cartId,
            isIds: getIsIds(),
            userId,
          },
          fetchPolicy: 'no-cache',
        });
        commit('SET_POSITIONS_COUNT', getCartPositionsCount);
      } catch (error) {
        if (error.message === 'GraphQL error: Invalid token') {
          commit('SET_TOKEN_INVALID', true);
        }
        console.error('Error fetching cart positions count', error);
      }
    },
    setPositionsCount({ commit }, amount) {
      commit('SET_POSITIONS_COUNT', amount);
    },
    setCsvImport({ commit }, csvImport) {
      commit('SET_CSV_IMPORT', csvImport);
    },
    setLastModifiedItems({ commit }, lastModifiedItems) {
      commit('SET_LAST_MODIFIED_ITEMS', lastModifiedItems);
    },
  },

  getters: {
    positionsCount: (state) => state.positionsCount,
    lastModifiedItems: (state) => state.lastModifiedItems,
    csvImport: (state) => state.csvImport,
    invalidToken: (state) => state.invalidToken,
  },
};

export default cart;
