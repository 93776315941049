<template>
  <div class="navigation-products-item-fi">
    <cloud-image-fi
      v-if="item.thumbnailUrl?.Url"
      :src="item.thumbnailUrl.Url"
      :width="imageSize"
      :height="imageSize"
      params="func=fit"
      trim
      class="navigation-products-item-fi__image"
      alt="teaser image"
      do-not-replace-url
    />
    {{ item.CategoryName }}
  </div>
</template>

<script>
import { CloudImageFi } from 'atoms';
import breakpointMixin from 'mixins/breakpointMixin';

export default {
  name: 'NavigationProductsItemFi',
  components: {
    CloudImageFi,
  },
  mixins: [breakpointMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageSize() {
      if (this.isMD) {
        return '60';
      }

      if (this.isXL) {
        return '72';
      }

      return '54';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './navigation-products-item-fi';
</style>
