<template>
  <!-- eslint-disable vue/no-parsing-error -->
  <label
    v-if="label || mandatory || htmlLabel"
    :class="{
      'label-fi': true,
      preferNonApp: true,
      'label-fi--80': limit80,
      'label-fi--no-ellipsis': noEllipsis,
    }"
    :for="for"
  >
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="htmlLabel"
      v-html="htmlLabel"
    />
    <!-- eslint-enable vue/no-v-html -->
    <template v-else>
      {{ label }}
    </template>
    <span
      v-if="mandatory"
      class="label-fi__mandatory"
      >*</span
    >
  </label>
</template>

<script>
export default {
  name: 'LabelFi',
  props: {
    label: {
      type: String,
      default: null,
    },
    htmlLabel: {
      type: String,
      default: null,
    },
    mandatory: {
      type: [Boolean, String],
      default: false,
    },
    for: {
      type: String,
      default: null,
    },
    limit80: {
      type: [Boolean, String],
      default: false,
    },
    noEllipsis: Boolean,
  },
};
</script>

<style lang="scss">
@import './label-fi-unscoped';
</style>

<style scoped lang="scss">
@import './label-fi';
</style>
