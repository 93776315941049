import { inject } from 'vue';

import type { SitecoreDictionary } from '@/lib/dictPlugin';
import type { FischerPlugin } from '@/lib/fischerPlugin';

export const useFischer = (): FischerPlugin => {
  const fischer: undefined | FischerPlugin = inject('fischerPlugin');
  if (!fischer) {
    throw new Error('missing fischer plugin');
  }
  return fischer;
};

export const useDict = (): SitecoreDictionary => {
  const dict: undefined | SitecoreDictionary = inject('dictPlugin');
  if (!dict) {
    throw new Error('missing dict plugin');
  }
  return dict;
};
