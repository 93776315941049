const layout = {
  namespaced: true,
  state: () => ({
    projectType: null,
    pageType: 'overview',
    siteId: null,
    areaId: null,
    roomId: null,
    gatewayId: null,
    anchorId: null,
    discId: null,
    documentId: null,
    documentType: null,
    currentSiteDetails: null,
    isArchive: false,
    isTransferProject: false,
    isAuditLog: false,
    isReports: false,
  }),

  mutations: {
    SET_PROJECTTYPE(state, projectType) {
      state.projectType = projectType;
    },
    SET_PAGETYPE(state, pageType) {
      state.pageType = pageType;
    },
    SET_SITEID(state, siteId) {
      state.siteId = siteId;
    },
    SET_AREAID(state, areaId) {
      state.areaId = areaId;
    },
    SET_ROOMID(state, roomId) {
      state.roomId = roomId;
    },
    SET_GATEWAYID(state, gatewayId) {
      state.gatewayId = gatewayId;
    },
    SET_ANCHORID(state, anchorId) {
      state.anchorId = anchorId;
    },
    SET_DISCID(state, discId) {
      state.discId = discId;
    },
    SET_DOCUMENTID(state, documentId) {
      state.documentId = documentId;
    },
    SET_DOCUMENTTYPE(state, documentType) {
      state.documentType = documentType;
    },
    SET_CURRENT_SITE_DETAILS(state, currentSiteDetails) {
      state.currentSiteDetails = currentSiteDetails;
    },
    SET_IS_ARCHIVE(state, isArchive) {
      state.isArchive = isArchive;
    },
    SET_IS_TRANSFER_PROJECT(state, isTransferProject) {
      state.isTransferProject = isTransferProject;
    },
    SET_IS_AUDIT_LOG(state, isAuditLog) {
      state.isAuditLog = isAuditLog;
    },
    SET_IS_REPORTS(state, isReports) {
      state.isReports = isReports;
    },
  },

  actions: {
    setProjectType({ commit }, projectType) {
      commit('SET_PROJECTTYPE', projectType);
    },
    setPageType({ commit }, pageType) {
      commit('SET_PAGETYPE', pageType);
    },
    setSiteId({ commit }, siteId) {
      commit('SET_SITEID', siteId);
    },
    setAreaId({ commit }, areaId) {
      commit('SET_AREAID', areaId);
    },
    setRoomId({ commit }, roomId) {
      commit('SET_ROOMID', roomId);
    },
    setGatewayId({ commit }, gatewayId) {
      commit('SET_GATEWAYID', gatewayId);
    },
    setAnchorId({ commit }, anchorId) {
      commit('SET_ANCHORID', anchorId);
    },
    setDiscId({ commit }, discId) {
      commit('SET_DISCID', discId);
    },
    setDocumentId({ commit }, documentId) {
      commit('SET_DOCUMENTID', documentId);
    },
    setDocumentType({ commit }, documentType) {
      commit('SET_DOCUMENTTYPE', documentType);
    },
    setCurrentSiteDetails({ commit }, currentSiteDetails) {
      commit('SET_CURRENT_SITE_DETAILS', currentSiteDetails);
    },
    setIsArchive({ commit }, isArchive) {
      commit('SET_IS_ARCHIVE', isArchive);
    },
    setIsTransferProject({ commit }, isTransferProject) {
      commit('SET_IS_TRANSFER_PROJECT', isTransferProject);
    },
    setIsAuditLog({ commit }, isAuditLog) {
      commit('SET_IS_AUDIT_LOG', isAuditLog);
    },
    setIsReports({ commit }, isReports) {
      commit('SET_IS_REPORTS', isReports);
    },
    goToOverview({ commit }) {
      if (!IS_SSR) {
        window.history.pushState('', '', '?');
      }
      commit('SET_PAGETYPE', 'overview');
    },
    goToArchive({ commit }) {
      if (!IS_SSR) {
        window.history.pushState('', '', '?archive=true');
      }

      commit('SET_PAGETYPE', 'archive');
    },
    goToTransferProject({ commit }, siteId) {
      if (!IS_SSR) {
        window.history.pushState('', '', `?siteId=${encodeURIComponent(siteId)}&transfer=true`);
      }
      commit('SET_PAGETYPE', 'transfer');
    },
    goToAuditLog({ commit, state }, { siteId, areaId, roomId, documentId }) {
      if (IS_SSR) {
        return;
      }

      if (documentId) {
        if (state.roomId) {
          window.history.pushState(
            '',
            '',
            `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
              state.areaId,
            )}&roomId=${encodeURIComponent(state.roomId)}&documentId=${encodeURIComponent(
              documentId,
            )}&auditlog=true`,
          );
        } else {
          window.history.pushState(
            '',
            '',
            `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
              state.areaId,
            )}&documentId=${encodeURIComponent(documentId)}&auditlog=true`,
          );
        }
        commit('SET_DOCUMENTID', documentId);
      } else if (roomId) {
        window.history.pushState(
          '',
          '',
          `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
            state.areaId,
          )}&roomId=${encodeURIComponent(roomId)}&auditlog=true`,
        );
        commit('SET_ROOMID', roomId);
        commit('SET_DOCUMENTID', null);
      } else if (areaId) {
        window.history.pushState(
          '',
          '',
          `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
            areaId,
          )}&auditlog=true`,
        );
        commit('SET_AREAID', areaId);
        commit('SET_ROOMID', null);
        commit('SET_DOCUMENTID', null);
      } else if (siteId) {
        window.history.pushState('', '', `?siteId=${encodeURIComponent(siteId)}&auditlog=true`);
        commit('SET_SITEID', siteId);
        commit('SET_AREAID', null);
        commit('SET_ROOMID', null);
        commit('SET_DOCUMENTID', null);
      } else {
        window.history.pushState('', '', '?auditlog=true');
        commit('SET_SITEID', null);
        commit('SET_AREAID', null);
        commit('SET_ROOMID', null);
        commit('SET_DOCUMENTID', null);
      }

      commit('SET_PAGETYPE', 'auditLog');
    },
    goToReports({ commit }, siteId) {
      if (!IS_SSR) {
        window.history.pushState('', '', `?siteId=${encodeURIComponent(siteId)}&reports=true`);
      }
      commit('SET_PAGETYPE', 'reports');
      commit('SET_SITEID', siteId);
    },
    goToSite({ commit }, siteId) {
      if (!IS_SSR) {
        window.history.pushState('', '', `?siteId=${encodeURIComponent(siteId)}`);
      }
      commit('SET_PAGETYPE', 'site');
      commit('SET_SITEID', siteId);
      commit('SET_AREAID', null);
      commit('SET_ROOMID', null);
    },
    goToArea({ commit, state }, areaId) {
      if (!IS_SSR) {
        window.history.pushState(
          '',
          '',
          `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(areaId)}`,
        );
      }
      commit('SET_PAGETYPE', 'area');
      commit('SET_AREAID', areaId);
      commit('SET_ROOMID', null);
    },
    goToSensorGateway({ commit, state }, gatewayId) {
      if (!IS_SSR) {
        window.history.pushState(
          '',
          '',
          `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
            state.areaId,
          )}&gatewayId=${encodeURIComponent(gatewayId)}`,
        );
      }
      commit('SET_PAGETYPE', 'sensor-gateway');
      commit('SET_GATEWAYID', gatewayId);
    },
    goToSensorAnchor({ commit, state }, anchorId) {
      if (!IS_SSR) {
        window.history.pushState(
          '',
          '',
          `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
            state.areaId,
          )}&gatewayId=${encodeURIComponent(state.gatewayId)}&anchorId=${encodeURIComponent(
            anchorId,
          )}`,
        );
      }
      commit('SET_PAGETYPE', 'sensor-anchor');
      commit('SET_ANCHORID', anchorId);
    },
    goToSensorDisc({ commit, state }, discId) {
      if (!IS_SSR) {
        window.history.pushState(
          '',
          '',
          `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
            state.areaId,
          )}&discId=${encodeURIComponent(discId)}`,
        );
      }
      commit('SET_PAGETYPE', 'sensor-disc');
      commit('SET_DISCID', discId);
    },
    goToRoom({ commit, state }, roomId) {
      if (!IS_SSR) {
        window.history.pushState(
          '',
          '',
          `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
            state.areaId,
          )}&roomId=${encodeURIComponent(roomId)}`,
        );
      }
      commit('SET_PAGETYPE', 'room');
      commit('SET_ROOMID', roomId);
    },
    goToDocument({ commit, state }, { documentId, documentType }) {
      let url = `?siteId=${encodeURIComponent(state.siteId)}&areaId=${encodeURIComponent(
        state.areaId,
      )}`;

      if (state.roomId) {
        commit('SET_ROOMID', state.roomId);
        url += `&roomId=${state.roomId}`;
      }

      if (!IS_SSR) {
        window.history.pushState(
          '',
          '',
          `${url}&documentId=${documentId}&documentType=${documentType}`,
        );
      }
      commit('SET_DOCUMENTTYPE', documentType);
      commit('SET_DOCUMENTID', documentId);
      commit('SET_PAGETYPE', 'document');
    },
    goToUsermanagement({ commit }) {
      commit('SET_PAGETYPE', 'usermanagement');
    },
  },

  getters: {
    getProjectType: (state) => state.projectType,
    getPageType: (state) => state.pageType,
    getSiteId: (state) => state.siteId,
    getAreaId: (state) => state.areaId,
    getRoomId: (state) => state.roomId,
    getGatewayId: (state) => state.gatewayId,
    getAnchorId: (state) => state.anchorId,
    getDiscId: (state) => state.discId,
    getDocumentId: (state) => state.documentId,
    getDocumentType: (state) => state.documentType,
    getCurrentSiteDetails: (state) => state.currentSiteDetails,
    getIsArchive: (state) => state.isArchive,
    getIsTransferProject: (state) => state.isTransferProject,
    getIsAuditLog: (state) => state.isAuditLog,
    getIsReports: (state) => state.isReports,
  },
};

export default layout;
