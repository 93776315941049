<template>
  <div
    :class="{
      'teaser-icon-element-fi': true,
      'teaser-icon-element-fi--dark': dark,
      'teaser-icon-element-fi--xpe': isEditMode,
    }"
    data-test="teaser-icon-element"
  >
    <a
      v-if="!isEditMode && link.value"
      class="teaser-icon-element-fi__link"
      :href="link.value?.href"
      :target="link.value?.target"
      @click="onClick(link.value?.href, link.value?.target)"
    >
      <span class="teaser-icon-element-fi__icon">
        <div
          v-if="isNews && newsCounter > 0"
          class="teaser-icon-element-fi__bubble"
        >
          {{ newsCounter }}
        </div>
        <icon-fi
          v-if="iconName"
          class="teaser-icon-element-fi__icon-svg"
          :icon="iconName"
          size="3"
          data-test="teaser-icon-element-icon"
          :data-test-2="iconName"
        />
      </span>
      <!-- eslint-disable vue/no-v-html -->
      <span
        class="teaser-icon-element-fi__link-text"
        v-html="legalisedContent(link.value.text)"
      />
      <!-- eslint-enable vue/no-v-html -->
    </a>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="isEditMode"
      class="teaser-icon-element-fi__link"
    >
      <span class="teaser-icon-element-fi__icon">
        <span
          v-if="iconModified"
          class="teaser-icon-element-fi__icon-svg"
          v-html="iconModified.editable"
        />
      </span>
      <span
        class="teaser-icon-element-fi__link-text"
        v-html="link.editable"
      />
    </div>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script setup lang="ts">
import { IconFi } from 'atoms/index';
import axios from 'axios';
import useIcon from 'composables/icon';
import legalisedContent from 'utils/legalisedContent';
import { onMounted, ref } from 'vue';

import { useFischer } from '@/composables/fischerPlugin';
import type { LinkField } from '@/sitecoreFieldTypes';

defineOptions({
  name: 'TeaserIconElementFi',
});

const props = withDefaults(
  defineProps<{
    icon: object;
    link: LinkField;
    isNews?: boolean;
    dark?: boolean;
  }>(),
  {
    icon: () => ({}),
    link: () => ({}),
    isNews: false,
    dark: false,
  },
);

const { iconModified, iconName } = useIcon(props, true);
const newsCounter = ref<number>(0);
const fischer = useFischer();

const getNewsCount = async () => {
  if (fischer.user.email()) {
    let url = '/api/teaser/get/news';

    if (IS_SHOWROOM) {
      url = `/__mocks__/sitecoreApi${url}/mockedNewsCountData.json`;
    }

    try {
      const result = await axios.get(url, {
        params: {
          count: true,
          email: fischer.user.email(),
        },
      });
      if (result.data?.Data) {
        newsCounter.value = result.data.Data.TotalSearchResults;
      }
    } catch (error) {
      console.error(error);
    }
  }
};

onMounted(() => {
  if (props.isNews) {
    getNewsCount();
  }
});

const onClick = (link: string | undefined, target: string | undefined) => {
  if (!link) {
    return;
  }

  if (target === '_blank') {
    window.open(link);
  } else {
    window.location.href = link;
  }
};
</script>

<style lang="scss" scoped>
@import './teaser-icon-element-fi';
</style>

<style lang="scss">
@import './teaser-icon-element-fi-unscoped';
</style>
