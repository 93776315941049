<template>
  <div
    :class="{
      'service-navigation-item-fi': true,
      'service-navigation-item-fi--brand-background': brandBackground,
    }"
  >
    <a
      class="service-navigation-item-fi__link"
      :href="navigationElement.link ? navigationElement.link.url : undefined"
      :aria-label="navigationElement.title"
      :data-test-2="navigationElement.identifier"
      data-test="navigation"
      @click="(evt) => onServiceNavIconClicked(navigationElement, evt)"
    >
      <icon-fi
        :icon="getIcon(navigationElement)"
        :alt="navigationElement.title"
      />

      <header-cart-fi
        v-if="['retailConnectCart', 'shopCart'].includes(navigationElement.identifier)"
        class="service-navigation-item-fi__bubble"
        data-test="cart-count"
      />
      <div
        v-else-if="navigationElement.identifier === 'myAccount'"
        class="service-navigation-item-fi__bubble"
      >
        <div class="service-navigation-item-fi__bubble-icon-wrapper">
          <icon-fi
            class="service-navigation-item-fi__bubble-icon"
            icon="check"
          />
        </div>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import { IconFi } from 'atoms/index';
import { useCountrySelect } from 'composables/countrySelect';
import { useFischer } from 'composables/fischerPlugin';
import { computed } from 'vue';
import { useStore } from 'vuex';

import type { ServiceNavigationElement } from '@/sitecoreState';

import HeaderCartFi from '../header-cart-fi/HeaderCartFi.vue';
import iconMappings, { type IconMappingKeys } from './iconMappings';

defineOptions({
  name: 'ServiceNavigationItemFi',
});

const { isSingleLocale, getLocaleData, countrySelectUrl } = useCountrySelect();

const store = useStore();
const fischer = useFischer();

const props = defineProps<{
  navigationElement: ServiceNavigationElement;
  brandBackground?: boolean;
}>();

const emit = defineEmits<{
  'toggle-flyout-icon': [value: void];
  login: [value: void];
}>();

const isCountrySelectActive = computed<boolean>(() => store.getters['navigation/isCountrySelect']);

const isSelectCountry = computed<boolean>(
  () => props.navigationElement.identifier === 'selectCountry',
);

const getIcon = (navigationElement: ServiceNavigationElement) => {
  const key: IconMappingKeys = navigationElement.identifier;
  return iconMappings[key];
};

const onServiceNavIconClicked = async (
  navigationElement: ServiceNavigationElement,
  evt: MouseEvent,
) => {
  if (isSelectCountry.value) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    await getLocaleData();

    if (isSingleLocale.value) {
      const url = navigationElement?.link?.url;
      const targetUrl = countrySelectUrl(url);
      if (targetUrl) {
        window.location.href = targetUrl;
      }

      return;
    }

    store.commit('navigation/SHOWFLYOUT', !isCountrySelectActive.value);
    store.commit('navigation/IS_COUNTRY_SELECT', !isCountrySelectActive.value);
  }

  if (navigationElement.identifier === 'login') {
    if (!fischer.isLoginDialogEnabled()) {
      return;
    }
    if (evt) {
      evt.preventDefault?.();
      if (evt.detail === 1) {
        emit('login');
      }
    }
    return;
  }

  emit('toggle-flyout-icon');
};
</script>

<style scoped lang="scss">
@import './service-navigation-item-fi';
</style>
