<template>
  <component-wrapper v-if="!isAppView">
    <div
      v-if="!isEditMode"
      class="page-header-fi"
    >
      <header-fi
        :fields="fields"
        :logo-header="logoHeader"
        :logo-brand-area="logoBrandArea"
        :home-data="homeData"
        :navigation-data="navigationData"
        :service-navigation="serviceNavigation"
        :breadcrumb-data="breadcrumbData"
      />

      <chat-bot-bubble-fi v-if="chatBotEnabled" />
      <support-box-fi v-if="supportBoxEnabled" />
    </div>
    <div
      v-else
      class="page-header-fi--xpe"
    >
      <header-fi-xpe
        :fields="fields"
        :logo-header="logoHeader"
        :logo-brand-area="logoBrandArea"
        :breadcrumb-data="breadcrumbData"
      />
    </div>
  </component-wrapper>
</template>

<script setup lang="ts">
import ChatBotBubbleFi from 'components/chat-box-fi/chat-bot-bubble-fi/ChatBotBubbleFi.vue';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import HeaderFi from 'components/header-fi/HeaderFi.vue';
import { useHeaderPage } from 'components/header-fi/headerPage';
import SupportBoxFi from 'components/Support-box-fi/SupportBoxFi.vue';
import { useDataLayer } from 'composables/dataLayer';
import { useFischer } from 'composables/fischerPlugin';
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';

const HeaderFiXpe = defineAsyncComponent(
  () => import('components/header-fi/header-fi-xpe/HeaderFiXpe.vue'),
);

defineOptions({
  name: 'PageHeaderFi',
});

const props = withDefaults(
  defineProps<{
    fields: any;
    customData?: any;
  }>(),
  {
    fields: () => ({}),
    customData: () => null,
  },
);

useDataLayer();
const fischer = useFischer();
const store = useStore();
const { breadcrumbData, logoHeader, logoBrandArea, cData, homeData } = useHeaderPage(props);

const navigationData = computed(() => {
  return cData.value?.navigation?.home?.subElements || [];
});

const serviceNavigation = computed(() => {
  return cData.value?.serviceNavigation || [];
});

const chatBotEnabled = computed<boolean>(() => {
  return fischer.isChatbotEnabled() && Boolean(props.fields.chatBotEnabled?.value);
});

const supportBoxEnabled = computed<boolean>(() => {
  if (IS_SHOWROOM) {
    return true;
  }
  return fischer.isCatalogPage() && fischer.isSupportBoxEnabled();
});
const setServiceNavigationMarker = () => {
  serviceNavigation.value.forEach((serviceItem: any, navItemIdx: number) => {
    if (!serviceItem?.elements) {
      return;
    }
    serviceItem.elements.forEach((element: any, elementIdx: number) => {
      if (!element.link) {
        return;
      }
      if (element.link.url === fischer.uri()) {
        const navIndex = navigationData.value.length + 10 * navItemIdx + elementIdx;
        store.commit('navigation/NAVINDEX', navIndex);
      }
    });
  });
};
setServiceNavigationMarker();
</script>

<style lang="scss" scoped>
@import './page-header-fi';
</style>
