<template>
  <transition
    mode="in-out"
    appear
    :leave-active-class="animationOut"
    :enter-active-class="animationIn"
  >
    <div
      v-if="show"
      data-test="toast-notification-message"
      :class="[
        'toast-notification-item-fi',
        showCloseBtn ? 'toast-notification-item-fi--with-close' : null,
        toast.type ? `toast-notification-item-fi--${toast.type}` : null,
        toast.action?.label ? 'toast-notification-item-fi--with-action' : null,
        toast.fullWidth ? 'toast-notification-item-fi--full-width' : null,
      ]"
    >
      <icon-fi
        v-if="toast.icon"
        class="toast-notification-item-fi__icon"
        :icon="toast.icon"
      />

      <span
        v-if="toast.text"
        class="toast-notification-item-fi__content"
      >
        {{ toast.text }}
      </span>
      <richtext-fi
        v-else
        class="toast-notification-item-fi__content"
        :html-content="toast.message"
      />

      <div
        v-if="toast.action?.label"
        class="toast-notification-item-fi__action"
        @click="actionClicked"
      >
        {{ toast.action.label }}
      </div>

      <div
        v-if="showCloseBtn"
        class="toast-notification-item-fi__close"
      >
        <icon-fi
          icon="close"
          class="toast-notification-item-fi__close-icon"
          data-test="toast-notification-close"
          @click="closeToast"
        />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { IconFi, RichtextFi } from 'atoms/index';
import { useBreakpoints } from 'composables/breakpoint';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

defineOptions({
  name: 'ToastNotificationItemFi',
});

export interface ToastAction {
  label?: string;
  clicked: () => void;
  closeOnClick: boolean;
}

export interface ToastMessage {
  value: string;
}

export interface Toast {
  action?: ToastAction;
  type?: string;
  icon?: string;
  close?: boolean;
  delayTime?: number;
  delayLong?: boolean;
  message?: ToastMessage;
  name?: string;
  persist?: boolean;
  fullWidth?: boolean;
  text?: string;
}

const props = defineProps<{
  toast: Toast;
}>();

const store = useStore();
const { isSM } = useBreakpoints();

const delay = 3000;
const delayLong = 5000;

const show = ref(true);
const timeout = ref();
const removeTimeout = ref();

const timeoutDelay = computed(() => {
  if (props.toast.delayTime) {
    return props.toast.delayTime;
  }
  return props.toast.delayLong ? delayLong : delay;
});

const showCloseBtn = computed(() => {
  if (props.toast.persist && !props.toast.action?.label) {
    return true;
  }
  return props.toast.close || false;
});

const animationOut = computed(() => {
  if (!isSM.value) {
    return 'fadeOutToastMobile';
  }
  return 'fadeOutToast';
});

const animationIn = computed(() => {
  if (!isSM.value) {
    return 'fadeInToastMobile';
  }
  return 'fadeInToast';
});

onMounted(() => {
  if (!props.toast.persist) {
    timeout.value = setTimeout(() => {
      show.value = false;
      closeToast();
    }, timeoutDelay.value);
  }
});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
  clearTimeout(removeTimeout.value);
});

const closeToast = () => {
  show.value = false;
  clearTimeout(timeout.value);
  removeToast();
};

const removeToast = () => {
  removeTimeout.value = setTimeout(() => {
    store.dispatch('notifications/closeToastNotification', props.toast.name);
  }, 1000);
};

const actionClicked = () => {
  if (props.toast.action?.clicked) {
    props.toast.action.clicked();
  } else {
    console.info("missing 'actionClicked' callback function");
  }

  if (props.toast.action?.closeOnClick) {
    closeToast();
  }
};
</script>

<style lang="scss">
@import './toast-notification-item-fi';
</style>
