<template>
  <div class="mobile-navigation-sub-products-fi">
    <ul
      class="mobile-navigation-sub-products-fi__list mobile-navigation-sub-products-fi__list--services"
    >
      <li
        class="mobile-navigation-sub-products-fi__item mobile-navigation-sub-products-fi__item--group"
      >
        <a
          :href="groupData.url"
          :target="groupData.target ? groupData.target : null"
          class="mobile-navigation-sub-products-fi__item__wrapper"
        >
          <span class="mobile-navigation-sub-products-fi__item__label">
            {{ navigationLayerTitle(groupData) }}
          </span>

          <arrow-icon class="mobile-navigation-sub-products-fi__item__icon" />
        </a>
      </li>
      <li
        v-for="item in subElements"
        :id="`fi-navigation-${item.CategoryId}`"
        :key="item.CateogryId"
        class="mobile-navigation-sub-products-fi__item mobile-navigation-sub-products-fi__item--page"
      >
        <a
          :href="item.url"
          :target="item.target ? item.target : null"
          class="mobile-navigation-sub-products-fi__item__wrapper"
        >
          <span class="mobile-navigation-sub-products-fi__item__label">
            {{ item.label }}
          </span>

          <arrow-icon class="mobile-navigation-sub-products-fi__item__icon" />
        </a>
      </li>
    </ul>
    <ul class="mobile-navigation-sub-products-fi__list">
      <li
        v-for="(item, index) in getAllCategories"
        :id="`fi-navigation-${item.CategoryId}`"
        :ref="'productItem'"
        :key="item.CateogryId"
        :class="{
          'mobile-navigation-sub-products-fi__item': true,
          'mobile-navigation-sub-products-fi__item--last-category':
            index === getAllCategories.length - 1,
        }"
      >
        <a
          :href="groupData.url + item.Slug"
          :target="item.Target ? item.Target : null"
          class="mobile-navigation-sub-products-fi__item__wrapper"
        >
          <div class="mobile-navigation-sub-products-fi__item__image-wrapper">
            <cloud-image-fi
              :src="item.thumbnailUrl ? item.thumbnailUrl.Url : placeholder"
              :alt="item.thumbnailUrl ? item.thumbnailUrl.Alt : item.CategoryName"
              width="75"
              height="62"
              params="func=fit"
              class="mobile-navigation-sub-products-fi__item__image"
            />
          </div>
          <span class="mobile-navigation-sub-products-fi__item__label">
            {{ item.CategoryName }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ArrowIcon, CloudImageFi } from 'atoms';
import GETALLCATEGORIES from 'gql/getAllCategories.gql';
import navigationMixin from 'mixins/navigationMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'MobileNavigationSubProductsFi',
  components: {
    CloudImageFi,
    ArrowIcon,
  },
  mixins: [navigationMixin],
  data() {
    return {
      getAllCategories: [],
    };
  },
  computed: {
    ...mapGetters({
      groupData: 'navigation/getGroupData',
      subElements: 'navigation/getSubElements',
    }),
  },
  apollo: {
    getAllCategories: {
      query: GETALLCATEGORIES,
      variables() {
        return {
          baseSiteId: this.$fischer.baseSiteId(),
          language: this.$fischer.catalogLocale(),
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mobile-navigation-sub-products-fi';
</style>
