export interface VideoFields {
  videoMp4Link?: {
    value?: {
      href?: string;
    };
  };
  videoWebmLink?: {
    value?: {
      href?: string;
    };
  };
  videoYoutubeLink?: {
    value?: {
      url?: string;
    };
  };
}

export const useVideo = () => {
  const isVideo = (fields: VideoFields): boolean => {
    if (fields.videoMp4Link?.value?.href) {
      return true;
    }

    if (fields.videoWebmLink?.value?.href) {
      return true;
    }

    if (fields.videoYoutubeLink?.value?.url) {
      return true;
    }

    return false;
  };

  return { isVideo };
};
