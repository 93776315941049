import type { WindowDimensions } from 'composables/breakpoint';
import { useFischer } from 'composables/fischerPlugin';
import { throttle } from 'throttle-debounce';
import { computed, nextTick, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export const useHeader = (headerRef: any) => {
  const store = useStore();
  const fischer = useFischer();

  const jumpMarks = computed<any[]>(() => store.getters['navigation/getJumpMarks']);
  const windowDimensions = computed<WindowDimensions>(
    () => store.getters['layout/getWindowDimensions'],
  );

  let throttledOnResize: () => void = () => console.debug('throttle called before initialization');

  const updateHeaderHeight = async () => {
    if (IS_SSR) {
      return;
    }
    await nextTick();

    if (!headerRef?.value) {
      console.error('missing header ref to update header height');
      return;
    }

    store.dispatch('layout/setHeaderHeight', headerRef.value.offsetHeight);
  };

  watch(jumpMarks, updateHeaderHeight, { deep: true });
  watch(windowDimensions, () => throttledOnResize(), { deep: true });

  onMounted(() => {
    updateHeaderHeight();
    throttledOnResize = throttle(500, updateHeaderHeight);

    if (fischer.user.getAssistedService()?.user) {
      // wait for assisted-service banner
      setTimeout(updateHeaderHeight, 500);
    }
  });
};
