<template>
  <div class="navigation-subnavigation-fi">
    <navigation-products-fi v-if="isProducts" />
    <navigation-companies-fi v-else-if="isCompany" />
    <navigation-items-fi
      v-else
      :key="groupData.url"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NavigationCompaniesFi from './navigation-companies-fi/NavigationCompaniesFi';
import NavigationItemsFi from './navigation-items-fi/NavigationItemsFi';
import NavigationProductsFi from './navigation-products-fi/NavigationProductsFi';

export default {
  name: 'NavigationSubnavigationFi',
  components: {
    NavigationItemsFi,
    NavigationProductsFi,
    NavigationCompaniesFi,
  },
  computed: {
    // add subElements
    ...mapGetters({
      groupData: 'navigation/getGroupData',
      isProducts: 'navigation/isProducts',
      isCompany: 'navigation/isCompany',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import './navigation-subnavigation-fi';
</style>
