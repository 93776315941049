<template>
  <component
    :is="link ? 'a' : 'div'"
    v-if="item.fields"
    :class="{
      'header-media-text-item-fi': true,
      'header-media-text-item-fi--link': link,
    }"
    :href="link"
  >
    <transition
      enter-active-class="animated fadeIn fastest"
      leave-active-class="animated fadeOut fastest"
    >
      <div
        v-if="!videoActive && !fields?.showBrandArea?.value"
        :class="{
          'header-media-text-item-fi__content': true,
          'header-media-text-item-fi__content--dark': isDark,
          'header-media-text-item-fi__content--align-right': textRight,
        }"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="item.fields.headline?.value"
          class="header-media-text-item-fi__content__headline"
          v-html="item.fields.headline.value"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div
          v-if="item.fields.subheadline?.value"
          class="header-media-text-item-fi__content__subheadline"
        >
          {{ item.fields.subheadline.value }}
        </div>
        <span
          v-if="link"
          class="fi-text-size-df-30"
        >
          <arrow-icon class="header-media-text-item-fi__content__arrow" />
        </span>
      </div>
    </transition>

    <div
      v-if="!isVideo(item.fields)"
      class="header-media-text-item-fi__image"
    >
      <header-media-text-item-image-fi :item="item" />
    </div>
    <template v-else>
      <video-player-fi
        class="header-media-text-item-fi__video"
        :fields="item.fields"
        :show-hide-delay="1000"
        :reset-video="slideChanged"
        preview-params="func=crop&org_if_sml=0"
        @video="videoEvent"
      >
        <template #responsiveVideoSource>
          <source
            v-if="getLink('videoMp4LinkMobile').url"
            media="(max-width: 767px)"
            :src="getLink('videoMp4LinkMobile').url"
            type="video/mp4"
          />
          <source
            v-if="getLink('videoWebmLinkMobile').url"
            media="(max-width: 767px)"
            :src="getLink('videoWebmLinkMobile').url"
            type="video/webm"
          />
          <source
            v-if="getLink('videoMp4LinkTabletSmall').url"
            media="(max-width: 1023px)"
            :src="getLink('videoMp4LinkTabletSmall').url"
            type="video/mp4"
          />
          <source
            v-if="getLink('videoWebmLinkTabletSmall').url"
            media="(max-width: 1023px)"
            :src="getLink('videoWebmLinkTabletSmall').url"
            type="video/webm"
          />
          <source
            v-if="getLink('videoMp4LinkTablet').url"
            media="(max-width: 1439px)"
            :src="getLink('videoMp4LinkTablet').url"
            type="video/mp4"
          />
          <source
            v-if="getLink('videoWebmLinkTablet').url"
            media="(max-width: 1439px)"
            :src="getLink('videoWebmLinkTablet').url"
            type="video/webm"
          />
        </template>
        <template #previewImage>
          <header-media-text-item-image-fi :item="item" />
        </template>
      </video-player-fi>
    </template>
  </component>
</template>

<script setup lang="ts">
import { ArrowIcon } from 'atoms/index';
import type { HeaderSlide } from 'components/header-fi/header-types';
import VideoPlayerFi from 'components/video-player-fi/VideoPlayerFi.vue';
import { useLinkHelper } from 'composables/linkHelper';
import { useVideo } from 'composables/video';
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';

import type { CheckboxField, LinkFieldValue } from '@/sitecoreFieldTypes';

import HeaderMediaTextItemImageFi from './header-media-text-item-image-fi/HeaderMediaTextItemImageFi.vue';

defineOptions({
  name: 'HeaderMediaTextItemFi',
});

const { isVideo } = useVideo();
const { getLinkData } = useLinkHelper();
const store = useStore();

const fields = inject<{
  showBrandArea: CheckboxField;
}>('fields');

const emits = defineEmits<{
  'pause-slider': [value: boolean];
}>();

const props = withDefaults(
  defineProps<{
    item: HeaderSlide;
    slideChanged?: boolean;
  }>(),
  {
    slideChanged: false,
  },
);

const videoActive = ref<boolean>(false);

const link = computed<string | undefined>(() => {
  return props.item.fields?.link?.value?.href;
});

const isDark = computed<boolean>(() => {
  return Boolean(props.item.fields?.darkMode?.value);
});

const textRight = computed<boolean>(() => {
  return Boolean(props.item.fields?.textRight?.value);
});

const videoEvent = (event: string): void => {
  if (event === 'beforePlaying') {
    videoActive.value = true;
    store.dispatch('navigation/showBrandArea', false);
    emits('pause-slider', true);
  }

  if (event === 'hid') {
    videoActive.value = false;
    store.dispatch('navigation/showBrandArea', true);
    emits('pause-slider', false);
  }
};

const getLink = (name: string): LinkFieldValue => getLinkData(props.item.fields, name);
</script>

<style lang="scss">
@import './header-media-text-item-fi';
</style>
