<template>
  <div ref="player" />
</template>

<script>
import getYouTubeID from 'get-youtube-id';

// inspired by https://github.com/NomNes/vue3-youtube/blob/main/src/YouTube.vue
export default {
  name: 'YouTube',
  props: {
    src: {
      type: String,
      required: true,
    },
    vars: {
      type: Object,
      required: true,
    },
  },
  emits: ['api-change', 'ended', 'error', 'paused', 'playing', 'ready'],
  data() {
    return {
      player: null,
    };
  },
  computed: {
    id() {
      return getYouTubeID(this.src) || this.src;
    },
  },
  async mounted() {
    if (IS_SSR) {
      return;
    }
    await this.loadApi();
    this.initPlayer();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  },
  methods: {
    loadApi() {
      if (!window.YouTubeApiReady) {
        window.YouTubeApiReady = new Promise((resolve) => {
          window.onYouTubeIframeAPIReady = resolve;
          const tag = document.createElement('script');
          tag.src = 'https://www.youtube.com/iframe_api';
          document.head.appendChild(tag);
        });
      }
      return window.YouTubeApiReady;
    },
    initPlayer() {
      this.player = new window.YT.Player(this.$refs.player, {
        videoId: this.id,
        playerVars: this.vars,
        events: {
          onReady: (e) => {
            setTimeout(() => this.$emit('ready', e));
          },
          onStateChange: ({ data }) => {
            if (data === 0) {
              this.$emit('ended');
            } else if (data === 1) {
              this.$emit('playing');
            } else if (data === 2) {
              this.$emit('paused');
            }
          },
          onError: (e) => this.$emit('error', e),
          onApiChange: (e) => this.$emit('api-change', e),
        },
      });
    },
  },
};
</script>
