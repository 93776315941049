<template>
  <chat-box-fi
    v-if="chatBoxHasOpened"
    ref="chatBoxComponent"
    @close="closeChatBox"
    @minimize="minimizeChatBox"
  />
  <transition
    leave-active-class="animated fadeOut fastest"
    enter-active-class="animated fadeIn fastest"
  >
    <div
      v-if="!showChatBox"
      class="chat-bot-bubble-fi__bubble-chat-container"
    >
      <button
        class="chat-bot-bubble-fi__bubble-chat"
        @click="openChatBox"
      >
        <icon-fi
          color="white"
          icon="message-double"
        />

        <div class="chat-bot-bubble-fi__bubble-chat-text-wrapper">
          <div class="chat-bot-bubble-fi__bubble-chat-text">
            <span>{{
              minimized
                ? $dict.get('chat-Box-Module.chat-bubble-title-continue')
                : $dict.get('chat-Box-Module.chat-bubble-title')
            }}</span>
          </div>
        </div>
      </button>
    </div>
  </transition>
</template>
<script>
import { IconFi } from 'components/atoms/';
import { defineAsyncComponent } from 'vue';

export const conversationCookieName = 'chat-bot-conversation';
export const isOpenCookieName = 'chat-bot-open';
export const checkChatWasOpened = ($cookies) =>
  $cookies.get(isOpenCookieName) && $cookies.get(conversationCookieName);

const ChatBoxFi = defineAsyncComponent(() => import('components/chat-box-fi/ChatBoxFi'));
export default {
  name: 'ChatBotBubbleFi',
  components: {
    ChatBoxFi,
    IconFi,
  },
  data() {
    return {
      showChatBox: false,
      chatBoxHasOpened: false,
      minimized: false,
    };
  },
  mounted() {
    if (checkChatWasOpened(this.$cookies)) {
      this.openChatBox();
    }
  },
  methods: {
    openChatBox() {
      this.$refs?.chatBoxComponent?.openChatBox();
      this.chatBoxHasOpened = true;
      this.showChatBox = true;
    },
    closeChatBox() {
      this.showChatBox = false;
      this.minimized = false;
    },
    minimizeChatBox() {
      this.showChatBox = false;
      this.minimized = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'chat-bot-bubble-fi.scss';
</style>
