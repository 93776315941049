<template>
  <div
    :class="{
      'grid-container-fi': true,
      'fi-max-w-xl': true,
      'fi-container': container,
      'sm:fi-container sm:fi-max-w-xl': containerSm,
      'md:fi-container md:fi-max-w-xl': containerMd,
      'xl:fi-container xl:fi-max-w-xl': containerXl,
    }"
  >
    <div :class="combinedGridClass">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridContainerFi',
  props: {
    container: {
      type: Boolean,
      default: true,
    },
    containerSm: Boolean,
    containerMd: Boolean,
    containerXl: Boolean,
    gridBaseClass: {
      type: String,
      default: 'fi-grid-cols-12 fi-gap-small md:fi-gap-large',
    },
    gridClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    combinedGridClass() {
      return `fi-grid ${this.gridBaseClass} ${this.gridClass}`;
    },
  },
};
</script>

<style lang="scss">
@import './grid-container-fi';
</style>
