<template>
  <div
    v-if="
      (showBreadcrumb && breadcrumbCombined) ||
      backLink.url ||
      backLink.clickEvent ||
      forceBreadcrumb
    "
    class="breadcrumb-fi"
  >
    <grid-container-fi>
      <div class="breadcrumb-fi__container fi-col-span-full">
        <button-fi
          v-if="backLink.url && !backLink.clickEvent"
          class="breadcrumb-fi__back-link button-fi__tertiary button-fi--small"
          data-test="breadcrumb-back-btn"
          natural-events
          :href="backLink.url"
        >
          {{ backLink.text }}
        </button-fi>
        <button-fi
          v-if="backLink.clickEvent"
          :class="{
            'button-fi--small': backLink.text,
            'button-fi__icon': !backLink.text,
            'button-fi__tertiary': backLink.text,
          }"
          class="breadcrumb-fi__back-link"
          :href="backLink.url"
          @click.prevent.stop="backlinkClicked"
        >
          <template v-if="backLink.text">
            {{ backLink.text }}
          </template>
          <template v-else>
            <arrow-icon class="breadcrumb-fi__back-arrow backwards" />
          </template>
        </button-fi>

        <ul
          v-if="breadcrumbCombined.length > 1 || forceBreadcrumb"
          class="breadcrumb-fi__list"
        >
          <li
            v-for="(item, index) in breadcrumbCombined"
            :key="item.url"
            class="breadcrumb-fi__list__item"
          >
            <a
              v-if="index !== breadcrumbCombined.length - 1"
              :href="item.url"
              :title="item.title"
              class="breadcrumb-fi__list__item--link"
            >
              {{ item.title }}
            </a>
            <span
              v-else
              class="breadcrumb-fi__list__item--active"
            >
              {{ item.title }}
            </span>
          </li>
        </ul>

        <div
          v-if="parentBreadcrumbLink"
          class="breadcrumb-fi__mobile"
        >
          <a
            :href="parentBreadcrumbLink.url"
            :title="parentBreadcrumbLink.title"
            class="breadcrumb-fi__list__item--link breadcrumb-fi__mobile__link"
          >
            <icon-fi
              icon="chevron-left"
              class="breadcrumb-fi__mobile__link__icon"
            />
            {{ parentBreadcrumbLink.title }}
          </a>
        </div>
      </div>
    </grid-container-fi>

    <ld-json :json="jsonld" />
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import ButtonFi from 'atoms/button-fi/ButtonFi.vue';
import IconFi from 'atoms/icon-fi/IconFi.vue';
import LdJson from 'atoms/ld-json/LdJson.vue';
import ArrowIcon from 'components/atoms/arrow-icon/ArrowIcon.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import GETBREADCRUMBBYCATALOGID from 'gql/getBreadcrumbByCatalogId.gql';
import { computed, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';

import { useFischer } from '@/composables/fischerPlugin';

defineOptions({
  name: 'BreadcrumbFi',
});

const error = ref<boolean>(false);
const store = useStore();
const fischer = useFischer();

export interface BackLink {
  url?: string;
  clickEvent?: boolean;
  text?: string;
}

export interface BreadCrumbItem {
  url: string;
  title: string;
}

export interface BreadCrumbItemGraphql {
  label: string;
  url: string;
}

interface JsonLdListItem {
  '@type': 'ListItem';
  position: number;
  name: string;
  item?: string;
}
interface JsonLd {
  '@context': 'https://schema.org';
  '@type': 'BreadcrumbList';
  itemListElement: Array<JsonLdListItem>;
}
const props = withDefaults(
  defineProps<{
    backLink?: BackLink;
    breadcrumbData?: Array<BreadCrumbItem>;
    showBreadcrumb?: boolean;
    forceBreadcrumb?: boolean;
  }>(),
  {
    backLink: () => ({}),
    breadcrumbData: () => [],
    showBreadcrumb: true,
  },
);

const variables = ref({
  channel: fischer.brandId(),
  language: fischer.catalogLocale(),
  catalogId: fischer.catalogId(),
  isProduct: fischer.isVariantPage(),
});

const enabled = ref(false);
watchEffect(() => {
  if (
    !(fischer.isVariantPage() || fischer.isFamilyPage() || fischer.isCategoryPage()) ||
    !fischer.catalogId() ||
    fischer.httpStatus() === 404
  ) {
    // no operation
  } else {
    variables.value.catalogId = fischer.catalogId();
    variables.value.isProduct = fischer.isVariantPage();
    enabled.value = true;
  }
});

const { result: getBreadcrumbByCatalogId, onError } = useQuery(
  GETBREADCRUMBBYCATALOGID,
  variables,
  { enabled },
);

onError((err: any) => {
  console.error(err);
  error.value = true;
});

const productBreadcrumb = computed<Array<BreadCrumbItem>>(() => {
  if (!getBreadcrumbByCatalogId.value) {
    return [];
  }
  const productUrl = fischer.pages.products().url;
  return getBreadcrumbByCatalogId.value.getBreadcrumbByCatalogId.breadcrumbItems.map(
    (item: BreadCrumbItemGraphql) => {
      return { title: item.label, url: productUrl + item.url };
    },
  );
});

const breadcrumbCombined = computed<Array<BreadCrumbItem>>(() => {
  if (!getBreadcrumbByCatalogId.value) {
    return props.breadcrumbData;
  }

  return [...props.breadcrumbData, ...productBreadcrumb.value];
});

const parentBreadcrumbLink = computed<BreadCrumbItem | null>(() => {
  if (breadcrumbCombined.value?.length > 1) {
    return breadcrumbCombined.value[breadcrumbCombined.value.length - 2];
  }

  return null;
});

const jsonld = computed<string>(() => {
  const base = fischer.hostUrlBase();
  const itemListElements = breadcrumbCombined.value.map((el, idx) => {
    const listItem: JsonLdListItem = {
      '@type': 'ListItem',
      position: idx + 1,
      name: el.title,
    };

    if (idx < breadcrumbCombined.value.length - 1) {
      listItem.item = `${base}${el.url}`;
    }

    return listItem;
  });
  const jsonLdObject: JsonLd = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElements,
  };

  return JSON.stringify(jsonLdObject);
});

watch(productBreadcrumb, (newValue) => {
  store.commit('navigation/SET_PRODUCT_BREADCRUMB', newValue);
});
const backlinkClicked = () => {
  store.dispatch('navigation/backLinkClicked', true);
};
</script>

<style lang="scss" scoped>
@import './breadcrumb-fi';
</style>
