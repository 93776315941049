<template>
  <div v-if="hasTransition">
    <transition
      leave-active-class="animated mobileNavigationSecondOut fast"
      enter-active-class="animated mobileNavigationSecondIn fast"
    >
      <div
        v-if="showMobileSubLvl"
        ref="subLevel"
        class="mobile-navigation-sub-level-fi"
      >
        <div
          :class="{
            'mobile-navigation-sub-level-fi__header': true,
            'mobile-navigation-sub-level-fi__header--items':
              !isProducts && !isCompany && !isCountrySelect,
            'mobile-navigation-sub-level-fi__header--scrolled': scrolled,
          }"
        >
          <div class="mobile-navigation-sub-level-fi__header--actions">
            <div
              class="mobile-navigation-sub-level-fi__header__back"
              @click="backToFirstLvl"
            >
              <icon-fi
                icon="arrow-left"
                size="2"
              />
            </div>

            <div class="mobile-navigation-sub-level-fi__header__title">
              <template v-if="isCountrySelect">
                {{ $dict.get('global-Labels.Country-Selection.Mobile-Title') }}
              </template>
              <template v-else>
                {{ groupData.label }}
              </template>
            </div>

            <div
              class="mobile-navigation-sub-level-fi__header__close"
              @click="hideFlyout"
            >
              <icon-fi
                icon="close"
                size="2"
              />
            </div>
          </div>
        </div>
        <mobile-navigation-sub-products-fi v-if="isProducts" />
        <mobile-navigation-sub-companies-fi v-else-if="isCompany" />
        <mobile-navigation-sub-country-select-fi
          v-else-if="isCountrySelect"
          @show-country-selection="onShowCountrySelection"
        />
        <mobile-navigation-sub-items-fi
          v-else
          @scroll.passive="handleScroll"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { IconFi } from 'atoms';
import bodyScrollMixin from 'mixins/bodyScrollMixin';
import breakpointMixin from 'mixins/breakpointMixin';
import { mapGetters } from 'vuex';

import MobileNavigationSubCompaniesFi from './mobile-navigation-sub-companies-fi/MobileNavigationSubCompaniesFi';
import MobileNavigationSubCountrySelectFi from './mobile-navigation-sub-country-select-fi/MobileNavigationSubCountrySelectFi';
import MobileNavigationSubItemsFi from './mobile-navigation-sub-items-fi/MobileNavigationSubItemsFi';
import MobileNavigationSubProductsFi from './mobile-navigation-sub-products-fi/MobileNavigationSubProductsFi';

export default {
  name: 'MobileNavigationSubLevelFi',
  components: {
    IconFi,
    MobileNavigationSubItemsFi,
    MobileNavigationSubProductsFi,
    MobileNavigationSubCompaniesFi,
    MobileNavigationSubCountrySelectFi,
  },
  mixins: [bodyScrollMixin, breakpointMixin],
  data() {
    return {
      scrolled: false,
      hasTransition: true,
    };
  },
  computed: {
    ...mapGetters({
      windowDimensions: 'layout/getWindowDimensions',
      groupData: 'navigation/getGroupData',
      isProducts: 'navigation/isProducts',
      isCompany: 'navigation/isCompany',
      showMobileSubLvl: 'navigation/getMobileSubLvl',
      navIndex: 'navigation/getNavIndex',
      isCountrySelect: 'navigation/isCountrySelect',
      showFlyout: 'navigation/getFlyout',
    }),
  },
  watch: {
    windowDimensions: {
      deep: true,
      handler() {
        this.windowResized();
      },
    },
  },
  mounted() {
    this.hasTransition = !this.isSM;
  },
  methods: {
    windowResized() {
      if (this.isSM) {
        if (this.wasLG === this.isLG && !this.hasTransition) {
          return;
        }
        this.wasLG = this.isLG;
        this.hasTransition = false;
        this.$store.commit('navigation/SHOWMOBILEFIRSTLVL', false);
        this.$store.commit('navigation/SHOWMOBILESUBLVL', false);
        this.$store.commit('navigation/SHOWFLYOUT', this.isCountrySelect);
      } else {
        this.hasTransition = true;
      }
    },
    backToFirstLvl() {
      this.scrolled = false;
      this.$store.commit('navigation/SUBELEMENTS', []);
      this.$store.commit('navigation/GROUPDATA', {});
      this.$store.commit('navigation/NAVINDEX', -1);
      this.$store.commit('navigation/SHOWMOBILESUBLVL', false);
      this.$store.commit('navigation/SHOWMOBILEFIRSTLVL', true);
    },
    hideFlyout() {
      this.scrolled = false;
      this.bodyEnableScroll();
      this.$store.commit('navigation/SHOWBRANDAREA', true);
      this.$store.commit('navigation/SHOWMOBILESUBLVL', false);
      this.$store.commit('navigation/IS_COUNTRY_SELECT', false);
    },
    handleScroll(e) {
      if (e.target.scrollTop > 30) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    onShowCountrySelection() {
      this.hideFlyout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mobile-navigation-sub-level-fi';
</style>
