<template>
  <transition
    mode="in-out"
    appear
    leave-active-class="select-options-wrapper-fi--leaving"
    @after-leave="$emit('close')"
  >
    <div
      v-if="notClosing"
      class="select-options-wrapper-fi"
    >
      <div
        v-if="!isSM"
        class="select-options-wrapper-fi__header"
      >
        <div
          class="select-options-wrapper-fi__close"
          @click="close"
        >
          <icon-fi icon="arrow-left" />
          {{ name }}
        </div>
        <div
          v-if="hasSelection"
          class="select-options-wrapper-fi__deselect"
          @click="$emit('clear-selection')"
        >
          {{ $dict.get('facets.Clear-Text-Mobile') }}
          <icon-fi icon="close" />
        </div>
      </div>
      <div class="select-options-wrapper-fi__item-wrapper">
        <slot />
      </div>
      <slot name="footer" />
    </div>
  </transition>
</template>

<script>
import IconFi from 'atoms/icon-fi/IconFi';
import breakpointMixin from 'mixins/breakpointMixin';

export default {
  name: 'SelectOptionsWrapperFi',
  components: {
    IconFi,
  },
  mixins: [breakpointMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
    hasSelection: {
      type: Boolean,
    },
  },
  emits: ['close', 'clear-selection'],
  data() {
    return {
      notClosing: true,
    };
  },
  methods: {
    async close() {
      if (!this.isSM) {
        this.notClosing = false;
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './select-options-wrapper-fi';
</style>
