import { computed } from 'vue';
import { useStore } from 'vuex';

export const headerHeightClassesFunction = (
  isAssistedServiceActive: boolean,
  showHandlingFeeBanner: boolean,
  jumpMarks: any[],
) => ({
  'header-height': true,
  'header-height--assisted-service-active': isAssistedServiceActive,
  'header-height--handling-fee-limit-banner': showHandlingFeeBanner,
  'header-height--jump-mark-tabs-active': Boolean(jumpMarks.length),
});

export const useHeaderHeight = () => {
  const store = useStore();

  const isAssistedServiceActive = computed<boolean>(
    () => store.getters['navigation/isAssistedServiceActive'],
  );
  const showHandlingFeeBanner = computed<boolean>(
    () => store.getters['navigation/showHandlingFeeBanner'],
  );
  const jumpMarks = computed<any[]>(() => store.getters['navigation/getJumpMarks']);

  const headerHeightClasses = computed(() =>
    headerHeightClassesFunction(
      isAssistedServiceActive.value,
      showHandlingFeeBanner.value,
      jumpMarks.value,
    ),
  );

  return {
    isAssistedServiceActive,
    showHandlingFeeBanner,
    jumpMarks,
    headerHeightClasses,
  };
};
