<template>
  <picture
    v-if="imgSrc"
    class="ssr-cloud-image-fi"
  >
    <source
      v-for="breakpoint in breakpoints"
      :key="breakpoint.mediaWidth"
      :media="`(max-width: ${breakpoint.mediaWidth}px)`"
      :srcset="formatSrcSet(imgSrc, breakpoint.imageWidth, breakpoint.imageHeight)"
    />
    <img
      :loading="eager ? undefined : 'lazy'"
      :src="baseSrc"
      :srcset="formatSrcSet(imgSrc, unlimited, unlimitedHeight)"
      :alt="altPropOrField"
      :width="field?.value?.width || unlimited"
      :height="
        field?.value?.height ||
        unlimitedHeight ||
        (aspectRatio ? asFloat(unlimited) / aspectRatio : undefined)
      "
      :title="titlePropOrField"
    />
  </picture>
</template>

<script setup lang="ts">
import { useCloudImage } from 'composables/cloudImage';
import { computed } from 'vue';

import type { ImageField } from '@/sitecoreFieldTypes.d.ts';

export interface Breakpoint {
  mediaWidth: number;
  imageWidth: number;
  imageHeight?: number;
}

export type BreakPoints = Array<Breakpoint>;

defineOptions({
  name: 'SsrCloudImageFi',
});

const { appendParams, getProxySrc } = useCloudImage();

const props = withDefaults(
  defineProps<{
    field?: ImageField;
    src?: string;
    params?: string;
    trim?: boolean;
    alt?: string;
    title?: string;
    breakpoints: BreakPoints;
    unlimited?: string | number;
    unlimitedHeight?: string | number;
    width?: string | number;
    height?: string | number;
    aspectRatio?: number;
    eager?: boolean;
  }>(),
  {
    unlimited: '2000',
    field: undefined,
    src: undefined,
    params: undefined,
    alt: undefined,
    title: undefined,
    unlimitedHeight: undefined,
    width: undefined,
    height: undefined,
    aspectRatio: undefined,
  },
);

const imgSrc = computed<string>(() => {
  let src = props.src || props.field?.value?.src;
  if (!src) {
    return '';
  }

  // NOTE: attempting to do both at once ...[hw]=... does not work, since it won't match the replacement
  src = src.replace(/([?&])w=[0-9]+(?:&|$)/, (_: string, separator: string) => separator);
  src = src.replace(/([?&])h=[0-9]+(?:&|$)/, (_: string, separator: string) => separator);

  const params = [];
  if (props.trim) {
    params.push('trim=5');
  }

  if (props.params) {
    params.push(props.params);
  }

  return appendParams(`${getProxySrc(src)}`, params.join('&'));
});

const asFloat = (input: string | number): number =>
  typeof input === 'number' ? input : Number.parseFloat(input);

const formatWidthHeight = (
  url: Array<string>,
  factor: number,
  imageWidth: number | string,
  imageHeight: number | string | undefined,
): void => {
  const factoredImageWidth = factor * asFloat(imageWidth);
  url.push('&w=', String(factoredImageWidth));
  if (imageHeight) {
    url.push('&h=', String(factor * asFloat(imageHeight)));
  } else if (props.aspectRatio) {
    url.push('&h=', String(factoredImageWidth / props.aspectRatio));
  }
};

const baseSrc = computed<string>(() => {
  const url = [imgSrc.value];
  formatWidthHeight(url, 1, props.unlimited, props.unlimitedHeight);
  return url.join('');
});

const altPropOrField = computed<string | undefined>(() => props.alt || props.field?.value?.alt);

const titlePropOrField = computed<string | undefined>(
  () => props.title || props.field?.value?.title,
);

const formatSrcSet = (
  src: string,
  imageWidth: string | number,
  imageHeight: string | number | undefined,
): string => {
  const url: Array<string> = [];
  [1, 1.5, 2, 3, 4].forEach((factor) => {
    url.push(src);

    formatWidthHeight(url, factor, imageWidth, imageHeight);

    url.push(' ', String(factor), 'x');
    if (factor !== 4) {
      url.push(',');
    }
  });

  return url.join('');
};
</script>

<style scoped lang="scss">
@import './ssr-cloud-image-fi';
</style>
