export const getLinkData = (fields, fieldName) => {
  const linkObj = fields[fieldName];
  let resultObj = {};

  if (linkObj) {
    resultObj = {
      url: linkObj.value.href,
      text: linkObj.value.text,
      altTitle: linkObj.value.title,
      target: linkObj.value.target,
    };

    if (IS_SHOWROOM) {
      resultObj = linkObj.value;
    }
  }

  return resultObj;
};

export const getLinkLabel = (dict, fields, label, links, classes = []) => {
  const linksArray = [];

  links.forEach((link) => {
    const linkData = getLinkData(fields, `${link}`);
    const linkTitleParam = linkData.altTitle?.length ? `title="${linkData.altTitle}"` : '';
    const linkTargetParam = linkData.target?.length ? `target="${linkData.target}"` : '';
    const template = `<a
              class="${classes.join(' ')}"
              href="${linkData.url}"
              ${linkTitleParam}"
              ${linkTargetParam}"
            >${linkData.text}</a>`;

    linksArray.push(template);
  });

  return fields && fields[label] ? dict.replace(fields[label].value, linksArray) : '';
};

export const hasNecessaryLinkDetails = (link) => !!(link && link.url && link.text);

export const getMailToHref = (email: string | null | undefined) => {
  if (!email) {
    return undefined;
  }

  const formattedMail = email.trim().replace(/^mailto:/, '');
  return `mailto:${formattedMail}`;
};

const getSchemeHref = (scheme: string, nr: string | null | undefined) => {
  if (!nr) {
    return undefined;
  }

  const formattedNr = nr
    .trim()
    .replace(new RegExp('[. ()/-]', 'g'), '')
    .replace(/^[a-zA-Z]*:/, '');
  return `${scheme}:${formattedNr}`;
};

export const getTelHref = (tel: string | null | undefined) => getSchemeHref('tel', tel);

export const getFaxHref = (fax: string | null | undefined) => getSchemeHref('fax', fax);

export const useLinkHelper = () => {
  return {
    getLinkData,
    getLinkLabel,
    hasNecessaryLinkDetails,
    getMailToHref,
    getTelHref,
    getFaxHref,
  };
};
