<template>
  <div
    :class="{
      'country-selection-locale-fi': true,
      'country-selection-locale-fi--is-navigation': isNavigation,
    }"
  >
    <div
      v-if="currentLocaleData?.country"
      class="country-selection-locale-fi__current-wrapper"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-if="localeLabel"
        class="country-selection-locale-fi__current-selection"
        v-html="localeLabel"
      />
      <!-- eslint-enable vue/no-v-html -->
      <div class="country-selection-locale-fi__locale-wrapper">
        <span
          v-if="countrySelectionUrl"
          class="country-selection-locale-fi__current-country"
        >
          <country-flag-fi
            :country="currentLocaleData.country.value.toLowerCase()"
            class="country-selection-locale-fi__icon"
          />
          fischer {{ currentLocaleData.country.title }}
        </span>
        <locale-item-fi
          v-for="locale in currentLocaleData.locales"
          :key="locale.code"
          :locale="locale"
          :current-code="currentCode"
          :multi-languages="multiLanguages"
        />
      </div>
      <span
        v-if="localeButton && countrySelectionUrl"
        class="country-selection-locale-fi__current-change"
      >
        <link-fi
          :size="16"
          :href="countrySelectionUrl"
          class="country-selection-locale-fi__current-change-link"
          @click="showCountrySelection"
        >
          {{ localeButton }}
        </link-fi>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CountryFlagFi, LinkFi } from 'atoms/index';
import { useCountrySelect } from 'composables/countrySelect';
import { useDict } from 'composables/fischerPlugin';
import { computed, inject, onMounted, ref } from 'vue';

import type { LinkFieldValue } from '@/sitecoreFieldTypes';

import LocaleItemFi from './LocaleItemFi.vue';

const { countrySelectUrl, getLocaleData, currentLocaleData, localizations, currentLocale } =
  useCountrySelect();

const dict = useDict();

defineOptions({
  name: 'CountrySelectionLocaleFi',
});

interface ServiceNavigationI18N {
  link?: LinkFieldValue;
}

const getServiceNavigationI18n = inject<() => ServiceNavigationI18N>('getServiceNavigationI18n');

defineProps<{
  isNavigation: boolean;
}>();

const emit = defineEmits<{
  'show-country-selection': [value: void];
}>();

const serviceNavigationI18n = ref<null | ServiceNavigationI18N>(null);

const currentCode = computed<string>(() => currentLocale.value?.code || '');

const countrySelectionUrl = computed<string | null>(() => {
  const url = serviceNavigationI18n.value?.link?.url;
  return countrySelectUrl(url);
});

const localeLabel = computed(() => {
  return dict.get('global-Labels.Country-Selection.Current-Locale-Label');
});

const localeButton = computed(() => {
  return dict.get('global-Labels.Country-Selection.Current-Locale-Button');
});

const showCountrySelection = () => emit('show-country-selection');

if (!getServiceNavigationI18n) {
  throw new Error('missing injection');
}
serviceNavigationI18n.value = getServiceNavigationI18n();

const multiLanguages = computed<boolean>(() => localizations.value.length > 1);

onMounted(async () => {
  await getLocaleData();
  if (!currentLocaleData.value?.locales) {
    console.error('Did not receive locale data');
  }
});
</script>

<style lang="scss" scoped>
@import './country-selection-locale-fi.scss';
</style>
