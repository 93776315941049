<template>
  <div
    :class="{
      'select-fi': true,
      'select-fi--small': small,
      'select-fi--large': large,
      'select-fi--light': light,
      'select-fi--transparent': transparent,
      'select-fi--disabled': disabled,
      'select-fi--options-overlap': optionsOverlap,
      'select-fi--has-error': hasError,
      'select-fi--open': open,
      'select-fi--inline-open': mobileInline && !isSM && open,
    }"
    data-test="select"
    :data-test-2="dataTest2"
  >
    <div
      ref="button"
      class="select-fi__content"
    >
      <button-fi
        :class="{
          'select-fi__button': true,
          'select-fi__button--open': open,
          'select-fi__button--disabled': disabled,
        }"
        :transparent="transparent"
        :disabled="disabled"
        data-test="select-button"
        @click.prevent.stop="onToggleOpen"
      >
        <div class="select-fi__text">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="select-fi__name"
            data-test="select-name"
            v-html="name"
          />
          <!-- eslint-enable vue/no-v-html -->

          <div
            v-if="!isSM && !noMobileSelection"
            class="select-fi__selection"
          >
            <slot name="selectionMobile">
              <template v-for="element in elements">
                <template v-if="element.selected">
                  {{ element.name || element.text }}
                </template>
              </template>
            </slot>
          </div>
        </div>
        <div
          v-if="isSM || mobileChevron"
          class="select-fi__chevron"
        >
          <icon-fi
            v-if="!open || !chevronOpen"
            :rotate="open ? '180' : '0'"
            :icon="chevronClosed"
          />
          <icon-fi
            v-else
            :icon="chevronOpen"
          />
        </div>
        <div v-else>
          <icon-fi icon="arrow-right" />
        </div>
      </button-fi>
    </div>

    <div
      v-if="mobileInline && !isSM && isMounted"
      class="select-fi__popout__mobile-inline-wrapper"
    >
      <transition>
        <div
          v-if="internalOpen"
          class="select-fi__popout__mobile-inline"
        >
          <div class="select-fi__popout__mobile-inline-wrapper">
            <slot>
              <facet-list-fi
                :name="name"
                :elements="elements"
                :multiselect="multiselect"
                @change="onChange"
                @close="close"
                @clear-selection="$emit('clear-selection')"
              />
            </slot>
          </div>
        </div>
      </transition>
    </div>
    <teleport
      v-else-if="isMounted && internalOpen"
      to="body"
    >
      <div
        :style="wrapperStyle"
        :class="{
          'select-fi__popout-wrapper': true,
          'select-fi__popout-wrapper--open-top': openTop && isSM,
          ...wrapperClasses,
        }"
      >
        <div
          ref="popout"
          v-click-outside="closeIsSM"
          class="select-fi__popout"
        >
          <slot>
            <facet-list-fi
              :name="name"
              :elements="elements"
              :multiselect="multiselect"
              @change="onChange"
              @close="close"
              @clear-selection="$emit('clear-selection')"
            />
          </slot>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import ButtonFi from 'atoms/button-fi/ButtonFi.vue';
import FacetListFi from 'atoms/facet-list-fi/FacetListFi';
import IconFi from 'atoms/icon-fi/IconFi';
import vClickOutside from 'click-outside-vue3';
import componentWrapperMixin from 'components/component-wrapper/componentWrapperMixin';
import bodyScrollMixin from 'mixins/bodyScrollMixin';
import breakpointMixin from 'mixins/breakpointMixin';
import formsConditionalFieldMixin from 'mixins/formsConditionalFieldMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'SelectFi',
  components: {
    ButtonFi,
    FacetListFi,
    IconFi,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [breakpointMixin, componentWrapperMixin, formsConditionalFieldMixin, bodyScrollMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    elements: {
      type: Array,
      default: null,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    optionsOverlap: {
      type: Boolean,
    },
    mobileChevron: {
      type: Boolean,
    },
    noMobileSelection: {
      type: Boolean,
    },
    mobileInline: {
      type: Boolean,
    },
    forceOpenTop: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    chevronClosed: {
      type: String,
      default: 'chevron-down',
    },
    chevronOpen: {
      type: String,
      default: null,
    },
    dataTest2: {
      type: String,
      default: null,
    },
  },
  emits: ['change', 'clear-selection', 'toggle-open'],
  data() {
    return {
      wrapperStyle: {},
      internalOpen: false,
      openTop: false,
      popupPositioningEnabled: false,
      isMounted: false,
    };
  },
  computed: {
    ...mapGetters({
      windowScrollPos: 'layout/getWindowScrollPos',
      windowDimensions: 'layout/getWindowDimensions',
    }),
  },
  watch: {
    open: {
      immediate: true,
      async handler(value) {
        if (IS_SSR) {
          return;
        }
        if (!this.isSM) {
          if (this.mobileInline) {
            this.internalOpen = value;
            return;
          }
          if (value) {
            this.bodyDisableScroll();
            this.internalOpen = value;
            this.wrapperStyle = {
              'max-height': '0px',
            };
            await this.applyStyles();
            if (this.$refs.popout) {
              const { height } = this.$refs.popout.getBoundingClientRect();

              this.wrapperStyle = {
                'max-height': `${height}px`,
              };
              await this.animationDuration();
            }
            this.wrapperStyle = {};
          } else {
            this.bodyEnableScroll();
            if (this.$refs.popout) {
              const { height } = this.$refs.popout.getBoundingClientRect();
              this.wrapperStyle = {
                'max-height': `${height}px`,
              };
              await this.applyStyles();
              this.wrapperStyle = {
                'max-height': '0px',
              };
              await this.animationDuration();
            }
            this.internalOpen = value;
          }
        } else {
          this.bodyEnableScroll();
          const transitionMaxHeight = 250;
          const shadowMarginPx = 10;
          if (value) {
            this.internalOpen = true;
            this.openTop = false;
            this.wrapperStyle = {
              'max-height': '0px',
            };
            this.positionPopup();
            await this.applyStyles();
            if (this.$refs.popout) {
              const { height } = this.$refs.popout.getBoundingClientRect();
              const { bottom: elementBottom } = this.$el.getBoundingClientRect();
              const position = elementBottom + window.document.documentElement.scrollTop;
              const { height: bodyHeight } = window.document.body.getBoundingClientRect();
              const doesFitBelow = bodyHeight > elementBottom + position;
              const targetHeight = height + shadowMarginPx;

              if (targetHeight > transitionMaxHeight) {
                this.wrapperStyle = {
                  transition: 'none',
                  opacity: 0,
                  'max-height': `${targetHeight - transitionMaxHeight}px`,
                };
                this.positionPopup();
                await this.applyStyles();
              }
              this.wrapperStyle = {
                'max-height': `${targetHeight}px`,
              };
              this.positionPopup();
              if (!doesFitBelow || this.forceOpenTop) {
                this.openTop = true;
              }
              await this.animationDuration();
            }
          } else {
            if (this.$refs.popout) {
              const { height } = this.$refs.popout.getBoundingClientRect();
              const targetHeight = height + shadowMarginPx;
              this.wrapperStyle['max-height'] = `${targetHeight}px`;
              await this.applyStyles();

              if (targetHeight > transitionMaxHeight) {
                this.wrapperStyle['max-height'] = `${targetHeight - transitionMaxHeight}px`;
                this.wrapperStyle.opacity = 0;
              } else {
                this.wrapperStyle['max-height'] = '0px';
              }
              await this.animationDuration();
            }
            this.internalOpen = false;
            await this.$nextTick();
          }
          this.wrapperStyle = {};
          this.positionPopup();
        }

        this.popupPositioningEnabled = value;
      },
    },
    windowScrollPos: {
      deep: true,
      handler() {
        if (this.popupPositioningEnabled) {
          this.positionPopup();
        }
      },
    },
    windowDimensions: {
      deep: true,
      handler() {
        if (this.popupPositioningEnabled) {
          this.positionPopup();
        }
      },
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    positionPopup() {
      if (IS_SSR || !this.isSM || !this.open || !this.$refs || !this.$refs.button) {
        return;
      }

      const buttonBounding = this.$refs.button.getBoundingClientRect();
      const shadowWidth = 2 * 10;
      const top = this.openTop ? buttonBounding.top : buttonBounding.top + buttonBounding.height;
      this.wrapperStyle.top = `${top}px`;
      this.wrapperStyle.left = `${buttonBounding.left}px`;
      this.wrapperStyle.width = `${buttonBounding.width + shadowWidth}px`;
    },
    animationDuration() {
      return new Promise((resolve) => {
        setTimeout(resolve, 300);
      });
    },
    applyStyles() {
      // unfortunately a 'nextTick' will not suffice to apply the styles with animations
      return new Promise((resolve) => {
        setTimeout(resolve, 10);
      });
    },
    closeIsSM() {
      if (this.isSM) {
        this.close();
      }
    },
    close() {
      if (this.open) {
        this.onToggleOpen();
      }
    },
    onToggleOpen() {
      this.$emit('toggle-open');
    },
    onChange(...params) {
      this.$emit('change', ...params);
      if (!this.multiselect) {
        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './select-fi';
</style>
