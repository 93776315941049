import nodeCrypto from 'crypto';
/*
 * For some reason this crashes on ssr, so we have to do it ourself
 * import { sha256 } from 'crypto-hash';
 */

const encoder = new TextEncoder();
const digestMessageBrowser = async (message: string) => {
  const msgUint8 = encoder.encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

const digestMessageNode = async (message: string) => {
  const hash = nodeCrypto.createHash('sha256');
  hash.write(message);
  return hash.digest('hex');
};

const sha256 = IS_SSR ? digestMessageNode : digestMessageBrowser;

export default sha256;
