<template>
  <div class="header-checkout-fi">
    <div class="header-checkout-fi__top">
      <assisted-service-module-fi />
      <handling-fee-limit-banner-fi :handling-fee-limit="handlingFeeLimit" />
      <div class="header-checkout-fi__top__container">
        <div class="header-checkout-fi__top__left">
          <a
            v-if="logoHeader && homeData"
            :href="homeData.url"
            :title="homeData.title"
            class="header-checkout-fi__top__logo"
          >
            <img
              :src="logoHeader.src"
              :alt="logoHeader.alt"
              :title="logoHeader.title"
              class="fischer-brand-logo"
            />
          </a>
        </div>

        <div class="header-checkout-fi__top__right">
          <a
            class="header-checkout-fi__top__back-link"
            :href="$fischer.pages.cart().url"
          >
            {{ $dict.get('online-Shop.Global-Labels.Back-To-Cart') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AssistedServiceModuleFi from 'components/assisted-service-module-fi/AssistedServiceModuleFi.vue';
import HandlingFeeLimitBannerFi from 'components/handling-fee-limit-banner-fi/HandlingFeeLimitBannerFi.vue';
// Unfortunately the banner has to be initialized here, because
// the store is not reactive in SSR mode (requesting the banner
// from the HandlingFeeBannderFi module does not propagate here)
import { useHandlingFeeSetup } from 'components/handling-fee-limit-banner-fi/handlingFeeSetup';

import type { ImageFieldValue, LinkFieldValue } from '@/sitecoreFieldTypes';

defineOptions({
  name: 'HeaderCheckoutFi',
});

withDefaults(
  defineProps<{
    logoHeader: undefined | ImageFieldValue;
    homeData: undefined | LinkFieldValue;
  }>(),
  {
    logoHeader: undefined,
    homeData: undefined,
  },
);

const { handlingFeeLimit } = useHandlingFeeSetup();
</script>

<style lang="scss">
@import './header-checkout-fi';
</style>
