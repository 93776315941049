import { computed } from 'vue';

import type { ImageField } from '@/sitecoreFieldTypes';

export interface IconProps {
  icon: ImageField;
}

const srcDefaultImageName = 'default_image';
const srcDefaultFull = '/sitecore/shell/Themes/Standard/Images/WebEdit/default_image.svg';
const srcIconBase = '/sitecore/shell/-/media/fischer-website/data/media/icons/';
const defaultIconName = 'check';

export default (props: IconProps, noDefaultIcon: boolean = false) => {
  const iconSrc = computed<string | undefined>(() => props.icon?.value?.src);

  const iconName = computed<string | undefined>(() => {
    const value = iconSrc.value;
    if (!value) {
      return noDefaultIcon ? undefined : defaultIconName;
    }
    return value
      .toLocaleLowerCase()
      .split('/')
      .pop()
      ?.replace(new RegExp('\\b\\.(svg|ashx)\\b', 'gi'), '')
      .split('?')[0];
  });

  const iconModified = computed<ImageField | undefined>(() => {
    if (
      props.icon?.value &&
      iconName.value !== srcDefaultImageName &&
      iconSrc.value?.includes(srcDefaultImageName)
    ) {
      return {
        value: {
          ...props.icon.value,
          src: `${srcIconBase}${iconName.value}.ashx?iar=0`,
        },
        editable: props.icon.editable?.replace(
          srcDefaultFull,
          `${srcIconBase}${iconName.value}.ashx`,
        ),
      };
    }
    return props.icon;
  });

  return {
    iconName,
    iconModified,
    iconSrc,
  };
};
