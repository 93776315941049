import Emitter from 'tiny-emitter';
// @ts-ignore
const emitter = !IS_SSR && new Emitter();

export default IS_SSR
  ? {
      // NOTE: best NOT to use this in SSR since it will have to be cleaned up or cause memory-leaks
      $on: () => null,
      $once: () => null,
      $off: () => null,
      $emit: () => null,
    }
  : {
      $on: (...args: any[]) => emitter.on(...args),
      $once: (...args: any[]) => emitter.once(...args),
      $off: (...args: any[]) => emitter.off(...args),
      $emit: (...args: any[]) => emitter.emit(...args),
    };
